import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Icon,
  Image,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import {useEffect} from "react";
import {NavLink as ReactRouterLink, useNavigate, useParams} from "react-router-dom";
import {Step} from "../../components/step/step";
import {useStep} from "../../components/step/use-step";
import OnboardingImage1 from "../../images/onboarding-images-1@2x.png";
import OnboardingImage2 from "../../images/onboarding-images-2@2x.png";
import OnboardingImage3 from "../../images/onboarding-images-3@2x.png";
import OnboardingImage4 from "../../images/onboarding-images-4@2x.png";
import {FiArrowRight, FiCheckCircle} from "react-icons/fi";

type Step = {
  title: string;
  description: string;
  image: any;
  body: {
    header: string;
    body: string;
    bulletHeader?: string;
    bullets: string[];
    cta: string;
    extra?: string;
    nextLink: string;
  };
};

const steps: Step[] = [
  {
    title: "Step 1",
    description: "Understand our deal engine",
    image: OnboardingImage1,
    body: {
      header: "Understand our deal engine",
      body: "Welcome to Kumo! Kumo monitors the markets so that you don’t have to. \n Our proprietary deal engine analyzes thousands of listings every day, and algorithmically cleans the data so that you can spend less time sourcing.",
      bulletHeader: "With Kumo, you get:",
      bullets: [
        "100,000+ deals, with hundreds of new deals added weekly",
        "100+ brokerage sites and marketplaces monitored daily, with algorithmic matching of duplicate listings",
        "Fresh data with listing status & details updated every 24 hours",
      ],
      cta: "Next: Analyze deals",
      nextLink: "/onboarding/2",
    },
  },
  {
    title: "Step 2",
    description: "Analyze deals",
    image: OnboardingImage2,
    body: {
      header: "Analyze deals",
      body: "Kumo pulls all the relevant data on a deal from multiple sources, so that you can properly analyze each deal:",
      bullets: [
        "Save deals for easy browsing",
        "Leave notes or comments on each deal",
        "View the deal’s listings on the web",
        "Track changes to deals (coming soon!)",
      ],
      cta: "Next: Manage your deal flow",
      nextLink: "/onboarding/3",
    },
  },
  {
    title: "Step 3",
    description: "Manage your deal flow",
    image: OnboardingImage3,
    body: {
      header: "Manage your deal flow",
      body: "Kumo helps you manage your deal flow and collaborate with your team, with an easy way to view:",
      bullets: [
        "Your saved deals",
        "Comments you’ve left on deals",
        "Your team’s saved deals and comments (coming soon!)",
      ],
      cta: "Next: Automate your sourcing",
      nextLink: "/onboarding/4",
    },
  },
  {
    title: "Step 4",
    description: "Automate your sourcing",
    image: OnboardingImage4,
    body: {
      header: "Automate your sourcing",
      body: "Kumo is like the MBA intern you wish you had, except instead of working 40 hours/week, it never stops working:",
      bullets: [
        "Build and save a search, and we’ll scan all our sources for relevant deals",
        "Set custom notification schedules for each search: daily, weekly, monthly",
        "Get notified as soon as your search finds targets across our 100+ marketplaces and brokerage sites",
      ],
      extra: "And that's the end of your Kumo Onboarding!",
      cta: "Your turn: Search for deals",
      nextLink: "/search",
    },
  },
];

export const OnboardingPage = () => {
  const { stepId } = useParams();
  const navigate = useNavigate();
  const [currentStep, { setStep }] = useStep({
    maxStep: steps.length,
    initialStep: Number(stepId) - 1,
  });

  useEffect(() => {
    setStep(Number(stepId) - 1);
  }, [stepId]);

  return (
    <Container py={{ base: "4", md: "8" }}>
      <Stack>
        <Stack spacing="0" direction={"row"}>
          {steps.map((step, id) => {
            return (
              <Step
                key={id}
                cursor="pointer"
                onClick={() => navigate("/onboarding/" + (id + 1))}
                title={step.title}
                description={step.description}
                isActive={currentStep === id}
                isCompleted={currentStep > id}
                isFirstStep={id === 0}
                isLastStep={steps.length === id + 1}
              />
            );
          })}
        </Stack>
        <Box>
          <OnboardingBody currentStepId={Number(stepId)} />
        </Box>
      </Stack>
    </Container>
  );
};

const OnboardingBody = ({ currentStepId }: { currentStepId: number }) => {
  const step = steps[currentStepId - 1];
  const isDesktop = useBreakpointValue({ base: false, xl: true });
  return (
    <Flex maxW="100vw" mx="auto" direction={isDesktop ? "row" : "column"}>
      <Image
        flex="1"
        src={step.image}
        loading="eager"
        maxW={isDesktop ? "900px" : "100%"}
        fallback={<Box w="906px" height="600px" backgroundColor="gray.100" />}
      />
      <Stack flex="3" spacing={6} mt={5}>
        <Heading size="md">{step.body.header}</Heading>
        <Text>{step.body.body}</Text>
        {step.body.bulletHeader && <Text fontWeight={"bold"}>{step.body.bulletHeader}</Text>}
        <List spacing={3}>
          {step.body.bullets.map((b, idx) => {
            return (
              <ListItem key={idx}>
                <ListIcon as={FiCheckCircle} color="green.500" />
                {b}
              </ListItem>
            );
          })}
        </List>
        {step.body.extra && <Text>{step.body.extra}</Text>}
        <Button as={ReactRouterLink} colorScheme="blue" to={step.body.nextLink}>
          {step.body.cta} <Icon as={FiArrowRight} />
        </Button>
      </Stack>
    </Flex>
  );
};
