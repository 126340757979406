import {useContext, useEffect, useState} from "react";
import {HeadingContext} from "../heading-provider";
import {useNavigate, useParams} from "react-router-dom";
import {Box, Flex, Tab, TabIndicator, TabList, Tabs} from "@chakra-ui/react";
import {StandardTextHeading} from "../layouts/BodyHeading";
import {DealPipelineKanban} from "../deal-pipeline-kanban/page";
import {MyDeals} from "../my-deals/page";


export const DealPipeline = () => {
  const { setHeadingContent } = useContext(HeadingContext);
  const navigate = useNavigate();
  useEffect(() => {
    setHeadingContent(<DealPipelineHeadingView pipelineView={pipelineView} handlePipelineViewChange={handlePipelineViewChange}/>);
  }, [setHeadingContent]);

  const { indexType } = useParams();

  const [pipelineView, setPipelineView] = useState(indexType || localStorage.getItem("pipeline-view") || 'deal_pipeline')

  const handlePipelineViewChange = (value) => {
    setPipelineView(value);
    localStorage.setItem("pipeline-view", value);
  }



  useEffect(() => {
    if (pipelineView !== indexType || !indexType) {
      navigate("/deal-pipeline/" + pipelineView)
    }

  }, [pipelineView]);


  return (
    <>
      {pipelineView === "kanban" && <DealPipelineKanban />}
      {pipelineView === "deal_pipeline" && <MyDeals />}
    </>
  )
}

const pipelinePageViews = [
  {
    name: "deal_pipeline",
    label: "List"
  },
  {
    name: "kanban",
    label: "Kanban"
  }
]

const DealPipelineHeadingView = ({ pipelineView, handlePipelineViewChange }) => {
  const currentTabIndex = pipelinePageViews.findIndex(view => view.name === pipelineView);
  const [tabIndex, setTabIndex] = useState(currentTabIndex)

  const handleTabChange = (index) => {
    handlePipelineViewChange(pipelinePageViews[index].name);
    setTabIndex(index);
  }

  return (
    <>
      <Box width={"100%"}>
        <Flex align="center" justify="space-between"> {/* Flex container to make children inline */}
          <StandardTextHeading text={'Deal Pipeline'} />
          <Tabs
            size={'md'}
            ml={"20px"}
            variant="indicator"
            onChange={handleTabChange}
            index={tabIndex}  // Set the active tab index
            position={'relative'}
          >
            <TabList>
              {pipelinePageViews.map((view, index) => (
                <Tab key={index}>{view.label}</Tab>
              ))}
            </TabList>
            <TabIndicator />
          </Tabs>
        </Flex>
      </Box>
    </>
  )
}