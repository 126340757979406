import React, {useState} from "react";

export const HeadingContext = React.createContext();

export const HeadingProvider = ({ children }) => {
  const [headingContent, setHeadingContent] = useState(null);

  return (
    <HeadingContext.Provider value={{ headingContent, setHeadingContent }}>
      {children}
    </HeadingContext.Provider>
  );
};
