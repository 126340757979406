import {Box, Button, Divider, SkeletonText, Text, useDisclosure, VStack} from '@chakra-ui/react';
import {useGetDealFeedQuery} from "../../../services/dealFeedsApi";
import * as React from "react";
import {useEffect, useState} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {LoadingStack, ShowRelease} from "../show-release/page";
import {useQuery} from "@apollo/client";
import {CURRENT_USER_QUERY} from "../../../graphql/current-user";
import {SignupForFreeModal} from "../../../components/upgrade-ctas/signup-for-free-modal";
import {useNavigate} from "react-router-dom";

export const ShowFeed = ({ id }) => {
  const { data, error, isLoading } = useGetDealFeedQuery(id);
  const { loading: userLoading, data: userData } = useQuery(CURRENT_USER_QUERY);
  const subscriptionLevel = userData?.currentUser?.account?.subscriptionLevel || "Public"

  if (isLoading) return <LoadingFeed />;
  if (error) return <Text>Error loading deal feed.</Text>;

  return (
    <Box>
      <Box mb={"6"}>
        {data?.data?.attributes?.name && <Text fontSize={"2xl"} fontWeight={"semibold"}>{data?.data?.attributes?.name}</Text>}
        {data?.data?.attributes?.description && <Text size={"md"} color={"fg.muted"}>{data?.data?.attributes?.description}</Text>}
      </Box>
      {!userLoading && subscriptionLevel === "Public" && <NonLoggedInFeed data={data} />}
      {!userLoading && subscriptionLevel !== "Public" && <LoggedInFeed data={data} />}
    </Box>
  );
};

const LoggedInFeed = ({ data }) => {
  const initialLoadCount = 3;
  const perScrollLoadCount = 3;

  const [activeReleaseId, setActiveReleaseId] = useState(null);
  const [displayCount, setDisplayCount] = useState(initialLoadCount); // Initial number of items to display
  const releases = data?.included?.slice(0, displayCount) || []; // Display only a subset of items
  // Set initial activeReleaseId when releases are available
  useEffect(() => {
    if (releases.length > 0) {
      setActiveReleaseId(releases[0].id);
    }
  }, [releases]);


  const loadMore = () => {
    setDisplayCount(prevCount => prevCount + perScrollLoadCount);
  };


  // Conditional rendering
  if (releases.length > 0) {
    return (
      <Box pb={"12"}>
        {/*<HorizontalScrollBox>*/}
        {/*  {releases.map((release) => (*/}
        {/*    <Button size={'sm'} mr={"3"}*/}
        {/*            variant={activeReleaseId === release.id ? "outline" : "ghost"}*/}
        {/*            key={release.id} onClick={() => setActiveReleaseId(release.id)}>*/}
        {/*      {release.attributes.title}*/}
        {/*    </Button>*/}
        {/*  ))}*/}
        {/*</HorizontalScrollBox>*/}
        {/*{activeReleaseId && <ShowRelease key={activeReleaseId} id={activeReleaseId} cachedRelease={release} />}*/}
        <InfiniteScroll
          dataLength={displayCount}
          next={loadMore}
          hasMore={displayCount < (data?.included?.length)}
          loader={<h4>Scroll Loading...</h4>}
          endMessage={<EndOfFeedButton actionType={"link"} />}
          scrollableTarget={"mainContainer"} // Added scrollable target
        >
          {releases.map((release, index) => (
            <Box key={release.id} pb={"12"}>
              <ShowRelease id={release.id} cachedRelease={release} />
            </Box>
          ))}
        </InfiniteScroll>
      </Box>
    );
  } else {
    return (
      <Box>
        <Text>No releases to display.</Text>
      </Box>
    );
  }
};

const NonLoggedInFeed = ({ data }) => {
  const initialLoadCount = 4;
  const perScrollLoadCount = 4;

  const [displayCount, setDisplayCount] = useState(initialLoadCount); // Initial number of items to display

  const releases = data?.included?.slice(0, displayCount) || []; // Display only a subset of items

  const loadMore = () => {
    setDisplayCount(prevCount => prevCount + perScrollLoadCount);
  };

  return(
    <Box pb={"12"}>
      <InfiniteScroll
        dataLength={releases.length}
        next={loadMore}
        hasMore={releases.length < (data?.included?.length)}
        loader={<h4>Scroll Loading...</h4>}
        endMessage={<EndOfFeedButton actionType={"signup"} />}
        scrollableTarget={"mainContainer"} // Added scrollable target
      >
        {releases.map((release, index) => (
          <Box key={release.id} pb={"12"}>
            <ShowRelease id={release.id} cachedRelease={release} />
          </Box>
        ))}
      </InfiniteScroll>
    </Box>
  )
}

const EndOfFeedButton = ({actionType}) => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure()

  const action = () => {
    if (actionType === "link") {
      navigate(`/search`);
    } else if (actionType === "signup") {
      onOpen()
    }
  }

  return (
    <>
      <Button py={"4"} px={"4"}
              onClick={action} variant={"solid"} width={"full"}>To explore older deals, try our search functionality</Button>
      {isOpen && <SignupForFreeModal isOpen={isOpen} onClose={onClose} />}
    </>

  )
}

export const LoadingFeed = () => {
  const LoadingRelease = () => {
    return (
      <Box mb="6">
        <VStack spacing={"3"} align={"left"}>
          <SkeletonText noOfLines={1} spacing="4" skeletonHeight="4" width={"200px"} />
          <Divider />
          <LoadingStack loadingCount={5} />
        </VStack>

      </Box>
    )
  }

  const releaseSkeletons = Array.from({ length: 5 }, (_, index) => (
    <LoadingRelease key={index} />
  ));

  return (
    <Box>
      <Box mb={"6"}>
        <SkeletonText mb="2" noOfLines={1} spacing="4" skeletonHeight="7" width={"300px"} />
        <SkeletonText noOfLines={1} spacing="4" skeletonHeight="5" width={"600px"} />
      </Box>
      {releaseSkeletons}
    </Box>
  )
}