import {Box} from '@chakra-ui/react'
import {useSelector} from "react-redux";
import {selectActiveSearch} from "../../../store/local-search";
import {TimeSeriesGraph} from "../../analytics/shared/time-series";
import {DistributionBarChart} from "../../analytics/shared/distribution-bar-chart";
import {AskingPriceBuckets, DefaultBarChartBuckets} from "../../analytics/shared/common-visualization-elements";
import {BoxPlot} from "../../analytics/shared/box-plot";
import * as React from "react";

export const Analytics = () => {
  const  { filters } = useSelector(selectActiveSearch)
  const analyticsTypes = ["Overview", "By Industry", "By Location"]

  return (
    <>
      <Box>
        <Box>
          <TimeSeriesGraph filters={filters}  title={"New Deals on Kumo (Listed & Delisted)"} graphType={"# of Deals"} timePeriod={"1 year"}/>
        </Box>

        <Box>
          <TimeSeriesGraph filters={filters} title={"Number of Days on Market"}   graphType={"Avg. # of Days to Sale"} timePeriod={"1 year"}/>
        </Box>

        <Box>
          <DistributionBarChart title={"Number of Deals by Asking Price Band"}   filters={filters} xmetric={"asking_price"} ymetric={"num_deals"} buckets={DefaultBarChartBuckets} />
        </Box>

        <Box>
          <DistributionBarChart title={"Number of Deals by Annual Revenue"} filters={filters} xmetric={"annual_revenue"} ymetric={"num_deals"} buckets={DefaultBarChartBuckets} />
        </Box>

        <Box>
          <DistributionBarChart title={"Number of Deals by Profit"} filters={filters} xmetric={"annual_ebitda_or_sde"} ymetric={"num_deals"} buckets={DefaultBarChartBuckets} titleToolTip={"EBITDA or SDE, based on what is present on each deal"}/>
        </Box>

        <Box>
          <DistributionBarChart title={"Number of Deals by Price-To-Profit Multiple"} filters={filters} xmetric={"ebitda_multiple"} ymetric={"num_deals"} buckets={DefaultBarChartBuckets} titleToolTip={"EBITDA or SDE, based on what is present on each deal"}/>
        </Box>

        <Box>
          <BoxPlot title={"Price-to-Profit Multiple by Price Band"}  filters={filters} ymetric={"asking_price"} buckets={AskingPriceBuckets} titleToolTip={"EBITDA or SDE, based on what is present on each deal"}/>
        </Box>
      </Box>
    </>
  )

}