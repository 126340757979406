import {Avatar, Box, Flex, HStack, Icon, Skeleton, Td, Text, Tooltip, Tr, Wrap} from "@chakra-ui/react";
import {FiMapPin} from "react-icons/fi";
import numbro from "numbro";
import {BookmarkButton} from "../../../components/deal/bookmark-button";
import {buildHeaderAttributes} from "./table-headers";
import {kumoDayJs} from "../../../utils/dayjs";
import {AiOutlineEye} from "react-icons/ai";
import {forwardRef} from "react";
import {NotesButton} from "../../../components/deal/notes-button";
import {HideDealButton} from "../../../components/deal/hide-deal-button";

import {MdChangeHistory} from "react-icons/md";

export const TableRow = ({ deal, handleDealClick }) => {
  const dealAttributes = deal.attributes;

  const conditionalOpacity = (dealAttributes.is_hidden || dealAttributes.is_read) ? {opacity: 1} : {opacity: 1}

  const CustomTooltip = forwardRef(({ children, ...rest }, ref) => (
    <Box ref={ref} {...rest} position={"relative"}>
      <Avatar
        cursor="pointer"
        height={"40px"}
        width={"40px"}
        src={dealAttributes.thumbnail_photo_url}
        name={dealAttributes.title || ""}
        borderRadius="md"
      />
      <Box
        position="absolute"
        top="0"
        right="0"
        bottom="0"
        left="0"
        borderRadius="md"
        bg="whiteAlpha.600" // Black transparent overlay
      />
      <Icon
        as={AiOutlineEye}
        position="absolute"
        top="0"
        right="0"
        fontSize={"xl"}
        bottom="0"
        left="0"
        m="auto"
        color="gray.500" // White color for the icon
        sx={{ opacity: 1 }}
      />
      {children}
    </Box>
  ))
  CustomTooltip.displayName = 'CustomTooltip';

  const {
    asking_price,
    annual_ebitda,
    annual_sde,
  } = dealAttributes || {};

  let multiple;
  if ((annual_ebitda || annual_sde) && asking_price) {
    const value = asking_price / (annual_ebitda || annual_sde)
    if (value >= 0 && value <= 100) {
      const multiple_string = numbro(asking_price / (annual_ebitda || annual_sde)).format({
        average: true,
        totalLength: 3,
      });
      multiple = `${multiple_string}x`;
    } else {
      multiple = "N/A";
    }
  } else {
    multiple = "N/A";
  }

  const hasListingHistoryEvents = deal?.attributes?.total_qual_event_count > 0 || deal?.attributes?.total_quant_event_count > 0;

  return (
    <Tr key={deal.id}
        id={`deal-${deal.id}`}
        background={dealAttributes.first_read_at ? "blue.50" : "bg.surface"}
    >
      <Td
        sx={conditionalOpacity}
      >
        <Box
          maxWidth={
            {base: ['450px', 'max(450px, 40vw)'], "xl": ['400px', 'max(400px, 30vw)'], "2xl": ['300px', 'max(300px, 30vw)']}
          }
          isTruncated
          onClick={e => {
            handleDealClick(deal.id)
          }}
          cursor={'pointer'}
          py={{xl: "6px", "2xl": "0px"}}
        >
          <HStack spacing="2"
                  align={"top"}
          >
            <Box pr={"0"} display={
              {base: "block", "2xl": "block"}
            }>
              {dealAttributes.first_read_at ? (
                  <Tooltip label={`Viewed ${kumoDayJs(dealAttributes.first_read_at).fromNow()}`} placement={"bottom"} strategy={"fixed"}>
                    <Box position="relative">
                      <CustomTooltip />
                    </Box>
                  </Tooltip>
                ) :
                <Avatar
                  cursor="pointer"
                  height={"40px"}
                  width={"40px"}
                  src={dealAttributes.thumbnail_photo_url}
                  name={dealAttributes.title || ""}
                  borderRadius="md" // Rounded square shape
                />
              }
            </Box>

            <Box>
              <Box pb={{xl: "6px", "2xl": "3px"}}>
                <Text fontWeight="medium" isTruncated>
                  {dealAttributes.title}
                </Text>
              </Box>
              <HStack spacing={4}>
                {dealAttributes.location && dealAttributes.location.length > 0 ? (
                  <Wrap>
                    <HStack spacing={"1"}>
                      <Icon as={FiMapPin} color="gray.500" />
                      <Text fontSize="xs" color="fg.muted">
                        {dealAttributes.location}
                      </Text>
                    </HStack>
                  </Wrap>
                ) : (
                  <Wrap>
                    <HStack spacing={"1"}>
                      <Icon as={FiMapPin} color="gray.500" />
                      <Text fontSize="xs" color="fg.muted">
                        No location available
                      </Text>
                    </HStack>
                  </Wrap>
                )}

                {hasListingHistoryEvents && (
                  <Tooltip label={'A listing on this deal was updated or changed'}>
                    <Box color="gray.500" borderBottom={"1px dashed"} >
                      <HStack spacing={"1"}>
                        <Icon as={MdChangeHistory} boxSize="1.25em" />
                        <Text fontSize="xs" color="fg.muted">History</Text>
                      </HStack>
                    </Box>
                  </Tooltip>
                )}
              </HStack>

            </Box>
          </HStack>
          <Box mt={"2"}>
            <Text fontSize={"sm"} color="fg.muted" isTruncated noOfLines={1} whiteSpace="normal">
              {dealAttributes.business_summary}
            </Text>
          </Box>
        </Box>

      </Td>
      {
        ['asking_price_to_display', 'annual_revenue_to_display', 'ebitda_or_sde_to_display'].map((attr, index) => (
          <Td key={index}
              sx={conditionalOpacity}
              display={{base: "table-cell", "2xl": "table-cell"}}
          >
            {dealAttributes[attr] ? (
              <Text>
                ${numbro(dealAttributes[attr]).format( {
                average: true,
                totalLength: 2,
              }).toUpperCase()}
              </Text>
            ) : (
              <Tooltip label={'When this # is unavailable in the listing, KUMO uses an estimated number to sort'}>
                <Text color="fg.muted" textDecoration="dotted">
                  N/A
                </Text>
              </Tooltip>
            )}
          </Td>
        ))
      }
      <Td
        sx={conditionalOpacity}
        display={{base: "none", "2xl": "table-cell"}}
      >
        {multiple === 'N/A' ? (
          <Tooltip label={'This # is irrelevant or unable to be calculated'}>
            <Text color="fg.muted" textDecoration="dotted">
              N/A
            </Text>
          </Tooltip>
        ) : (
          <Text>
            {multiple}
          </Text>
        )}
      </Td>
      <Td
        sx={conditionalOpacity}
      >
        <Text fontSize="xs" color="muted">
          {dealAttributes.added_to_kumo_at ? `${kumoDayJs(dealAttributes.added_to_kumo_at).fromNow()}` : "--"}
        </Text>
      </Td>
      <Td>
        <Flex justifyContent="center">
          <HStack spacing="1">
            <BookmarkButton deal={deal}/>
            <NotesButton deal={deal}/>
            <HideDealButton deal={deal}/>
          </HStack>
        </Flex>
      </Td>
    </Tr>
  );
};

const DealAdditionalText = ({ dealAttributes }) => {
  if (dealAttributes.is_hidden) {
    return (
      <Text fontSize="xs" color="muted" fontStyle="italic">
        You hid this deal {kumoDayJs(dealAttributes.hidden_at).fromNow()}
      </Text>
    );
  // } else if (dealAttributes.first_read_at) {
  //   return (
  //     <Text fontSize="xs" color="muted" fontStyle="italic">
  //       Viewed {kumoDayJs(dealAttributes.first_read_at).fromNow()}
  //     </Text>
  //   );
  } else {
    return null;
  }

}

export const LoadingTableRow = () => {
  const headers = buildHeaderAttributes();
  return (
    <Tr>
      <Td colSpan={headers.length}>
        <Skeleton height={"40px"} />
      </Td>
    </Tr>
  );
};