import { Container } from "@chakra-ui/react";
import * as React from "react";
import { ResetPasswordConfirmationForm } from "./reset-password-confirmation-form";

export const ForgotPasswordConfirmationPage = () => {
  return (
    <Container maxW="md" py={{ base: "12", md: "24" }}>
      <ResetPasswordConfirmationForm
        px={{ base: "4", md: "8" }}
        py={{ base: "12", md: "48" }}
        width="full"
        maxW="md"
      />
    </Container>
  );
};
