import * as React from "react";
import {useContext, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {MyDealsBody} from "./my-deals-body";
import {Box, Center, Spinner,} from "@chakra-ui/react";
import {StandardTextHeading} from "../layouts/BodyHeading";
import {HeadingContext} from "../heading-provider";
import {Helmet} from "react-helmet";

export const MyDeals = () => {
  const { indexType } = useParams();
  const navigate = useNavigate();

  const IndexTypes = {
    "user_history": "History",
    "user_favorites": "Favorites",
    "deal_pipeline": "Deal Pipeline"
  };


  const { setHeadingContent } = useContext(HeadingContext);
  useEffect(() => {
    if (indexType !== 'deal_pipeline' && Object.keys(IndexTypes).includes(indexType)) {
      setHeadingContent(<StandardTextHeading text={`${IndexTypes[indexType] || 'My Deals'}`} />);
    }
  }, [setHeadingContent, indexType]);

  useEffect(() => {
    if (!indexType) {
      navigate("/my-deals/" + "user_history");
    }
  }, [indexType]);

  if (!indexType || !Object.keys(IndexTypes).includes(indexType)) {
    return (
      <Center w="100%" h="150px">
        <Spinner />
      </Center>
    );
  }

  return (
    <>
      <Helmet title={`KUMO | ${IndexTypes[indexType]}`} />
      <Box id="top-of-page" mb={"20"}>
        <MyDealsBody key={indexType} indexType={indexType}  />
      </Box>
    </>
  );
};
