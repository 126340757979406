import * as React from "react";
import {useEffect, useRef} from "react";
import {Box, Button, Flex, FormControl, FormLabel, Input, Stack} from "@chakra-ui/react";
import {RadioButtonGroupForm} from "../../../components/search-filters-form/RadioButtonGroupForm";
import {NavLink} from "react-router-dom";
import {FiExternalLink} from "react-icons/fi";

export const SearchSettingsForm = ({ localSearch, updateLocalSearch }) => {
  const includeWeeklyDigestOptions =     [
    { value: "true", label: "Yes" },
    { value: "false", label: "No" },
  ]

  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.focus();
  }, [localSearch.attributes.title]);

  return (
    <Flex>
      <Box width="100%" overflowY="auto" px={'3px'}>
        <Stack spacing="10">
          <FormControl id="title">
            <FormLabel mb={"10px"}>Search Name</FormLabel>
            <Input
              id="medium"
              size="md"
              ref={inputRef}
              value={localSearch.attributes.title}
              onChange={(e) => {
                updateLocalSearch("title", e.target.value)
              }}
              maxLength={70}
            />
          </FormControl>

          <FormControl id="weeklyDigest">
            <Stack spacing={5}>
              <FormLabel>Include in Notification Emails</FormLabel>
              <Box px={"2px"}>
                <RadioButtonGroupForm
                  options={includeWeeklyDigestOptions}
                  value={localSearch.attributes.include_in_weekly_digest}
                  onChange={val => updateLocalSearch("include_in_weekly_digest", val)}
                />
              </Box>
              <Box>
                <Button
                  as={NavLink}
                  to="/settings/notifications"
                  variant={"link"}
                  size={"xs"}
                  target="_blank"
                  rightIcon={<FiExternalLink />}
                >
                  Adjust your global email notification settings
                </Button>
              </Box>

            </Stack>
          </FormControl>
        </Stack>
      </Box>
    </Flex>
  )
}