import {useDispatch, useSelector} from "react-redux";
import {selectActiveSearch} from "../../../store/local-search";
import {useGetDealsQuery} from "../../../services/dealsApi";
import {Text} from "@chakra-ui/react";
import numbro from "numbro";


export const ResultsCounter = () => {
  const  { filters, sort, page } = useSelector(selectActiveSearch)
  const dispatch = useDispatch()
  let queryHook;
  let queryOptions;

  queryHook = useGetDealsQuery;
  queryOptions = {
    filters: filters,
    page: page,
    sortField: sort.sortField,
    sortOrder: sort.sortOrder,
  };

  const { data, isLoading, isFetching, isError } = queryHook(queryOptions);
  const meta = data?.meta;

  if (isLoading || isFetching) return (
    <Text ml={"5px"}>
      (Loading...)
    </Text>
  )
  const formattedCount = meta?.total_count ? numbro(meta.total_count).format({ thousandSeparated: true }) : "...";


  return(
    <Text ml={"5px"}>
      ({formattedCount})
    </Text>
  )
}