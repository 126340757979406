export enum AuthenticationState {
  DuplicateUser = "DuplicateUser",
  GenericError = "GenericError",
  InvalidUserAndPassword = "InvalidUserAndPassword",
}

export type AuthErrorMessage = {
  state: AuthenticationState;
  message: string;
  link?: string;
};

/**
 * Validates the authentication responses to descriptive error codes
 * @param response String
 * @returns AuthenticationState
 */
export const authenticationErrorMessageGenerator = (response: string): AuthErrorMessage => {
  if (response.includes("UniqueViolation")) {
    return {
      state: AuthenticationState.DuplicateUser,
      message: "An account already exists with this email. Please",
      link: "/signin",
    };
  } else if (response.includes("Invalid Email or password.")) {
    return {
      state: AuthenticationState.InvalidUserAndPassword,
      message: "Invalid email or password. Please try again or",
      link: "/reset-password",
    };
  }
  return {
    state: AuthenticationState.GenericError,
    message:
      "Something went wrong. If this persists, please contact our team at support@withkumo.com",
  };
};
