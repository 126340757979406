import React from "react";
import {Navigate, Outlet} from "react-router-dom";
import {AuthContext} from "../../auth/auth-context";
import {useQuery} from "@apollo/client";
import {CurrentUser} from "../../graphql/__generated__/CurrentUser";
import {CURRENT_USER_QUERY} from "../../graphql/current-user";

interface Props {
  redirectPath?: string;
  currentUser?: any;
  children?: any;
}

export const ProtectedRoute = ({ children, redirectPath = "/signin" }: Props) => {
  const { authenticated } = React.useContext(AuthContext);
  const { data, loading } = useQuery<CurrentUser>(CURRENT_USER_QUERY);

  const currentUser = data?.currentUser;
  if (!authenticated && navigator.userAgent !== "ReactSnap") {
    return <Navigate to={redirectPath} replace />;
  }

  if (!currentUser && !loading) {
    if (!data?.currentUser) {
      return <Navigate to={redirectPath} replace />;
    }
  }

  return children ? children : <Outlet />;
};
