import axios from "axios";

export const signinUser = async ({ email, password }: { email: string; password: string }) => {
  const resp = await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/users/login`, {
    user: {
      email,
      password,
    },
  });
  const authToken = resp.headers.authorization;
  localStorage.setItem("with-kumo-token", authToken);
  return resp.data;
};
