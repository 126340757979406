import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_BASE_URL}api/deal_analytics`,
  // prepareHeaders is used to configure the header of every request and gives access to getState which we use to include the token from the store
  prepareHeaders: (
    headers
    // { getState }
  ) => {
    const token = localStorage.getItem("with-kumo-token");
    if (token) {
      headers.set("authorization", token);
    }
    return headers;
  },
});

// Analytics types:
// time_series_based_on_added_at, histogram, box_plot, scatter_plot_by_industry_or_location, time_series_snapshot

export const dealAnalyticsApi = createApi({
  reducerPath: "dealAnalyticsApi",
  baseQuery: baseQuery,
  tagTypes: ["DealAnalytics"],
  endpoints: (builder) => ({
    getTimeSeriesBasedOnAddedAt: builder.query({
      query: ({ filters, unpublished_after }) => ({
        url: `/time_series_based_on_added_at`,
        method: 'POST',
        body: {
          filters: filters,
          unpublished_after: unpublished_after
        }
      }),
    }),
    getHistogram: builder.query({
      query: ({ filters, added_at_time_range, asking_price_range, buckets }) => ({
        url: `/histogram`,
        method: 'POST',
        body: {
          filters: filters,
          asking_price_range: asking_price_range,
          buckets: buckets,
          added_at_time_range: added_at_time_range
        }
      }),
    }),
    getBoxPlot: builder.query({
      query: ({ filters, added_at_time_range, buckets }) => ({
        url: `/box_plot`,
        method: 'POST',
        body: {
          filters: filters,
          buckets: buckets,
          added_at_time_range: added_at_time_range
        }
      })
    }),
    getScatterPlotByIndustryOrLocation: builder.query({
      query: ({ filters, time_range, group_by }) => ({
        url: `/scatter_plot_by_industry_or_location`,
        method: 'POST',
        body: {
          filters: filters,
          group_by: group_by,
          time_range: time_range

        }
      })
    }),
    getTimeSeriesSnapshot: builder.query({
      query: ({ filters, time_range }) => ({
        url: `/time_series_snapshot`,
        method: 'POST',
        body: {
          filters: filters,
          time_range: time_range
        }
      })
    })
  }),
});

export const {
  useGetTimeSeriesBasedOnAddedAtQuery,
  useGetHistogramQuery,
  useGetBoxPlotQuery,
  useGetScatterPlotByIndustryOrLocationQuery,
  useGetTimeSeriesSnapshotQuery
} = dealAnalyticsApi;
