import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  Input,
  Spinner,
  Stack,
  Text,
  UseRadioGroupProps,
} from "@chakra-ui/react";
import {RadioCard, RadioCardGroup} from "./radio-card-group";
import {useMutation, useQuery} from "@apollo/client";
import React from "react";
import {NOTIFICATION_SETTINGS_BY_USER_QUERY} from "../../graphql/notification-settings-by-user";
import {EDIT_USER_NOTIFICATION_SETTINGS} from "../../graphql/mutations/edit-user-notification-settings";
import {notificationSettingsByUser} from "../../graphql/__generated__/notificationSettingsByUser";
import {
  EditUserNotificationSettings,
  EditUserNotificationSettingsVariables,
} from "../../graphql/mutations/__generated__/editUserNotificationSettings";
import {CURRENT_USER_QUERY} from "../../graphql/current-user";
import {CurrentUser} from "../../graphql/__generated__/CurrentUser";
import {StripeCheckoutButton} from "../../components/stripe-checkout-button";
import {Banner} from "../../components/banner";

export const NotificationsTab = () => {
  const [state, setState] = React.useState<EditUserNotificationSettingsVariables>(
    {} as EditUserNotificationSettingsVariables
  );
  const { data: currentUserData } = useQuery<CurrentUser>(CURRENT_USER_QUERY);
  const isActive = ["Pro", "Ultimate"].includes(currentUserData?.currentUser?.account?.subscriptionLevel || "Public");

  const [editUserNotificationSettingsAction] = useMutation<EditUserNotificationSettings>(
    EDIT_USER_NOTIFICATION_SETTINGS,
    {
      refetchQueries: ["notificationSettingsByUser"],
    }
  );

  let alertEmailComponent;
  let submitComponent;
  const { loading, data } = useQuery<notificationSettingsByUser>(
    NOTIFICATION_SETTINGS_BY_USER_QUERY
  );
  const handleChange = (value: string, field: keyof EditUserNotificationSettingsVariables) => {
    const newValue = Object.assign({}, state);
    newValue[field] = value;
    setState(Object.assign({}, newValue) as EditUserNotificationSettingsVariables);
  };
  if (loading) {
    alertEmailComponent = <Spinner />;
    submitComponent = <></>;
  }
  if (data) {
    if (data.notificationSettingsByUser) {
      //We load the current state as the data object initially
      if (state.notificationEmail == undefined) {
        setState(
          Object.assign(
            {},
            data.notificationSettingsByUser
          ) as EditUserNotificationSettingsVariables
        );
      }

      const dataChanged = () => {
        const emailChanged =
          state.notificationEmail &&
          state.notificationEmail != data.notificationSettingsByUser.notificationEmail;
        const frequencyChanged =
          state.activityEmailFrequency &&
          state.activityEmailFrequency != data.notificationSettingsByUser.activityEmailFrequency;
        return frequencyChanged || emailChanged;
      };

      const handleCancel = () => {
        setState(
          Object.assign(
            {},
            data.notificationSettingsByUser
          ) as EditUserNotificationSettingsVariables
        );
      };

      const handleSubmit = async () => {
        try {
          await editUserNotificationSettingsAction({
            variables: state,
          });
        } catch (e) {
          console.error("Something happened: ", e);
        }
      };

      alertEmailComponent = (
        <>
          <NotificationEmailInput
            value={state.notificationEmail}
            valueKey={"notificationEmail"}
            onChange={handleChange}
          />
        </>
      );

      submitComponent = (
        <>
          {dataChanged() ? (
            <Button variant="primary" onClick={() => handleSubmit()}>
              Save Changes
            </Button>
          ) : (
            ""
          )}
          {dataChanged() ? (
            <Button variant="default" onClick={() => handleCancel()}>
              Cancel Changes
            </Button>
          ) : (
            ""
          )}
        </>
      );
    }
  }

  return (
    <Box>
      <Stack spacing="5">
        {!isActive && (
          <Banner>
            <>
              <Text fontWeight="medium">
                Get weekly or daily email notifications from your saved searches.
              </Text>
              <StripeCheckoutButton tracking="upgrade_pro_notifications_click" textColor={"white"}>
                Upgrade to Pro
              </StripeCheckoutButton>
            </>
          </Banner>
        )}
        <Stack
          spacing="4"
          direction={{ base: "column", lg: "row" }}
          justify="space-between">
          <Box opacity={isActive ? "1" : "0.7"}>
            <Text fontSize="lg" fontWeight="medium">
              Email Notifications
            </Text>
            <Text color="muted" fontSize="sm">
              How often you want to receive emails
            </Text>
          </Box>
          <Box width={{base: "100%", large: "70%"}}  px={0} py={0} opacity={isActive ? "1" : "0.7"}>
            <FrequencyComponent state={state} handleChange={handleChange} isDisabled={!isActive} />
          </Box>
        </Stack>
        <Divider />
        <Stack
          spacing="4"
          direction={{ base: "column", lg: "row" }}
          justify="space-between">
          <Box>
            <Text fontSize="lg" fontWeight="medium">
              Alert Email
            </Text>
            <Text color="muted" fontSize="sm">
              Where your email notifications are sent to
            </Text>
          </Box>
          <Box width={{base: "100%", large: "70%"}} px={0} py={0} mr={0}>
            <FormControl width={"100%"}>{alertEmailComponent}</FormControl>
          </Box>
        </Stack>
        <Divider />
        <Flex direction="row-reverse">{submitComponent}</Flex>
      </Stack>
    </Box>
  );
};

interface Option {
  label: string;
  value: string;
}

interface FrequencyPickerProps extends UseRadioGroupProps {
  options: Option[];
  valueKey: string;
  onChange: any;
}

const RadioGroupInput = (props: FrequencyPickerProps) => {
  const { options, onChange, valueKey } = props;

  return (
    <RadioCardGroup
      value={props.value as "string | undefined"}
      spacing="3"
      justifyContent={"flex-end"}
      onChange={(newValue) => {
        onChange(newValue, valueKey);
      }}>
      {options.map((option) => (
        <RadioCard key={option.label} value={option.value}>
          <Text color="emphasized" fontWeight="medium" fontSize="sm">
            {option.label}
          </Text>
        </RadioCard>
      ))}
    </RadioCardGroup>
  );
};

type NotficationEmailInput = {
  value: string;
  valueKey: string;
  onChange: any;
};

const NotificationEmailInput = (props: NotficationEmailInput) => {
  const { onChange, valueKey, value } = props;

  return (
    <Input
      id="medium"
      size="md"
      value={value}
      placeholder="Type your email"
      onChange={(e) => {
        onChange(e.target.value as string, valueKey);
      }}
      data-peer
    />
  );
};

const FrequencyComponent = ({
  state,
  handleChange,
  isDisabled,
}: {
  state: any;
  handleChange: (value: string, field: keyof EditUserNotificationSettingsVariables) => void;
  isDisabled: boolean;
}) => {
  return (
    <>
      <RadioGroupInput
        value={state.activityEmailFrequency}
        valueKey={"activityEmailFrequency"}
        onChange={(value: string, field: any) => {
          if (isDisabled) {
            return;
          }
          handleChange(value, field);
        }}
        options={["Weekly", "Daily", "Never"].map((optionText) => {
          return { label: optionText, value: optionText };
        })}
      />
    </>
  );
};
