import {Tag, TagCloseButton, TagLabel, TagLeftIcon} from "@chakra-ui/react";
import {LiaFilterSolid} from "react-icons/lia";
import {useFormattedFilterChanges} from "../store/local-search";

export const SearchFiltersButtons = ({filtersToCompare, onTagClick, onTagClose, includeFiltersButton, includeOtherFiltersCount, ...rest}) => {

  const formattedChanges = useFormattedFilterChanges(filtersToCompare)
  const displayKeys = [
    // Key values match the keys in the original changes objects
    "addedDaysAgo", "price", "ebitda", "revenue", "price_to_ebitda_or_sde_ratio", "dealChildIndustryId",
    // These keys are special and have custom behavior in how tag close is applied
    "location", "text_search"
  ]

  const formattedChangesToDisplay = displayKeys.reduce((obj, key) => {
    if (formattedChanges[key]) {
      obj[key] = formattedChanges[key];
    }
    return obj;
  }, {})

  const changedFiltersLength = Object.entries(formattedChanges).length

  const otherFiltersCount = changedFiltersLength > Object.entries(formattedChangesToDisplay).length ? changedFiltersLength - Object.entries(formattedChangesToDisplay).length : 0


  return (
    <>
      {Object.entries(formattedChangesToDisplay).length > 0 &&
        Object.entries(formattedChangesToDisplay).map(([key, value], index) => {
          return (
            <Tag
              size={{ base: "sm", sm: "sm" }}
              maxWidth={{ base: "%", sm: "auto" }}
              variant='outline'
              key={index}
              mr={"15px"}
              mb={"15px"}
              py={{ base: '5px', sm: '7px' }}
            >
              <TagLabel cursor="pointer" onClick={onTagClick}>{value}</TagLabel>
              {onTagClose && <TagCloseButton onClick={e => { onTagClose(key) }} />}
            </Tag>
          );
        })}

      {includeOtherFiltersCount && otherFiltersCount > 0 &&
        <Tag
          size={{ base: "sm", sm: "sm" }}
          maxWidth={{ base: "%", sm: "auto" }}
          variant='outline'
          mr={"15px"}
          mb={"15px"}
          py={{ base: '5px', sm: '7px' }}
        >
          <TagLabel cursor="pointer" onClick={onTagClick}>{otherFiltersCount} other filters</TagLabel>
        </Tag>
      }

      {Object.entries(formattedChangesToDisplay).length === 0 && otherFiltersCount === 0 &&
        <Tag
          size={{ base: "sm", sm: "sm" }}
          maxWidth={{ base: "%", sm: "auto" }}
          variant='outline'
          mr={"15px"}
          mb={"15px"}
          py={{ base: '5px', sm: '7px' }}
        >
          <TagLabel cursor="pointer" onClick={onTagClick}>No filters applied</TagLabel>
        </Tag>
      }

      {includeFiltersButton &&
        <Tag
          size={{ base: "sm", sm: "sm" }}
          maxWidth={{ base: "90%", sm: "auto" }}
          variant='outline'
          colorScheme='blue'
          onClick={onTagClick}
          cursor="pointer"
          py={{ base: '5px', sm: '7px' }}
          mb={"15px"}
          id={"filters-button"}
        >
          <TagLeftIcon boxSize={{ base: '16px', sm: '20px' }} as={LiaFilterSolid} />
          <TagLabel>
            Filters
          </TagLabel>
        </Tag>
      }





    </>
  )
}