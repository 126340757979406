import { chakra, HTMLChakraProps } from "@chakra-ui/react";

export const LogoIcon = (props: HTMLChakraProps<"svg">) => (
  <chakra.svg
    color="white"
    width="auto"
    height="40px"
    viewBox="0 0 172.24 171.24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#8898f7" />
        <stop offset="1" stopColor="#4dbffd" />
      </linearGradient>
    </defs>
    <g id="Group_84" data-name="Group 84" transform="translate(-113.88 -97.38)">
      <path
        id="Path_219"
        data-name="Path 219"
        d="M0,0,171.24,171.24H0Z"
        transform="translate(114.88 97.38)"
      />
      <path
        id="Path_220"
        data-name="Path 220"
        d="M0,0,171.24,171.24H0Z"
        transform="translate(285.12 97.38) rotate(90)"
        fill="url(#linear-gradient)"
      />
    </g>
  </chakra.svg>
);

export const Logo = (props: HTMLChakraProps<"svg">) => {
  const fill = (props.color as string) || "black";
  const number = Math.floor(Math.random() * 10);
  return (
    <chakra.svg
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 440.09 130.68"
      color="white"
      height="8"
      width="auto"
      {...props}>
      <defs>
        <linearGradient
          id={`linear-gradient-${number}`}
          x1="-433.24"
          y1="453.45"
          x2="-433.24"
          y2="452.45"
          gradientTransform="matrix(0, 130.68, 130.68, 0, -59124.68, 56679.45)"
          gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#8898f7" />
          <stop offset="1" stopColor="#4dbffd" />
        </linearGradient>
      </defs>
      <path id="Path_150" data-name="Path 150" d="M.76,0,131.44,130.68H.76Z" />
      <path
        id="Path_151"
        data-name="Path 151"
        className="cls-1"
        style={{ fill: `url(#linear-gradient-${number})` }}
        d="M130.68,0,0,130.68V0Z"
      />
      <g
        id="Kumo"
        className="cls-2"
        style={{
          isolation: "isolate",
          fill: fill,
        }}>
        <g className="cls-2" style={{ isolation: "isolate" }}>
          <path d="M178.71,101.84V29.11h11V63.84h.89l30.51-34.73h13.81L205.56,61.85l29.44,40H221.79l-23.51-32.5-8.6,9.88v22.62Z" />
          <path d="M276.44,79.22V47.29H287.1v54.55H276.66V92.39h-.57a17,17,0,0,1-6,7.26,17.51,17.51,0,0,1-10.28,2.9,18.26,18.26,0,0,1-9.29-2.33,16,16,0,0,1-6.32-6.9A25.16,25.16,0,0,1,241.89,82V47.29h10.62V80.71a12.49,12.49,0,0,0,3.09,8.88,10.48,10.48,0,0,0,8,3.3,13.2,13.2,0,0,0,5.95-1.49,12.74,12.74,0,0,0,4.94-4.51A13.55,13.55,0,0,0,276.44,79.22Z" />
          <path d="M302.33,101.84V47.29h10.2v8.88h.67a14.19,14.19,0,0,1,5.58-7.05,16.54,16.54,0,0,1,9.27-2.54,15.78,15.78,0,0,1,9.18,2.56,15,15,0,0,1,5.48,7h.57a15.45,15.45,0,0,1,6.21-7,19.08,19.08,0,0,1,10.16-2.61,16.53,16.53,0,0,1,12.13,4.65q4.7,4.67,4.7,14v36.58H365.87V66.26c0-3.7-1-6.37-3-8a11,11,0,0,0-7.21-2.49q-5.19,0-8.06,3.18a11.68,11.68,0,0,0-2.88,8.15v34.77H334.12V65.58a9.54,9.54,0,0,0-2.77-7.14,9.9,9.9,0,0,0-7.21-2.7,10.35,10.35,0,0,0-5.56,1.58,11.76,11.76,0,0,0-4.08,4.39,13.27,13.27,0,0,0-1.55,6.5v33.63Z" />
          <path d="M414.66,102.94a25,25,0,0,1-13.38-3.52,23.59,23.59,0,0,1-8.88-9.83,32.63,32.63,0,0,1-3.16-14.78A32.93,32.93,0,0,1,392.4,60a23.52,23.52,0,0,1,8.88-9.87,27.21,27.21,0,0,1,26.77,0A23.65,23.65,0,0,1,436.93,60a32.93,32.93,0,0,1,3.16,14.84,32.63,32.63,0,0,1-3.16,14.78,23.73,23.73,0,0,1-8.88,9.83A25,25,0,0,1,414.66,102.94Zm0-8.91a12.75,12.75,0,0,0,8.24-2.63,15.56,15.56,0,0,0,4.84-7,30,30,0,0,0,0-19.23,15.82,15.82,0,0,0-4.84-7.05,14.17,14.17,0,0,0-16.53,0,15.72,15.72,0,0,0-4.87,7.05,30,30,0,0,0,0,19.23,15.46,15.46,0,0,0,4.87,7A12.86,12.86,0,0,0,414.7,94Z" />
        </g>
      </g>
    </chakra.svg>
  );
};
