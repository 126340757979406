import {useParams} from "react-router-dom";
import {MyDealsTable} from "./my-deals-table";

export const MyDealsBody = ({ indexType }) => {
  const { subIndexType } = useParams();

  return (
    <>
      <MyDealsTable key={indexType} indexType={indexType} />
    </>
  );
};
