import {
  Box,
  Button,
  Circle,
  createIcon,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Skeleton,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  useId,
  useRadio,
  useRadioGroup,
  useStyleConfig,
} from "@chakra-ui/react";
import {FiHeart} from "react-icons/fi";
import {GoBookmark, GoBookmarkSlashFill} from "react-icons/go";
import {IoHeartDislike} from "react-icons/io5";
import React, {Children, cloneElement, isValidElement, useMemo} from "react";
import {kumoDayJs} from "../../utils/dayjs";
import {useUserActionOnDealMutation} from "../../services/dealsApi";
import {useGetDealFlowStagesQuery} from "../../services/backendApi";
import {AiFillMinusCircle, AiFillPlusCircle} from "react-icons/ai";
import {useQuery} from "@apollo/client";
import {CURRENT_USER_QUERY} from "../../graphql/current-user";
import {SwitchToPaidModal} from "../upgrade-ctas/switch-to-paid-modal";
import {SignupForFreeModal} from "../upgrade-ctas/signup-for-free-modal";

const showNewFeatures = process.env.REACT_APP_SHOW_NEW_FEATURES === "true" || false;

export const TriggerButton = React.forwardRef((props, ref) => {
  const { deal, buttonType, ...otherProps } = props;
  const dealAttributes = deal.attributes;

  const showBookmarked = () => {
    if (showNewFeatures) {
      if (
        dealAttributes.is_favorited ||
        dealAttributes.deal_flow_stage_id !== "no_pipeline_stage_id"
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return dealAttributes.is_favorited;
    }

  };

  const FillableIcon = () => {
    return(
      <Icon
        as={showBookmarked() ? GoBookmarkSlashFill : GoBookmark}
        // fill={showBookmarked() ? "black" : "white"}
        transition="all 0.15s ease"
      />
    )
  }

  if (buttonType === "textButton") {
    return(
      <Button leftIcon={<FillableIcon />}
              variant={"outline"}
              // bg={showBookmarked() ? 'brand.600' : 'white'}
              ref={ref}
              // color="gray.900"
              size="xs"
              width={"full"}
              _hover={{ transform: "scale(1.05)" }}
              sx={{ ":hover > svg": { transform: "scale(1.05)" } }}
              transition="all 0.15s ease"
              {...otherProps} // if you want to spread otherProps
      >
        {showBookmarked() ? 'Undo Save' : 'Save'}
      </Button>
    )
  } else {
    return (
      <IconButton
        isRound
        ref={ref}
        bg="white"
        color="gray.900"
        size="md"
        _hover={{ transform: "scale(1.1)" }}
        sx={{ ":hover > svg": { transform: "scale(1.1)" } }}
        transition="all 0.15s ease"
        icon={
          <FillableIcon />
        }
        aria-label="Favorite this deal"
        {...otherProps} // if you want to spread otherProps
      />
    );
  }


});

TriggerButton.displayName = "HeartButton";

export const RadioCardGroup = (props) => {
  const { children, name, defaultValue, value, onChange, ...rest } = props;
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    value,
    onChange,
  });
  const cards = useMemo(
    () =>
      Children.toArray(children)
        .filter(isValidElement)
        .map((card) => {
          return cloneElement(card, {
            radioProps: getRadioProps({
              value: card.props.value,
            }),
          });
        }),
    [children, getRadioProps]
  );
  return <Stack {...getRootProps(rest)}>{cards}</Stack>;
};
export const RadioCard = (props) => {
  const { radioProps, children, ...rest } = props;
  const { getInputProps, getCheckboxProps, getLabelProps, state } = useRadio(radioProps);
  const id = useId(undefined, "radio-button");
  const styles = useStyleConfig("RadioCard", props);
  const inputProps = getInputProps();
  const checkboxProps = getCheckboxProps();
  const labelProps = getLabelProps();
  return (
    <Box
      as="label"
      cursor="pointer"
      {...labelProps}
      sx={{
        ".focus-visible + [data-focus]": {
          boxShadow: "outline",
          zIndex: 1,
        },
      }}>
      <input {...inputProps} aria-labelledby={id} />
      <Box sx={styles} {...checkboxProps} {...rest}>
        <Stack direction="row">
          <Box flex="1">{children}</Box>
          {state.isChecked ? (
            <Circle bg="accent" size="4">
              <Icon as={CheckIcon} boxSize="2.5" color="fg.inverted" />
            </Circle>
          ) : (
            <Circle borderWidth="2px" size="4" />
          )}
        </Stack>
      </Box>
    </Box>
  );
};
export const CheckIcon = createIcon({
  displayName: "CheckIcon",
  viewBox: "0 0 12 10",
  path: (
    <polyline
      fill="none"
      strokeWidth="2px"
      stroke="currentColor"
      strokeDasharray="16px"
      points="1.5 6 4.5 9 10.5 1"
    />
  ),
});

const PipelineSelector = ({ deal, ...otherProps }) => {
  const { data: dealFlowStages, isLoading, isFetching, isError } = useGetDealFlowStagesQuery();
  const [runUserActionOnDealMutation, { isLoading: mutationIsLoading, isError: mutationError }] =
    useUserActionOnDealMutation();

  const [stageId, setStageId] = React.useState(deal.attributes.deal_flow_stage_id);

  if (dealFlowStages) {
    const formattedOptions = dealFlowStages.data.map((stage) => {
      return { label: stage.attributes.name, value: stage.id };
    });
    const stageOptions = [
      // {
      //   label: "Not in pipeline",
      //   value: "no_pipeline_stage_id",
      // },
      formattedOptions,
    ].flat();

    const valueChanged = () => {
      return deal.attributes.deal_flow_stage_id !== stageId;
    };

    let mutationOptions;
    mutationOptions = {
      dealId: deal.id,
      secondaryId: stageId,
      actionOnDeal: "set_deal_pipeline_stage_id",
    };

    const saveChanges = async () => {
      await runUserActionOnDealMutation(mutationOptions);
    };

    const SaveComponent = () => {
      return (
        <Box mt={"20px"}>
          {valueChanged() && (
            <HStack spacing="2" justify="space-between">
              <Button
                size="sm"
                variant="ghost"
                isDisabled={mutationIsLoading}
                onClick={(e) => {
                  setStageId(deal.attributes.deal_flow_stage_id);
                }}>
                Cancel
              </Button>
              <Button
                size="sm"
                colorScheme="blue"
                isLoading={mutationIsLoading}
                onClick={(e) => {
                  saveChanges();
                }}>
                Save Changes
              </Button>
            </HStack>
          )}
        </Box>
      );
    };

    if (stageId === "no_pipeline_stage_id") {
      return (
        <>
          <Box>
            <Stack>
              <Button
                variant="outline"
                size="md"
                fontSize="sm"
                leftIcon={<Icon as={AiFillPlusCircle} boxSize="4" />}
                onClick={() => {
                  setStageId(dealFlowStages.data[0].id);
                }}>
                Add to Deal Pipeline
              </Button>
            </Stack>
          </Box>
          <SaveComponent />
        </>
      );
    } else {
      return (
        <>
          <Box>
            <FormLabel>Set Deal Pipeline Stage</FormLabel>
            <RadioCardGroup value={stageId} onChange={setStageId} spacing="3">
              {stageOptions.map((option) => (
                <RadioCard key={option.value} value={option.value}>
                  <Text color="fg.emphasized" fontWeight="medium" fontSize="sm">
                    {option.label}
                  </Text>
                </RadioCard>
              ))}
            </RadioCardGroup>
            <Stack>
              <Button
                variant="outline"
                size="lg"
                fontSize="md"
                mt={"13px"}
                leftIcon={<Icon as={AiFillMinusCircle} boxSize="4" />}
                onClick={() => {
                  setStageId("no_pipeline_stage_id");
                }}>
                Remove from Deal Pipeline
              </Button>
            </Stack>
          </Box>
          <SaveComponent />
        </>
      );
    }
  } else if (isLoading || isFetching) {
    return (
      <>
        <Skeleton height={"40px"} />
      </>
    );
  } else {
    return <></>;
  }
};

const FavoritesSelector = ({ deal, ...otherProps }) => {
  const dealAttributes = deal.attributes;

  const [runUserActionOnDealMutation, { isLoading, isError }] = useUserActionOnDealMutation();

  let mutationOptions;
  mutationOptions = {
    dealId: deal.id,
    secondaryId: null,
    actionOnDeal: "favorite",
  };

  const toggleFavorite = async () => {
    if (dealAttributes.is_favorited) {
      mutationOptions["actionOnDeal"] = "unfavorite";
      await runUserActionOnDealMutation(mutationOptions);
    } else {
      mutationOptions["actionOnDeal"] = "favorite";
      await runUserActionOnDealMutation(mutationOptions);
    }
  };

  return (
    <>
      <Stack flex="1">
        <Tooltip
          label={
            dealAttributes.favorited_at &&
            `Saved ${kumoDayJs(dealAttributes.favorited_at).fromNow()}`
          }>
          <Button
            variant={"outline"}
            size="md"
            fontSize="sm"
            leftIcon={
              <Icon
                as={dealAttributes.is_favorited ? IoHeartDislike : FiHeart}
                boxSize="4"
              />
            }
            isLoading={isLoading ? true : false}
            onClick={async () => {
              toggleFavorite();
            }}>
            {dealAttributes.is_favorited ? "Remove favorite" : "Favorite"}
          </Button>
        </Tooltip>
      </Stack>
    </>
  );
};

export const BookmarkButton = ({ deal, buttonType, ...otherProps }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { loading: userLoading, data: userData } = useQuery(CURRENT_USER_QUERY);
  const subscriptionLevel = userData?.currentUser?.account?.subscriptionLevel || "Public"
  const isPaid = ["Pro", "Ultimate"].includes(subscriptionLevel);

  const ActiveButton = () => {
    return (
      <Popover arrowSize={10} placement={"auto"} isLazy>
        <PopoverTrigger>
          <TriggerButton deal={deal} buttonType={buttonType} />
        </PopoverTrigger>
        <PopoverContent zIndex={9999} >
          <PopoverArrow />
          <PopoverHeader py={3}>Save</PopoverHeader>

          <PopoverCloseButton />
          <PopoverBody>
            <Box mt={2} mb={4}>
              <FavoritesSelector deal={deal} />
            </Box>
            <Box mt={2} mb={4}>
              {showNewFeatures && <PipelineSelector deal={deal} />}
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  }

  if (!isPaid) {
    return (
      <>
        <Tooltip label="Upgrade to Pro or Ultimate to save deals"
                 borderRadius="md"
                 p={2} // padding
                 maxWidth="200px" // adjust as needed to control the text breaking
                 textAlign="center" // for centered text, if needed
                 hasArrow={true}
                 arrowSize={10} // adjust the size of the arrow if needed
        >
          <TriggerButton deal={deal} buttonType={buttonType} onClick={onOpen} />
        </Tooltip>
        {isOpen && subscriptionLevel === "Public" && <SignupForFreeModal isOpen={isOpen} onClose={onClose} />}
        {isOpen && subscriptionLevel !== "Public" && <SwitchToPaidModal isOpen={isOpen} onClose={onClose} plan={"Pro"} />}
      </>
    );
  } else {
    return <ActiveButton />
  }
};
