import {Box, Button, Center, Flex, Spinner, Text, Tooltip} from "@chakra-ui/react";

export const DefaultAnalyticsContainer = ({ children, ...otherProps }) => {
  return (
    <Box size={"lg"} height={"450px"} px={"0"} pb={"20"} my={"5"} {...otherProps} border={'1px'} borderColor={"gray.200"}>
      {children}
    </Box>
  )
}

export const DefaultLoadingContainer = ({children, ...otherProps}) => {
  return (
    <DefaultAnalyticsContainer {...otherProps}>
      {children}
      <Center h="200px"> {/* Adjust height as needed */}
        <Box textAlign="center">
          <Spinner />
        </Box>
      </Center>
    </DefaultAnalyticsContainer>
  )
}

export const DefaultErrorContainer = ({...otherProps}) => {
  return (
    <DefaultAnalyticsContainer {...otherProps}>
      <Text>
        Error...
      </Text>
    </DefaultAnalyticsContainer>
  )
}

export const TitleRowWithTimeSelector = ({title, options, timePeriod, handleTimeChange, titleToolTip, ...otherProps}) => {
  return (
    <Box px={"3"} py={"4"}>
      <Flex justifyContent="space-between" alignItems="center">
        <Tooltip label={titleToolTip}>
          <Text fontSize={"md"} fontWeight={"bold"}>{title}</Text>
        </Tooltip>
        <TimeRangeSelector options={options} timePeriod={timePeriod} handleTimeChange={handleTimeChange} />
      </Flex>
    </Box>

  )
}

export const TimeRangeSelector = ({options, timePeriod, handleTimeChange, ...otherProps}) => {
  return (
    <Flex>
      {options.map((timeValue) => (
        <Button
          key={timeValue.value}
          variant="ghost"
          color={"black"}
          rounded={"none"}
          px={"0"}
          mr={"3"}
          borderBottom={timePeriod === timeValue.value ? "2px solid" : "none"}
          onClick={() => handleTimeChange(timeValue.value)}
        >
          {timeValue.label}
        </Button>
      ))}
    </Flex>
  )
}

export const AskingPriceBuckets = [
  {
    name: "0-0.5M",
    range: [0, 500000]
  },
  {
    name: "0.5-1M",
    range: [500000, 1000000]
  },
  {
    name: "1M-2M",
    range: [1000000, 2000000]
  },
  {
    name: "2M-5M",
    range: [2000000, 5000000]
  },
  {
    name: "5M+",
    range: [5000000, "infinity"]
  },
]

export const EbitdaMultiplesBuckets = [
  {
    name: "< 1x",
    range: [0, 1]
  },
  {
    name: "1x-2x",
    range: [1, 2],
  },
  {
    name: "2x-3x",
    range: [2, 3],
  },
  {
    name: "3x-4x",
    range: [3, 4],
  },
  {
    name: "4x-5x",
    range: [4, 5],
  },
  {
    name: "5x-6x",
    range: [5, 6],
  },
  {
    name: "6x+",
    range: [6, "infinity"],
  }
]

export const DefaultBarChartBuckets = {
  "asking_price": AskingPriceBuckets,
  "annual_revenue": AskingPriceBuckets,
  "annual_ebitda_or_sde": AskingPriceBuckets,
  "ebitda_multiple": EbitdaMultiplesBuckets
}

export const customColors = [
  '#9097f7', // A light blue-violet at the start of the gradient
  '#7d95f7', // A midway color between the first and second key colors
  '#6a9af8', // A brighter blue at about halfway through the gradient
  '#57b1f9', // A midway color between the second and third key colors
  '#46c6fd', // A vibrant cyan-like blue towards the end
  '#55b4fb', // A mix between cyan and the last blue
  '#64a2f9'  // A deep sky blue at the end of the gradient
];

export const darkerCustomColors = [
  '#7a82d8', // Darker shade of #9097f7
  '#697ed8', // Darker shade of #7d95f7
  '#5685d9', // Darker shade of #6a9af8
  '#439cda', // Darker shade of #57b1f9
  '#32b1ee', // Darker shade of #46c6fd
  '#419fdb', // Darker shade of #55b4fb
  '#508ddc'  // Darker shade of #64a2f9
];

export const contrastingGraphColors = [
  '#FF8C00',
  '#7a82d8',
  '#508ddc',
];

export const defaultTimeValues = [
  {
    label: "3 mo",
    value: "3 months"
  },
  {
    label: "6 mo",
    value: "6 months"
  },
  {
    label:"1 yr",
    value: "1 year"
  }
]

