import {Box, Link, SkeletonText, Stack, Table, Tbody, Td, Text, Th, Thead, Tr} from "@chakra-ui/react";
import {useGetDealQuantitativeEventsQuery} from "../../../../services/dealsApi";
import numbro from "numbro";
import dayjs from "dayjs";
import {ListingOpener} from "../../../../components/deal/listing-opener";


export const QuantitativeChangeEvents = ({ deal, listingSource }) => {
  const { data: quantData, isLoading: quantDataLoading, isFetching: quantDataFetching, isError: quantDataError } = useGetDealQuantitativeEventsQuery(deal?.id);
  const quantEvents = quantData?.data;
  if (quantEvents && quantEvents.length > 0) {
    const filteredEvents = quantEvents.filter(event => event.attributes.listing_source_id === listingSource.listing_source_id)
    return (
      <>
        <QuantitativeChangeEventsTable deal={deal} events={filteredEvents} />
      </>
    )
  } else {
    return (
      <LoadingSkeleton />
    )
  }
}

function formatDate(dateString) {
  return dayjs(dateString).format('MM/DD/YY');
}

function formatEvents(deal, events, hideIrrelevantEvents = true) {
  const startingEvent = {
    "created_at": formatDate(deal.attributes.added_to_kumo_at),
    "listing_generated_uid": "--",
    "listing_source_name": "--",
    "status": "listed",
    "asking_price": deal.attributes.asking_price ? parseInt(deal.attributes.asking_price) : null,
    "annual_revenue": deal.attributes.annual_revenue ? parseInt(deal.attributes.annual_revenue) : null,
    "highest_profit_number": deal.attributes.highest_profit_number ? parseInt(deal.attributes.highest_profit_number) : null,
    "lowest_profit_number": deal.attributes.lowest_profit_number ? parseInt(deal.attributes.lowest_profit_number) : null,
  }

  let formattedEvents = [startingEvent]

  events.forEach((event, index) => {
    const previousEvent = formattedEvents[formattedEvents.length - 1];

    // Create a new event by selectively merging non-null attributes from the current event
    const newEvent = { ...previousEvent };
    let hasChanged = false;

    Object.keys(startingEvent).forEach(key => {
      if (event.attributes[key] !== null && key in previousEvent) {
        if (['asking_price', 'annual_revenue', 'highest_profit_number', 'lowest_profit_number'].includes(key)) {
          newEvent[key] = parseInt(event.attributes[key]);
        } else {
          newEvent[key] = event.attributes[key];
        }

      }

      if (event.attributes[key] !== null && newEvent[key] !== previousEvent[key]) {
        if ( ['asking_price', 'annual_revenue', 'highest_profit_number', 'lowest_profit_number'].includes(key) && parseInt(newEvent[key]) === parseInt(previousEvent[key]) ) {
          // Skip the change detection for this key as the values are considered the same
        } else if (!['created_at', 'listing_source_name', 'listing_generated_uid'].includes(key)) {
          hasChanged = true;
        }
      }
    });

    // Push the new event to the formattedEvents array
    if (hideIrrelevantEvents) {
      if (hasChanged) {
        formattedEvents.push(newEvent);
      }
    } else {
      formattedEvents.push(newEvent);
    }
  })

  formattedEvents = formattedEvents.reverse();
  return formattedEvents
}

export const QuantitativeChangeEventsTable = ({deal, events, ...props}) => {
  const formattedEvents = formatEvents(deal, events)

  const formatNumber = (number) => {
    if (number === null || number === "" || number === undefined) {
      return "--";
    } else {
      return numbro(parseInt(number)).formatCurrency({ thousandSeparated: true })
    }
  }

  const hasValueChanged = (currentValue, previousValue) => {
    if (Number.isFinite(currentValue) && Number.isFinite(previousValue)) {
      // Compare as integers if both values are numbers
      return parseInt(currentValue, 10) !== parseInt(previousValue, 10);
    }
    // Fallback to strict equality check for non-number values
    return currentValue !== previousValue;
  };


  const FormattedCell = (props) => {
    const {formattedEvent, eventIndex, attributeKey, ...otherProps} = props;
    const previousEvent = formattedEvents[eventIndex + 1]
    const newValue = previousEvent && hasValueChanged(formattedEvent[attributeKey], previousEvent[attributeKey])

    return (
      <Td>
        <Text color={newValue ? undefined : 'fg.muted'} fontWeight={newValue ? "medium": undefined}>
          {attributeKey !== 'status' ? formatNumber(formattedEvent[attributeKey]) : formattedEvent[attributeKey]}
        </Text>
      </Td>
    )
  }

  const showSecondNetIncome = () => {
    let show = false
    formattedEvents.forEach((event, index) => {
      if (event.highest_profit_number !== null && event.lowest_profit_number !== null) {
        if (parseInt(event.highest_profit_number) !== parseInt(event.lowest_profit_number)) {
          show = true
        }
      }
    })
    return show
  }

  return (
    <Table {...props}>
      <Thead>
        <Tr>
          <Th>Date</Th>
          <Th>Status</Th>
          <Th>Price</Th>
          <Th>Revenue</Th>
          <Th>Net</Th>
          {showSecondNetIncome() && <Th>Net #2</Th>}
        </Tr>
      </Thead>
      <Tbody>
        {formattedEvents.map((event, index) => (
          <Tr key={index}>
            <Td>
              {/* First event is the base value and not a change event*/}
              {index === formattedEvents.length - 1 ? (
                  <Text>{formatDate(event.created_at)}</Text>
                ) : (
                  <ListingOpener listingId={event.listing_generated_uid}>
                    <Link to={"#"}>{formatDate(event.created_at)}</Link>
                  </ListingOpener>
                )
              }
            </Td>
            <FormattedCell formattedEvent={event} eventIndex={index} attributeKey={"status"} />
            <FormattedCell formattedEvent={event} eventIndex={index} attributeKey={"asking_price"} />
            <FormattedCell formattedEvent={event} eventIndex={index} attributeKey={"annual_revenue"} />
            <FormattedCell formattedEvent={event} eventIndex={index} attributeKey={"highest_profit_number"} />
            {showSecondNetIncome() && <FormattedCell formattedEvent={event} eventIndex={index} attributeKey={"lowest_profit_number"} />}
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}

const LoadingSkeleton = () => {
  return (
    <Box>
      <Stack>
        <SkeletonText mt="4" noOfLines={1} spacing="4" skeletonHeight="7" width={"300px"} />
        <SkeletonText mt="4" noOfLines={2} spacing="1" skeletonHeight="5" />
        <SkeletonText mt="4" noOfLines={15} spacing="1" skeletonHeight="5" />
      </Stack>
    </Box>
  )
}