import {Box, Divider, Text, VStack} from "@chakra-ui/react";
import {ResponsiveScatterPlot} from '@nivo/scatterplot'
import {useGetScatterPlotByIndustryOrLocationQuery} from "../../../services/dealAnalyticsApi";
import {useGetChildIndustriesQuery, useGetSearchableLocationsQuery} from "../../../services/savedSearchesApi";
import {
  DefaultAnalyticsContainer,
  DefaultErrorContainer,
  DefaultLoadingContainer
} from "./common-visualization-elements";
import numbro from "numbro";

const ScatterPlotTypes = {
  'industry_id': 'Industry',
  'location': 'Location'
}

export const ScatterPlotByIndustryOrLocation = ({group_by, filters, ...otherProps}) => {
  let queryHook;
  let queryOptions;

  let options = [];
  if (group_by === 'industry_id') {
    const { data: industriesData, isLoading: industriesDataIsLoading, isFetching: industriesDataIsFetching, isError: industriesDataIsError } = useGetChildIndustriesQuery();
    options = industriesData?.data?.map(industry => { return {id: industry.id, label: industry.attributes.label, group: industry.attributes.group}}) || []
  } else if (group_by === 'location_id') {
    const { data: locationData, isLoading, isFetching, isError } = useGetSearchableLocationsQuery();
    options = locationData?.data?.map(location => { return {id: location.id, label: location.attributes.label, group: location.attributes.group}}) || []
  }

  queryHook = useGetScatterPlotByIndustryOrLocationQuery;
  queryOptions = {
    filters: filters,
    group_by: group_by,
  };

  const { data, isLoading, isFetching, isError } = queryHook(queryOptions);


  if (isLoading || isFetching) {
    return (
      <DefaultLoadingContainer {...otherProps} />
    )
  } else if (isError) {
    return (
      <DefaultErrorContainer {...otherProps} />
    )
  }

  if (options && options.length > 0 && data ) {
    const transformedData = data ? transformToNivoFormat(data, options, group_by, 'avg_sde_or_ebitda', 'avg_sortable_asking_price') : [];
    // console.log('data', JSON.stringify(data.slice(0, 3), null, 2))
    // console.log('transformed', JSON.stringify(transformedData.slice(0, 3), null, 2))
    return (
      <DefaultAnalyticsContainer {...otherProps}>
        <ScatterPlotWithFormatting data={transformedData} />
      </DefaultAnalyticsContainer>
    )
  }


}


function transformToNivoFormat(rawData, options, group_by, xMetric, yMetric) {
  // Create a map to hold grouped data
  const groupedData = new Map();

  rawData.forEach(item => {
    const option = options.find(ind => parseInt(ind.id) === parseInt(item[group_by]));
    const groupKey = option ? option.label : `Unknown Group`;

    // Initialize group in map if it doesn't exist
    if (!groupedData.has(groupKey)) {
      groupedData.set(groupKey, []);
    }

    console.log('item', item)
    console.log('option', option)
    // Add item to the appropriate group
    groupedData.get(groupKey).push({
      x: parseFloat(item[xMetric]),
      y: parseFloat(item[yMetric]),
      size: item.num_deals,
      id: item[group_by],
      group: option?.group || "Unknown Group",
      label: groupKey
    });
  });

  // Convert the map into an array of groups for Nivo
  const scatterData = Array.from(groupedData, ([key, value]) => ({
    id: key,
    data: value
  }));

  return scatterData;
}

const customTheme = {
  axis: {
    domain: {
      line: {
        strokeWidth: '1',
        stroke: 'rgba(204,204,204,0.6)'
      }
    },
    ticks: {
      text: {
        fontSize: 12, // Change the font size
        fill: 'gray', // Text color
        // Add additional styles as needed
      },
      line: {
        stroke: 'gray', // Grey border color
        strokeWidth: 1, // Border width
      }
    },
    legend: {
      text: {
        fontSize: 18,    // Change the font size for legend
        fill: 'gray', // Text color
        fontWeight: 700  // Font weight for legend
      }
    }
  },
  labels: {
    text: {
      // Customize the label color, font size, and weight
      fill: '#ffffff', // Text color
      fontSize: 16,    // Font size
      fontWeight: 600  // Font weight
    }
  },

};

const calculate75thPercentile = (data) => {
  const sortedData = [...data].sort((a, b) => a - b);
  const index = Math.ceil(0.75 * sortedData.length);
  return sortedData[index - 1];
};

const scatterPlotColors = [
  '#8c7ad8', // Adjusted from #7a82d8
  '#6a8ed8', // Adjusted from #697ed8
  '#56a0d9', // Adjusted from #5685d9
  '#43b4da', // Adjusted from #439cda
  '#32ccee', // Adjusted from #32b1ee
  '#41afdb', // Adjusted from #419fdb
  '#5092dc'  // Adjusted from #508ddc
];


const ScatterPlotWithFormatting = ({data}) => {
  // Assuming your data is structured as an array of objects
  // with each object having x and y values
  const xValues = data.flatMap(group => group.data.map(d => d.x));
  const yValues = data.flatMap(group => group.data.map(d => d.y));

  const x75thPercentile = calculate75thPercentile(xValues);
  const y75thPercentile = calculate75thPercentile(yValues);

  const maxXValue = x75thPercentile * 1.5; // 50% more than the 75th percentile
  const maxYValue = y75thPercentile * 1.5; // 50% more than the 75th percentile

  const numberFormat = {
    average: true,
    totalLength: 3,
    // mantissa: 2,
  };

  const formatTick = (value) => {
    return numbro(value).formatCurrency(numberFormat).toUpperCase();
  }

  return (
    <>
      <ResponsiveScatterPlot
        data={data}
        theme={customTheme}
        colors={ scatterPlotColors }
        margin={{ top: 60, right: 0, bottom: 70, left: 90 }}
        xScale={{ type: 'linear', min: 0, max: maxXValue }} // Set max value for x-axis
        xFormat=">-.2f"
        yScale={{ type: 'linear', min: 0, max: maxYValue }}
        yFormat=">-.2f"
        blendMode="multiply"
        axisTop={null}
        axisRight={null}
        tooltip={CustomTooltip}
        axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          format: formatTick,
          legend: 'Average Profit (SDE/EBITDA)',
          legendPosition: 'middle',
          legendOffset: 46
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          format: formatTick,
          legend: 'Average Asking Price',
          legendPosition: 'middle',
          legendOffset: -75
        }}
      />
    </>
  )
}



const CustomTooltip = ({node}) => {
  return (
    <Box p={3} bg="white" boxShadow="md" borderRadius="md">
      <VStack align="start">
        <Text fontSize="md" fontWeight="bold">
          {node.data.label}
        </Text>
        <Text fontSize="sm">
          {node.data.group}
        </Text>
        <Divider />
        <Text fontSize="md">
          <strong>Average Asking Price:</strong> ${node.data.y.toLocaleString()}
        </Text>
        <Text fontSize="md">
          <strong>Average SDE/EBITDA:</strong> ${node.data.x.toLocaleString()}
        </Text>

        <Text fontSize="md">
          <strong># of Deals:</strong> {node.data.size}
        </Text>
      </VStack>
    </Box>
  );
};