import { gql } from "@apollo/client";

export const UPDATE_DEAL_FLOW_STAGE = gql`
  mutation UpdateDealFlowStage($stageId: ID!, $order: Float, $name: String, $hidden: Boolean) {
    updateDealFlowStage(input: { name: $name, stageId: $stageId, order: $order, hidden: $hidden }) {
      dealFlowStage {
        id
        name
        order
        colorScheme
      }
    }
  }
`;
