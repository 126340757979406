import {Box, Container, Link, Stack, Text, useDisclosure} from "@chakra-ui/react";
import {NavLink as ReactRouterLink, useLocation} from "react-router-dom";
import {CurrentUser_currentUser} from "../graphql/__generated__/CurrentUser";
import {kumoDayJs} from "../utils/dayjs";
import {SwitchToPaidModal} from "./upgrade-ctas/switch-to-paid-modal";
import React from "react";

const AUTH_URLS = ["signin", "signup", "reset-password"];

const FixedFullWidthBanner = ({ children, bgColor = "accent" }: { children: any; bgColor?: string }) => {
  return (
    <Box as="section" bg={bgColor} color="on-accent">
      <Box borderBottomWidth="1px">
        <Container py="3">
          <Stack
            direction={{ base: "column", md: "row" }}
            justify="space-between"
            spacing={{ base: "3", md: "2" }}>
            <Box textAlign="center" flex="1" mx="4">
              <Stack direction="row" justify="center">
                {children}
              </Stack>
            </Box>
          </Stack>
        </Container>
      </Box>
    </Box>
  )
}

export const SubscriptionBanner = ({ currentUser }: { currentUser?: CurrentUser_currentUser }) => {
  const location = useLocation();
  const HIDE_BANNER = AUTH_URLS.some((url) => location.pathname.includes(url));

  const { isOpen, onOpen, onClose } = useDisclosure()

  const subscriptionLevel = currentUser?.account?.subscriptionLevel || "Public"

  if (
    ["Pro", "Ultimate"].includes(subscriptionLevel) ||
    HIDE_BANNER ||
    navigator.userAgent === "ReactSnap"
  ) {
    return null;
  }

  if (subscriptionLevel === "Public") {
    return null;
  }

  return (
    <FixedFullWidthBanner>
      <BannerCopy currentUser={currentUser} onOpen={onOpen} />
      {isOpen && <SwitchToPaidModal isOpen={isOpen} onClose={onClose} onOpen={onOpen} plan={"Pro"}
                                    ctaText={""} />}
    </FixedFullWidthBanner>
  );
};

const BannerCopy = ({ currentUser, onOpen }: { currentUser?: CurrentUser_currentUser, onOpen: any }) => {
  if (currentUser?.account?.status === "canceled") {
    return (
      <Text fontWeight="medium" color={"white"}>
        You're on the limited{" "}
        <Box as="span" borderBottom={"1px dashed"} onClick={onOpen} style={{ cursor: "pointer" }}>
          Free Plan
        </Box>.{" "}You cancelled on{" "}
        {kumoDayJs(currentUser?.account?.canceledAt).format("MMMM D, YYYY")}.{" "}
        <Box display={{ base: 'block', md: 'none'}} />
        {/*<ProPlanCtaCheckout tracking="upgrade_pro_top_banner_click" textColor={"white"}>*/}
        {/*  Reactivate your subscription to unlock all deals*/}
        {/*</ProPlanCtaCheckout>*/}
        {" "}<Link
          textDecoration="underline"
          as={ReactRouterLink}
          to="/plan-selection"
          color={"white"}
          fontWeight="medium">
          Subscribe again
        </Link>{" "}
        to unlock full access.
      </Text>
    );
  } else {
    return (
      <Text fontWeight="medium" color={"white"}>
        You're on the limited{" "}
        <Box as="span" borderBottom={"1px dashed"} onClick={onOpen} style={{ cursor: "pointer" }}>
          Free Plan
        </Box>.{" "}
        <Box display={{ base: 'block', md: 'none'}} />
        {/*<ProPlanCtaCheckout tracking="upgrade_pro_top_banner_click" textColor={"white"}>*/}
        {/*  Upgrade to Pro*/}
        {/*</ProPlanCtaCheckout>*/}
        For full access,{" "}
        <Link
          textDecoration="underline"
          as={ReactRouterLink}
          to="/plan-selection"
          color={"white"}
          fontWeight="medium">
          upgrade to Pro or Ultimate
        </Link>.
      </Text>
    );
  }
};
