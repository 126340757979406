import {
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from "@chakra-ui/react";
import {useDestroySavedSearchMutation} from "../../../services/savedSearchesApi";
import {FiTrash2} from "react-icons/fi";
import * as React from "react";

export const DeleteButton = ({search}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [destroySavedSearch] = useDestroySavedSearchMutation();

  const deleteSuggestedSearch = async () => {
    const { data } = await destroySavedSearch({
      id: search.id,
    });

    if (data) {
      onClose();
    }
  }

  return (
    <>
      <IconButton onClick={onOpen} icon={<FiTrash2 />}  variant="tertiary" aria-label="Delete" />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete confirmation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to delete saved search: "{search.attributes.title}"?</ModalBody>

          <ModalFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={deleteSuggestedSearch}>
              Yes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};