import {Box, useBreakpointValue} from "@chakra-ui/react";
import Helmet from "react-helmet";
import {Outlet} from "react-router-dom";

export const DefaultLayout = () => {
  const overflowXValue = useBreakpointValue({ base: "hidden", md: "auto" });

  return (
    <Box
      as="section"
      minHeight="100vh"
      overflowY="auto"
      overflowX={overflowXValue}
      width="100%"
      position="relative">
      <Helmet title="KUMO | Find Deals" />
      <Outlet />
    </Box>
  )
}