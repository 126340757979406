import { Flex, HStack, Icon, Link, Stack, Text, useColorMode, Wrap } from "@chakra-ui/react";
import numbro from "numbro";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { MdBusiness } from "react-icons/md";
import { DealFlowStages_dealFlowStages_prospects } from "../../../../graphql/__generated__/dealFlowStages";
import { kumoDayJs } from "../../../../utils/dayjs";

interface Props {
  statusId: string;
  prospect?: DealFlowStages_dealFlowStages_prospects;
  index: number;
  autoFocus?: boolean;
  draggable?: boolean;
  showAddProspect?: boolean;
}

const Prospect: React.FC<Props> = ({ prospect, index, draggable = true }) => {
  const { colorMode } = useColorMode();
  const bgColor: any = { light: "whiteAlpha.900", dark: "gray.800" };

  const ProspectContent = ({
    dragHandleProps = {},
  }: {
    dragHandleProps?: any;
    draggableProps?: any;
  }) => {
    return (
      <Flex
        //  mb={2}
        justify="space-between"
        direction="row">
        <Flex
          p={3}
          flex={1}
          justify="space-between"
          direction="column"
          wrap="nowrap"
          {...dragHandleProps}>
          <Link
            textDecoration={"none"}
            _hover={{ textDecoration: "none" }}
            href={`/deal/${prospect?.deal?.id}`}
            target="_blank">
            <Stack>
              <Text fontWeight="medium" userSelect="none">
                {prospect?.deal?.formattedTitle || "Not Available"}
              </Text>
              <Wrap>
                <HStack>
                  <Icon as={MdBusiness} color="gray.400" />
                  <Text fontSize="xs" color="muted">
                    {prospect?.deal?.tags?.slice(0, 2).join(", ")}
                    {(prospect?.deal?.tags?.length || 0) > 2
                      ? `, and ${(prospect?.deal?.tags?.length || 0) - 2} more`
                      : ""}
                  </Text>
                </HStack>
              </Wrap>
              <Text fontSize="sm" fontWeight="medium" color="muted">
                {prospect?.deal?.sortableAskingPrice
                  ? `$${numbro(prospect?.deal.sortableAskingPrice).format({
                      thousandSeparated: true,
                      mantissa: 0,
                    })}`
                  : "N/A"}
              </Text>

              {prospect?.deal?.noteThread?.notes?.length && (
                <Text fontSize="sm">
                  {
                    prospect?.deal?.noteThread?.notes[prospect?.deal?.noteThread?.notes?.length - 1]
                      .content
                  }
                </Text>
              )}

              <Text fontSize="sm" fontStyle={"italic"}>
                {prospect?.createdAt
                  ? prospect?.createdAt
                    ? `Added ${kumoDayJs(prospect?.createdAt).fromNow()}`
                    : "--"
                  : "Not available"}
              </Text>
            </Stack>
          </Link>
        </Flex>
      </Flex>
    );
  };

  // if (draggable)
  return (
    <Draggable draggableId={prospect?.id || "test"} index={index} isDragDisabled={!draggable}>
      {({ draggableProps, dragHandleProps, innerRef }) => (
        <Flex
          bg={bgColor[colorMode]}
          direction="column"
          // p={2}
          mb={2}
          boxShadow="md"
          rounded="md"
          // border="1px solid black"
          // borderRadius="5px"
          {...draggableProps}
          {...dragHandleProps}
          ref={innerRef}>
          <ProspectContent dragHandleProps={dragHandleProps} />
        </Flex>
      )}
    </Draggable>
  );
};

export default React.memo(Prospect);
