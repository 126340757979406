//https://github.com/nfl/react-helmet/issues/548
//'react-helmet' throws warning messages

import {useQuery} from "@apollo/client";
import {useEffect} from "react";
import {AuthProvider} from "./auth/auth-provider";
import ErrorBoundary from "./components/error-boundary";
import {CURRENT_USER_QUERY} from "./graphql/current-user";
import {FreshdeskInitialize} from "./utils/freshdesk";
import {GtagInitializer} from "./utils/gtag";

import "./index.css";
import {Router} from "./views/router";

function App() {
  const { loading, data } = useQuery(CURRENT_USER_QUERY);

  useEffect(() => {
    (function (w, r) {
      w._rwq = r;
      w[r] =
        w[r] ||
        function () {
          // eslint-disable-next-line prefer-rest-params
          (w[r].q = w[r].q || []).push(arguments);
        };
    })(window, "rewardful");

    const s = document.createElement("script");
    s.async = true;
    s.src = "https://r.wdfl.co/rw.js";
    s.setAttribute("data-rewardful", "29740e");
    document.head.appendChild(s);
  }, []);
  useEffect(() => {
    FreshdeskInitialize(data?.currentUser.email);
    GtagInitializer(data?.currentUser.id);
  }, [data?.currentUser.email, data?.currentUser.id]);

  return (
    <ErrorBoundary>
      <AuthProvider>
        <Router />
      </AuthProvider>
    </ErrorBoundary>
  );
}

export default App;
