export const generateIndex = (point1?: number | null, point2?: number | null): number => {
  if (point1 === undefined || point1 === null) {
    return 0;
  } else if (point2 === undefined || point2 === null) {
    return point1 + 1;
  } else {
    const LIMIT = 0.999999999999998;
    const newPoint = (point2 - point1) / 2 + point1;
    if (newPoint % 1 >= LIMIT) {
      throw new Error("Cannot increment any more");
    }

    return newPoint;
  }
};
