import { Center, Flex, Icon, Stack } from "@chakra-ui/react";
import { FiInfo } from "react-icons/fi";

export const NotificationMessage = ({ children }: { children: any }) => (
  <Flex direction="row-reverse" position="absolute" zIndex={2} right="8" top="8">
    <Flex
      direction={{ base: "column", sm: "row" }}
      width={{ base: "full", sm: "md" }}
      boxShadow="md"
      bg="bg-surface"
      borderRadius="lg"
      overflow="hidden">
      <Center display={{ base: "none", sm: "flex" }} bg="bg-accent" px="5">
        <Icon as={FiInfo} boxSize="10" color="on-accent" />
      </Center>
      <Stack direction="row" p="4" spacing="3" flex="1">
        <Stack spacing="2.5" flex="1">
          {children}
        </Stack>
      </Stack>
    </Flex>
  </Flex>
);
