import {
  Box,
  Heading,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useColorModeValue
} from "@chakra-ui/react";
import React from "react";
import {SignupForm} from "../../views/authentication/signup-page/signup-form";

import {FaGlobe} from 'react-icons/fa';
import {MdHistory, MdNotificationsActive} from 'react-icons/md';
import {AiOutlineDownload} from 'react-icons/ai';
import {LogoImage} from "../LogoImage";

const features = [
  {
    icon: FaGlobe,
    text: '100K+ deals from hundreds of marketplaces and brokers',
  },
  {
    icon: MdNotificationsActive,
    text: 'Email alerts when new deals match your search parameters',
  },
  {
    icon: AiOutlineDownload,
    text: 'Export deals to CSVs to match your workflows',
  },
  {
    icon: MdHistory,
    text: 'Track historical changes on listings, like price decreases and new listing content',
  },
];

export const Features = (props) => {
  const color = useColorModeValue('gray.600', 'gray.300')
  return (
    <Stack spacing="8" p="6" bg={useColorModeValue('gray.50', 'gray.700')} {...props}>
      <HStack spacing="4" color={color}>
        <Text>Paid subscribers get access to:</Text>
      </HStack>

      {features.map((feature, id) => (
        <HStack key={id} spacing="4" color={color}>
          <Icon as={feature.icon} fontSize="2xl" />
          <Text>{feature.text}</Text>
        </HStack>
      ))}
    </Stack>
  )
}

export const SignupForFreeModal = ({isOpen, onClose, onOpen}) => {
  return(
    <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}
           size={{base: "full", lg: "6xl"}}
    >
      <ModalOverlay />
      <ModalContent
        // bg="bg.accent.default"
        // color="fg.accent.default"
        borderRadius={"md"}
        px={"0"}
        py={"0"}
      >
        <ModalCloseButton
          // color={"white"}
        />
        <ModalBody px={0} py={0}>
          <Box>
            <Stack
              direction={{
                base: 'column',
                lg: 'row',
              }}
              spacing="0"
            >
              <Box flex="1"
                   background={'bg.accent.default'}
                   order={{base: 1, lg: 0}}
                   minHeight={{base: "50vh", lg: null}}
                   px={{
                     base: '8',
                     lg: '12',
                   }}
                   py={{
                     base: '8',
                     lg: '12',
                   }}
                   borderTopLeftRadius={"md"}
                   borderBottomLeftRadius={"md"}
              >
                <Stack spacing="8">
                  <Stack spacing="4">
                    <Stack align={"center"}>
                      <Box mb={"3"} display={{base: "none", lg: "block"}}>
                        <LogoImage logoColor={'white'} maxWidth={"125px"} />
                      </Box>
                      <Heading
                        size={{ base: "sm", lg: "sm" }}
                        fontWeight="medium"
                        color={"white"}
                        textAlign={{ base: "center" }}
                      >
                        Every business for sale in one place
                      </Heading>
                      <Heading
                        size={{ base: "sm", lg: "xs" }}
                        fontWeight="light"
                        color={"white"}
                        textAlign={{ base: "center" }}
                      >
                        Sign up for a free limited preview!
                      </Heading>
                    </Stack>
                  </Stack>
                  <Features />
                </Stack>
              </Box>
              <Box flex="1"
                   px={{
                     base: '8',
                     lg: '12',
                   }}
                   py={{
                     base: '8',
                     lg: '12',
                   }}
              >
                <SignupForm></SignupForm>
              </Box>
            </Stack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}