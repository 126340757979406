import {Box, Button, Collapse, HStack, Icon, Text, useDisclosure} from '@chakra-ui/react'
import {FiChevronDown} from 'react-icons/fi'
import React from 'react';
import {useLocation} from 'react-router-dom'; // Import useLocation hook from react-router-dom

export const CollapsableButtonGroup = ({icon, routes, label, children}) => {
  const location = useLocation(); // Get the current location

  const { isOpen, onToggle } = useDisclosure({
    // Determine if the disclosure is open based on the current route
    defaultIsOpen: routes.some(route => location.pathname === route)
  });

  return (
    <Box>
      <Button onClick={onToggle}
              variant="ghost"
              color={'gray.700'}
              // color={true ? 'on-accent' : 'gray.700'}
              p={"2"}
              // bg={true ? 'gray.100' : ''}
              isTruncated
              justifyContent="space-between"
              width="full">
        <HStack spacing="3">
          <Icon as={icon} />
          <Text as="span">{label}</Text>
        </HStack>
        <PopoverIcon isOpen={isOpen} />
      </Button>
      <Collapse in={isOpen} animateOpacity>
        {children}
      </Collapse>
    </Box>
  )
}
export const PopoverIcon = (props) => {
  const iconStyles = {
    transform: props.isOpen ? 'rotate(-180deg)' : undefined,
    transition: 'transform 0.2s',
    transformOrigin: 'center',
  }
  return <Icon aria-hidden as={FiChevronDown} __css={iconStyles} />
}

