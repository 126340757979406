import {PlanRate} from "./../views/authentication/plan-selection-page/page";
import axios from "axios";

export const checkout = async (plan: string, planRate: PlanRate) => {
  const rewardful = (window as any).Rewardful;
  const rewardfulObject = rewardful
    ? {
        referral: (window as any).Rewardful.referral,
        stripe_coupon_id: (window as any).Rewardful.coupon.id,
      }
    : {};
  const resp = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}api/checkout/${plan}/${planRate}`,
    {
      ...rewardfulObject,
      root_url: window.location.origin,
      cancel_url: window.location.href,
    },
    {
      headers: {
        authorization: localStorage.getItem("with-kumo-token") || "",
      },
    }
  );
  return resp.data;
};
