import {useDispatch, useSelector} from "react-redux";
import {localSearchActions, selectActiveSearch} from "../../../store/local-search";
import {useGetDealsQuery} from "../../../services/dealsApi";
import {Box, Container, HStack, Text, useBreakpointValue, useDisclosure} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import {SearchTable} from "./search-table";
import {Pagination} from "../../../components/Pagination";
import {DealDrawer} from "../../../components/deal/deal-drawer";
import {MobileSearchCards} from "./mobile-search-cards";
import {SortDropdown} from "./table-headers";
import {FreeUserCta} from "./FreeUserCta";


export const ActiveSearchDisplay = () => {
  const  { filters, sort, page } = useSelector(selectActiveSearch)
  const dispatch = useDispatch()
  let queryHook;
  let queryOptions;

  queryHook = useGetDealsQuery;
  queryOptions = {
    filters: filters,
    page: page,
    sortField: sort.sortField,
    sortOrder: sort.sortOrder,
  };

  const { data, isLoading, isFetching, isError } = queryHook(queryOptions);

  const deals = data?.data;
  const meta = data?.meta;

  const setPageAndScrollToTop = (e) => {
    dispatch(localSearchActions.updateActiveSearch({page: e.page}))
    const element = document.getElementById("top-of-page");
    if (element) {
      element.scrollIntoView({ block: "start", inline: "nearest", behavior: "smooth" });
    }
  };

  const handleSort = (newSort) => {
    dispatch(localSearchActions.updateActiveSearch({sort: newSort}))
  };

  // Used for deal drawer
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [modalDealId, setModalDealId] = useState(deals?.[0]?.id)
  const handleDealClick = (dealId) => {
    onOpen()
    setModalDealId(dealId)
  }

  useEffect(() => {
    const currentDealIndex = deals?.findIndex((deal) => deal.id === modalDealId);

    if (currentDealIndex > 0) {
      const prevDealId = deals[currentDealIndex - 1].id;
      const element = document.getElementById(`deal-${prevDealId}`);
      if (element) {
        element.scrollIntoView({ block: "start", inline: "nearest", behavior: "smooth" });

        // Use a timeout to wait for the scrolling animation to start
        setTimeout(() => {
          // Adjust this if the scroll container is not the body
          // Using `document.documentElement` for scrolling the entire page, modify if you have a specific scroll container
          document.getElementById("top-of-page").scrollTo({
            top: 100,
            behavior: "smooth",
          });
        }, 1000);
      }
    }
  }, [modalDealId]);

  const temporaryDeal = deals?.find((deal) => deal.id === modalDealId)
  const isMobile = useBreakpointValue({ base: true, xl: false });

  if (isMobile) {
    return (
      <Box bg="bg.surface">
        <Box display={{ base: "block", xl: "none" }}
             mb={"20px"}
             w="full"
        >
          <HStack justifyContent={"flex-end"}>
            <Text flexShrink={0} color="gray.500" fontSize="sm">
              Sort by
            </Text>
            <Box maxW={"xs"}>
              <SortDropdown handleSort={handleSort} queryOptions={queryOptions} />
            </Box>
          </HStack>
        </Box>

        {modalDealId && <DealDrawer deals={deals} dealId={modalDealId} temporaryDeal={temporaryDeal}
                                    setModalDealId={setModalDealId}
                                    isOpen={isOpen} onOpen={onOpen} onClose={onClose} />}

        <FreeUserCta />
        <MobileSearchCards handleDealClick={handleDealClick} deals={deals} isLoading={isLoading} isFetching={isFetching} queryOptions={queryOptions} handleSort={handleSort} />
        <Container
          py={{
            base: "4",
            md: "6",
          }}>
          {meta && (
            <Pagination
              count={meta?.total_pages}
              pageSize={1}
              siblingCount={2}
              page={page}
              onChange={(e) => setPageAndScrollToTop(e)}
            />
          )}
        </Container>
      </Box>
    );

  } else {
    return (
      <>
        <Box display={{ lg: "block", "xl": "none" }}
             mb={"20px"}
             w="full"
        >
          <HStack justifyContent={"flex-end"}>
            <Text flexShrink={0} color="gray.500" fontSize="sm">
              Sort by
            </Text>
            <Box maxW={"xs"}>
              <SortDropdown handleSort={handleSort} queryOptions={queryOptions} />
            </Box>
          </HStack>
        </Box>

        <FreeUserCta />

        <Box bg="bg.surface"
             borderWidth="1px"
        >
          {modalDealId && <DealDrawer deals={deals} dealId={modalDealId} temporaryDeal={temporaryDeal}
                                      setModalDealId={setModalDealId}
                                      isOpen={isOpen} onOpen={onOpen} onClose={onClose} />}
          <Box overflowX="auto">
            <SearchTable handleDealClick={handleDealClick} deals={deals} isLoading={isLoading} isFetching={isFetching} queryOptions={queryOptions} handleSort={handleSort} />
          </Box>
          <Container
            py={{
              base: "4",
              md: "6",
            }}>
            {meta && (
              <Pagination
                count={meta?.total_pages}
                pageSize={1}
                siblingCount={2}
                page={page}
                onChange={(e) => setPageAndScrollToTop(e)}
              />
            )}
          </Container>
        </Box>
      </>
    );
  }

};



