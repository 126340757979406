import {Box, Text, VStack} from "@chakra-ui/react";
import {ResponsiveLine} from "@nivo/line";
import {
  contrastingGraphColors,
  DefaultAnalyticsContainer,
  DefaultErrorContainer,
  DefaultLoadingContainer,
  defaultTimeValues,
  TitleRowWithTimeSelector
} from "./common-visualization-elements";
import {useGetTimeSeriesBasedOnAddedAtQuery} from "../../../services/dealAnalyticsApi";
import {useState} from "react";


const allFormatSettings = {
  '# of Deals': [
    {
      id: 'Listed',
      x_axis_name: "Added to Kumo At",
      x_axis_value: "date",
      y_axis_name: "# of New Deals",
      y_axis_value: "num_new_deals",
    },
    {
      id: 'Delisted',
      x_axis_name: "Added to Kumo At",
      x_axis_value: "date",
      y_axis_name: "# of New Deals",
      y_axis_value: "num_delisted_deals",
    }
  ],
  'Avg. # of Days to Sale': [
    {
      id: 'Avg. # of Days to Sale',
      x_axis_name: "Added to Kumo At",
      x_axis_value: "date",
      y_axis_name: "Days to Sale",
      y_axis_value: "avg_days_on_market_to_delist",
    },
    {
      id: 'Median # of Days to Sale',
      x_axis_name: "Added to Kumo At",
      x_axis_value: "date",
      y_axis_name: "Days to Sale",
      y_axis_value: "median_days_on_market_to_delist",
    }
  ]
}

export const TimeSeriesGraph = ({title, filters, graphType, timePeriod, ...otherProps}) => {
  let queryHook;
  let queryOptions;
  const defaultTimeRange = (defaultTimeValues.find((time) => time.value === timePeriod) || defaultTimeValues[0]).value;
  const [currentTimePeriod, setCurrentTimePeriod] = useState(defaultTimeRange);
  queryHook = useGetTimeSeriesBasedOnAddedAtQuery;
  queryOptions = {
    filters: filters,
    unpublished_after: currentTimePeriod
  };



  const handleTimeChange = (value) => {
    setCurrentTimePeriod(value);
  };

  const { data, isLoading, isFetching, isError } = queryHook(queryOptions);

  if (isLoading || isFetching) {
    return (
      <DefaultLoadingContainer {...otherProps} >
        <TitleRowWithTimeSelector
          options={defaultTimeValues} title={title} timePeriod={currentTimePeriod} handleTimeChange={handleTimeChange} />
      </DefaultLoadingContainer>
    )
  } else if (isError) {
    return (
      <DefaultErrorContainer {...otherProps} >
        <TitleRowWithTimeSelector
          options={defaultTimeValues} title={title} timePeriod={currentTimePeriod} handleTimeChange={handleTimeChange} />
      </DefaultErrorContainer>
    )
  }

  if (data) {
    const formatSettings = allFormatSettings[graphType]
    const formattedData = data ? formatDataForTimeSeriesGraph(data, formatSettings, timePeriod) : null

    return (
      <DefaultAnalyticsContainer {...otherProps}>
        <TitleRowWithTimeSelector
          options={defaultTimeValues} title={title} timePeriod={currentTimePeriod} handleTimeChange={handleTimeChange} />
        <Box width={"full"} height={"full"}>
          <TimeSeries data={formattedData} formatSettings={formatSettings} />
        </Box>
      </DefaultAnalyticsContainer>
    )
  }
}

const formatDataForTimeSeriesGraph = (data, formatSettingsForGraph, timePeriod) => {
  let filteredData = data
  // const now = new Date();
  //
  // switch (timePeriod) {
  //   case '3 months':
  //     filteredData = data.filter(item => (now - new Date(item.month)) <= (3 * 30 * 24 * 60 * 60 * 1000));
  //     break;
  //   case '6 months':
  //     filteredData = data.filter(item => (now - new Date(item.month)) <= (6 * 30 * 24 * 60 * 60 * 1000));
  //     break;
  //   case '1 year':
  //     filteredData = data.filter(item => (now - new Date(item.month)) <= (12 * 30 * 24 * 60 * 60 * 1000));
  //     break;
  //   default:
  //     break;
  // }

  // Format the date to an ISO format (YYYY-MM-DD)
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  const formattedData = [];

  formatSettingsForGraph.forEach((formatSetting) => {
    let dataSet = filteredData.map(d => {
      return {
        x: formatDate(d.date), // Use the formatDate function
        y: d[formatSetting.y_axis_value],
        ...d
      };
    });

    formattedData.push({
      id: formatSetting.id,
      data: dataSet
    });
  });

  return formattedData;
};

const customTheme = {
  axis: {
    domain: {
      line: {
        strokeWidth: '1',
        stroke: 'rgba(204,204,204,0.6)'
      }
    },
    ticks: {
      text: {
        fontSize: 12, // Change the font size
        fill: 'gray', // Text color
        // Add additional styles as needed
      },
      line: {
        stroke: 'gray', // Grey border color
        strokeWidth: 1, // Border width
      }
    },
    legend: {
      text: {
        fontSize: 18,    // Change the font size for legend
        fill: 'gray', // Text color
        fontWeight: 700  // Font weight for legend
      }
    }
  },
  labels: {
    text: {
      // Customize the label color, font size, and weight
      fill: '#ffffff', // Text color
      fontSize: 16,    // Font size
      fontWeight: 600  // Font weight
    }
  },
  legends: {
    text: {
      // Customize the legend color and font size
      // fill: '#ffffff', // Text color
      fontSize: 16,    // Font size
    }
  },
  crosshair: {
    line: {
      stroke: 'blue', // Custom color for the crosshair line
      strokeWidth: 1, // Custom stroke width
      // strokeOpacity: 0.75, // Custom stroke opacity
      strokeDasharray: null // Custom dash pattern (optional)
    }
  }

};

const CustomTooltip = (point) => {

  const date = new Date(point.slice.points[0].data.date).toLocaleDateString();

  return (
    <Box bg="white" boxShadow="sm" borderRadius="lg" width={"200px"} border={"1px"} borderColor={"gray.300"}>
      <Box bg="gray.100" p={2}>
        <Text fontSize="md" fontWeight="bold">{date}</Text>
      </Box>
      <Box>
        <VStack align="start" spacing={"0"}>
          {point.slice.points.map((pt, index) => (
            <Box key={index} borderLeft={`4px solid ${pt.serieColor}`} pl={2} mt={0} py={"2"}>
              <Text fontSize="md" fontWeight="bold" color={pt.serieColor}>{pt.serieId}</Text>
              <Text fontSize="sm">{parseInt(pt.data.yFormatted).toLocaleString()}</Text>
            </Box>
          ))}
        </VStack>
      </Box>

    </Box>
  );
};

const TimeSeries = ({formatSettings, data}) => {
  return(
    <>
      <ResponsiveLine
        data={data}
        theme={customTheme}
        colors={contrastingGraphColors}
        colorBy="indexValue"
        enableGridX={false}
        sliceTooltip={CustomTooltip}
        margin={{ top: 50, right: 20, bottom: 40, left: 60 }}
        xScale={{
          type: 'time',
          format: "%Y-%m-%d", // Adjust this format to match your date data
          precision: "day",
        }}
        xFormat="time:%Y-%m-%d"
        curve="catmullRom"
        yScale={{
          type: 'linear',
          min: 0,
          max: 'auto',
          stacked: false,
          reverse: false
        }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          format: "%b '%y", // Format of the tick labels (e.g., Jan 01)
          tickValues: "every 1 month", // Adjust the frequency of ticks
          // legend: "Week",
          // legendOffset: 26,
          // legendPosition: 'middle',
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          // legend: formatSettings[0].y_axis_name,
          // legendOffset: -60,
          // legendPosition: 'middle'
        }}
        pointSize={2}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
        enableSlices="x"
        enableCrosshair={true}
        crosshairType="vertical"
        legends={[
          {
            anchor: 'top-left', // Position the legend at the top left
            direction: 'row', // Align the legend items in a row
            justify: false,
            translateX: 0, // Adjust horizontal position, 0 for left alignment
            translateY: -40, // Adjust vertical position to move above the graph
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 210,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)'
          }
        ]}
      />

    </>
  )
}