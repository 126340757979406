import { useToast } from "@chakra-ui/react";
import React from "react";
import { defaultErrorToastSettings } from "../constants/default-error";

interface Props {
  children?: any;
}

interface State {
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error("error: ", error, "errorInfo: ", errorInfo);
  }

  render() {
    if (this.state.hasError) {
      const toast = useToast();
      toast(defaultErrorToastSettings);

      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}
