import {
  Avatar,
  AvatarGroup,
  Box,
  Center,
  DarkMode,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import * as React from "react";
import {NavLink as ReactRouterLink} from "react-router-dom";
import {LogoImage} from "../../components/LogoImage";

const currentYear = new Date().getFullYear();

export const AuthenticationLeftBackground = ({ body }: { body: JSX.Element }) => (
  <Flex
    minH={{ base: "auto", md: "100vh" }}
    bgGradient={useBreakpointValue({
      md: mode("linear(to-r, blue.600 50%, white 50%)", "linear(to-r, blue.600 50%, gray.900 50%)"),
    })}>
    <Flex maxW="8xl" mx="auto" width="full">
      <Box flex="1" display={{ base: "none", md: "block" }} color={"white"}>
        <DarkMode>
          <Flex direction="column" px={{ base: "4", md: "8" }} height="full" color="on-accent">
            <Flex align="center" h="24" as={ReactRouterLink} to={"/"}>
              <HStack>
                <LogoImage logoColor={'white'} maxWidth={"150px"} />
              </HStack>
            </Flex>
            <Flex flex="1" align="center">
              <Stack spacing="8">
                <Stack spacing="6">
                  <Heading size={useBreakpointValue({ md: "lg", xl: "xl" })}>
                    Find every deal in one platform.
                  </Heading>
                  <Text fontSize="lg" maxW="md" fontWeight="medium">
                    View 100K+ active deals sourced from 200K+ listings on Kumo.
                  </Text>
                  <Text fontWeight="medium">Join Kumo today for free!</Text>
                </Stack>
                <HStack spacing="4">
                  <AvatarGroup
                    size="md"
                    max={useBreakpointValue({ base: 2, lg: 5 })}
                    borderColor="fg.accent.default"
                  >
                    <Avatar name="Ryan Florence" src="https://bit.ly/ryan-florence" />
                    <Avatar name="Segun Adebayo" src="https://bit.ly/sage-adebayo" />
                    <Avatar name="Kent Dodds" src="https://bit.ly/kent-c-dodds" />
                    <Avatar name="Prosper Otemuyiwa" src="https://bit.ly/prosper-baba" />
                    <Avatar name="Christian Nwamba" src="https://bit.ly/code-beast" />
                  </AvatarGroup>
                </HStack>
              </Stack>
            </Flex>
            <Flex align="center" h="24">
              <Text color="fg.accent.subtle" fontSize="sm">
                © {currentYear} Kumo. All rights reserved.
              </Text>
            </Flex>
          </Flex>
        </DarkMode>
      </Box>
      <Center flex="1">{body}</Center>
    </Flex>
  </Flex>
);
