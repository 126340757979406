import {ScrollMenu, VisibilityContext} from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import './HorizontalScrollBox.css'
import React from "react";
import {Box, HStack, IconButton} from "@chakra-ui/react";
import {FiArrowLeft, FiArrowRight} from "react-icons/fi";

export const HorizontalScrollBox = ({ children, ...otherProps }) => {

  return (
    <Box
      position={"relative"}
      {...otherProps}
    >
      <ScrollMenu
        LeftArrow={LeftArrow}
        RightArrow={RightArrow}
        style={{ width: '100%' }}
        // Footer={<Arrows />}
      >
        <Box pl={"2px"}>
        </Box>
        {children}
      </ScrollMenu>
    </Box>
  );
}

const Arrows = () => {
  return(
    <Box display="flex" justifyContent="center" alignItems="center" mt={"10px"}>
      <HStack spacing={"4"}>
        <LeftArrow />
        <RightArrow />
      </HStack>
    </Box>
  )
}

function Arrow({ children, disabled, onClick, icon, ...props  }) {
  return (
    <IconButton
      isDisabled={disabled}
      onClick={onClick}
      variant="secondary"
      isRound
      icon={icon}
      bgColor={disabled ? null: "white"}
      position="absolute"
      top="50%"
      visibility={!disabled ? "visible" : "hidden"}
      transform="translateY(-50%)"
      zIndex="1"
      {...props}
    />
  );
}

export function LeftArrow() {
  const {
    isFirstItemVisible,
    scrollPrev,
    visibleElements,
    initComplete
  } = React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !initComplete || (initComplete && isFirstItemVisible && visibleElements.length > 0)
  );
  React.useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleElements.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleElements]);

  return (
    <Arrow disabled={disabled} onClick={() => scrollPrev()} icon={<FiArrowLeft />}>
    </Arrow>
  );
}

export function RightArrow() {
  const {
    isLastItemVisible,
    scrollNext,
    visibleElements,
    initComplete
  } = React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !initComplete || (initComplete && !visibleElements.length && isLastItemVisible && visibleElements.length > 0)
  );
  React.useEffect(() => {
    if (visibleElements.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleElements]);

  return (
    <Arrow disabled={disabled} onClick={() => scrollNext()}
           icon={<FiArrowRight />}
           right="10px"
    />
  );
}