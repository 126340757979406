import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure
} from "@chakra-ui/react";
import * as React from "react";
import {useState} from "react";
import {useCreateSavedSearchMutation} from "../../../services/savedSearchesApi";
import {FiSave} from "react-icons/fi";
import {SearchFiltersForm} from "../../../components/search-filters-form";
import {SearchSettingsForm} from "./SearchSettingsForm";
import {useSelector} from "react-redux";
import {selectDefaultFilters} from "../../../store/local-search";
import {useNavigate} from "react-router-dom";

export const CreateSearchButton = ({filters, customButton}) => {
  const defaultFilters = useSelector(selectDefaultFilters)
  const defaultSearch = {
    attributes: {
      title: "",
      include_in_weekly_digest: "true",
      filters: filters || defaultFilters
    }
  }

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [localSearch, setLocalSearch] = useState(defaultSearch);

  const [createSavedSearch] = useCreateSavedSearchMutation();
  const navigate = useNavigate();

  const createSearch = async () => {
    const { data } = await createSavedSearch({
      title: localSearch.attributes.title,
      include_in_weekly_digest: localSearch.attributes.include_in_weekly_digest,
      filters: localSearch.attributes.filters,
    });

    if (data) {
      navigate(`/search/saved/${data?.data?.id}`);
      onClose();
    }
  }

  const updateLocalSearch = (key, value) => {
    setLocalSearch({
      ...localSearch,
      attributes: {
        ...localSearch.attributes,
        [key]: value
      }
    })
  }

  const [tabIndex, setTabIndex] = useState(0)

  const handleFiltersChange = (filters) => {
    updateLocalSearch("filters", filters)
  }

  const handleClose = () => {
    onClose()
    setLocalSearch(defaultSearch)
  }

  const allFormsFilledOut = localSearch.attributes.title.length > 0 && Object.keys(localSearch.attributes.filters).length > 0

  return (
    <>
      {customButton ? <customButton onClick={onOpen} /> :
        <Button size={"xs"} leftIcon={<FiSave />} variant='outline' onClick={onOpen}>
          Save Search
        </Button>
      }

      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create New Saved Search</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Tabs onChange={(index) => setTabIndex(index)}>
              <TabList>
                <Tab>Settings</Tab>
                <Tab>Search Filters</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <SearchSettingsForm localSearch={localSearch} updateLocalSearch={updateLocalSearch} />
                </TabPanel>
                <TabPanel>
                  <SearchFiltersForm filters={localSearch.attributes.filters} handleFiltersChange={handleFiltersChange} />
                </TabPanel>
              </TabPanels>
            </Tabs>
            {}
          </ModalBody>

          <ModalFooter>
            <Button variant="outline" mr={3} onClick={handleClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={createSearch} isDisabled={!allFormsFilledOut}>
              Create Search
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};