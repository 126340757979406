import {useMutation} from "@apollo/client";
import {sort} from "fast-sort";
import {Box, Flex, FormLabel, HStack, SimpleGrid, Stack} from "@chakra-ui/react";
import {CustomSelect, Option} from "../../../components/custom-select";
import {UPDATE_DEAL_FLOW_SETTING} from "../../../graphql/mutations/update-deal-flow-setting";
import {DealFlowStagesDrawer} from "../deal-flow-stages-drawer";
import {CheckboxFilterPopover} from "./CheckboxFilterPopover";

export const DealManagementFilters = ({dealFlowSettingId, filters, stages,}) => {
  if (!filters) {
    return null;
  }
  const stageOptions = [{ label: "Show All", value: "All" }].concat(
    sort(stages)
      .asc((s) => s.order)
      .map((s) => ({ label: s?.name || "", value: s?.id || "" }))
  );
  const notHiddenStageIds = filters.stageIds || [];
  const daysAgoFilter = filters.addedDaysAgo;
  const hasNotesFilter = filters.showNotes;
  const defaultValues = stages
    .filter((s) => notHiddenStageIds.includes(Number(s.id)))
    .map((s) => s?.id || "");
  const dayFilterOptions = [
    { key: null, value: "Show All" },
    { key: 7, value: "Added last 7 days" },
    { key: 30, value: "Added last 30 days" },
    { key: 90, value: "Added last 90 days" },
  ];
  const hasNotesFilterOptions = [
    {
      key: "Show All",
      value: "Show All",
    },
    {
      key: "No Notes",
      value: "No Notes",
    },
    {
      key: "Has Notes",
      value: "Has Notes",
    },
  ];
  const currentDayFilterOption = dayFilterOptions.find((option) => option.key === daysAgoFilter);
  const currentHasNotesFilterOption = hasNotesFilterOptions.find(
    (option) => option.key === hasNotesFilter
  );
  const [updateDealFlowSetting] = useMutation(UPDATE_DEAL_FLOW_SETTING);
  const handleOnSubmit = (values) => {
    updateDealFlowSetting({
      variables: {
        dealFlowSettingId: dealFlowSettingId,
        stageIds: values.map((s) => Number(s)),
      },
    });
  };

  return (
    <Box mx="0" px={{ base: "4", md: "6", lg: "6" }} py={{ base: "0", md: "0", lg: "0" }} mt={4}>
      <Flex justify="space-between" align="center" display={{ base: "none", md: "flex" }}>
        <Box flex="1">
          <HStack spacing="6" justifyContent={"space-between"}>
            <SimpleGrid display="inline-grid" spacing="4" columns={3}>
              <CheckboxFilterPopover buttonLabel="Deal Stages" options={stageOptions} defaultValue={defaultValues} onSubmit={handleOnSubmit} />
              <Stack spacing={0} minW={"250px"}>
                <FormLabel>Date Added to Pipeline</FormLabel>
                <CustomSelect
                  value={currentDayFilterOption?.value}
                  onChange={(ev) => {
                    const key = dayFilterOptions.find((option) => option.value === ev)?.key || null;
                    updateDealFlowSetting({
                      variables: {
                        dealFlowSettingId: dealFlowSettingId,
                        addedDaysAgo: key,
                      },
                    });
                  }}>
                  {dayFilterOptions.map((option) => (
                    <Option key={option.key} value={option.value} />
                  ))}
                </CustomSelect>
              </Stack>
              <Stack spacing={0} minW={"250px"}>
                <FormLabel>Notes?</FormLabel>
                <CustomSelect
                  value={currentHasNotesFilterOption?.value}
                  onChange={(ev) => {
                    const key = (hasNotesFilterOptions.find((option) => option.value === ev)?.key || "Show All");
                    updateDealFlowSetting({
                      variables: {
                        dealFlowSettingId: dealFlowSettingId,
                        showNotes: key,
                      },
                    });
                  }}>
                  {hasNotesFilterOptions.map((option) => (
                    <Option key={option.key} value={option.value} />
                  ))}
                </CustomSelect>
              </Stack>
            </SimpleGrid>
            <DealFlowStagesDrawer />
          </HStack>
        </Box>
      </Flex>
    </Box>
  );
};
