import { gql } from "@apollo/client";

export const DEAL_FLOW_SETTING = gql`
  query DealFlowSetting {
    dealFlowSetting {
      id
      accountId
      userId
      filters
    }
  }
`;
