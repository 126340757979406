import {HStack, Icon, Select, Text, Th, Thead, Tr} from "@chakra-ui/react";
import {IoArrowDown, IoArrowUp} from "react-icons/io5";

export const Header = ({ headerAttributes, queryOptions, handleSort, ...props }) => {
  const { headerText, sortable, sortOrder, sortField } = headerAttributes;

  let directionIcon;

  if (queryOptions.sortField === sortField) {
    if (queryOptions.sortOrder === "ASC") {
      directionIcon = <Icon as={IoArrowUp} color="muted" boxSize="4" />;
    } else if (queryOptions.sortOrder === "DESC") {
      directionIcon = <Icon as={IoArrowDown} color="muted" boxSize="4" />;
    } else {
      directionIcon = <></>;
    }
  } else {
    directionIcon = <></>;
  }

  const manageSort = () => {
    if (sortable) {
      const newSort = {
        sortField: sortField
      }

      if (queryOptions.sortField === sortField) {
        newSort.sortOrder = (queryOptions.sortOrder === "ASC" ? "DESC" : "ASC")
      } else {
        newSort.sortOrder = "DESC";
      }
      handleSort(newSort);
    }
  };

  return (
    <Th onClick={() => manageSort()} cursor={sortable ? "pointer" : "default"}
        {...props }
    >
      <HStack spacing="3"
              // This is just to override spacing when in XL view
              py={{base: "6px", "2xl": "0px"}}

      >
        <HStack spacing="1">
          <Text>{headerText}</Text>
          {sortable && directionIcon}
        </HStack>
      </HStack>
    </Th>
  );
};

export const buildHeaderAttributes = ({ indexType }) => {
  let attributeArray = [];

  attributeArray.push({
    headerText: "Overview",
    sortable: false,
    // sortOrder: "ASC",
    // sortField: "updated_at",
    width:
      {base: ['450px', 'max(450px, 50vw)'], "xl": ['400px', 'max(400px, 30vw)'], "2xl": ['300px', 'max(300px, 30vw)']}
    ,
  });

  switch (indexType) {
    case 'user_history':
      attributeArray.push({
        headerText: "First Viewed At",
        sortable: true,
        sortOrder: "DESC",
        sortField: "deal_read_associations.created_at",
      });
      break;
    case 'user_favorites':
      attributeArray.push({
        headerText: "Favorited At",
        sortable: true,
        sortOrder: "ASC",
        sortField: "favorites.created_at",
      });
      break;
    case 'deal_pipeline':
      attributeArray.push({
        headerText: "Added to Pipeline At",
        sortable: true,
        sortOrder: "ASC",
        sortField: "deal_flow_prospects.created_at",
      });
      break;
    default:
      break;
  }


  attributeArray.push({
    headerText: "Price",
    sortable: true,
    sortOrder: "DESC",
    sortField: "sortable_asking_price",
    // specialDisplay: {base: "none", "2xl": "table-cell"}
  });
  attributeArray.push({
    headerText: "Revenue",
    sortable: true,
    sortOrder: "DESC",
    sortField: "sortable_annual_revenue",
    // specialDisplay: {base: "none", "2xl": "table-cell"}
  });
  attributeArray.push({
    headerText: "EBITDA/SDE",
    sortable: true,
    sortOrder: "DESC",
    sortField: "sortable_annual_ebitda_or_sde",
    // specialDisplay: {base: "none", "2xl": "table-cell"}
  });
  attributeArray.push({
    headerText: "Added at",
    sortable: true,
    sortOrder: "DESC",
    sortField: "added_to_kumo_at",
  });
  attributeArray.push({
    headerText: "",
    sortable: false,
    sortOrder: "ASC",
    sortField: "name",
  });

  return attributeArray;
};

export const buildSortableHeadersForDropdown = ({indexType}) => {
  let attributeArray = buildHeaderAttributes({indexType});
  let options = [];

  attributeArray.forEach(attr => {
    if (attr.sortable) {
      options.push({
        text: `${attr.headerText} (Low to High)`,
        value: `${attr.sortField}-ASC`,
      });
      options.push({
        text: `${attr.headerText} (High to Low)`,
        value: `${attr.sortField}-DESC`,
      });
    }
  });

  return options;
};

export const SortDropdown = ({ indexType, queryOptions, handleSort }) => {
  const sortOptions = buildSortableHeadersForDropdown({indexType});

  const handleSortInDropdown = (event) => {
    const [field, order] = event.target.value.split('-');
    const newSort = {
      sortField: field,
      sortOrder: order,
    };
    handleSort(newSort);
  };

  return (
    <Select size="sm"
            aria-label="Sort by"
            defaultValue={`${queryOptions.sortField}-${queryOptions.sortOrder}`}
            rounded="md"
            onChange={handleSortInDropdown}
    >
      {sortOptions.map(option => (
        <option key={option.value} value={option.value}>
          {option.text}
        </option>
      ))}
    </Select>
  );
};


export const TableHeaders = ({ indexType, queryOptions, handleSort }) => {
  return (
    <Thead>
      <Tr>
        {buildHeaderAttributes({ indexType }).map((headerAttributes, index) => (
          <Header
            headerAttributes={headerAttributes}
            queryOptions={queryOptions}
            handleSort={handleSort}
            key={index}
            maxWidth={headerAttributes.width}
            width={headerAttributes.width}
            // display={"none"}
            // display={headerAttributes.specialDisplay}
            display={headerAttributes.specialDisplay ? headerAttributes.specialDisplay : "table-cell"}
          />
        ))}
      </Tr>
    </Thead>
  );
};

export default TableHeaders;
