import {PriceRangePicker} from "./search-filters-form/PriceRangePicker";
import {
	Box,
	Button,
	Flex,
	Icon,
	Input,
	InputGroup,
	InputRightElement,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Spinner,
	Stack,
	Text,
	useDisclosure
} from "@chakra-ui/react";
import {FiSearch, FiXCircle} from "react-icons/fi";
import {CheckboxSearchForm} from "./search-filters-form/CheckboxSearchForm";
import {useEffect, useRef, useState} from "react";
import {useGetChildIndustriesQuery} from "../services/savedSearchesApi";
import {RadioButtonGroupForm} from "./search-filters-form/RadioButtonGroupForm";
import {FormValueOptions} from "./search-filters-form/FormValueOptions";
import {debounce} from 'lodash';
import LocationFilter from "./search-filters-form/LocationFilter";
import TextSearchFilterGroup from "./search-filters-form/TextSearchFilterGroup";
import {IoMdTime} from "react-icons/io";
import {MdChangeHistory} from "react-icons/md";
import {BiDollar} from "react-icons/bi";
import {LiaIndustrySolid} from "react-icons/lia";
import {PiEye, PiEyeSlash} from "react-icons/pi";

export const SearchFiltersForm = ({filters, handleFiltersChange}) => {

	const handleFilterChange = (filterKey, filterValue) => {
		handleFiltersChange({
			...filters,
			[filterKey]: filterValue
		})
	}


	return (
		<>
			<NumbersRangeFilter label={"Asking Price"}
													min={0}
													max={100000000}
													icon={BiDollar}
													filterKey={"price"} filters={filters} handleFilterChange={handleFilterChange} />

			<NumbersRangeFilter label={"Revenue"}
													min={0}
													max={100000000}
													icon={BiDollar}
													filterKey={"revenue"} filters={filters} handleFilterChange={handleFilterChange} />

			<NumbersRangeFilter label={"EBITDA/SDE"}
													min={0}
													max={100000000}
													icon={BiDollar}
													filterKey={"ebitda"} filters={filters} handleFilterChange={handleFilterChange} />

			<NumbersRangeFilter label={"Price-to-Profit Multiple"}
													min={0}
													max={100}
													icon={BiDollar}
													numberFormat={'multiple'}
													filterKey={"price_to_ebitda_or_sde_ratio"} filters={filters} handleFilterChange={handleFilterChange} />

			<FilterSection>
				<IndustryFilter label={"Industry"}
												filterKey={"dealChildIndustryId"} filters={filters} handleFilterChange={handleFilterChange} />

			</FilterSection>

			<FilterSection>
				<LocationFilter filters={filters} handleFilterChange={handleFilterChange} handleFiltersChange={handleFiltersChange} />
			</FilterSection>

			<FilterSection>
				<TextSearchFilterGroup filters={filters} handleFilterChange={handleFilterChange} handleFiltersChange={handleFiltersChange} />
			</FilterSection>

			<FilterSection>
				<RadioButtonFilter label={"Deals with Changed Numbers (e.g., price)"} options={FormValueOptions} filterKey={"quantitativeChanges"} filters={filters} handleFilterChange={handleFilterChange} icon={MdChangeHistory} />
			</FilterSection>

			<FilterSection>
				<RadioButtonFilter label={"Deals I've Viewed"} options={FormValueOptions} filterKey={"showRead"} filters={filters} handleFilterChange={handleFilterChange} icon={PiEye} />
			</FilterSection>

			<FilterSection>
				<RadioButtonFilter label={"Deals I've Hidden"} options={FormValueOptions} filterKey={"showHidden"} filters={filters} handleFilterChange={handleFilterChange} icon={PiEyeSlash}  />
			</FilterSection>

			<FilterSection>
				<RadioButtonFilter label={"Days on Kumo"} options={FormValueOptions} filterKey={"addedDaysAgo"} filters={filters} handleFilterChange={handleFilterChange} icon={IoMdTime} />
			</FilterSection>


		</>
	)
}

const FilterSection = ({children}) => {
	return (
		<>
			<Box mt={"12"}>
				{children}
			</Box>
		</>
	)
}

export const SearchFilterLabel = ({label, subtext, icon, ...props}) => {
	return (
		<>
			<Flex align="center" mb="3">
				<Icon as={icon} fontSize="xl" color="gray.600" mr={"6px"}></Icon>
				<Text fontWeight="medium">{label}{subtext && ` ${subtext}`}</Text>
			</Flex>
		</>
	)

}


export const TextSearchFilter = ({ label, filterKey, filters, handleFilterChange, ...props }) => {
	const [localValue, setLocalValue] = useState(filters[filterKey] || "");

	const hasValue = Boolean(localValue && localValue.length > 0);

	// Reference for the debounced function
	const debouncedChangeRef = useRef();

	// This function cancels the current debounced call if there is one
	const cancelDebouncedChange = () => {
		if (debouncedChangeRef.current) {
			debouncedChangeRef.current.cancel();
		}
	};

	const handleLocalChange = (value) => {
		setLocalValue(value);  // immediate local update

		// Cancel the previous debounced call
		cancelDebouncedChange();

		// Set a new debounced function
		debouncedChangeRef.current = debounce(() => handleFilterChange(filterKey, value), 700);
		debouncedChangeRef.current();  // Call the debounced function
	};

	// Cleanup on component unmount
	useEffect(() => {
		return () => cancelDebouncedChange();
	}, []);

	return (
		<>
			<InputGroup>
				<Input
					type="text"
					placeholder="Search for one phrase"
					value={localValue}  // Use localValue for input's value
					onChange={(e) => handleLocalChange(e.target.value)}
				/>
				<InputRightElement
					color="gray.400"
					fontSize="lg"
					onClick={hasValue ? () => {
						handleLocalChange("");
					} : null}
					cursor={hasValue ? "pointer" : "default"}
				>
					{localValue !== filters[filterKey] ? <Spinner /> : (hasValue ? <FiXCircle /> : <FiSearch />)}
				</InputRightElement>
			</InputGroup>
		</>
	);
}


const RadioButtonFilter = ({label, filterKey, filters, options, handleFilterChange, icon, ...props}) => {
	const handleChange = (value) => {handleFilterChange(filterKey, value)}
	return (
		<>
			<SearchFilterLabel label={label} icon={icon} />
			<RadioButtonGroupForm
				options={options[filterKey]}
				value={filters[filterKey]}
				onChange={handleChange}
			/>
		</>
	)
}

const NumbersRangeFilter = ({label, filterKey, filters, handleFilterChange, numberFormat, icon, ...props}) => {
	return(
		<>
			<Stack spacing="1" mb={"10"}>
				<SearchFilterLabel label={label} icon={icon} />
				<Box px={"3"}>
					<PriceRangePicker
						filterKey={filterKey}
						filterValue={filters[filterKey]}
						onChange={handleFilterChange}
						numberFormat={numberFormat}
						{...props}
					/>
				</Box>

			</Stack>
		</>
	)
}
const IndustryFilter = ({label, filterKey, filters, handleFilterChange, ...props}) => {
	const { data: industriesData, isLoading, isFetching, isError } = useGetChildIndustriesQuery();
	const items = industriesData?.data?.map(industry => { return {id: industry.id, label: industry.attributes.label, group: industry.attributes.group}}) || []

	const originalValues = filters[filterKey] || []

	const labelText = `${label} ${originalValues.length > 0 ? `(${originalValues.length} selected)` : '(none selected)'}`
	return(
		<>
			<SearchFilterLabel label={labelText} icon={LiaIndustrySolid} />
			<CheckBoxModalForm
				items={items}
				label={label}
				filterKey={filterKey}
				filters={filters}
				isLoading={isLoading}
				handleFilterChange={handleFilterChange}
			/>
		</>
	)
}



export const CheckBoxModalForm = ({items, label, isLoading, filterKey, filters, handleFilterChange, placeholder, ...props}) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [localValues, setLocalValues] = useState(filters[filterKey] || [])

	const originalValues = filters[filterKey] || []

  const arraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }
    return true;
  };

	useEffect(() => {
		setLocalValues(filters[filterKey] || [])
	}, [filters]);

	const handleApplyChanges = () => {
		handleFilterChange(filterKey, localValues);
		onClose();
	}

	const handleUndoChanges = () => {
		setLocalValues(originalValues);
		onClose();
	}

	const changesMade = !arraysEqual(localValues, originalValues);

	return(
		<>
			<InputGroup size="md" pb="1" onClick={onOpen}>
				<Input
					placeholder={placeholder || "Search..."}
					rounded="md"
					focusBorderColor={'brand.500'}
				/>
				<InputRightElement pointerEvents="none" color="gray.400" fontSize="lg">
					<FiSearch />
				</InputRightElement>
			</InputGroup>

			<Modal isOpen={isOpen} onClose={onClose} size={"lg"}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{label} ({localValues.length} selected)</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<CheckboxSearchForm
							key={filterKey}
							isLoading={isLoading} items={items}
							values={localValues} onChange={setLocalValues} />
					</ModalBody>
					<ModalFooter>
						<Flex w="100%" align="center">
							<Button
								variant="link"
								onClick={() => { setLocalValues([]) }}
								isDisabled={!(localValues.length > 0)}
							>
								Unselect All
							</Button>

							{!changesMade &&
								<Button colorScheme="blue" ml='auto' onClick={handleApplyChanges} isDisabled={!changesMade}>
									No Changes to Apply
								</Button>
							}

							{changesMade &&
								<Button variant="outline" ml={'auto'} mr={3} onClick={handleUndoChanges}>
									Undo Changes
								</Button>
							}

							{changesMade &&
								<Button colorScheme="blue" onClick={handleApplyChanges} isDisabled={!changesMade}>
									Apply Changes
								</Button>
							}
						</Flex>



					</ModalFooter>
				</ModalContent>
			</Modal>
		</>

	)
}