import {ResponsiveBoxPlot} from "@nivo/boxplot";
import {useGetBoxPlotQuery} from "../../../services/dealAnalyticsApi";
import {
  darkerCustomColors,
  DefaultAnalyticsContainer,
  DefaultErrorContainer,
  DefaultLoadingContainer,
  defaultTimeValues,
  TitleRowWithTimeSelector
} from "./common-visualization-elements";
import {useState} from "react";
import {Box} from "@chakra-ui/react";


const prettySubGroupNames = {
  "asking_price": "Asking Price",
  "annual_revenue": "Annual Revenue",
  "annual_ebitda_or_sde": "Annual SDE/Profit"
}

export const BoxPlot = ({title, filters, ymetric, buckets, titleToolTip, ...otherProps}) => {
  const defaultTimeRange = defaultTimeValues[0].value;
  const [currentTimePeriod, setCurrentTimePeriod] = useState(defaultTimeRange);

  let queryHook;
  let queryOptions;
  queryHook = useGetBoxPlotQuery;
  queryOptions = {
    filters: filters,
    buckets: buckets,
    added_at_time_range: currentTimePeriod
  };

  const handleTimeChange = (value) => {
    setCurrentTimePeriod(value);
  };

  const { data, isLoading, isFetching, isError } = queryHook(queryOptions);
  if (isLoading || isFetching) {
    return (
      <DefaultLoadingContainer {...otherProps} >
        <TitleRowWithTimeSelector
          options={defaultTimeValues} title={title} timePeriod={currentTimePeriod} handleTimeChange={handleTimeChange} titleToolTip={titleToolTip}/>
      </DefaultLoadingContainer>
    )
  } else if (isError) {
    return (
      <DefaultErrorContainer {...otherProps} >
        <TitleRowWithTimeSelector
          options={defaultTimeValues} title={title} timePeriod={currentTimePeriod} handleTimeChange={handleTimeChange} titleToolTip={titleToolTip}/>
      </DefaultErrorContainer>
    )
  }

  if (data) {
    const transformedData = data ? transformToNivoFormat(
      data.filter(item => item.filtered_by === ymetric)
    ) : [];

    return (
     <DefaultAnalyticsContainer {...otherProps}>
       <TitleRowWithTimeSelector
         options={defaultTimeValues} title={title} timePeriod={currentTimePeriod} handleTimeChange={handleTimeChange} titleToolTip={titleToolTip} />
       <Box width={"full"} height={"full"}>
         <BoxPlotWithFormatting data={transformedData} />
       </Box>

     </DefaultAnalyticsContainer>
    )
  }


}


function transformToNivoFormat(rawData) {
  const nivoData = [];
  const sampleSize = 20; // Number of data points to generate for each bucket

  rawData.forEach(item => {
    const min = item.ratio_10_perc;
    const q1 = item.ratio_25_perc;
    const median = item.ratio_50_perc;
    const q3 = item.ratio_75_perc;
    const max = item.ratio_90_perc;

    const group = item.bucket;
    const subgroup = item.filtered_by; // or any other appropriate designation

    for (let i = 0; i < sampleSize; i++) {
      const randomValue = generateRandomValue(min, q1, median, q3, max);
      nivoData.push({
        group: group,
        subgroup: prettySubGroupNames[subgroup],
        mu: median, // Assuming median as mean for simplicity
        sd: (q3 - q1) / 1.349, // Approximation of standard deviation
        n: sampleSize,
        value: randomValue
      });
    }
  });

  return nivoData;
}

function generateRandomValue(min, q1, median, q3, max) {
  // Simple random generator for demonstration purposes
  // This could be replaced with a more accurate statistical distribution model
  const range = Math.random();
  if (range < 0.01) return min;
  if (range < 0.25) return Math.random() * (q1 - min) + min;
  if (range < 0.5) return Math.random() * (median - q1) + q1;
  if (range < 0.75) return Math.random() * (q3 - median) + median;
  if (range < 0.99) return Math.random() * (max - q3) + q3;
  return max;
}


const customTheme = {
  axis: {
    domain: {
      line: {
        strokeWidth: '1',
        stroke: 'rgba(204,204,204,0.6)'
      }
    },
    ticks: {
      text: {
        fontSize: 12, // Change the font size
        fill: 'gray', // Text color
        // Add additional styles as needed
      },
      line: {
        stroke: 'gray', // Grey border color
        strokeWidth: 1, // Border width
      }
    },
    legend: {
      text: {
        fontSize: 18,    // Change the font size for legend
        fill: 'gray', // Text color
        fontWeight: 700  // Font weight for legend
      }
    }
  },
  labels: {
    text: {
      // Customize the label color, font size, and weight
      fill: '#ffffff', // Text color
      fontSize: 16,    // Font size
      fontWeight: 600  // Font weight
    }
  },

};

const BoxPlotWithFormatting = ({ data, ...otherProps }) => {
  let maxDataValue = Number.MIN_VALUE; // Initialize to the smallest possible number

  data.forEach(item => {
    if (item.value > maxDataValue) {
      maxDataValue = item.value;
    }
  });

  const thresholdValue = 20;
  const maxValue = Math.min(maxDataValue, thresholdValue);

  return (
    <ResponsiveBoxPlot
      {...otherProps}
      data={data}
      theme={customTheme}
      colors={ darkerCustomColors }
      margin={{ top: 0, right: 20, bottom: 60, left: 60 }}
      minValue={0}
      maxValue={maxValue}
      subGroupBy="subgroup"
      layout="horizontal"
      padding={0.35}
      innerPadding={0}
      tooltip={'none'}
      enableGridY={false}
      enableGridX={false}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Price-to-Profit Multiple',
        legendPosition: 'middle',
        legendOffset: 32,
        format: value => (value) + 'x',
      }}
      axisLeft={undefined}
      colorBy="group"
      borderRadius={2}
      borderWidth={2}
      borderColor={{
        from: 'color',
        modifiers: [
          [
            'darker',
            0.3
          ]
        ]
      }}
      medianWidth={4}
      medianColor={{
        from: 'color',
        modifiers: [
          [
            'darker',
            0.3
          ]
        ]
      }}
      whiskerWidth={3}
      whiskerEndSize={0.45}
      whiskerColor={{
        from: 'color',
        modifiers: [
          [
            'darker',
            0.3
          ]
        ]
      }}
      motionConfig="stiff"
    />
  )
}