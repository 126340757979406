import {Outlet} from 'react-router-dom';
import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  IconButton,
  Spinner,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {Navbar} from "./navbar/Navigation";
import Helmet from "react-helmet";
import {SubscriptionBanner} from "../../components/subscription-banner";
import {useQuery} from "@apollo/client";
import {CURRENT_USER_QUERY} from "../../graphql/current-user";
import {HeadingContainerWithNavbar} from "./SidebarLayout";
import {SignupForFreeModal} from "../../components/upgrade-ctas/signup-for-free-modal";
import {FiX} from 'react-icons/fi'

export const DealLayout = (props) => {
  const [sidebarIsScrolled, setSidebarIsScrolled] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { loading, data } = useQuery(CURRENT_USER_QUERY);
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const currentUser = data?.currentUser;

  // Get the value from localStorage
  const pricingSurveySnooze = localStorage.getItem('pricingSurveySnooze');
  const subscriptionLevel = data?.currentUser?.account?.subscriptionLevel || "Public"

  // Check if the snooze value is either null or less than the current date/time.
  // The `+` before pricingSurveySnooze converts the string to a number (date timestamp in this case).
  const pricingSurveySnoozeExpired = !pricingSurveySnooze || +pricingSurveySnooze < Date.now();


  useEffect(() => {
    const destroyWidget = () => {
      try {
        window.FreshworksWidget('destroy');
      } catch (e) {
        // Don't know the method to check if FreshworksWidget is still mounted...
        // console.log(e);
      }
    };

    const checkAndDestroyWidget = () => {
      if (window.FreshworksWidget && typeof window.FreshworksWidget === 'function') {
        destroyWidget();
      } else {
        // If the widget isn't available yet, check again after a short delay
        setTimeout(checkAndDestroyWidget, 500);
      }
    };

    checkAndDestroyWidget();

    // Optional: Cleanup function if needed when the component unmounts
    return () => {
      // Reset or reinitialize widget if necessary
    };
  }, []);

  if (loading) {
    return (
      <Center w="100%" h="150px">
        <Spinner />
      </Center>
    );
  }

  return (
    <Box as="section" minHeight={"100vh"}>
      <Helmet title="KUMO | Find Deals" />
      <Flex height="100vh" direction="column"> {/* Set Flex direction to column */}
        <SubscriptionBanner currentUser={currentUser} />
        {subscriptionLevel === "Public" && <DealBanner />}
        <Flex flex="1" overflowY="auto"> {/* Added an inner Flex container */}
          <Box
            height="full"
            width={{
              md: '16rem',
            }}
            display={{
              base: 'none',
              lg: 'initial',
            }}
            overflowY="auto"
            borderRightWidth="1px"
            // bg="gray.50"
          >
            <Navbar />
          </Box>
          <Box
            // borderRightWidth="1px"
            // width={{ md: '20rem', xl: '24rem' }}
            // display={{ base: 'none', md: 'initial' }}
            bg={'white'}
            flex="1"
            overflowY="auto"
            onScroll={(x) => setSidebarIsScrolled(x.currentTarget.scrollTop > 32)}
          >
            {isMobile && <HeadingContainerWithNavbar sidebarIsScrolled={sidebarIsScrolled} isOpen={isOpen} onClose={onClose} onOpen={onOpen} />}
            <Box>
              <Outlet />
            </Box>

          </Box>
        </Flex>
      </Flex>
    </Box>
  )
}


const DealBanner = () => {
  const [isBannerVisible, setIsBannerVisible] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleCloseBanner = () => {
    setIsBannerVisible(false);
  };

  return (
    isBannerVisible && (
      <Box as="section">
        <Box borderBottomWidth="1px" bg="teal.50" position="relative">
          <Container maxW={"full"} py={{ base: '4', md: '3.5' }}>
            <IconButton
              display={{ base: 'inline-flex', md: 'none' }}
              icon={<FiX />}
              variant="tertiary"
              aria-label="Close banner"
              position="absolute"
              right="3"
              top="2"
              onClick={handleCloseBanner}
              _hover={{ bg: 'transparent' }}
            />
            <Stack
              spacing="4"
              justify={{ base: 'start', md: 'space-between' }}
              direction={{ base: 'column', md: 'row' }}
            >
              <Box pe={{ base: '4', md: '0' }}>
                <Text fontWeight="medium">You are viewing a business listed for sale!</Text>
                <Text color="fg.muted">Sign up to view 100K+ businesses from hundreds of sources.</Text>
              </Box>
              <Stack
                direction={{ base: 'column', md: 'row' }}
                spacing="3"
                align={{ base: 'stretch', md: 'center' }}
              >
                <Button onClick={onOpen}>Sign Up for Free</Button>
                <IconButton
                  icon={<FiX />}
                  variant="tertiary"
                  aria-label="Close banner"
                  display={{ base: 'none', md: 'inline-flex' }}
                  onClick={handleCloseBanner}
                  _hover={{ bg: 'transparent' }}
                />
              </Stack>
            </Stack>
          </Container>
        </Box>
        {isOpen && <SignupForFreeModal isOpen={isOpen} onClose={onClose} />}
      </Box>
    )
  );
};


