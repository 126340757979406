import {Route, Routes} from "react-router-dom";
import {SigninPage} from "./authentication/signin-page/page";
import {SignupPage} from "./authentication/signup-page/page";
import {ForgotPasswordPage} from "./authentication/forgot-password-page/page";
import {ForgotPasswordConfirmationPage} from "./authentication/forgot-password-confirmation-page/page";
import {ProtectedRoute} from "./layouts/protected-route";
import {SearchDeals} from "./search-deals/page";
import {MyDeals} from "./my-deals/page";
import {DealPage} from "./deal/page";
import {SettingsPage} from "./settings/page";
import {PlanSelectionPage} from "./authentication/plan-selection-page/page";
import {OnboardingPage} from "./onboarding/page";
import {NewSubscriptionSuccess} from "./new-subscription-success";
import {DealPipeline} from "./deal-pipeline/page";
import {NoMatch} from "./no-match";
import {SidebarLayout} from "./layouts/SidebarLayout";
import {DefaultLayout} from "./layouts/DefaultLayout";
import React from 'react';
import {HeadingProvider} from "./heading-provider";
import {SavedSearches} from "./saved-searches/page";
import {DealLayout} from "./layouts/DealLayout";
import {OnboardingLayout} from "./layouts/OnboardingLayout";
import {AnalyticsOverview} from "./analytics/overview/page";
import {AnalyticsIndustriesIndex} from "./analytics/industries/page";
import {AnalyticsLocationsIndex} from "./analytics/locations/page";
import {LocationAnalytics} from "./analytics/location/page";
import {IndustryAnalytics} from "./analytics/industry/page";
import {DealFeed} from "./deal-feed/page";

export const Router = () => {
  return (
    <HeadingProvider>
      <Routes>
        <Route element={<DefaultLayout/>}>
          {/* <Route path="*" element={<MaintenancePage />} /> */}
          <Route path="/signin" element={<SigninPage/>}/>
          <Route path="/signup" element={<SignupPage/>}/>
          <Route path="/reset-password" element={<ForgotPasswordPage/>}/>
          <Route
            path="/reset-password-confirmation/:tokenId"
            element={<ForgotPasswordConfirmationPage/>}
          />
        </Route>
        <Route element={<ProtectedRoute/>}>
          <Route element={<OnboardingLayout />}>
            <Route path="/plan-selection" element={<PlanSelectionPage/>}/>
            <Route path="/onboarding/:stepId" element={<OnboardingPage/>}/>
            <Route path="/new-subscription-success" element={<NewSubscriptionSuccess/>}/>
          </Route>
        </Route>
        <Route element={<DealLayout />}>
          <Route path="/deal/:dealId" element={<DealPage/>}/>
        </Route>
        <Route element={<SidebarLayout/>}>
          <Route path="/" element={<DealFeed />}/>
          <Route path="/deal-feeds/" element={<DealFeed />}/>
          <Route path="/deal-feeds/:feedId" element={<DealFeed />}/>

          <Route element={<ProtectedRoute/>}>
            <Route path="/search/" element={<SearchDeals/>}/>
            <Route path="/suggested-search/:suggestedSearchId" element={<SearchDeals/>}/>
            <Route path="/search/saved/:searchId" element={<SearchDeals/>}/>
            <Route path="/search/:searchId" element={<SearchDeals/>}/>
            <Route path="/saved-searches" element={<SavedSearches/>}/>
            <Route path="/my-deals/:indexType" element={<MyDeals/>}/>
            <Route path="/my-deals/:indexType/:subIndexType" element={<MyDeals/>}/>
            <Route path="/my-deals" element={<MyDeals/>}/>
            <Route path="/settings/" element={<SettingsPage/>}/>
            <Route path="/settings/:tabPanel" element={<SettingsPage/>}/>
            <Route path="/deal-pipeline" element={<DealPipeline/>}/>
            <Route path="/deal-pipeline/:indexType" element={<DealPipeline/>}/>
            <Route path="/deal-pipeline/:indexType/:subIndexType" element={<DealPipeline/>}/>
            <Route path="/analytics" element={<AnalyticsOverview/>} />
            <Route path="/analytics/industries" element={<AnalyticsIndustriesIndex />} />
            <Route path="/analytics/locations" element={<AnalyticsLocationsIndex />} />
            <Route path="/analytics/industries/:industryId" element={<IndustryAnalytics />} />
            <Route path="/analytics/locations/:locationId" element={<LocationAnalytics />} />
          </Route>

          <Route path="*" element={<NoMatch/>}/>
        </Route>
      </Routes>
    </HeadingProvider>
  )
}