import axios from "axios";

export const transmitMarketingRefSource = async ({
  uniqueBrowserId,
  refId,
}: {
  uniqueBrowserId?: string;
  refId: string;
}) => {
  if (uniqueBrowserId && refId) {
    const resp = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}api/account-marketing-ref-source`,
      {
        unique_browser_id: uniqueBrowserId,
        ref_id: refId,
      }
    );
    return resp.data;
  }
};
