import {Badge, Box, BoxProps, Text, useBreakpointValue, useColorModeValue,} from "@chakra-ui/react";

export interface CardProps extends BoxProps {
  isPopular?: boolean;
}

export const Card = (props: CardProps) => {
  const { children, isPopular, ...rest } = props;

  const popularStyles: BoxProps = {
    borderWidth: "2px",
    borderColor: "blue.500",
    zIndex: 1,
    backgroundColor: "rgba(238, 242, 255,  1)",
  };

  const styles = isPopular ? popularStyles : null;
  return (
    <Box
      bg={useColorModeValue("white", "gray.700")}
      position="relative"
      px={{base: "2", lg: "3"}}
      pb="8"
      pt="16"
      overflow="hidden"
      shadow="lg"
      width="100%"
      height={{ base: "auto", lg: "auto", xl: "auto" }}
      {...styles}
      {...rest}>
      {isPopular && (
        <Badge
          colorScheme="green"
          alignSelf="start"
          position="absolute"
          top="25px"
          right="33px"
          size={useBreakpointValue({ base: "md", md: "lg" })}>
          <Text fontSize="xs">Most Popular</Text>
        </Badge>
      )}
      {children}
    </Box>
  );
};
