import {Link, useToast} from "@chakra-ui/react";
import {checkout} from "../api/checkout";
import {PlanRate} from "../views/authentication/plan-selection-page/page";

export const StripeCheckoutButton = ({
  plan = "Pro",
  planRate = "month",
  tracking,
  children,
  textColor,
}: {
  plan?: string;
  planRate?: PlanRate;
  tracking: string;
  children: any;
  textColor?: string;
}) => {
  const toast = useToast();
  const handleClick = async () => {
    (window as any).gtag("event", tracking, {
      send_to: "G-EQH88TS87N",
    });
    try {
      const resp = await checkout(plan, planRate);
      window.location.replace(resp.checkout_url);
    } catch (exception: any) {
      toast({
        title: exception?.response?.data?.errors || "Whoops something went wrong.",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
    }
  }
  return (
    <Link
      textDecoration="underline"
      fontWeight="medium"
      color={textColor || undefined}
      onClick={handleClick}>
      {children}
    </Link>
  );
};
