import React, {useEffect} from 'react';
import {
  Button,
  chakra,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  Grid,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  useRadio,
  useRadioGroup,
  useTheme,
  useToast,
  VisuallyHidden
} from '@chakra-ui/react';
import {CgExport} from "react-icons/cg";
import {useFormik} from "formik";
import options from './export_to_csv/user_deal_export_request_options.json';
import {transparentize} from '@chakra-ui/theme-tools'
import {useRequestCsvExportMutation} from "../../../services/dealExportsApi";
import {FiltersDrawerButton} from "./FiltersDrawerButton";
import {useSelector} from "react-redux";
import {selectActiveSearch} from "../../../store/local-search";
import {useQuery} from "@apollo/client";
import {CURRENT_USER_QUERY} from "../../../graphql/current-user";
import {SwitchToPaidModal} from "../../../components/upgrade-ctas/switch-to-paid-modal";
import {MdLockOutline} from "react-icons/md";

export const ExportToCsv = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { loading: userLoading, data: userData } = useQuery(CURRENT_USER_QUERY);
  const isUltimate = ["Ultimate"].includes(userData?.currentUser?.account?.subscriptionLevel || "Public");

  if (isUltimate) {
    return (
      <>
        <ExportCsvButton onOpen={onOpen} />
        {isOpen && <ExportModal isOpen={isOpen} onClose={onClose} />}
      </>
    );
  } else {
    return(
      <>
        <Tooltip label="Upgrade to Ultimate to export deals to CSV"
                 borderRadius="md"
                 p={2} // padding
                 maxWidth="200px" // adjust as needed to control the text breaking
                 textAlign="center" // for centered text, if needed
                 hasArrow={true}
                 arrowSize={10} // adjust the size of the arrow if needed
        >
          <Button color={"gray.500"} leftIcon={<MdLockOutline />} onClick={onOpen} variant={"outline"} size={"xs"}>
            Export to CSV
          </Button>
        </Tooltip>
        {isOpen && <SwitchToPaidModal isOpen={isOpen} onClose={onClose} onOpen={onOpen} plan={"Ultimate"}
                                      ctaText={"Only Ultimate users can export deals to CSVs!"} />}
      </>
    )
  }

}

const ExportCsvButton = ({ onOpen }) => {
  return (
    <Button leftIcon={<CgExport color={"brand"} />} onClick={onOpen} variant={"outline"} size={"xs"}>
      Export to CSV
    </Button>
  );
};

const ExportModal = ({ isOpen, onClose }) => {
  const [runFormSubmit, { isLoading, isError, error }] = useRequestCsvExportMutation();
  const toast = useToast();

  const submitForm = (request_response) => {
    let mutationOptions = {
      request_params: request_response
    };

    return runFormSubmit(mutationOptions);
  };

  const { filters, sort } = useSelector(selectActiveSearch);

  const formik = useFormik({
    initialValues: {
      request_context: 'search',
      search_main_columns_requested: options.search_main_columns_requested.map((column, index) => (column.value)),
      search_user_action_columns_requested: [],
      sort_column: 'added_to_kumo_at',
      sort_order: 'DESC',
      number_of_results_requested: 500,
      search_filters_requested: filters
    },
    onSubmit: values => {
      submitForm(values).then((response) => {
        if (response?.data?.success) {
          toast({
            title: 'CSV Export Started',
            description: 'Your file will be available under "Your Downloads".',
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: "top-right"
          });
          onClose();
        } else {
          toast({
            title: 'CSV Export Failed',
            description: `${response?.error?.data?.message || 'Unknown error'}`,
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: "top-right"
          });
          console.log('error');
        }
      }).catch((e) => {
        toast({
          title: 'Export Failed',
          description: `Error: ${e.message}`,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: "top-right"
        });
      });
    }
  });

  // Update form values when filters change
  useEffect(() => {
    formik.setFieldValue('search_filters_requested', filters);
  }, [filters]);

  const handleClose = () => {
    formik.resetForm(); // Reset the form to initial values
    onClose(); // Call the original onClose prop
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={formik.handleSubmit}>
        <ModalHeader>Export to CSV</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <FormControl>
              <FormLabel fontWeight="semibold" as="legend" mb="3" mt="2">
                Filters applied:
              </FormLabel>
              <FiltersDrawerButton id={"export_to_csv_filters_button"} />
            </FormControl>

            <FormControl>
              <FormLabel fontWeight="semibold" as="legend" mb="3" mt="2">
                Deal Columns
              </FormLabel>
              <CheckboxFilter
                options={options.search_main_columns_requested}
                hideLabel={true}
                label="Deal Columns"
                name="search_main_columns_requested"
                value={formik.values.search_main_columns_requested}
                onChange={(selectedValues) => formik.setFieldValue('search_main_columns_requested', selectedValues)}
              />

            </FormControl>

            <FormControl>
              <FormLabel fontWeight="semibold" as="legend" mb="3" mt="2">
                Account-specific Columns
              </FormLabel>
              <CheckboxFilter
                options={options.search_user_action_columns_requested}
                hideLabel={true}
                label="Your Actions"
                name="search_user_action_columns_requested"
                value={formik.values.search_user_action_columns_requested}
                onChange={(selectedValues) => formik.setFieldValue('search_user_action_columns_requested', selectedValues)}
              />
            </FormControl>

            {/* Sort Columns */}
            <FormControl>
              <FormLabel fontWeight="semibold" as="legend" mb="3" mt="2">
                Sort by
              </FormLabel>
              <Select
                name="sort_column"
                onChange={formik.handleChange}
                value={formik.values.sort_column}
              >
                {options.sort_column.map((option, index) => (
                  <option key={index} value={option.value}>{option.label}</option>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel fontWeight="semibold" as="legend" mb="3" mt="2">
                Sort Direction
              </FormLabel>
              <Select
                name="sort_order"
                onChange={formik.handleChange}
                value={formik.values.sort_order}
              >
                {options.sort_order.map((option, index) => (
                  <option key={index} value={option.value}>{option.label}</option>
                ))}
              </Select>
            </FormControl>

            {/* Number of Deals */}
            <FormControl>
              <FormLabel fontWeight="semibold" as="legend" mb="3" mt="2">
                Number of results to export
              </FormLabel>
              <SizePicker
                name="number_of_results_requested"
                onChange={(value) => formik.setFieldValue('number_of_results_requested', parseInt(value))}
                value={formik.values.number_of_results_requested}
                options={options.number_of_results_requested}
                label="Number of results" />
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" colorScheme="blue">
            Export
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};


export const CheckboxFilter = (props) => {
  const { options, label, hideLabel, spacing = '2', showSearch, value, onChange, ...rest } = props
  return (
    <Stack as="fieldset" spacing={spacing}>
      {!hideLabel && (
        <FormLabel fontWeight="semibold" as="legend" mb="0">
          {label}
        </FormLabel>
      )}
      <CheckboxGroup value={value} onChange={onChange} {...rest}>
        <Grid templateColumns="repeat(2, 1fr)" gap={2}>
          {options.map((option) => (
            <Checkbox key={option.value} value={option.value}>
              {option.label}
            </Checkbox>
          ))}
        </Grid>
      </CheckboxGroup>
    </Stack>
  )
}

export const SizePicker = (props) => {
  const { options, name, value, onChange, ...rest } = props;
  const radioGroup = useRadioGroup({
    name,
    onChange,
    value,
    ...rest
  });

  return (
    <FormControl>
      <HStack {...radioGroup.getRootProps()}>
        {options.map((option) => (
          <SizePickerButton
            key={option.value}
            label={option.label}
            {...radioGroup.getRadioProps({ value: option.value })}
          />
        ))}
      </HStack>
    </FormControl>
  );
};


export const SizePickerButton = (props) => {
  const { value, label } = props
  const { getInputProps, htmlProps, getCheckboxProps, getLabelProps } = useRadio(props)
  const theme = useTheme()
  return (
    <chakra.label {...htmlProps}>
      <chakra.input {...getInputProps()} />
      <Button
        as="span"
        px="2"
        cursor="pointer"
        variant="outline"
        colorScheme="blue"
        color={useColorModeValue('gray.600', 'gray.400')}
        borderRadius="base"
        borderColor={useColorModeValue('gray.200', 'gray.600')}
        _checked={{
          color: useColorModeValue('blue.500', 'blue.200'),
          bg: useColorModeValue('blue.50', transparentize('blue.200', 0.12)(theme)),
          borderColor: useColorModeValue('blue.500', 'blue.200'),
          borderWidth: '2px',
        }}
        _focus={{
          boxShadow: 'none',
        }}
        _focusVisible={{
          boxShadow: 'outline',
        }}
        {...getCheckboxProps()}
      >
        {value}
      </Button>
      <VisuallyHidden {...getLabelProps()}>{label} selected</VisuallyHidden>
    </chakra.label>
  )
}