export const setCookie = function (
  cname: string,
  cvalue: string,
  exdays = 365,
  base = "withkumo.com"
) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";domain=" + base + ";path=/";
};
