import {useApolloClient, useMutation, useQuery, useSubscription} from "@apollo/client";
import {Button, ButtonGroup, Center, Flex, Spinner, Stack, Text, useToast,} from "@chakra-ui/react";
import {sort} from "fast-sort";
import produce from "immer";
import {DragDropContext} from "react-beautiful-dnd";
import {ColorSchemes} from "../../constants/color-schemes";
import {defaultErrorToastSettings} from "../../constants/default-error";
import {CURRENT_USER_QUERY} from "../../graphql/current-user";
import {DEAL_FLOW_SETTING} from "../../graphql/deal-flow-setting";
import {DEAL_FLOW_STAGES} from "../../graphql/deal-flow-stages";
import {CREATE_DEAL_FLOW_STAGE} from "../../graphql/mutations/create-deal-flow-stage";
import {UPDATE_DEAL_FLOW_PROSPECT} from "../../graphql/mutations/update-deal-flow-prospect";
import {UPDATE_DEAL_FLOW_STAGE} from "../../graphql/mutations/update-deal-flow-stage";
import {CreateDealFlowStage} from "../../graphql/mutations/__generated__/CreateDealFlowStage";
import {UpdateDealFlowProspect} from "../../graphql/mutations/__generated__/UpdateDealFlowProspect";
import {UpdateDealFlowStage} from "../../graphql/mutations/__generated__/UpdateDealFlowStage";
import {ON_DEAL_SENT_TO_PIPELINE} from "../../graphql/subscriptions/on-deal-sent-to-pipeline";
import {CurrentUser} from "../../graphql/__generated__/CurrentUser";
import {DealFlowSetting} from "../../graphql/__generated__/DealFlowSetting";
import {DealFlowStages, DealFlowStages_dealFlowStages_prospects,} from "../../graphql/__generated__/dealFlowStages";
import Column from "./components/Column";
import {DealManagementFilters} from "./components/filters";
import {NotificationMessage} from "./notification-message";
import {DraggableMovement, EditDealFlowStage} from "./types";
import {generateIndex} from "./utils/generate-index";

export const DealPipelineKanban = () => {
  const toast = useToast();
  const apolloClient = useApolloClient();
  const { data: currentUserData, loading: currentUserLoading } =
    useQuery<CurrentUser>(CURRENT_USER_QUERY);
  const { data: dealFlowSetting, loading: dealFlowSettingLoading } =
    useQuery<DealFlowSetting>(DEAL_FLOW_SETTING);
  currentUserData?.currentUser?.account?.accountPermission?.canViewDealFlow;

  const daysAgoFilter = dealFlowSetting?.dealFlowSetting.filters.addedDaysAgo || null;
  const hasNotesFilter = dealFlowSetting?.dealFlowSetting.filters.showNotes || "Show All";
  const stageIds = dealFlowSetting?.dealFlowSetting.filters.stageIds || [];
  const { loading, data } = useQuery<DealFlowStages>(DEAL_FLOW_STAGES, {
    variables: { addedDaysAgo: daysAgoFilter },
  });
  const [createDealFlowStage] = useMutation<CreateDealFlowStage>(CREATE_DEAL_FLOW_STAGE);
  const [updateDealFlowStage] = useMutation<UpdateDealFlowStage>(UPDATE_DEAL_FLOW_STAGE);
  const [updateDealFlowProspect] = useMutation<UpdateDealFlowProspect>(UPDATE_DEAL_FLOW_PROSPECT);
  useSubscription(ON_DEAL_SENT_TO_PIPELINE, {
    onSubscriptionData: () => {
      apolloClient.refetchQueries({ include: ["DealFlowStages", "DealFlowSetting"] });
    },
    variables: {
      currentUserId: currentUserData?.currentUser.id || "",
    },
  });
  if (currentUserLoading || dealFlowSettingLoading || loading) {
    return (
      <Center w="100%" h="150px">
        <Spinner />
      </Center>
    );
  }

  const stages = sort(data?.dealFlowStages || [])
    .asc((s) => s.order)
    .filter((s) => stageIds.includes(Number(s.id)));

  /**
   * Prospect Actions
   */
  const moveProspect = (args: DraggableMovement) => {
    const prospectId = args.draggableId;
    const allProspects =
      data?.dealFlowStages.reduce((acc, curr) => {
        return acc.concat(curr?.prospects || []);
      }, [] as DealFlowStages_dealFlowStages_prospects[]) || [];
    const prospect = allProspects.find((p) => p.id === prospectId);
    const destination = args.destination;
    const source = args.source;
    const stage = data?.dealFlowStages.find((s) => s.id === destination.droppableId);
    const prospects = stage?.prospects || [];
    let newPoint;
    if (destination.index === 0) {
      // it's at the beginning
      newPoint = generateIndex(null, prospects[destination.index]?.order || 0);
    } else if (destination.index >= prospects.length - 1) {
      // it's at the end
      newPoint = generateIndex(prospects[destination.index]?.order || prospects.length - 1);
    } else {
      newPoint = generateIndex(
        prospects[destination.index - 1].order,
        prospects[destination.index].order
      );
    }
    updateDealFlowProspect({
      variables: {
        stageId: destination.droppableId,
        prospectId: prospectId,
        order: newPoint,
      },
      optimisticResponse: {
        updateDealFlowProspect: {
          prospect: {
            id: prospect?.id || "",
            deal: prospect?.deal as any,
            updatedAt: new Date(),
            createdAt: prospect?.createdAt,
            order: newPoint,
            __typename: "Prospect",
          },
          __typename: "UpdateDealFlowProspectPayload",
        },
      },
      update: (cache, result) => {
        const data = cache.readQuery<DealFlowStages>({
          query: DEAL_FLOW_STAGES,
          variables: { addedDaysAgo: daysAgoFilter },
        });

        cache.writeQuery({
          query: DEAL_FLOW_STAGES,
          variables: {
            id: source.droppableId,
            addedDaysAgo: daysAgoFilter,
          },
          data: {
            dealFlowStages: produce(data?.dealFlowStages || [], (stages) => {
              stages.forEach((stage) => {
                if (stage.id === source.droppableId) {
                  // remove from source
                  stage.prospects = (stage?.prospects || []).filter((p) => p.id !== prospectId);
                }
                if (stage.id === destination.droppableId) {
                  const prospect = result?.data?.updateDealFlowProspect?.prospect;
                  if (prospect) {
                    const prospects = stage.prospects || [];
                    // add it to destination in the correct index
                    stage.prospects = prospects
                      .slice(0, Number(destination.index))
                      .concat(prospect)
                      .concat(prospects.slice(Number(destination.index)));
                  }
                }
              });
            }),
          },
        });
      },
    });
  };

  /**
   * Stage Actions
   */

  const createStage = (args: { title: string }) => {
    const colorScheme = ColorSchemes[Math.floor(Math.random() * ColorSchemes.length)];
    createDealFlowStage({
      variables: {
        name: args.title,
        colorScheme,
      },
      update: (cache, result) => {
        cache.modify({
          id: "ROOT_QUERY",
          fields: {
            dealFlowStages: (dealFlowStages) => {
              return dealFlowStages.concat(result.data?.createDealFlowStage?.dealFlowStage);
            },
          },
        });
      },
      onError: (error) => {
        toast({
          ...defaultErrorToastSettings,
          title: error.message,
        });
      },
    });
  };
  const editStage = (args: EditDealFlowStage) => {
    updateDealFlowStage({
      variables: {
        stageId: args.id,
        name: args.title,
      },
      update: (cache, response) => {
        cache.modify({
          id: cache.identify({ id: args.id, __typename: "Stage" }),
          fields: {
            name() {
              return response.data?.updateDealFlowStage?.dealFlowStage.name || null;
            },
          },
        });
      },
    });
  };

  const moveStage = (args: DraggableMovement) => {
    const stageId = args.draggableId;
    const stages = data?.dealFlowStages || [];
    const currentStage = data?.dealFlowStages.find((s) => s.id === stageId);
    const destinationIndex = args.destination.index;
    let newPoint;
    if (destinationIndex === 0) {
      // it's at the beginning
      newPoint = generateIndex(null, stages[destinationIndex]?.order || 0);
    } else if (destinationIndex >= stages.length - 1) {
      // it's at the end
      newPoint = generateIndex(stages[destinationIndex]?.order || stages.length - 1);
    } else {
      newPoint = generateIndex(stages[destinationIndex - 1].order, stages[destinationIndex].order);
    }

    updateDealFlowStage({
      variables: {
        stageId,
        name: currentStage?.name,
        order: newPoint,
      },
      optimisticResponse: {
        updateDealFlowStage: {
          dealFlowStage: {
            id: stageId,
            name: currentStage?.name || "",
            order: newPoint,
            colorScheme: currentStage?.colorScheme || null,
            __typename: "Stage",
          },
          __typename: "UpdateDealFlowStagePayload",
        },
      },
      update: (cache, result) => {
        const data = cache.readQuery<DealFlowStages>({
          query: DEAL_FLOW_STAGES,
        });

        cache.writeQuery({
          query: DEAL_FLOW_STAGES,
          data: {
            dealFlowStages: produce(data?.dealFlowStages || [], (writeableStages) => {
              writeableStages.map((stage) => {
                if (stage.id === stageId) {
                  stage = {
                    ...stage,
                    order: result.data?.updateDealFlowStage?.dealFlowStage?.order as any,
                  };
                }
              });
            }),
          },
        });
      },
    });
  };

  /**
   * React DnD handlers
   */
  const handleDragEnd = (args: any) => {
    const currentArgs = args as DraggableMovement;
    if (currentArgs.type === "PROSPECT") {
      moveProspect(currentArgs);
    } else if (currentArgs.type === "STAGE") {
      moveStage(currentArgs);
    } else {
      console.error("Something happened invalid args", currentArgs);
    }
  };

  const showCtaToUpgrade =
    !currentUserData?.currentUser.account?.accountPermission?.canViewDealFlow;

  return (
    <Flex h="100%" direction="column" position="relative">
      {showCtaToUpgrade && (
        <NotificationMessage>
          <>
            <Stack spacing="1">
              <Text fontSize="sm" fontWeight="medium">
                You hit your free limit of 5 deal prospects!
              </Text>
              <Text fontSize="sm" color="muted">
                Get unlimited deal prospects on the Pro Plan
              </Text>
            </Stack>
            <ButtonGroup variant="link" size="sm" spacing="3">
              <Button colorScheme="orange">Upgrade now</Button>
            </ButtonGroup>
          </>
        </NotificationMessage>
      )}
      <DealManagementFilters
        stages={data?.dealFlowStages || []}
        dealFlowSettingId={dealFlowSetting?.dealFlowSetting.id}
        filters={dealFlowSetting?.dealFlowSetting.filters}
      />
      <Flex flex={1} mt={15} wrap="nowrap" overflowX="scroll">
        <DragDropContext onDragEnd={handleDragEnd}>
          {stages.map((stage, index) => {
            const column = stage;
            return (
              <Column
                hasNotesFilter={hasNotesFilter}
                key={column.id}
                index={index}
                column={column}
                createStage={createStage}
                editStage={editStage}
              />
            );
          })}
        </DragDropContext>
      </Flex>
    </Flex>
  );
};
