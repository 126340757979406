import { gql } from "@apollo/client";

export const UPDATE_DEAL_FLOW_SETTING = gql`
  mutation UpdateDealFlowSetting(
    $dealFlowSettingId: ID!
    $showNotes: String
    $stageIds: [Int!]
    $addedDaysAgo: Int
  ) {
    updateDealFlowSetting(
      input: {
        dealFlowSettingId: $dealFlowSettingId
        showNotes: $showNotes
        stageIds: $stageIds
        addedDaysAgo: $addedDaysAgo
      }
    ) {
      dealFlowSetting {
        id
        userId
        accountId
        filters
        createdAt
        updatedAt
      }
    }
  }
`;
