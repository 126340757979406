import * as React from "react";
import { AuthenticationLeftBackground } from "../authentication-left-background";
import { SignupForm } from "./signup-form";

export const SignupPage = () => {
  return (
    <AuthenticationLeftBackground
      body={
        <SignupForm
          px={{ base: "4", md: "8" }}
          py={{ base: "12", md: "48" }}
          width="full"
          maxW="md"
        />
      }
    />
  );
};
