import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";

/**
 * This route is hit when a user successfully hits the subscription
 * and reroutes to onboarding
 */

export const NewSubscriptionSuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // pushToDataLayer("new_customer_subscribe", {});
    if (typeof window !== 'undefined' && typeof window.gtag === 'function') {
      window.gtag('event', 'new_customer_subscribe', {
        send_to: 'G-EQH88TS87N',
      });
    }

    navigate("/onboarding/1");
  }, [navigate]);

  return null; // Or some loading indicator
};
