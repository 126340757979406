import axios from "axios";

export const resetPasswordConfirmation = async ({
  resetToken,
  password,
}: {
  resetToken: string;
  password: string;
}) => {
  const resp = await axios.put(`${process.env.REACT_APP_API_BASE_URL}api/users/password`, {
    user: {
      reset_password_token: resetToken,
      password: password,
    },
  });
  return resp.data;
};
