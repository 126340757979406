import axios from "axios";

export const signoutUser = async () => {
  await axios.delete(`${process.env.REACT_APP_API_BASE_URL}api/users/logout`, {
    headers: {
      authorization: localStorage.getItem("with-kumo-token") || "",
    },
  });
  localStorage.removeItem("with-kumo-token");
};
