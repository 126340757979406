import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Stack,
  StackProps,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useState } from "react";
import { Field, Form, Formik } from "formik";
import { ResetSchema } from "../../../validators/auth-validators";
import { LogoIcon } from "../../../components/Logo";
import { resetPassword } from "../../../api/reset-password";

export const ResetPasswordForm = (props: StackProps) => {
  const [message, setMessage] = useState("");
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Stack spacing="8" {...props}>
      <Stack spacing="6">
        {isMobile && <LogoIcon />}
        <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
          <Heading size={useBreakpointValue({ base: "xs", md: "sm" })}>Reset password</Heading>
        </Stack>
      </Stack>
      {message ? (
        <Text align="center">{message}</Text>
      ) : (
        <Formik
          initialValues={{
            email: "",
          }}
          onSubmit={async (values) => {
            await resetPassword(values);
            setMessage("Password email sent. Check your inbox.");
          }}
          validationSchema={ResetSchema}>
          {({ errors, touched, isSubmitting }) => {
            return (
              <Form>
                <Stack spacing="6">
                  <Stack spacing="5">
                    <Field name="email">
                      {({ field }: any) => (
                        <FormControl>
                          <FormLabel htmlFor="email">Email</FormLabel>
                          <Input
                            {...field}
                            id="email"
                            placeholder="Enter your email"
                            type="string"
                          />
                          {errors.email && touched.email && (
                            <FormHelperText>{errors.email}</FormHelperText>
                          )}
                        </FormControl>
                      )}
                    </Field>
                  </Stack>
                  <Stack spacing="4">
                    <Button type="submit" variant="primary" isLoading={isSubmitting}>
                      Reset password
                    </Button>
                  </Stack>
                </Stack>
              </Form>
            );
          }}
        </Formik>
      )}
    </Stack>
  );
};
