import {Button, Flex, IconButton, Text, useColorModeValue} from '@chakra-ui/react'

export const ColumnHeader = (props) => (
	<Flex
		minH="60px"
		position="sticky"
		zIndex={999}
		top="0"
		align="center"
		bg='white'
		color={useColorModeValue('gray.700', 'white')}
		{...props}
	/>
)
export const ColumnHeading = (props) => (
	<Text
		as={'b'}
		noOfLines={1}
		css={{ display: '-webkit-box', overflow: 'hidden', WebkitLineClamp: 1, WebkitBoxOrient: 'vertical' }}
		fontSize="md" lineHeight="1.25rem" {...props} />
)
export const ColumnButton = (props) => (
	<Button
		variant="tertiary"
		size="sm"
		fontSize="xs"
		_hover={{
			bg: useColorModeValue('gray.100', 'gray.700'),
		}}
		_active={{
			bg: useColorModeValue('gray.200', 'gray.600'),
		}}
		_focus={{
			boxShadow: 'none',
		}}
		_focusVisible={{
			boxShadow: 'outline',
		}}
		{...props}
	/>
)
export const ColumnIconButton = (props) => (
	<IconButton
		size="sm"
		fontSize="md"
		variant="tertiary"
		_hover={{
			bg: useColorModeValue('gray.100', 'gray.700'),
		}}
		_active={{
			bg: useColorModeValue('gray.200', 'gray.600'),
		}}
		_focus={{
			boxShadow: 'none',
		}}
		_focusVisible={{
			boxShadow: 'outline',
		}}
		{...props}
	/>
)