export const FormValueOptions = {
  addedDaysAgo: [
    { value: ">30", label: "> 30" },
    { value: "<1", label: "< 1" },
    { value: "<3", label: "< 3" },
    { value: "<7", label: "< 7" },
    { value: '', label: "All" },
  ],
  showRead: [
    { value: "true", label: "Show" },
    { value: "false", label: "Hide" },
  ],
  showHidden: [
    { value: "true", label: "Show" },
    { value: "false", label: "Hide" },
  ],
  quantitativeChanges: [
    { value: "true", label: "Only Show" },
    { value: "", label: "Show All" },
  ],
}