import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Stack,
  StackProps,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { default as React, useState } from "react";
import { Field, Form, Formik } from "formik";
import { ResetConfirmationSchema } from "../../../validators/auth-validators";
import { LogoIcon } from "../../../components/Logo";
import { resetPasswordConfirmation } from "../../../api/reset-password-confirmation";
import { NavLink as ReactRouterLink, useParams } from "react-router-dom";

export const ResetPasswordConfirmationForm = (props: StackProps) => {
  const [message, setMessage] = useState("");
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { tokenId } = useParams();

  return (
    <Stack spacing="8" {...props}>
      <Stack spacing="6">
        {isMobile && <LogoIcon />}
        <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
          <Heading size={useBreakpointValue({ base: "xs", md: "sm" })}>
            Enter your new password
          </Heading>
        </Stack>
      </Stack>
      {message ? (
        <>
          <Text align="center">
            {message === "Success"
              ? "Your password has been updated. Please sign in with your new password."
              : "Your password url is invalid or expired. Please try again."}
          </Text>
          <Stack spacing="4">
            {message === "Success" ? (
              <Button
                variant="link"
                colorScheme="blue"
                size="sm"
                mt={"10px"}
                as={ReactRouterLink}
                to="/signin">
                Sign in
              </Button>
            ) : (
              <Button
                variant="link"
                colorScheme="blue"
                size="sm"
                mt={"10px"}
                as={ReactRouterLink}
                to={"/reset-password"}>
                Request another password reset
              </Button>
            )}
          </Stack>
        </>
      ) : (
        <Formik
          initialValues={{
            resetToken: tokenId as string,
            password: "",
          }}
          onSubmit={async (values) => {
            try {
              await resetPasswordConfirmation(values);
              setMessage("Success");
            } catch (e) {
              setMessage("Failure");
            }
          }}
          validationSchema={ResetConfirmationSchema}>
          {({ errors, touched, isSubmitting }) => {
            return (
              <Form>
                <Stack spacing="6">
                  <Stack spacing="5">
                    <Field name="password">
                      {({ field }: any) => (
                        <FormControl>
                          <FormLabel htmlFor="email">New Password</FormLabel>
                          <Input
                            {...field}
                            id="password"
                            placeholder="Enter your new password"
                            type="string"
                          />
                          {errors.password && touched.password && (
                            <FormHelperText>{errors.password}</FormHelperText>
                          )}
                        </FormControl>
                      )}
                    </Field>
                  </Stack>
                  <Stack spacing="4">
                    <Button type="submit" variant="primary" isLoading={isSubmitting}>
                      Save password
                    </Button>
                  </Stack>
                </Stack>
              </Form>
            );
          }}
        </Formik>
      )}
    </Stack>
  );
};
