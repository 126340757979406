import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {getCookie} from "../utils/get-cookie";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_BASE_URL}api/deal_feeds`,
  // prepareHeaders is used to configure the header of every request and gives access to getState which we use to include the token from the store
  prepareHeaders: (
    headers
    // { getState }
  ) => {
    const token = localStorage.getItem("with-kumo-token");
    if (token) {
      headers.set("authorization", token);
    }

    const uniqueBrowserId = getCookie("unique_browser_id");
    if (uniqueBrowserId) {
      // console.log("unique_browser_id found", uniqueBrowserId)
      headers.set("BrowserId", uniqueBrowserId);
    } else {
      console.log("unique_browser_id not found")
      // headers.set("BrowserId", 'no_id');
    }

    return headers;
  },
});

export const dealFeedsApi = createApi({
  reducerPath: "dealFeedsApi",
  baseQuery: baseQuery,
  tagTypes: ["deal_feed"],
  endpoints: (builder) => ({
    getDealFeeds: builder.query({
      query: () => {
        return `/`;
      },
      providesTags: ["deal_feed"],
    }),
    getDealFeed: builder.query({
      query: (id) => `/show_feed/${id}`,
      providesTags: ["deal_feed"],
    }),
    getRelease: builder.query({
      query: (id) => `/show_release/${id}`,
      providesTags: ["release"],
    }),
    getNonPickedDealsForRelease: builder.query({
      query: (id) => `/show_nonpicked_deals_for_release/${id}`,
      providesTags: ["Deal"],
    }),
  }),
});



export const { useGetDealFeedsQuery, useGetDealFeedQuery, useGetReleaseQuery, useGetNonPickedDealsForReleaseQuery } = dealFeedsApi;
