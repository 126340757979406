import {BookmarkButton} from "./bookmark-button";
import {Box, HStack} from "@chakra-ui/react";
import {HideDealButton} from "./hide-deal-button";
import {NotesButton} from "./notes-button";
import {ShareDealButton} from "./share-deal-button";


export const UserActionsPanel = ({ deal, ...otherProps }) => {
  return (
    <HStack>
      <Box flex={"1"}>
        <BookmarkButton key={`panel_${deal.id}`} deal={deal} buttonType={"textButton"}/>
      </Box>
      <Box flex={"1"}>
        <HideDealButton deal={deal} buttonType={"textButton"}/>
      </Box>
      <Box flex={"1"}>
        <NotesButton deal={deal} buttonType={"textButton"}/>
      </Box>
      <Box flex={"1"}>
        <ShareDealButton deal={deal} buttonType={"textButton"}/>
      </Box>
    </HStack>
  )
}