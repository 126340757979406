import {useGetDealQualitativeEventsQuery} from "../../../../services/dealsApi";
import {Box, SkeletonText, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useTheme} from "@chakra-ui/react";
import dayjs from "dayjs";
import ReactDiffViewer from 'react-diff-viewer'
import React from 'react';

export const QualitativeChangeEvents = ({ deal, listingSource }) => {
  const { data: qualData, isLoading: qualDataLoading, isFetching: qualDataFetching, isError: qualDataError } = useGetDealQualitativeEventsQuery(deal?.id);
  const qualEvents = qualData?.data;

  const formattedKeys = {
    'listing_description': 'Listing Description',
    'title': 'Listing Title',
  }

  if (qualEvents && qualEvents.length > 0) {
    const filteredEvents = qualEvents.filter(event => event.attributes.listing_source_id === listingSource.listing_source_id);
    let groupedEvents = {};
    if (filteredEvents && filteredEvents.length > 0) {
      groupedEvents = filteredEvents.reduce((acc, event) => {
        (acc[event.attributes.changed_value_key] = acc[event.attributes.changed_value_key] || []).push(event);
        return acc;
      }, {});
    } else {
      return <Text>
        No meaningful qualitative change events to display for this listing source.
      </Text>
    }


    if (groupedEvents && Object.keys(groupedEvents).length > 0) {
      return (
        <Box>
          <Tabs size={"sm"} >
            <TabList>
              {Object.keys(groupedEvents).map(key => <Tab key={key}>{formattedKeys[key]}</Tab>)}
            </TabList>
            <TabPanels>
              {Object.entries(groupedEvents).map(([key, events]) => {
                let formattedEvents = formatQualitativeEvents(events)
                return (
                  <TabPanel key={key} px={"0"} pt={"5"}>
                    <QualitativeChangeEventsTable deal={deal} events={formattedEvents} />
                  </TabPanel>
                )
              })}
            </TabPanels>
          </Tabs>
        </Box>
      );
    } else {
      return <LoadingSkeleton />;
    }


  } else {
    return <LoadingSkeleton />;
  }
}

function formatQualitativeEvents(events) {
  let formattedEvents = []
  events.forEach((event, index) => {
    const previousEvent = formattedEvents[formattedEvents.length - 1];
    let hasChanged = false;
    const value_is_blank = event.attributes.old_value === "" || event.attributes.new_value === ""

    if (value_is_blank) {
      //   We do nothing
    } else {
      if (previousEvent) {
        const value_changed = event.attributes.old_value !== previousEvent.old_value || event.attributes.new_value !== previousEvent.new_value
        const value_is_blank = event.attributes.old_value === "" || event.attributes.new_value === ""
        if (value_changed && !value_is_blank) {
          hasChanged = true;
        } else {
          hasChanged = false;
        }

        if (hasChanged) {
          formattedEvents.push(event)
        }
      } else {
        formattedEvents.push(event);
      }
    }
  })
  return formattedEvents;
}

export const QualitativeChangeEventsTable = ({ deal, events, ...props }) => {
  const theme = useTheme();

  const styles = {
    diffContainer: {
      fontFamily: theme.fonts.body,     // Use Chakra's default body font
      fontSize: theme.fontSizes.xs,     // Use extra-small font size from Chakra UI
      color: theme.colors.gray[700],    // Text color
      lineHeight: theme.lineHeights.normal, // Line height
      letterSpacing: theme.letterSpacings.normal, // Letter spacing
      fontWeight: theme.fontWeights.normal
    },
    contentText: {
      fontFamily: theme.fonts.body,     // Use Chakra's default body font
      fontSize: theme.fontSizes.xs,     // Use extra-small font size from Chakra UI
      color: theme.colors.gray[700],    // Text color
      lineHeight: theme.lineHeights.normal, // Line height
      letterSpacing: theme.letterSpacings.normal, // Letter spacing
      fontWeight: theme.fontWeights.normal
    },
    codeFold: {
      fontFamily: theme.fonts.body,     // Use Chakra's default body font
      fontSize: theme.fontSizes.sm,     // Use extra-small font size from Chakra UI
      // color: theme.colors.gray[700],    // Text color
      lineHeight: theme.lineHeights.normal, // Line height
      letterSpacing: theme.letterSpacings.normal, // Letter spacing
      fontWeight: theme.fontWeights.semibold
    }
    // Add other styles or override other parts of the diff viewer as needed
  };

  const formatDate = (dateString) => {
    return dayjs(dateString).format('MM/DD/YY');
  };

  const splitIntoSentences = (text) => {
    return text.match(/[^.!?]+[.!?]+/g) || [];
  };

  return (
    <Box>
      <Tabs orientation="vertical" size={"sm"}>
        <TabList>
          {events.reverse().map((event, index) => (
            <Tab key={index}>{formatDate(event.attributes.created_at)}</Tab>
          ))}
        </TabList>
        <TabPanels>
          {events.reverse().map((event, index) => {
            const oldSentences = splitIntoSentences(event.attributes.old_value).join("\n");
            const newSentences = splitIntoSentences(event.attributes.new_value).join("\n");

            return (
              <TabPanel key={index} pt={"0"}>
                <ReactDiffViewer
                  compareMethod="diffWordsWithSpace"
                  showDiffOnly={true}
                  extraLinesSurroundingDiff={1}
                  oldValue={oldSentences.length > 0 ? oldSentences : event.attributes.old_value}
                  newValue={newSentences.length > 0 ? newSentences : event.attributes.new_value}
                  styles={styles}
                  splitView={true}
                />
              </TabPanel>
            );
          })}
        </TabPanels>
      </Tabs>
    </Box>
  );
};

const LoadingSkeleton = () => {
  return (
    <Box>
      <Stack>
        <SkeletonText mt="4" noOfLines={1} spacing="4" skeletonHeight="7" width={"300px"} />
        <SkeletonText mt="4" noOfLines={2} spacing="1" skeletonHeight="5" />
        <SkeletonText mt="4" noOfLines={15} spacing="1" skeletonHeight="5" />
      </Stack>
    </Box>
  )
}