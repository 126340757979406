import {Table, Tbody} from "@chakra-ui/react";
import TableHeaders from "./table-headers";
import {LoadingTableRow, TableRow} from "./search-table-row";


export const SearchTable = ({ deals, queryOptions, handleSort, handleDealClick, isLoading, isFetching, ...otherProps }) => {
  return (
    <Table size={{base: "sm", "2xl": "md"}}>
      <TableHeaders queryOptions={queryOptions} handleSort={handleSort} />
      <Tbody>
        {!(isLoading || isFetching) && deals && deals.map((deal) => <TableRow handleDealClick={handleDealClick} deal={deal} key={deal.id} />)}
        {(isLoading || isFetching) &&
          Array.from({ length: 50 }).map((_, index) => (
            <LoadingTableRow key={index} />
          ))}
      </Tbody>
    </Table>
  )
}


