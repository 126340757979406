import {Action, configureStore, ThunkAction} from "@reduxjs/toolkit";
import {backendApi} from "../services/backendApi";
import rootReducer from "./root-reducer";
import {dealsApi} from "../services/dealsApi";
import {savedSearchesApi} from "../services/savedSearchesApi";
import {dealAnalyticsApi} from "../services/dealAnalyticsApi";
import {dealExportsApi} from "../services/dealExportsApi";
import {userApi} from "../services/userApi";
import {dealFeedsApi} from "../services/dealFeedsApi";

const middleware = [
  backendApi.middleware,
  dealsApi.middleware,
  savedSearchesApi.middleware,
  dealAnalyticsApi.middleware,
  dealFeedsApi.middleware,
  userApi.middleware,
  dealExportsApi.middleware,
]

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middleware),
  devTools: true, // TODO: might have to remove this based on environment we don't want this on in prod later
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = any> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
