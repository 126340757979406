import {Button, Icon, IconButton, useDisclosure,} from "@chakra-ui/react";
import React from "react";
import {useUserActionOnDealMutation} from "../../services/dealsApi";
import {AiFillEye, AiOutlineEyeInvisible} from "react-icons/ai";
import {useQuery} from "@apollo/client";
import {CURRENT_USER_QUERY} from "../../graphql/current-user";
import {SwitchToPaidModal} from "../upgrade-ctas/switch-to-paid-modal";
import {SignupForFreeModal} from "../upgrade-ctas/signup-for-free-modal";

export const HideDealButton = (props) => {
  const { deal, buttonType, ...otherProps } = props;
  const dealAttributes = deal.attributes;

  const [runUserActionOnDealMutation, { isLoading, isError }] = useUserActionOnDealMutation();

  let mutationOptions;
  mutationOptions = {
    dealId: deal.id,
    actionOnDeal: (dealAttributes?.is_hidden ? "unhide" : "hide")
  };

  const { loading: userLoading, data: userData } = useQuery(CURRENT_USER_QUERY);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const subscriptionLevel = userData?.currentUser?.account?.subscriptionLevel || "Public"
  const isPaid = ["Pro", "Ultimate"].includes(subscriptionLevel);

  const handleClick = async () => {
    await runUserActionOnDealMutation(mutationOptions);
  };


  if (!isPaid) {
    return (
      <>
        <ButtonComponent buttonType={buttonType} dealAttributes={dealAttributes} isLoading={isLoading} handleClick={onOpen} {...otherProps} />
        {isOpen && subscriptionLevel === "Public" && <SignupForFreeModal isOpen={isOpen} onClose={onClose} />}
        {isOpen && subscriptionLevel !== "Public" && <SwitchToPaidModal isOpen={isOpen} onClose={onClose} plan={"Pro"} />}
      </>
    );
  } else {
    return <ButtonComponent buttonType={buttonType} dealAttributes={dealAttributes} isLoading={isLoading} handleClick={handleClick} {...otherProps} />
  }
};

export const ButtonComponent = ((props) => {

  const {buttonType, dealAttributes, isLoading, handleClick, ...otherProps} = props;
  const FillableIcon = () => {
    return(
      <Icon
        as={dealAttributes?.is_hidden ? AiFillEye : AiOutlineEyeInvisible}
        transition="all 0.15s ease"
      />
    )
  }

  if (buttonType === "textButton") {
    return(
      <Button leftIcon={<FillableIcon />}
              variant={"outline"}
              size="xs"
              width={"full"}
              _hover={{ transform: "scale(1.05)" }}
              sx={{ ":hover > svg": { transform: "scale(1.05)" } }}
              transition="all 0.15s ease"
              onClick={handleClick}
              isDisabled={isLoading}
              {...otherProps} // if you want to spread otherProps
      >
        {dealAttributes?.is_hidden ? 'Undo Hide' : 'Hide'}
      </Button>
    )
  } else {
    return(
      <IconButton
        isRound
        bg="white"
        color="gray.900"
        size="md"
        _hover={{ transform: "scale(1.1)" }}
        sx={{ ":hover > svg": { transform: "scale(1.1)" } }}
        transition="all 0.15s ease"
        onClick={handleClick}
        isDisabled={isLoading}
        icon={
          <FillableIcon />
        }
        aria-label="Hide this deal"
        {...otherProps} // if you want to spread otherProps
      >
      </IconButton>
    )
  }
});
