import { gql } from "@apollo/client";

export const CREATE_DEAL_FLOW_STAGE = gql`
  mutation CreateDealFlowStage($colorScheme: String, $name: String!) {
    createDealFlowStage(input: { name: $name, colorScheme: $colorScheme }) {
      dealFlowStage {
        id
        name
        colorScheme
      }
    }
  }
`;
