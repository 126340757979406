import * as React from "react";
import {useState} from "react";
import {
  Avatar,
  Box,
  Card,
  Center,
  HStack,
  SkeletonText,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text
} from "@chakra-ui/react";
import {QualitativeChangeEvents} from "./change-events/qualitative-change-events";
import {QuantitativeChangeEvents} from "./change-events/quantitative-change-events";
import {HorizontalScrollBox} from "../../../components/HorizontalScrollBox";


export const ChangeEvents = ({ deal }) => {
  if (deal && deal.id) {
    const hasListingHistoryEvents = deal?.attributes?.total_qual_event_count > 0 || deal?.attributes?.total_quant_event_count > 0;
    if (!hasListingHistoryEvents) {
      return (
        <NoChangeEvents />
      )
    }

    const [currentListingSource, setCurrentListingSource] = useState(deal?.attributes?.listing_source_details?.[0])
    return (
      <>
        <Box mt={"20px"} mb={"20px"}>
          <HorizontalScrollBox>
            {deal.attributes.listing_source_details.map((listingSource, index) => (
              <Card p={"10px"}
                    minWidth={"150px"}
                    key={index}
                    my={"5px"}
                    mr={"20px"}
                    border={currentListingSource.listing_source_id === listingSource.listing_source_id ? "2px solid" : "1px solid"}
                    boxShadow='none'
                    bg={currentListingSource.listing_source_id === listingSource.listing_source_id ? "brand.50" : "white"}
                    borderColor={currentListingSource.listing_source_id === listingSource.listing_source_id ? "brand.100" : "gray.100"}
                    cursor={"pointer"}
                    _hover={{
                      textDecoration: "none",
                    }}
                    onClick={() => setCurrentListingSource(listingSource)}
              >
                <HStack alignItems="center">
                  <Box mr={"1"}>
                    <Avatar
                      cursor="pointer"
                      size="sm"
                      src={listingSource.listing_source_avatar_image_url}  // Add your image URL here
                      name={listingSource.listing_source_name}
                      showBorder={true}
                      borderWidth={"1px"}
                      borderColor={"gray.200"}
                      // boxShadow="xl"
                    />
                  </Box>
                  <Box
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}>
                    <Text
                      fontSize={"sm"}
                      fontWeight="medium"
                      noOfLines={1}
                      sx={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 1,
                        whiteSpace: "nowrap",
                      }}>
                      {listingSource.listing_source_name}
                    </Text>
                    <Box mt={"5px"}>
                      <HStack spacing={"1"} color={"gray.600"}>
                        {/*<Icon as={FiClock} />*/}
                        <Text
                          fontSize="xs"
                          fontWeight="medium"
                          noOfLines={1}
                          sx={{
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: "1",
                            overflow: "hidden",
                            display: "-webkit-box",
                          }}>
                          {listingSource.event_count} changes
                        </Text>
                      </HStack>
                    </Box>
                  </Box>
                </HStack>
              </Card>
            ))}
          </HorizontalScrollBox>
        </Box>
        {currentListingSource && <ChangeEventsContainer deal={deal} listingSource={currentListingSource} key={currentListingSource.listing_source_name}/>}
      </>
    )
  } else {
    return (
      <LoadingSkeleton />
    )
  }
}

const ChangeEventsContainer = ({ deal, listingSource }) => {

  const defaultIndex = listingSource.quant_event_count > 0 ? 0 : 1;

  return (
    <>
      <Tabs defaultIndex={defaultIndex} size={"sm"}>
        <TabList>
          <Tab isDisabled={listingSource.quant_event_count < 1}>Key Numbers ({listingSource.quant_event_count})</Tab>
          <Tab isDisabled={listingSource.qual_event_count < 1}>Listing Contents ({listingSource.qual_event_count})</Tab>
        </TabList>

        <TabPanels>
          <TabPanel px={"0"} pt={"3"}>
            <QuantitativeChangeEvents deal={deal} listingSource={listingSource} />
          </TabPanel>
          <TabPanel px={"0"} pt={"3"}>
            <QualitativeChangeEvents deal={deal} listingSource={listingSource} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  )
}

const NoChangeEvents = () => {
  return (
    <Box mt="6" borderWidth="2px" px={{base: "2", lg: "3"}} rounded="xl" borderStyle="dashed"
         position="relative"
    >
      <Box mx="auto" overflowY="auto" pt={{base: "3", lg: "5"}} pb={{base: "3", lg: "5"}} w={{base: "95%"}}>
        <Center gap="6">
          <Text fontSize={{base: "md"}} color="fg.muted" fontWeight="medium">
            No changes have been tracked on this deal yet, but any new changes will appear here.
          </Text>
        </Center>
      </Box>
    </Box>
  )
}

const LoadingSkeleton = () => {
  return (
    <Box>
      <Stack>
        <SkeletonText mt="4" noOfLines={1} spacing="4" skeletonHeight="7" width={"300px"} />
        <SkeletonText mt="4" noOfLines={2} spacing="1" skeletonHeight="5" />
        <SkeletonText mt="4" noOfLines={15} spacing="1" skeletonHeight="5" />
      </Stack>
    </Box>
  )
}