import {marketingRefSourceInitializer} from "../services/marketing-ref-source";

const KumoCookie = {
  BASE: "withkumo.com",

  setCookie: function (cname, cvalue, exdays = 365) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";domain=" + this.BASE + ";path=/";
  },

  getCookie: function (cname) {
    let name = cname + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },

  fetchRefId: function () {
    return new URL(window.location.href).searchParams.get("ref_id");
  },

  fetchOrCreateAndSetUniqueBrowserId: function () {
    const uniqueBrowserId = this.getCookie("unique_browser_id");
    // console.log('fetch or create unique browser id')
    // console.log(this.BASE)
    if (uniqueBrowserId) {
      // console.log('fetched')
      return uniqueBrowserId;
    } else {
      // console.log('created')
      const id = this.makeId(12);
      this.setCookie("unique_browser_id", id);
      return id;
    }
  },

  makeId: function (length) {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  },

  urlWithoutParams: function () {
    return window.location.protocol + "//" + window.location.host + window.location.pathname;
  },

  run: function () {
    this.fetchOrCreateAndSetUniqueBrowserId();
    const refId = this.fetchRefId();
    if (refId) {
      const oldRefIds = this.getCookie("ref_ids");
      if (oldRefIds) {
        const refIds = [...new Set(oldRefIds.split(",").concat(refId))];
        this.setCookie("ref_ids", refIds.join(","));
      } else {
        this.setCookie("ref_ids", refId);
      }
      const redirectUrl = this.urlWithoutParams();
      marketingRefSourceInitializer();
      if (window.history.replaceState) {
        window.history.replaceState({}, "", redirectUrl);
      } else {
        setTimeout(() => {
          window.location = redirectUrl;
        }, 100);
      }
    }
  },
};

window.addEventListener(
  "DOMContentLoaded",
  function () {
    KumoCookie.run();
    console.log('cookie here')
  },
  false
);
