import React from "react";

export type IAuthContext = {
  authenticated: boolean;
  setAuthenticated: (isAuthenticated: boolean) => void;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

export const AuthContext = React.createContext<IAuthContext>({
  authenticated: false,
  setAuthenticated: noop,
});
