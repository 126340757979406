import {Children, cloneElement, isValidElement} from 'react';
import {
  Box,
  Circle,
  createIcon,
  FormControl,
  HStack,
  Icon,
  Stack,
  Text,
  useRadio,
  useRadioGroup,
  VStack
} from '@chakra-ui/react';
import {useGetSearchableLocationsQuery} from "../../services/savedSearchesApi";
import {CheckBoxModalForm, SearchFilterLabel} from "../search-filters-form";
import {FormForSearchByRadius} from "./LocationFormSearchByRadius";
import {IoLocationOutline} from "react-icons/io5";

// Custom Check Icon using Chakra UI's createIcon function
export const CheckIcon = createIcon({
  displayName: 'CheckIcon',
  viewBox: '0 0 12 10',
  path: (
    <polyline
      fill="none"
      strokeWidth="2px"
      stroke="currentColor"
      strokeDasharray="16px"
      points="1.5 6 4.5 9 10.5 1"
    />
  ),
});

// RadioCard Component
export const RadioCard = (props) => {
  const {title} = props
  const { getInputProps, getCheckboxProps, getLabelProps } = useRadio(props.radioProps);
  const input = getInputProps();
  const checkbox = getCheckboxProps();
  const label = getLabelProps();

  if (input.checked) {
    return (
      <Box as="label" >
        <Box
          {...checkbox}
          cursor="pointer"
          borderWidth="1px"
          borderRadius="md"
          // boxShadow="md"
          bg="gray.100"
          _checked={{
            bg: "white",
            // color: "white",
            borderColor: "blue.200",
          }}
          _focus={{
            // boxShadow: "outline",
          }}
          p={4}
        >
          <HStack spacing={2} alignItems="center" w="full" justifyContent="space-between"> {/* Set width to full and justify content */}
            <Circle size="24px" bg="white" borderWidth="2px">
              {input.checked && <Icon as={CheckIcon} color="blue.500" />}
            </Circle>
            <Text flex="1" fontSize={"md"} textAlign="left">{title}</Text> {/* Ensure Text takes up maximum space and align text to the left */}
          </HStack>
          {/* Additional Content */}
          {input.checked && (
            <Box mt={2} width={"full"}>
              {props.children}
            </Box>
          )}
        </Box>
      </Box>
    );
  } else {
    return (
      <Box as="label" {...label}>
        <input {...input} />
        <Box
          {...checkbox}
          cursor="pointer"
          borderWidth="1px"
          borderRadius="md"
          // boxShadow="md"
          bg="gray.100"
          _checked={{
            bg: "white",
            // color: "white",
            // borderColor: "blue.500",
          }}
          _focus={{
            // boxShadow: "outline",
          }}
          p={4}
        >
          <HStack spacing={2} alignItems="center" w="full" justifyContent="space-between"> {/* Set width to full and justify content */}
            <Circle size="24px" bg="white" borderWidth="2px">
              {input.checked && <Icon as={CheckIcon} color="blue.500" />}
            </Circle>
            <Text flex="1" textAlign="left" fontSize={"md"}>{title}</Text> {/* Ensure Text takes up maximum space and align text to the left */}
          </HStack>
        </Box>
      </Box>
    );
  }
};

export const RadioCardGroup = (props) => {
  const { name, defaultValue, value, onChange, children, ...rest } = props;
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    value,
    onChange,
  });

  const group = getRootProps();

  const cards = Children.toArray(children).filter(isValidElement).map((child) =>
    cloneElement(child, {
      radioProps: getRadioProps({ value: child.props.value }),
    })
  );

  return (
    <Stack {...group} {...rest}>
      {cards}
    </Stack>
  );
};

const FormForSearchableLocations = ({label, filterKey, filters, handleFilterChange, ...props}) => {
  const { data: locationData, isLoading, isFetching, isError } = useGetSearchableLocationsQuery();
  const items = locationData?.data?.map(location => { return {id: location.id, label: location.attributes.label, group: location.attributes.group}}) || []

  const originalValues = filters[filterKey] || []

  const selected_length = filters['dealSearchableLocationId'].length
  const filterText = selected_length > 0 ? `${selected_length} region${selected_length > 1 ? "s" : ""} selected` : 'Select a region to filter by...'

  return(
    <>
      <CheckBoxModalForm
        items={items}
        label={label}
        placeholder={filterText}
        filterKey={filterKey}
        filters={filters}
        isLoading={isLoading}
        handleFilterChange={handleFilterChange}
      />
    </>
  )
}

const LocationFilter = ({ label, filters, handleFilterChange, handleFiltersChange, ...props }) => {
  let titleSubText = ''
  if (filters.locationFilterType === 'dealSearchableLocationId') {
    const selected_length = filters['dealSearchableLocationId'].length
    titleSubText = selected_length > 0 ? `(Regional: ${selected_length} selected)` : '(Regional: None selected)'
  } else if (filters.locationFilterType === 'dealLocationCoordinateRange') {
    const selected_city = filters['locationPointTextDescription']
    titleSubText = selected_city ? `(Local: ${selected_city})`: `(Local: No city selected)`
  }

  return (
    <>
      <SearchFilterLabel label="Location" subtext={titleSubText} icon={IoLocationOutline} />
      <FormControl as="fieldset" w="full">
        <RadioCardGroup name="locationFilterType" defaultValue={filters.locationFilterType || 'dealSearchableLocationId'} onChange={(value) => handleFilterChange('locationFilterType', value)} spacing={"3"}>
          <RadioCard value="dealSearchableLocationId" title={"Regional: State & Country"}>
            <Box mt={"4"}>
              <VStack align="start" w="full">
                <FormForSearchableLocations filterKey={"dealSearchableLocationId"} filters={filters} handleFilterChange={handleFilterChange} />
              </VStack>
            </Box>
          </RadioCard>
          <RadioCard value="dealLocationCoordinateRange" title={"Local: City & Radius in miles"}>
            <FormForSearchByRadius filters={filters} handleFilterChange={handleFilterChange} handleFiltersChange={handleFiltersChange} />
          </RadioCard>
        </RadioCardGroup>
      </FormControl>
    </>
  );
};
export default LocationFilter;