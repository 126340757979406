import axios from "axios";

export const gotoStripeBilling = async () => {
  const resp = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}api/billings`,
    {
      root_url: window.location.origin,
      cancel_url: window.location.href
    },
    {
      headers: {
        authorization: localStorage.getItem("with-kumo-token") || "",
      },
    }
  );
  return resp.data;
};
