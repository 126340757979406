import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Text,
  useColorModeValue as mode,
  usePopoverContext
} from "@chakra-ui/react";
import {useState} from "react";
import {FiSearch} from "react-icons/fi";
import {HiChevronDown} from "react-icons/hi";

export const CheckboxFilterPopover = ({buttonLabel, options, defaultValue, onSubmit}) => {
  const containsAllAvailableOptions = defaultValue.length === options.length - 1;
  const selectedValues = containsAllAvailableOptions ? defaultValue.concat("All") : defaultValue;

  const state = useFilterState({
    defaultValue: selectedValues,
    onSubmit: onSubmit,
  })

  const formLabels = (state.value || []).map((id) => options.find((s) => s?.value === id)?.label)
  const label = formLabels.length === options.length ? "Show All" : "Showing " + formLabels.length + " Stages";

  return (
    <Popover placement="bottom-start">
      <Stack spacing={0}>
        <FormLabel>{buttonLabel}</FormLabel>
        <FilterPopoverButton label={label} />
      </Stack>

      <FilterPopoverContent
        isCancelDisabled={!state.canCancel}
        onClickApply={state.onSubmit}
        onClickCancel={state.onReset}
      >
        <CheckboxFilter
          hideLabel={false}
          value={state.value}
          onChange={(values) => {
            const containsAll = values.includes("All");
            const previouslyContainedAll = (state?.value || []).includes("All");
            if (containsAll) {
              if (!previouslyContainedAll && values.length < options.length) {
                state.onChange(options.map((option) => option.value));
              } else if (previouslyContainedAll) {
                state.onChange(values.filter((v) => v !== "All"));
              }
            } else if (previouslyContainedAll) {
              state.onChange([]);
            } else {
              state.onChange(values);
            }
          }}
          options={options}
        />
      </FilterPopoverContent>
    </Popover>
  )
}

export function useFilterState(props) {
  const { defaultValue, onSubmit } = props
  const [state, setState] = useState(defaultValue)
  return {
    canCancel: defaultValue !== state,
    value: state,
    onChange: setState,
    onReset() {
      setState(defaultValue)
    },
    onSubmit() {
      onSubmit?.(state)
    },
  }
}


export const CheckboxFilter = (props) => {
  const { options, label, hideLabel, spacing = '2', showSearch, ...rest } = props
  return (
    <Stack as="fieldset" spacing={spacing}>
      {!hideLabel && (
        <FormLabel fontWeight="semibold" as="legend" mb="0">
          {label}
        </FormLabel>
      )}
      {showSearch && (
        <InputGroup size="md" pb="1">
          <Input
            placeholder="Search..."
            rounded="md"
            focusBorderColor={"brand"}
          />
          <InputRightElement pointerEvents="none" color="gray.400" fontSize="lg">
            <FiSearch />
          </InputRightElement>
        </InputGroup>
      )}
      <CheckboxGroup {...rest}>
        {options.map((option) => (
          <Checkbox key={option.value} value={option.value} colorScheme="brand">
            <span>{option.label}</span>
            {option.count != null && (
              <Box as="span" color="gray.500" fontSize="sm">
                {' '}
                ({option.count})
              </Box>
            )}
          </Checkbox>
        ))}
      </CheckboxGroup>
    </Stack>
  )
}

export const FilterPopoverButton = (props) => {
  const { label, icon, selected } = props
  return (
    <PopoverTrigger>
      <HStack
        justify="space-between"
        position="relative"
        as="button"
        fontSize="sm"
        borderWidth="1px"
        zIndex="11"
        rounded="lg"
        paddingStart="3"
        paddingEnd="2"
        paddingY="1.5"
        spacing="1"
        data-selected={selected || undefined}
        _expanded={{
          bg: mode('gray.100', 'gray.700'),
        }}
        _selected={{
          bg: 'brand.50',
          borderColor: 'brand.500',
        }}
      >
        {icon && <Icon as={icon} boxSize="2" />}
        <Text fontWeight="medium">{label}</Text>
        <Icon as={HiChevronDown} fontSize="xl" color="gray.400" />
      </HStack>
    </PopoverTrigger>
  )
}
export const FilterPopoverContent = (props) => {
  const { header, children, onClickCancel, onClickApply, isCancelDisabled } = props
  const { onClose } = usePopoverContext()
  return (
    <PopoverContent
      _focus={{
        shadow: 'none',
        outline: 0,
      }}
      _focusVisible={{
        shadow: 'outline',
      }}
    >
      {header && <PopoverHeader srOnly>{header}</PopoverHeader>}
      <PopoverBody padding="6">{children}</PopoverBody>
      <PopoverFooter>
        <FilterActionButtons
          onClickCancel={() => {
            onClickCancel?.()
            onClose()
          }}
          isCancelDisabled={isCancelDisabled}
          onClickApply={() => {
            onClickApply?.()
            onClose()
          }}
        />
      </PopoverFooter>
    </PopoverContent>
  )
}

export const FilterActionButtons = (props) => {
  const { onClickApply, onClickCancel, isCancelDisabled } = props
  return (
    <HStack spacing="2" justify="space-between">
      <Button size="sm" variant="tertiary" onClick={onClickCancel} isDisabled={isCancelDisabled}>
        Cancel
      </Button>
      <Button size="sm" colorScheme="brand" onClick={onClickApply}>
        Save
      </Button>
    </HStack>
  )
}
