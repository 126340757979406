import * as React from "react";
import {Helmet} from "react-helmet";
import {AuthenticationLeftBackground} from "../authentication-left-background";
import {SignInForm} from "./signin-form";
import {useQuery} from "@apollo/client";
import {CurrentUser} from "../../../graphql/__generated__/CurrentUser";
import {CURRENT_USER_QUERY} from "../../../graphql/current-user";
import {Center, Spinner} from "@chakra-ui/react";
import {Navigate, useSearchParams} from "react-router-dom";
import {AuthContext} from "../../../auth/auth-context";

export const SigninPage = () => {
  const { loading, data } = useQuery<CurrentUser>(CURRENT_USER_QUERY);
  const { authenticated } = React.useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const from = searchParams.get('from') || '/';

  if (loading) {
    return (
      <Center w="100%" h="150px">
        <Spinner />
      </Center>
    );
  }

  if (authenticated && data?.currentUser) {
    // If user is already logged in, require a log out
    return <Navigate to={from} replace />;
  } else {
    return (
      <>
        <Helmet title="KUMO | Sign in to see deals" />
        <AuthenticationLeftBackground
          body={
            <SignInForm
              px={{ base: "4", md: "8" }}
              py={{ base: "12", md: "48" }}
              width="full"
              maxW="md"
            />
          }
        />
      </>
    );
  }
};
