import React from "react";
import "./index.css";
import App from "./App";
import { store } from "./store/store";
import { Provider } from "react-redux";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter as Router } from "react-router-dom";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  concat,
  createHttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { createConsumer } from "@rails/actioncable";
import ActionCableLink from "graphql-ruby-client/subscriptions/ActionCableLink";

import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { theme } from "./custom-themes/base-theme";
import { GtagScriptInitializer } from "./utils/gtag";
import { marketingRefSourceInitializer } from "./services/marketing-ref-source";
import produce from "immer";

import "./utils/cookie";
import { createRoot } from "react-dom/client";

const link = createHttpLink({
  uri: process.env.REACT_APP_API_BASE_URL + "graphql",
});

// Setup a link for action cable
const tokenParams = localStorage.getItem("with-kumo-token")
  ? `?token=${localStorage.getItem("with-kumo-token")}`
  : "";
const cable = createConsumer(process.env.REACT_APP_API_BASE_URL + "cable" + tokenParams);
const actionCableLink = new ActionCableLink({
  cable: cable as any,
});

// Redirect subscriptions to the action cable link, while using the HTTP link for other queries
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);

    return definition.kind === "OperationDefinition" && definition.operation === "subscription";
  },
  actionCableLink,
  link
);

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: localStorage.getItem("with-kumo-token") || null,
    },
  }));

  return forward(operation);
});

GtagScriptInitializer();
marketingRefSourceInitializer();

export const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      DealFlowStages: {
        // Singleton types that have no identifying field can use an empty
        // array for their keyFields.
        keyFields: [],
      },
      Query: {
        fields: {
          myDeals: {
            keyArgs: ["filterType"],
            merge: (existing = null, incoming) => {
              if (existing === null) {
                return incoming;
              }
              return produce(existing, (draft: any) => {
                draft.nodes = [...(draft.nodes || []), ...incoming.nodes];
                draft.pageInfo = incoming.pageInfo;
              });
            },
          },
        },
      },
    },
  }),
  link: concat(authMiddleware, splitLink),
  connectToDevTools: process.env.NODE_ENV === "production" ? false : true, // exposes apollo client in chrome debugger
  headers: {
    authorization: localStorage.getItem("with-kumo-token") || "",
  },
});
const rootElement = document.getElementById("root");
if (rootElement) {
  const root = createRoot(rootElement);

  const app = (
    <>
      <script src="https://widget.freshworks.com/widgets/72000002009.js" async defer></script>
      <ApolloProvider client={client}>
        <Provider store={store}>
          <ChakraProvider theme={theme}>
            <Router>
              <App />
            </Router>
          </ChakraProvider>
        </Provider>
      </ApolloProvider>
    </>
  );

  root.render(app);
} else {
  // Handle the error if the root element is not found
  console.error("Root element not found");
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
