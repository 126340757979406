import {Box, Stack} from "@chakra-ui/react";

export const Banner = ({ children }: { children: any }) => {
  return (
    <Box
      bg="bg.accent.default"
      color="fg.accent.default"
      px={{ base: "4", md: "3" }}
      py={{ base: "4", md: "2.5" }}
      position="relative"
      borderRadius="xl">
      <Stack
        direction={{ base: "column", md: "row" }}
        justify="space-between"
        spacing={{ base: "3", md: "2" }}>
        <Stack
          spacing="4"
          direction={{ base: "column", md: "row" }}
          align={{ base: "start", md: "center" }}>
          <Stack spacing="2" pe={{ base: "4", md: "0" }}>
            {children}
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
