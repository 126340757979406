import React, {useEffect, useRef, useState} from "react";
import {Button, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Flex} from "@chakra-ui/react";
import {IoIosArrowDown, IoIosArrowRoundBack, IoIosArrowUp} from "react-icons/io";
import {DealPage} from "../../views/deal/page";
import {MdOpenInFull} from "react-icons/md";

export const DealDrawer = ({ dealId, onClose, isOpen, onOpen, temporaryDeal, deals, setModalDealId }) => {
  const currentDealIndex = deals?.findIndex((deal) => deal.id === dealId);

  const [previousUrl, setPreviousUrl] = useState('');

  const modifiedOnClose = () => {
    if (window.location.pathname.includes('deal')) {
      window.history.replaceState({}, '', previousUrl);
    }
    onClose()
  }

  useEffect(() => {
    if (isOpen) {
      // Store the current URL before opening the drawer
      if (!previousUrl) {
        setPreviousUrl(window.location.pathname);
      }
      // Change the URL in the browser without navigating
      window.history.replaceState({}, '', `/deal/${dealId}`);
    } else if (previousUrl) {
      // Revert back to the previous URL when the drawer closes
      window.history.replaceState({}, '', previousUrl);
    }
  }, [isOpen, dealId, previousUrl]);


  // handle previous and next deal navigation
  const goToPrevDeal = () => {
    if (currentDealIndex > 0) {
      const prevDealId = deals[currentDealIndex - 1].id;
      setModalDealId(prevDealId);
    }
  };

  const goToNextDeal = () => {
    if (currentDealIndex < deals.length - 1) {
      const nextDealId = deals[currentDealIndex + 1].id;
      setModalDealId(nextDealId);
    }
  };

  // listen to arrow key presses
  useEffect(() => {
    const handleArrowKeys = (event) => {
      if (event.key === "ArrowLeft" || event.key === "ArrowUp") {
        goToPrevDeal();
      } else if (event.key === "ArrowRight" || event.key === "ArrowDown") {
        goToNextDeal();
      } else if (event.key === 'Escape') {
        onClose()
      }
    };

    document.addEventListener("keydown", handleArrowKeys);
    return () => {
      document.removeEventListener("keydown", handleArrowKeys);
    };
  }, [currentDealIndex]);

  const scrollToTopRef = useRef(null);

  useEffect(() => {
    // Check if the ref's current property exists
    if (scrollToTopRef.current) {
      // Set the scrollTop to 0 to scroll to the top
      scrollToTopRef.current.scrollTop = 0;
    }
  }, [dealId]);

  // Otherwise the initial button steals focus
  const initialFocusRef = useRef(null);

  const openNewWindow = () => {
    window.open(`/deal/${dealId}`, '_blank', 'noopener,noreferrer');
  };

  return(
    <>
      <Drawer onClose={modifiedOnClose}
              isOpen={isOpen}
              size={{ base: "full", md: "lg" }}
              closeOnOverlayClick={true}
              blockScrollOnMount={false}
              initialFocusRef={initialFocusRef}
      >
        <DrawerOverlay zIndex={1000}  ref={scrollToTopRef} />
        <DrawerContent>
          {/*<DrawerCloseButton />*/}
          <DrawerHeader py={"10px"} borderBottomWidth="1px">
            <Flex alignItems={"center"}>
              <Button size={"xs"} variant={"link"} onClick={modifiedOnClose} leftIcon={<IoIosArrowRoundBack />}
                      display={{ base: 'inline-flex', lg: 'none' }} mr={"20px"} >
                Close
              </Button>
              <Button size={"xs"} variant={"outline"} leftIcon={<MdOpenInFull />}  onClick={openNewWindow}>
                New Tab
              </Button>
              <Button size={"xs"} variant="link" leftIcon={<IoIosArrowUp />} isDisabled={currentDealIndex === 0} onClick={goToPrevDeal} ml={'auto'} mr={"20px"}>
                Previous
              </Button>
              <Button size={"xs"} variant="link" leftIcon={<IoIosArrowDown />} isDisabled={currentDealIndex === deals.length - 1} onClick={goToNextDeal}>
                Next
              </Button>
            </Flex>
          </DrawerHeader>
          <DrawerBody style={{ scrollBehavior: 'smooth' }}>
            <DealPage initialFocusRef={initialFocusRef} key={dealId} modalDealId={dealId} temporaryDeal={temporaryDeal} />
          </DrawerBody>
          {/*<DrawerFooter borderTopWidth="1px" >*/}

          {/*</DrawerFooter>*/}
        </DrawerContent>
      </Drawer>
    </>
  )
}

