import {gql} from "@apollo/client";

export const CURRENT_USER_QUERY = gql`
  query CurrentUser {
    currentUser {
      id
      name
      email
      account {
        id
        name
        plan
        subscriptionLevel
        prettifiedSubscriptionLevel
        status
        canceledAt
        cancelAt
        currentPeriodEnd
        shouldShowPricingSurvey
        trialEnd
        upcomingAmountOff
        upcomingChargeDate
        upcomingInvoiceAmount
        upcomingPercentOff
        accountPermission {
          canViewDealFlow
        }
      }
    }
  }
`;
