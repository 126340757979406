import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  // eslint-disable-next-line no-undef
  baseUrl: `${process.env.REACT_APP_API_BASE_URL}api/saved_searches`,
  // prepareHeaders is used to configure the header of every request and gives access to getState which we use to include the token from the store
  prepareHeaders: (
    headers
    // { getState }
  ) => {
    const token = localStorage.getItem("with-kumo-token");
    if (token) {
      headers.set("authorization", token);
    }
    return headers;
  },
});

export const savedSearchesApi = createApi({
  reducerPath: "savedSearchesApi",
  baseQuery: baseQuery,
  tagTypes: ["SavedSearch"],
  endpoints: (builder) => ({
    getSavedSearchesForUser: builder.query({
      query: () => {
        return `/`;
      },
      providesTags: ["SavedSearch"],
    }),
    getChildIndustries: builder.query({
      query: () => {
        return `/child_industries`;
      },
      providesTags: ["DealChildIndustry"],
    }),
    getSearchableLocations: builder.query({
      query: () => {
        return `/searchable_locations`;
      },
      providesTags: ["DealSearchableLocation"],
    }),
    getLocationAutocomplete: builder.query({
      query: ({ query }) => {
        return `/location_autocomplete?autocomplete_query=${query}`;
      },
      providesTags: ["Addresses"],
    }),
    createSavedSearch: builder.mutation({
      query: ({ title, include_in_weekly_digest, filters }) => ({
        url: `/create`,
        method: "POST",
        body: {
          title: title,
          include_in_weekly_digest: include_in_weekly_digest,
          filters: filters
        },
      }),
      invalidatesTags: ["SavedSearch"],
    }),
    destroySavedSearch: builder.mutation({
      query: ({ id }) => ({
        url: `/destroy/${id}`,
        method: "DELETE",
        body: {},
      }),
      invalidatesTags: ["SavedSearch"],
    }),
    updateSavedSearch: builder.mutation({
      query: ({ id, title, include_in_weekly_digest, filters }) => ({
        url: `/update/${id}`,
        method: "POST",
        body: {
          title: title,
          include_in_weekly_digest: include_in_weekly_digest,
          filters: filters
        },
      }),
      invalidatesTags: ["SavedSearch"],
    }),
  }),
});


export const {
  useGetSavedSearchesForUserQuery,
  useGetChildIndustriesQuery,
  useGetSearchableLocationsQuery,
  useGetLocationAutocompleteQuery,
  useCreateSavedSearchMutation,
  useDestroySavedSearchMutation,
  useUpdateSavedSearchMutation,
} = savedSearchesApi;
