import {Flex, Stack, Text} from "@chakra-ui/react";
import numbro from "numbro";
import {kumoDayJs} from "../../utils/dayjs";

export const BillingSummary = ({
  upcomingInvoiceAmount,
  upcomingAmountOff,
  upcomingPercentOff,
  status,
  trialEnd,
  canceledAt,
  cancelAt,
  currentPeriodEnd,
}: {
  upcomingInvoiceAmount?: number | null;
  upcomingAmountOff?: number | null;
  upcomingPercentOff?: number | null;
  upcomingChargeDate?: any | null;
  status?: string | null;
  trialEnd?: any | null;
  canceledAt?: any | null;
  cancelAt?: any | null;
  currentPeriodEnd?: any | null;
}) => {
  return (
    <Flex alignItems="center">
      <Stack
        spacing={0}
        alignItems={{ base: "flex-start", sm: "flex-end" }}
        textAlign={{ base: "left", sm: "right" }}>
        {
          <Text fontWeight="medium">
            Upcoming charge: $
            {numbro((upcomingInvoiceAmount ?? 0) / 100).format({
              thousandSeparated: true,
              mantissa: 2,
            })}
          </Text>
        }
        <CouponAmount
          upcomingAmountOff={upcomingAmountOff}
          upcomingPercentOff={upcomingPercentOff}
        />
        <PlanSummary
          status={status}
          trialEnd={trialEnd}
          canceledAt={canceledAt}
          cancelAt={cancelAt}
          currentPeriodEnd={currentPeriodEnd}
        />
      </Stack>
    </Flex>
  );
};

const CouponAmount = ({
  upcomingAmountOff,
  upcomingPercentOff,
}: {
  upcomingAmountOff?: number | null;
  upcomingPercentOff?: number | null;
}) => {
  if (upcomingAmountOff || upcomingPercentOff) {
    return (
      <Text color="muted" fontSize="sm">
        (Discount applied,{" "}
        {upcomingAmountOff
          ? `$${numbro(upcomingAmountOff / 100).format({ thousandSeparated: true, mantissa: 0 })}`
          : `${upcomingPercentOff}%`}{" "}
        off next invoice)
      </Text>
    );
  }

  return null;
};

const PlanSummary = ({
  status,
  trialEnd,
  canceledAt,
  cancelAt,
  currentPeriodEnd,
}: {
  status?: string | null;
  trialEnd?: any | null;
  canceledAt?: any | null;
  cancelAt?: any | null;
  currentPeriodEnd?: any | null;
}) => {
  if (status === "trialing") {
    // const msg = `After your free trial ends`;
    const endMessage = canceledAt
      ? `Your plan ends on ${kumoDayJs(trialEnd).format("MMMM D, YYYY")}`
      : `Your plan renews on ${kumoDayJs(trialEnd).format("MMMM D, YYYY")}`;

    return (
      <Text color="muted" fontSize="sm">
        {endMessage}
      </Text>
    );
  } else if (status === "active") {
    const copy = canceledAt
      ? `Your plan will end on ${kumoDayJs(cancelAt).format("MMMM D, YYYY")}`
      : `Your plan renews on ${kumoDayJs(currentPeriodEnd).format("MMMM D, YYYY")}`;
    return (
      <Text fontSize="sm" color="muted">
        {copy}
      </Text>
    );
  } else if (status === "canceled") {
    return (
      <Text fontSize="sm" color="muted">{`Your plan was deactivated on ${kumoDayJs(
        canceledAt
      ).format("MMMM D, YYYY")}`}</Text>
    );
  } else if (status === "past_due") {
    return (
      <Text fontSize="sm" color="muted" w="sm" textAlign={{ base: "left", sm: "right" }}>
        You have an outstanding balance. Please update your payment method or contact Kumo Support.
      </Text>
    );
  }

  return (
    <Text fontSize="sm" color="muted" w="sm" textAlign={{ base: "left", sm: "right" }}>
      You don't have a subscription yet.
    </Text>
  );
};
