import { setCookie } from "./../utils/set-cookie";
import { transmitMarketingRefSource } from "./../api/transmit-marketing-ref-source";
import { makeId } from "./../utils/make-id";
import { getCookie } from "../utils/get-cookie";

export const marketingRefSourceInitializer = () => {
  let uniqueBrowserId = getCookie("unique_browser_id");
  const refIds = getCookie("ref_ids");
  if (refIds) {
    if (!uniqueBrowserId) {
      uniqueBrowserId = makeId(12);
      setCookie("unique_browser_id", uniqueBrowserId);
    }
    const transmittedRefIds = getCookie("transmitted_ref_ids");
    const refIdsToBeTransmitted = refIds
      .split(",")
      .filter((x) => !transmittedRefIds?.split(",").includes(x));
    refIdsToBeTransmitted.map((refId) => {
      if (refId) {
        transmitMarketingRefSource({ uniqueBrowserId, refId });
      }
    });

    if (refIdsToBeTransmitted.length > 0) {
      setCookie(
        "transmitted_ref_ids",
        (transmittedRefIds?.split(",") || []).concat(refIdsToBeTransmitted).join(",")
      );
    }
  }
};
