import {Link, useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import {
  Box,
  Button,
  Card,
  CloseButton,
  Divider,
  Flex,
  HStack,
  LinkBox,
  LinkOverlay,
  Skeleton,
  Stack,
  Text,
  useBreakpointValue,
  VStack
} from "@chakra-ui/react";
import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {useGetDealFeedsQuery} from "../../services/dealFeedsApi";
import {LoadingFeed, ShowFeed} from "./show-feed/page";
import {HorizontalScrollBox} from "../../components/HorizontalScrollBox";
import {HeadingContext} from "../heading-provider";
import {StandardTextHeading} from "../layouts/BodyHeading";

export const DealFeed = () => {
  const { data,  isLoading, isFetching, isError} = useGetDealFeedsQuery();
  const { feedId } = useParams();

  const { setHeadingContent } = useContext(HeadingContext);

  useEffect(() => {
    // const activeFeed = data?.data?.find((feed) => feed.id === feedId) || data?.data[0]
    // const pageName = activeFeed ? `Explore Deals By Feed> ${activeFeed.attributes.name}` : `Explore > Loading...`;
    setHeadingContent(<StandardTextHeading text={"Explore Deals"} />);
  }, [data, feedId]);


  const flexDirection = useBreakpointValue({ base: 'column-reverse', xl: 'column-reverse' });
  const isRowLayout = flexDirection === 'row';

  const [removeExplanation, setRemoveExplanation] = useState(localStorage.getItem('removeExplanation1') ? true : false);

  if (data ) {
    const activeFeedId = feedId || data?.data[0]?.id;
    const feedToDisplay = data?.data?.find((feed) => feed.id === activeFeedId) || data?.data[0]

    return (
      <>
        <Helmet title={`KUMO | Explore Deals | ${feedToDisplay ? feedToDisplay.attributes.name : 'Loading...'}`} />
        <Flex direction={flexDirection}>
          <Box flex="1" maxWidth={flexDirection === 'row' ? '700px' : '900px'}>
            {!removeExplanation &&
              <Box mb={{base: "12", xl: "16"}} mt={{base: "3", xl: "5"}} position={"relative"}>
                <Box px={{base: "4", xl: "8"}} py={{base: "6", xl: "8"}} bg="blue.50" borderRadius="xs">
                  <HStack>
                    <Box>
                      <Text fontSize={{base: "md", xl: "xl"}} fontWeight="bold">Welcome! Kumo tracks new business listings from 100s of sources.</Text>
                      <Text fontSize={{base: "sm", xl: "lg"}}>In "Explore Deals" we regularly pick and share a few deals from popular searches.</Text>
                    </Box>
                  </HStack>
                  <CloseButton
                    size={{base: "xs", xl: "sm"}}
                    position="absolute"
                    right={{base: "1", xl: "2"}}
                    top={{base: "1", xl: "2"}}
                    color="gray.600"
                    onClick={() => {localStorage.setItem('removeExplanation1', 'true'); setRemoveExplanation(true)}}
                  />
                </Box>
              </Box>
            }
            {activeFeedId && <ShowFeed key={activeFeedId} id={activeFeedId} />}
          </Box>
          {flexDirection === 'row' && <Divider orientation="vertical" height="auto" alignSelf="center" mx={4} />}
          <Box flex="1"
               maxWidth={flexDirection === 'row' ? '300px' : '100%'}
               mt={flexDirection === 'row' ? '0px' : '2'}
               mb={flexDirection === 'row' ? '0px' : '6'}
          >
            <DealFeedsList feeds={data?.data} activeFeedId={activeFeedId} />
          </Box>
        </Flex>
      </>
    );
  } else {
    return (
      <>
        <Helmet title={`KUMO | Explore Deals`} />
        <Flex direction={flexDirection}>
          <Box flex="1" maxWidth={flexDirection === 'row' ? '700px' : '900px'}>
            <LoadingFeed />
          </Box>
          {flexDirection === 'row' && <Divider orientation="vertical" height="auto" alignSelf="center" mx={4} />}
          <Box flex="1"
               mt={flexDirection === 'row' ? '0px' : '2'}
               mb={flexDirection === 'row' ? '0px' : '6'}
          >
            <FeedsSkeleton />
          </Box>
        </Flex>

      </>
    )
  }


}

const DealFeedsList = ({feeds, activeFeedId}) => {
  // We want the breakpoint value
  const verticalList = useBreakpointValue({ base: false, xl: false }, { ssr: false });

  if (verticalList) {
    return (
      <Box >
        <VStack align="stretch" spacing={4}>
          {feeds && feeds.map((feed) => (
            <LinkBox as="article" p={3} bg={activeFeedId === feed.id ? 'blue.50' : 'white'} borderWidth="1px" borderRadius="md" key={feed.id}>
              <LinkOverlay as={Link} to={`/deal-feeds/${feed.id}`}>
                <Text fontSize="md" fontWeight="bold">{feed.attributes.name}</Text>
                <Text fontSize="sm">{feed.attributes.description}</Text>
              </LinkOverlay>
            </LinkBox>
          ))}
        </VStack>
      </Box>
    );
  } else {
    if (feeds) {
      return (
        <Box>
          <HorizontalScrollBox>
            {
              feeds.map((feed, index) => (
                <Link key={feed.id}
                      to={`/deal-feeds/${feed.id}`} style={{ textDecoration: 'none' }}>
                  <Button borderRadius={'full'} size={'sm'} mr={"3"} variant={activeFeedId && activeFeedId === feed.id ? "solid" : "outline"}

                  >
                    {feed.attributes.name}
                  </Button>
                  {/*<FeedCard*/}
                  {/*  primaryString={feed.attributes.name}*/}
                  {/*  secondaryString={feed.attributes.description}*/}
                  {/*  active={activeFeedId && activeFeedId === feed.id}*/}
                  {/*/>*/}
                </Link>
              ))
            }
          </HorizontalScrollBox>
        </Box>

      );
    }
    return (
      <FeedsSkeleton />
    )
  }

}


const FeedCard = ({ primaryString, secondaryString, active, ...otherProps }) => {
  return (
    <Card p={"10px"} width={"225px"}
          my={"5px"}
          mr={"20px"}
          cursor={"pointer"}
          boxShadow='sm'
          bg={active ? "blue.50" : "white"}
          _hover={{
            textDecoration: "none",
            transform: 'translateY(3px)', // Moves the card down by 2 pixels
            transition: 'transform 0.2s, box-shadow 0.2s' // Smooth transition for the effect
          }}
          {...otherProps}
    >
      <HStack alignItems="center">
        <Box
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}>
          <Text
            fontSize={"sm"}
            fontWeight="medium"
            noOfLines={1}
            sx={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
              whiteSpace: "nowrap",
            }}>
            {primaryString}
          </Text>
          <Box mt={"2px"}>
            <HStack spacing={"1"} color={"gray.600"}>
              {/*<Icon as={FiClock} />*/}
              <Text
                fontSize="xs"
                fontWeight="medium"
                noOfLines={1}
                sx={{
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: "1",
                  overflow: "hidden",
                  display: "-webkit-box",
                }}>
                {secondaryString}
              </Text>
            </HStack>
          </Box>
        </Box>
      </HStack>
    </Card>
  )
}

const FeedsSkeleton = () => {
  return (
    <Box>
      <Stack>
        <Box>
          <HorizontalScrollBox>
            {Array.from({ length: 8 }).map((_, index) => (
              <Skeleton borderRadius={"full"} key={index} width="150px" height={"35px"} mr={"10px"} />
            ))}
          </HorizontalScrollBox>
        </Box>
      </Stack>
    </Box>
  );
};
