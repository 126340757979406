import {useGetSavedSearchesForUserQuery, useUpdateSavedSearchMutation} from "../../services/savedSearchesApi";
import {
	localSearchActions,
	selectActiveSearch,
	selectDefaultFilters,
	selectDefaultSearch,
	suggestedSearches,
	useFormattedFilterChanges
} from "../../store/local-search";
import {useDispatch, useSelector} from "react-redux";
import {
	Box,
	Button,
	Flex,
	Icon,
	Link as ChakraLink,
	Tab,
	TabIndicator,
	TabList,
	Tabs,
	Text,
	Tooltip,
	useBreakpointValue,
	useDisclosure
} from "@chakra-ui/react";
import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {StandardTextHeading} from "../layouts/BodyHeading";
import {HeadingContext} from "../heading-provider";
import {NavLink as RouterNavLink, useNavigate, useParams} from "react-router-dom";
import {Analytics} from "./analytics/page";
import {isEqual} from "lodash";
import {CreateSearchButton} from "../saved-searches/components/CreateSearchButton";
import {FiltersDrawerButton} from "./sections/FiltersDrawerButton";
import {ActiveSearchDisplay} from "./sections/ActiveSearchDisplay";
import Helmet from "react-helmet";
import {IoMdUndo} from "react-icons/io";
import {CiSaveUp2} from "react-icons/ci";
import {EmptySearchBox} from "./sections/EmptySearchBox";
import {Xwrapper} from "react-xarrows";
import {ExportToCsv} from "./sections/ExportToCsv";
import {ResultsCounter} from "./sections/ResultsCounter";
import {useGetDealsQuery} from "../../services/dealsApi";
import {useQuery} from "@apollo/client";
import {CURRENT_USER_QUERY} from "../../graphql/current-user";
import {SwitchToPaidModal} from "../../components/upgrade-ctas/switch-to-paid-modal";
import {MdLockOutline} from "react-icons/md";
import {skipToken} from "@reduxjs/toolkit/dist/query/react";

const showNewFeatures = process.env.REACT_APP_SHOW_NEW_FEATURES === "true" || false;

export const SearchDeals = () => {
	const { searchId, suggestedSearchId } = useParams();
	const { filters, sort, page } = useSelector(selectActiveSearch);
	const defaultFilters = useSelector(selectDefaultFilters);
	const defaultSearch = useSelector(selectDefaultSearch);
	const dispatch = useDispatch();
	const { setHeadingContent } = useContext(HeadingContext);
	const navigate = useNavigate();
	const { isOpen, onOpen, onClose } = useDisclosure()
	
	const { loading: userLoading, data: userData } = useQuery(CURRENT_USER_QUERY);
	const subscriptionLevel = userData?.currentUser?.account?.subscriptionLevel || "Public";
	const isUltimate = ["Ultimate"].includes(subscriptionLevel);
	const isPaid = ["Pro", "Ultimate"].includes(subscriptionLevel);


	const { data, isLoading, isFetching } = useGetSavedSearchesForUserQuery(
		isPaid ? undefined : skipToken
	);


	// We need to cache the resultsData so the tab indicator updates based on count
	let queryHook;
	let queryOptions;

	queryHook = useGetDealsQuery;
	queryOptions = {
		filters: filters,
		page: page,
		sortField: sort.sortField,
		sortOrder: sort.sortOrder,
	};

	const { data: resultsData, isFetching: resultsFetching } = queryHook(queryOptions);
	const meta = resultsData?.meta;

	const filtersChanged = !isEqual(filters, defaultFilters);

	const updateHeadingContent = (content) => {
		if (setHeadingContent) {
			setHeadingContent(content);
		}
	};

	useEffect(() => {
		// Function to handle suggested search
		const handleSuggestedSearch = (id) => {
			const suggestedSearch = suggestedSearches.find(search => search.id === id);
			if (suggestedSearch) {
				updateHeadingContent(<UnsavedSuggestedSearchHeading />)
				dispatch(localSearchActions.updateActiveSearch({
					filters: suggestedSearch.filters,
					page: 1
				}));
			} else {
				updateHeadingContent(<StandardTextHeading text={`Suggested Search > Error Loading`} />);
			}
		};

		// Function to handle saved search
		const handleSavedSearch = (id) => {
			const savedSearch = data?.data?.find(search => search.id === id);
			if (savedSearch) {
				updateHeadingContent(<SavedSearchHeading savedSearch={savedSearch} />);
				dispatch(localSearchActions.updateActiveSearch({
					filters: savedSearch.attributes.filters,
					page: 1
				}));
			} else if (isLoading || isFetching) {
				updateHeadingContent(<StandardTextHeading text={"Saved Searches > Loading..."} />);
			} else {
				updateHeadingContent(<StandardTextHeading text={`Saved Searches > Error Loading`} />);
			}
		};

		// Decide which function to call based on IDs
		if (suggestedSearchId) {
			handleSuggestedSearch(suggestedSearchId);
		} else if (searchId && searchId !== 'new_search') {
			handleSavedSearch(searchId);
		} else if (searchId === 'new_search') {
			navigate("/search")
		} else {
			updateHeadingContent(<StandardTextHeading text={"Search Deals"} />);
			dispatch(localSearchActions.updateActiveSearch(defaultSearch));
		}
	}, [suggestedSearchId, searchId, data, isLoading, isFetching, defaultFilters, dispatch]);

	useEffect(() => {
		if ( !searchId && !suggestedSearchId) {
			if (filtersChanged) {
				updateHeadingContent(<UnsavedSearchHeading filters={filters}/>);
			} else {
				updateHeadingContent(<StandardTextHeading text={"Search Deals"} />);
			}
		}
	}, [filtersChanged, filters, searchId, suggestedSearchId]);

	useEffect(() => {
		if (suggestedSearchId) {
			updateHeadingContent(<UnsavedSuggestedSearchHeading />)
		}
	}, [filters, searchId, suggestedSearchId, meta]);


	const [tabIndex, setTabIndex] = useState(0);
	const [plan, setPlan] = useState("Pro");
	const handleTabChange = (index) => {
		if (!isUltimate && index !== 0) {
			setPlan("Ultimate")
			setTabIndex(tabIndex)
		} else {
			setTabIndex(index)
		}
	}

	const noMeaningfulFilterChange = Object.keys(useFormattedFilterChanges(filters)).length === 0;

	const showEmptySearchBox = noMeaningfulFilterChange && !searchId && !suggestedSearchId;

	return (
		<>
			<Xwrapper>
			<Helmet title={`Kumo | ${searchId ? 'Saved Searches' : 'Search Deals'}`} />
			<Box bg={"white"} id="top-of-page" mb={"20"}>
				<Box pt={"15px"}>
					<Flex direction="column" position="relative">
						<Tabs size={'md'}
									onChange={handleTabChange}
									index={tabIndex}
									key={"searchPage"}
									variant="underline">
							<TabList>
								<Tab>Results<ResultsCounter /></Tab>
								{isUltimate && <Tab>Insights</Tab>}
								{!isUltimate && <Tab onClick={onOpen}>
									<Icon as={MdLockOutline} w={4} h={4} color="gray.500" mr={"1"}/>
									<Tooltip label="Upgrade to access this feature" hasArrow>
										Insights
									</Tooltip>
								</Tab>
								}
							</TabList>
							<TabIndicator key={meta ? meta.total_count : (resultsFetching ? "loading": "no_results")} />
						</Tabs>
						<Box position="absolute" right="0" top="-2">
							<ExportToCsv />
						</Box>
					</Flex>
					{isOpen && <SwitchToPaidModal isOpen={isOpen} onClose={onClose} plan={plan} />}
				</Box>
				<Box py={"3"}>
					<FiltersDrawerButton id={"deals_page_filters_button"} />
					{tabIndex === 0 && !showEmptySearchBox && <ActiveSearchDisplay />}
					{tabIndex === 0 && showEmptySearchBox && <EmptySearchBox />}
					{tabIndex === 1 && isUltimate && <Analytics />}
				</Box>
			</Box>
			</Xwrapper>
		</>
	)
}

const UnsavedSuggestedSearchHeading = () => {
	const { filters, sort } = useSelector(selectActiveSearch);
	const { suggestedSearchId } = useParams();
	const suggestedSearch = suggestedSearches.find(search => search.id === suggestedSearchId);
	const { isOpen, onOpen, onClose } = useDisclosure()
	const { loading: userLoading, data: userData } = useQuery(CURRENT_USER_QUERY);
	const isPaid = ["Pro", "Ultimate"].includes(userData?.currentUser?.account?.subscriptionLevel || "Public");

	if (!suggestedSearchId || !filters) {
		return (
			<>
				<HeadingLink text={"Suggested"} url={'/search/new_search'} />
				<HeadingText text={` > Error Loading`} />
			</>
		);
	}

	return (
		<Box
			pl={{ base: '0', lg: '10' }}
		>
			<Flex align="center" gap="2">
				<HeadingLink text={"Suggested"} url={'/search/new_search'} />
				<HeadingText text={` > ${suggestedSearch.name}`} />
				{isPaid && <CreateSearchButton filters={filters} />}
				{!isPaid && <Button color={"gray.500"} size={"xs"} leftIcon={<MdLockOutline />} variant='outline' onClick={onOpen}>Save Search</Button>}
				{isOpen && <SwitchToPaidModal isOpen={isOpen} onClose={onClose} plan={"Pro"} />}
			</Flex>
		</Box>
	);
}


const UnsavedSearchHeading = ({ filters }) => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const { loading: userLoading, data: userData } = useQuery(CURRENT_USER_QUERY);
	const isPaid = ["Pro", "Ultimate"].includes(userData?.currentUser?.account?.subscriptionLevel || "Public");
	return (
		<Box
			pl={{ base: '0', lg: '10' }}
		>
			<Flex align="center" gap="2">
				<HeadingLink text={"Search"} url={'/search/new_search'} />
				<HeadingText text={" > Unsaved Search"} />
				{isPaid && <CreateSearchButton filters={filters} />}
				{!isPaid && <Button color={"gray.500"} size={"xs"} leftIcon={<MdLockOutline />} variant='outline' onClick={onOpen}>Save Search</Button>}
				{isOpen && <SwitchToPaidModal isOpen={isOpen} onClose={onClose} plan={"Pro"} />}
			</Flex>
		</Box>
	);
}

const HeadingLink = ({text, url}) => {
	const headingSize = useBreakpointValue({ base: '15px', lg: '20px' });
	return (
		<ChakraLink
			as={RouterNavLink}
			to={url}
		>
			<Text fontSize={headingSize}
						fontWeight={"medium"}
						noOfLines={1}
						css={{ display: '-webkit-box', overflow: 'hidden', WebkitLineClamp: 1, WebkitBoxOrient: 'vertical' }}
			>{text}</Text>
		</ChakraLink>
	)
}
const HeadingText = ({text}) => {
	const headingSize = useBreakpointValue({ base: '15px', lg: '20px' });
	return (
		<Text fontSize={headingSize}
					fontWeight={"medium"}
					noOfLines={1}
					css={{ display: '-webkit-box', overflow: 'hidden', WebkitLineClamp: 1, WebkitBoxOrient: 'vertical' }}
		>{text}</Text>
	)
}

const SavedSearchHeading = ({savedSearch }) => {
	const { filters, sort } = useSelector(selectActiveSearch);
	const filtersChanged = !isEqual(filters, savedSearch.attributes.filters);

	const [updateSavedSearch] = useUpdateSavedSearchMutation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const saveChanges = async () => {
		const { data } = await updateSavedSearch({
			id: savedSearch.id,
			title: savedSearch.attributes.title,
			include_in_weekly_digest: savedSearch.attributes.include_in_weekly_digest,
			filters: filters,
		});

		if (data) {
			navigate(`/search/saved/${data?.data?.id}`);
		}
	}

	const undoChanges = () => {
		dispatch(localSearchActions.updateActiveSearch({
			filters: savedSearch.attributes.filters,
			page: 1
		}));
	}

	const headingSize = useBreakpointValue({ base: '15px', lg: '20px' });

	return (
		<>
			<Box
				pl={{ base: '0', lg: '10' }}
			>
				<Flex align="center" gap="2">
					{!filtersChanged && <HeadingLink text={"Saved Searches"} url={'/saved-searches'} />}
					{!filtersChanged && <Text fontSize={headingSize}
								fontWeight={"medium"}
								noOfLines={1}
								css={{ display: '-webkit-box', overflow: 'hidden', WebkitLineClamp: 1, WebkitBoxOrient: 'vertical' }}
					>{` > ${savedSearch.attributes.title}`}</Text>}
					{filtersChanged && <Text fontSize={headingSize}
																		fontWeight={"medium"}
																		noOfLines={1}
																		css={{ display: '-webkit-box', overflow: 'hidden', WebkitLineClamp: 1, WebkitBoxOrient: 'vertical' }}
					>{`${savedSearch.attributes.title}`}</Text>}
					{filtersChanged && <Button size={"xs"} ml={"10px"} leftIcon={<IoMdUndo />} variant='outline' onClick={undoChanges}>Undo</Button>}
					{filtersChanged && <Button size={"xs"} ml={"10px"} leftIcon={<CiSaveUp2 />} variant='outline' onClick={saveChanges}>Update</Button>}
				</Flex>
			</Box>

		</>
	);
}




