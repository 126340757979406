import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import Utc from "dayjs/plugin/utc";

// register plugins
dayjs.extend(relativeTime);
dayjs.extend(Utc);

export const getRelativeTimeFromNow = (
  date: string | number | Date | dayjs.Dayjs | null | undefined
): string => {
  return dayjs(date).fromNow(true);
};

export const kumoDayJs = dayjs;
