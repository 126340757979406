import { gql } from "@apollo/client";
import { PROSPECT_FRAGMENT } from "../fragments/prospect";

export const UPDATE_DEAL_FLOW_PROSPECT = gql`
  ${PROSPECT_FRAGMENT}
  mutation UpdateDealFlowProspect($stageId: ID!, $prospectId: ID!, $order: Float!) {
    updateDealFlowProspect(input: { stageId: $stageId, prospectId: $prospectId, order: $order }) {
      prospect {
        ...prospect
      }
    }
  }
`;
