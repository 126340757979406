import {Box, Image} from '@chakra-ui/react'
import darkLogo from "../images/kumo-icon.png";
import whiteLogo from "../images/kumo-icon-white.png";

export const LogoImage = ({logoColor, ...props}) => {
  if (logoColor === 'white') {
    return <Box {...props}>
      <Image src={whiteLogo} alt="NameLogo" objectFit="cover"/>
    </Box>
  } else {
    return <Box {...props}>
      <Image src={darkLogo} alt="NameLogo" objectFit="cover"/>
    </Box>
  }
}