import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  // eslint-disable-next-line no-undef
  baseUrl: `${process.env.REACT_APP_API_BASE_URL}api/users`,
  // prepareHeaders is used to configure the header of every request and gives access to getState which we use to include the token from the store
  prepareHeaders: (
    headers
    // { getState }
  ) => {
    const token = localStorage.getItem("with-kumo-token");
    if (token) {
      headers.set("authorization", token);
    }
    return headers;
  },
});

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    createSurveyResponse: builder.mutation({
      query: ({survey_response, survey_type}) => ({
        url: `/create_survey_response`,
        method: "POST",
        body: {
          survey_response: survey_response,
          survey_type: survey_type
        },
      }),
    }),
  }),
});


export const {
  useCreateSurveyResponseMutation,
} = userApi;
