import {Children, cloneElement, isValidElement, useState} from 'react';
import {
  Box,
  Button,
  Center,
  Circle,
  createIcon,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Icon,
  Input,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useDisclosure,
  useRadio,
  useRadioGroup,
  VStack,
  Wrap
} from '@chakra-ui/react';
import {SearchFilterLabel, TextSearchFilter} from "../search-filters-form";
import {useQuery} from "@apollo/client";
import {CURRENT_USER_QUERY} from "../../graphql/current-user";
import {SwitchToPaidModal} from "../upgrade-ctas/switch-to-paid-modal";
import {BoxButton} from "../../views/search-deals/sections/EmptySearchBox";
import {IoMdSearch} from "react-icons/io";

// Custom Check Icon using Chakra UI's createIcon function
export const CheckIcon = createIcon({
  displayName: 'CheckIcon',
  viewBox: '0 0 12 10',
  path: (
    <polyline
      fill="none"
      strokeWidth="2px"
      stroke="currentColor"
      strokeDasharray="16px"
      points="1.5 6 4.5 9 10.5 1"
    />
  ),
});

// RadioCard Component
export const RadioCard = (props) => {
  const {title} = props
  const { getInputProps, getCheckboxProps, getLabelProps } = useRadio(props.radioProps);
  const input = getInputProps();
  const checkbox = getCheckboxProps();
  const label = getLabelProps();

  if (input.checked) {
    return (
      <Box as="label" >
        <Box
          {...checkbox}
          cursor="pointer"
          borderWidth="1px"
          borderRadius="md"
          // boxShadow="md"
          bg="gray.100"
          _checked={{
            bg: "white",
            // color: "white",
            borderColor: "blue.200",
          }}
          _focus={{
            // boxShadow: "outline",
          }}
          p={4}
        >
          <HStack spacing={2} alignItems="center" w="full" justifyContent="space-between"> {/* Set width to full and justify content */}
            <Circle size="24px" bg="white" borderWidth="2px">
              {input.checked && <Icon as={CheckIcon} color="blue.500" />}
            </Circle>
            <Text flex="1" fontSize={"md"} textAlign="left">{title}</Text> {/* Ensure Text takes up maximum space and align text to the left */}
          </HStack>
          {/* Additional Content */}
          {input.checked && (
            <Box mt={2} width={"full"}>
              {props.children}
            </Box>
          )}
        </Box>
      </Box>
    );
  } else {
    return (
      <Box as="label" {...label}>
        <input {...input} />
        <Box
          {...checkbox}
          cursor="pointer"
          borderWidth="1px"
          borderRadius="md"
          // boxShadow="md"
          bg="gray.100"
          _checked={{
            bg: "white",
            // color: "white",
            // borderColor: "blue.500",
          }}
          _focus={{
            // boxShadow: "outline",
          }}
          p={4}
        >
          <HStack spacing={2} alignItems="center" w="full" justifyContent="space-between"> {/* Set width to full and justify content */}
            <Circle size="24px" bg="white" borderWidth="2px">
              {input.checked && <Icon as={CheckIcon} color="blue.500" />}
            </Circle>
            <Text flex="1" textAlign="left" fontSize={"md"}>{title}</Text> {/* Ensure Text takes up maximum space and align text to the left */}
          </HStack>
        </Box>
      </Box>
    );
  }
};

export const RadioCardGroup = (props) => {
  const { name, defaultValue, value, onChange, children, ...rest } = props;
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    value,
    onChange,
  });

  const group = getRootProps();

  const cards = Children.toArray(children).filter(isValidElement).map((child) =>
    cloneElement(child, {
      radioProps: getRadioProps({ value: child.props.value }),
    })
  );

  return (
    <Stack {...group} {...rest}>
      {cards}
    </Stack>
  );
};

const AdvancedSearchForm = ({ filters, handleFiltersChange }) => {
  const [andInput, setAndInput] = useState('');
  const [orInput, setOrInput] = useState('');
  const [notInput, setNotInput] = useState('');

  const handleAddSearchTag = (type, value) => {
    if (value.trim() !== '') {
      const newTags = [...filters[type], value.trim()];
      handleFiltersChange({ ...filters, [type]: newTags });
      // Reset input state
      if (type === 'ANDSearch') setAndInput('');
      else if (type === 'ORSearch') setOrInput('');
      else if (type === 'NOTSearch') setNotInput('');
    }
  };

  // Function to handle removing search tags
  const handleRemoveSearchTag = (type, index) => {
    const newTags = [...filters[type]];
    newTags.splice(index, 1);
    handleFiltersChange({ ...filters, [type]: newTags });
  };

  return (
    <VStack align="start" w="full" spacing={5}>
      {['ORSearch', 'ANDSearch', 'NOTSearch'].map((searchType) => {
        // Custom names mapping
        const searchLabels = {
          ANDSearch: 'Always Include Keywords (AND)',
          ORSearch: 'Multiple Keywords (OR)',
          NOTSearch: 'Exclude Keywords (NOT)'
        };

        const searchInstructions = {
          ANDSearch: 'Every term here must be present...',
          ORSearch: 'If any of these terms are present...',
          NOTSearch: 'None of these terms should be present...'
        }

        return (
          <Box key={searchType} w={"full"}>
            {/* Use custom names */}
            <FormLabel>{searchLabels[searchType]}</FormLabel>
            <HStack spacing={4}>
              <Input
                placeholder={searchInstructions[searchType]}
                value={searchType === 'ANDSearch' ? andInput : searchType === 'ORSearch' ? orInput : notInput}
                onChange={event => {
                  if (searchType === 'ANDSearch') setAndInput(event.target.value);
                  else if (searchType === 'ORSearch') setOrInput(event.target.value);
                  else if (searchType === 'NOTSearch') setNotInput(event.target.value);
                }}
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    handleAddSearchTag(searchType, event.target.value);
                  }
                }}
                w="full"
              />
              <Button onClick={() => handleAddSearchTag(searchType, searchType === 'ANDSearch' ? andInput : searchType === 'ORSearch' ? orInput : notInput)}>
                Add
              </Button>
            </HStack>
            {/* Replace HStack with Wrap for the tags */}
            <Wrap spacing={2} mt={2}>
              {filters[searchType].map((tag, index) => (
                <Tag size="lg" key={index} borderRadius="full">
                  <TagLabel>{tag}</TagLabel>
                  <TagCloseButton onClick={() => handleRemoveSearchTag(searchType, index)} />
                </Tag>
              ))}
            </Wrap>
          </Box>
        );
      })}
      <FormHelperText>Searches for keywords and phrases in titles, business summaries, and listing descriptions.</FormHelperText>
    </VStack>
  );
}
const TextSearchFilterGroup = ({ label, filters, handleFilterChange, handleFiltersChange, ...props }) => {
  let titleSubText = ''
  if (filters.textSearchType === 'SimpleSearch') {
    const textSearch = filters['titleAndDescriptionSearch']
    titleSubText = textSearch > 0 ? `(Simple Search: ${textSearch})` : '(Simple Search)'
  } else if (filters.textSearchType === 'AdvancedSearch') {
    titleSubText = '(Advanced Search)'
  }

  const { loading: userLoading, data: userData } = useQuery(CURRENT_USER_QUERY);
  const isUltimate = ["Ultimate"].includes(userData?.currentUser?.account?.subscriptionLevel || "Public");
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <SearchFilterLabel label="Search Text" subtext={titleSubText} icon={IoMdSearch} />
      <FormControl as="fieldset" w="full">
        <RadioCardGroup name="textSearchType" defaultValue={filters.textSearchType || 'SimpleSearch'} onChange={(value) => handleFilterChange('textSearchType', value)} spacing={"3"}>
          <RadioCard value="SimpleSearch" title={"Simple (Single basic phrase)"}>
            <Box mt={"4"}>
              <VStack align="start" w="full">
                <TextSearchFilter label={"Search Title & Description:"} filterKey={"titleAndDescriptionSearch"} filters={filters} handleFilterChange={handleFilterChange} />
                <FormHelperText>Searches for a single phrase in titles, business summaries, and listing descriptions.</FormHelperText>
              </VStack>
            </Box>
          </RadioCard>
          <RadioCard value="AdvancedSearch" title={"Advanced (Multiple keywords, AND/OR/NOT filters)"}>
            <Box mt={"4"}>
              {isOpen && !isUltimate && <SwitchToPaidModal isOpen={isOpen} onClose={onClose} plan={"Ultimate"} />}
              {isUltimate && <AdvancedSearchForm filters={filters} handleFiltersChange={handleFiltersChange} />}
              {!isUltimate &&
                <BoxButton
                  onClick={onOpen}
                  borderStyle={'dashed'}
                  bg={"gray.50"}
                  customContent={
                    <Center py={"4"}>
                      <VStack spacing={"1"}>
                        <Box>
                          <Text textAlign={"center"} color="fg.muted">
                            Want to search for multiple terms and exclude others?
                          </Text>
                        </Box>
                        <Box>
                          <Text textAlign={"center"} fontWeight={"semibold"}>
                            Upgrade to Ultimate.
                          </Text>
                        </Box>
                      </VStack>
                    </Center>
                  }
                />
              }
            </Box>
          </RadioCard>
        </RadioCardGroup>
      </FormControl>
    </>
  );
};
export default TextSearchFilterGroup;