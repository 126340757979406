import {
  Avatar,
  Box,
  HStack,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import {FiSettings} from "react-icons/fi";
import React from "react";
import {LogoutButton, NavButton} from "./Navigation";
import {useQuery} from "@apollo/client";
import {CURRENT_USER_QUERY} from "../../../graphql/current-user";

export const UserPopoverButton = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { data } = useQuery(CURRENT_USER_QUERY);
  const user = data?.currentUser

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} trigger="click" openDelay={0}>
      <PopoverTrigger>
        <Box width={"100%"}
             _hover={
               { bg: 'gray.100', color: 'gray.900' }
             }
             borderRadius={"lg"}
             cursor={"pointer"}
             p={"3"}
        >
          <HStack spacing="3"

          >
            <Avatar boxSize="10" name={user.name} />
            <Box
              isTruncated
            >
              <Text textStyle="sm" fontWeight="medium">
                {/*{user.name}*/}
                {user.email}
              </Text>
              <Text textStyle="sm" color="fg.muted">
                {user?.account?.prettifiedSubscriptionLevel}
              </Text>
            </Box>
          </HStack>
        </Box>
      </PopoverTrigger>
      <PopoverContent p="2" width={{base: "290px", lg: "14rem"}}>
        <Stack spacing="0" alignItems="stretch">
          <Stack spacing="1">
            {user && <NavButton to="/settings" label="Settings" icon={FiSettings}></NavButton>}
            {user && <LogoutButton buttonType={'NavButton'}></LogoutButton>}
          </Stack>
        </Stack>
      </PopoverContent>
    </Popover>
  )
}