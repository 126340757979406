import {Avatar, Box, Divider, Flex, HStack, Icon, Link, Skeleton, Stack, Text, useDisclosure} from '@chakra-ui/react';
import * as React from "react";
import {useState} from "react";
import {useGetNonPickedDealsForReleaseQuery, useGetReleaseQuery} from "../../../services/dealFeedsApi";
import {useQuery} from "@apollo/client";
import {CURRENT_USER_QUERY} from "../../../graphql/current-user";
import {FiMapPin} from "react-icons/fi";
import numbro from "numbro";
import {DealDrawer} from "../../../components/deal/deal-drawer";
import {SignupForFreeModal} from "../../../components/upgrade-ctas/signup-for-free-modal";
import {SwitchToPaidModal} from "../../../components/upgrade-ctas/switch-to-paid-modal";

export const ShowRelease = ({ id, cachedRelease }) => {
  const { loading: userLoading, data: userData } = useQuery(CURRENT_USER_QUERY);
  const subscriptionLevel = userData?.currentUser?.account?.subscriptionLevel || "Public"

  return (
    <>
      <Box>
        {!userLoading && subscriptionLevel === "Public" && <NonLoggedInRelease id={id} cachedRelease={cachedRelease} />}
        {!userLoading && subscriptionLevel !== "Public" && <LoggedInRelease subscriptionLevel={subscriptionLevel} id={id} cachedRelease={cachedRelease} />}
      </Box>
    </>
  )
};


const NonLoggedInRelease = ({ id, cachedRelease }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { data, error, isLoading, isFetching } = useGetReleaseQuery(id);

  return (
    <Box>
      <Stack spacing="3">
        <Text textStyle="lg" fontWeight="medium">
          {cachedRelease?.attributes?.title}
        </Text>
        <Divider />
        <DealStack id={id} actionType={"modal"} loadingCount={4} />
        {data && <BlurredContentWithCta onOpen={onOpen} cachedRelease={cachedRelease}>
          <Stack spacing={"5"}>
            <FakeDealCard />
            <FakeDealCard />
          </Stack>
        </BlurredContentWithCta>}

        {isOpen && <SignupForFreeModal isOpen={isOpen} onClose={onClose} />}
      </Stack>
    </Box>
  )
}

const BlurredContentWithCta = ({ onOpen, children, cachedRelease }) => {
  return (
    <Box position="relative" width="100%" px={"1"}>
      {/* Children Content */}
      {children}

      {/* Blurred Overlay */}
      <Box
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        backgroundColor="rgba(255, 255, 255, 0.8)"
        backdropFilter="blur(3px)"
        zIndex="1"

      />

      {/* Overlay Content in a Centered Card */}
      <Flex
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)" // Center the card
        zIndex="2"
        alignItems="center"
        justifyContent="center"
      >
        <CTAButton
          onClick={onOpen}
          text={`Sign up to view more deals from ${cachedRelease?.attributes?.title} for free`} />
      </Flex>
    </Box>
  )
}

const CTAButton = ({text, onClick}) => {
  return (
    <Box w={"100%"}
         py={"4"} px={"4"}
         border={"1px"}
         borderColor={"gray.300"}
         borderRadius={"md"}
         _hover={{ bg: "brand.50",cursor: "pointer" }}
         onClick={onClick}
         bg={"white"}
    >
      <Stack justifyContent="space-between" alignItems="center" w="100%"> {/* Centers content and uses full width */}
        <Box flex="1" display="flex" justifyContent="center" alignItems="center"> {/* Centers text inside the box */}
          <Text textAlign={"center"} fontSize={{base: "sm", md: "md"}} fontWeight={"semibold"}>
            {text}
          </Text>
        </Box>
      </Stack>
    </Box>
  )
}

const LoggedInRelease = ({ subscriptionLevel, id, cachedRelease}) => {
  const isPaid = ["Pro", "Ultimate"].includes(subscriptionLevel);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [showAll, setShowAll] = useState(false);
  const clickAction = () => {
    if (isPaid) {
      setShowAll(true)
    } else {
      onOpen()
    }
  }

  return (
    <Box>
      <Stack spacing="3">
        <Text textStyle="lg" fontWeight="medium">
          {cachedRelease?.attributes?.title}
        </Text>
        <Divider />
        <DealStack id={id} actionType={"modal"} loadingCount={cachedRelease?.attributes?.total_entries || 2}/>
        {isPaid && showAll && <NonPickedDealStack id={id} actionType={"modal"} loadingCount={cachedRelease?.attributes?.non_picked_deals_size || 2} />}
        {isPaid && !showAll && cachedRelease?.attributes?.non_picked_deals_size > 0 && <CTAButton
          onClick={clickAction}
          text={`Show remaining deals from ${cachedRelease?.attributes?.title}`} />}
        {!isPaid && <CTAButton
          onClick={clickAction}
          text={`Subscribe to view ${cachedRelease?.attributes?.total_deal_pool_size > 7 ? `all ${cachedRelease?.attributes?.total_deal_pool_size}` : 'all'} deals from ${cachedRelease?.attributes?.title}`} />}
        {isOpen && !isPaid && <SwitchToPaidModal isOpen={isOpen} onClose={onClose} plan={"Pro"} />}
      </Stack>

    </Box>
  )
}


const DealStack = ({id, actionType, loadingCount}) => {
  const { data, error, isLoading, isFetching } = useGetReleaseQuery(id);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [modalDealId, setModalDealId] = useState(null)

  if (isLoading || isFetching) {
    return (
      <LoadingStack loadingCount={loadingCount} />
    );
  }
  if (error) return <Text>Error loading Release.</Text>;

  const handleDealClick = (id) => {
    if (actionType === "modal") {
      setModalDealId(id)
      onOpen()
    } else {
      window.open(`/deal/${id}`, '_blank');
    }
  };

  const deals = data?.included?.filter((item) => item.type === 'Deal');

  if (deals && deals.length > 0) { // Corrected condition
    return (
      <>
        <Stack spacing={"5"}>
          {deals.map((deal) => ( // Added return statement
            <DealCard
              key={deal.id}
              deal={deal}
              handleDealClick={handleDealClick}
            />
          ))}
        </Stack>
        {modalDealId && <DealDrawer deals={deals} dealId={modalDealId} setModalDealId={setModalDealId}
                                    isOpen={isOpen} onOpen={onOpen} onClose={onClose} />}
      </>
    );
  } else {
    return (
      <>
        <Text>No deals available.</Text> {/* Message for no deals */}
      </>
    );
  }
}


const NonPickedDealStack = ({id, actionType, loadingCount}) => {
  const { data, error, isLoading, isFetching } = useGetNonPickedDealsForReleaseQuery(id);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [modalDealId, setModalDealId] = useState(null)

  if (isLoading || isFetching) {
    return (
      <LoadingStack loadingCount={loadingCount} />
    );
  }
  if (error) return <Text>Error loading Release.</Text>;

  const handleDealClick = (id) => {
    if (actionType === "modal") {
      setModalDealId(id)
      onOpen()
    } else {
      window.open(`/deal/${id}`, '_blank');
    }
  };

  const deals = data?.data

  if (deals && deals.length > 0) { // Corrected condition
    return (
      <>
        <Stack spacing={"5"}>
          {deals.map((deal) => ( // Added return statement
            <DealCard
              key={deal.id}
              deal={deal}
              handleDealClick={handleDealClick}
            />
          ))}
        </Stack>
        {modalDealId && <DealDrawer deals={deals} dealId={modalDealId} setModalDealId={setModalDealId}
                                    isOpen={isOpen} onOpen={onOpen} onClose={onClose} />}
      </>
    );
  } else {
    return (
      <>
        <Text>No deals available.</Text> {/* Message for no deals */}
      </>
    );
  }
}



export const LoadingStack = ({ loadingCount }) => {
  // Create an array of DealSkeleton components based on the count
  const dealSkeletons = Array.from({ length: loadingCount }, (_, index) => (
    <DealSkeleton key={index} />
  ));

  return (
    <Box width={"100%"}>
      <Stack spacing={"5"}>
        {dealSkeletons}
      </Stack>
    </Box>
  );
};

const DealSkeleton = () => {
  return (
    <Box width={"100%"} height={"70px"}>
      <HStack spacing={"2"}>
        <Skeleton height={"70px"} width={"64px"} />
        <Skeleton height={"70px"} width={"calc(100% - 64px - 100px)"} /> {/* Adjusted width */}
        <Skeleton height={"70px"} width={"100px"} />
      </HStack>
    </Box>
  )
}

export const DealCard = ({deal, handleDealClick}) => {
  const {
    id,
    attributes: {
      title,
      location,
      added_to_kumo_at,
      business_summary,
      first_read_at,
      thumbnail_photo_url,
      asking_price,
      annual_ebitda,
      annual_sde,
      annual_revenue,
      highest_profit_number,
      ...dealAttributes // Only destructure what you need
    },
  } = deal;

  const numberFormat = {
    average: true,
    totalLength: 3,
    // mantissa: 2,
  };


  let multiple;
  if ((annual_ebitda || annual_sde) && asking_price) {
    const value = asking_price / (annual_ebitda || annual_sde)
    if (value >= 0 && value <= 100) {
      const multiple_string = numbro(asking_price / (annual_ebitda || annual_sde)).format({
        average: true,
        totalLength: 3,
      });
      multiple = `${multiple_string}x`;
    } else {
      multiple = "N/A";
    }
  } else {
    multiple = "N/A";
  }

  const formattedAskingPrice =  asking_price ? numbro(asking_price).format(numberFormat).toUpperCase() : "N/A"
  const formattedAnnualRevenue =  annual_revenue ? numbro(annual_revenue).format(numberFormat).toUpperCase() : "N/A"
  const formattedHighestProfitNumber =  highest_profit_number ? numbro(highest_profit_number).format(numberFormat).toUpperCase() : "N/A"

  return (
    <Box
      // bg="bg-surface"
      py="2"
      // boxShadow={useColorModeValue("sm", "sm-dark")}
      borderRadius="lg"
      // border={"1px"}
      // borderColor={"gray.100"}
      // background={first_read_at ? "green.50" : "bg.surface"}
      _hover={{ textDecoration: "none"}}
    >
      <Link
        textDecoration={"none"}
        _hover={{ textDecoration: "none"}}
        color={"gray.700"}
        onClick={(e) => {
          e.preventDefault(); // Prevent default to stop navigation
          handleDealClick(id);
        }}
        href={`/deal/${id}`} // Removed target="_blank" as it's not typical for mobile links
      >
        <Stack
          fontSize="sm"
          // px="4"
          spacing="2"
          sx={first_read_at ? { opacity: 1 } : { opacity: 1 }}
        >
          <Flex direction={{ base: "row" }} align="top">

            <Box position={"relative"}>
              <Avatar
                height={{base: '40px', md: '68px'}}
                width={{base: '40px', md: '68px'}}
                // size={{base: 'sm', md: 'lg'}}
                src={thumbnail_photo_url}
                name={title}
                borderRadius="md"
              />
              {/*{first_read_at &&*/}
              {/*  <Box*/}
              {/*    position="absolute"*/}
              {/*    top="0"*/}
              {/*    right="0"*/}
              {/*    bottom="0"*/}
              {/*    left="0"*/}
              {/*    borderRadius="md"*/}
              {/*    bg="whiteAlpha.800" // Black transparent overlay*/}
              {/*  />*/}
              {/*}*/}
            </Box>

            <Flex direction="column" flex="1" ml="3" overflow="hidden"> {/* Constrain the width */}
              <Box>
                <Text
                  fontSize={{base: 'sm', md: 'md'}}
                  fontWeight="medium"
                  color="gray.900"
                  sx={{
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                    overflow: "hidden",
                    display: "-webkit-box",
                  }}
                >
                  {title || ""}
                </Text>
              </Box>
              <Box py={"0"} mt={"1"}>
                <HStack spacing={"2"} wrap={"wrap"}>
                  <HStack>
                    <Text fontSize="xs" color="muted">
                      Revenue: {formattedAnnualRevenue}
                    </Text>
                  </HStack>
                  <Text fontSize={"xs"} color={"muted"}>|</Text>
                  <HStack>
                    <Text fontSize="xs" color="muted">
                      Profit: {formattedHighestProfitNumber}
                    </Text>
                  </HStack>
                  <Text display={{base: 'none', md: 'inline-block'}} fontSize={"xs"} color={"muted"}>|</Text>
                  <HStack display={{base: 'none', md: 'inline-block'}} >
                    <Text fontSize="xs" color="purple.500">
                      Multiple: {multiple}
                    </Text>
                  </HStack>
                </HStack>
                {/*<Text*/}
                {/*  color="muted"*/}
                {/*  fontSize="sm"*/}
                {/*  sx={{*/}
                {/*    WebkitBoxOrient: "vertical",*/}
                {/*    WebkitLineClamp: 2,*/}
                {/*    overflow: "hidden",*/}
                {/*    display: "-webkit-box",*/}
                {/*  }}*/}
                {/*>*/}
                {/*  {business_summary}*/}
                {/*</Text>*/}
              </Box>

              <Box>
                <HStack spacing={"4"} mt={"1"} wrap="wrap">
                  <HStack>
                    <Icon as={FiMapPin} color="gray.400" />
                    <Text fontSize="xs" color="muted">
                      {location || "Not Available"}
                    </Text>
                  </HStack>
                </HStack>
              </Box>


            </Flex>
            <Box>
              <Box
                px={{
                  base: '2',
                  md: '2',
                }}
                py={{
                  base: '2',
                  md: '2',
                }}
                bg="white"
                borderRadius="lg"
                border={"1px"}
                borderColor={"gray.100"}
                width={{base: '70px', md: '75px'}}
                // boxShadow="sm"
              >
                <Stack spacing={"1"}>
                  <Text fontSize={{ base: "xs", md: "md" }}
                        fontWeight={formattedAskingPrice === "N/A" ? "normal" : "normal"}
                        color={formattedAskingPrice === "N/A" ? "fg.muted" : "fg.default"}
                        align={"center"}
                  >
                    {formattedAskingPrice === "N/A" ? "" : "$"}{formattedAskingPrice}
                  </Text>
                  <Text textStyle={{ base: "xs", md: "sm" }} color="fg.muted" align={"center"}>
                    Price
                  </Text>
                </Stack>
              </Box>
            </Box>
          </Flex>




        </Stack>

      </Link>
    </Box>
  );
}

const FakeDealCard = () => {
  function generateRandomLoremIpsumWithRandomLength(minLength, maxLength) {
    const loremIpsum = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";

    // Function to get a random length
    function getRandomLength(min, max) {
      if (min > max) {
        throw new Error("Minimum value must not be greater than the maximum value.");
      }
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    // Get a random length
    const length = getRandomLength(minLength, maxLength);

    // Check if the length exceeds the Lorem Ipsum text
    if (length > loremIpsum.length) {
      throw new Error("Requested length exceeds the Lorem Ipsum base string length.");
    }

    return loremIpsum.substring(0, length);
  }

  const title = generateRandomLoremIpsumWithRandomLength(10, 20);
  const business_summary = generateRandomLoremIpsumWithRandomLength(50, 100);
  const location = generateRandomLoremIpsumWithRandomLength(10, 20);
  const formattedAskingPrice = generateRandomLoremIpsumWithRandomLength(2, 3);
  const formattedAnnualRevenue = generateRandomLoremIpsumWithRandomLength(2, 3);
  const formattedHighestProfitNumber = generateRandomLoremIpsumWithRandomLength(2, 3);
  const multiple = generateRandomLoremIpsumWithRandomLength(2, 2);

  return (
    <Box
      py="2"
      borderRadius="lg"
      _hover={{ textDecoration: "none"}}
    >
      <Link
        textDecoration={"none"}
        _hover={{ textDecoration: "none"}}
        color={"gray.700"}
      >
        <Stack
          fontSize="sm"
          spacing="2"
        >
          <Flex direction={{ base: "row" }} align="top">

            <Box position={"relative"}>
              <Avatar
                size={{base: 'sm', md: 'lg'}}
                name={title}
                borderRadius="md"
              />
              {/*{first_read_at &&*/}
              {/*  <Box*/}
              {/*    position="absolute"*/}
              {/*    top="0"*/}
              {/*    right="0"*/}
              {/*    bottom="0"*/}
              {/*    left="0"*/}
              {/*    borderRadius="md"*/}
              {/*    bg="whiteAlpha.800" // Black transparent overlay*/}
              {/*  />*/}
              {/*}*/}
            </Box>

            <Flex direction="column" flex="1" ml="3" overflow="hidden"> {/* Constrain the width */}
              <Box>
                <Text
                  fontSize={{base: 'sm', md: 'md'}}
                  fontWeight="medium"
                  color="gray.900"
                  sx={{
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                    overflow: "hidden",
                    display: "-webkit-box",
                  }}
                >
                  {title || ""}
                </Text>
              </Box>
              <Box py={"0"} mt={"1"}>
                <HStack spacing={"2"} wrap={"wrap"}>
                  <HStack>
                    <Text fontSize="xs" color="muted">
                      Revenue: {formattedAnnualRevenue}
                    </Text>
                  </HStack>
                  <Text fontSize={"xs"} color={"muted"}>|</Text>
                  <HStack>
                    <Text fontSize="xs" color="muted">
                      Profit: {formattedHighestProfitNumber}
                    </Text>
                  </HStack>
                  <Text display={{base: 'none', md: 'inline-block'}} fontSize={"xs"} color={"muted"}>|</Text>
                  <HStack display={{base: 'none', md: 'inline-block'}} >
                    <Text fontSize="xs" color="purple.500">
                      Multiple: {multiple}
                    </Text>
                  </HStack>
                </HStack>
                {/*<Text*/}
                {/*  color="muted"*/}
                {/*  fontSize="sm"*/}
                {/*  sx={{*/}
                {/*    WebkitBoxOrient: "vertical",*/}
                {/*    WebkitLineClamp: 2,*/}
                {/*    overflow: "hidden",*/}
                {/*    display: "-webkit-box",*/}
                {/*  }}*/}
                {/*>*/}
                {/*  {business_summary}*/}
                {/*</Text>*/}
              </Box>

              <Box>
                <HStack spacing={"4"} mt={"1"} wrap="wrap">
                  <HStack>
                    <Icon as={FiMapPin} color="gray.400" />
                    <Text fontSize="xs" color="muted">
                      {location || "Not Available"}
                    </Text>
                  </HStack>
                </HStack>
              </Box>


            </Flex>
            <Box>
              <Box
                px={{
                  base: '2',
                  md: '2',
                }}
                py={{
                  base: '2',
                  md: '2',
                }}
                bg="white"
                borderRadius="lg"
                border={"1px"}
                borderColor={"gray.100"}
                width={{base: '70px', md: '75px'}}
                // boxShadow="sm"
              >
                <Stack spacing={"1"}>
                  <Text fontSize={{ base: "xs", md: "md" }}
                        fontWeight={"normal"}
                        color={"fg.default"}
                        align={"center"}
                  >
                    {formattedAskingPrice}
                  </Text>
                  <Text textStyle={{ base: "xs", md: "sm" }} color="fg.muted" align={"center"}>
                    Price
                  </Text>
                </Stack>
              </Box>
            </Box>
          </Flex>
        </Stack>

      </Link>
    </Box>
  )
}