import React, {useState} from 'react';
import {useDownloadCsvFileQuery} from "../services/dealExportsApi";
import {Button, useToast} from "@chakra-ui/react";

const FileDownloaderButton = ({ fileId, fileName }) => {
  const toast = useToast();
  const [skipDownload, setSkipDownload] = useState(true);

  // Use object destructuring here
  const { data, error, isLoading, isFetching, refetch } = useDownloadCsvFileQuery(fileId, {
    skip: skipDownload // The query will not automatically run
  });

  const handleDownloadClick = () => {
    if (!fileName || !fileId) {
      toast({
        title: 'Download Failed',
        description: 'No file name or id specified',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: "top-right"
      });
      return;
    }
    toast({
      title: 'Downloading...',
      description: 'File download started',
      status: 'info',
      duration: 1000,
      isClosable: true,
      position: "top-right"
    });
    if (skipDownload) {
      setSkipDownload(false);
    } else {
      refetch();
    }
  };

  React.useEffect(() => {
    if (data) {
      const a = document.createElement('a');
      a.href = data;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
      toast({
        title: 'Download complete',
        description: 'File downloaded',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: "top-right"
      });
    }

    if (error) {
      toast({
        title: 'Download Failed',
        description: 'Unknown error',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: "top-right"
      });
    }
  }, [data, error, toast]);

  return (
    <Button variant={"secondary"} onClick={handleDownloadClick} isLoading={isLoading || isFetching}>
      Download
    </Button>
  );
};

export default FileDownloaderButton;