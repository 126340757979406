import { gql } from "@apollo/client";

export const NOTIFICATION_SETTINGS_BY_USER_QUERY = gql`
  query notificationSettingsByUser {
    notificationSettingsByUser {
      notificationEmail
      activityEmailFrequency
    }
  }
`;
