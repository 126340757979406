import {Outlet} from 'react-router-dom';
import {
  Box,
  Center,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  Spinner,
  useBreakpointValue,
  useDisclosure
} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import {Navbar} from "./navbar/Navigation";
import {ColumnHeader, ColumnIconButton} from "./navbar/Column";
import {FiMenu} from "react-icons/fi";
import Helmet from "react-helmet";
import {SubscriptionBanner} from "../../components/subscription-banner";
import {useQuery} from "@apollo/client";
import {CURRENT_USER_QUERY} from "../../graphql/current-user";
import BodyHeading from "./BodyHeading";

export const SidebarLayout = (props) => {
  const [sidebarIsScrolled, setSidebarIsScrolled] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { loading, data } = useQuery(CURRENT_USER_QUERY);

  const currentUser = data?.currentUser;

  // Get the value from localStorage
  const pricingSurveySnooze = localStorage.getItem('pricingSurveySnooze');

  // Check if the snooze value is either null or less than the current date/time.
  // The `+` before pricingSurveySnooze converts the string to a number (date timestamp in this case).
  const pricingSurveySnoozeExpired = !pricingSurveySnooze || +pricingSurveySnooze < Date.now();


  const isMobile = useBreakpointValue({ base: true, lg: false });
  const showHeading = (props.hideHeading && !isMobile) ? false : true;

  useEffect(() => {

    const destroyWidget = () => {
      try {
        window.FreshworksWidget('destroy');
      } catch (e) {
        // Don't know the method to check if FreshworksWidget is still mounted...
        // console.log(e);
      }
    };

    const checkAndDestroyWidget = () => {
      if (window.FreshworksWidget && typeof window.FreshworksWidget === 'function') {
        destroyWidget();
      } else {
        // If the widget isn't available yet, check again after a short delay
        setTimeout(checkAndDestroyWidget, 500);
      }
    };

    checkAndDestroyWidget();

    // Optional: Cleanup function if needed when the component unmounts
    return () => {
      // Reset or reinitialize widget if necessary
    };
  }, []);

  if (loading) {
    return (
      <Center w="100%" h="150px">
        <Spinner />
      </Center>
    );
  }

  return (
    <Box as="section" minHeight={"100vh"} >
      <Helmet title="KUMO | Find Deals" />
      <Flex height="100vh" direction="column"> {/* Set Flex direction to column */}
        <SubscriptionBanner currentUser={currentUser} /> {/* This will now be at the top */}

        <Flex flex="1" overflowY="auto"> {/* Added an inner Flex container */}
          <Box
            height="full"
            width={{
              md: '16rem',
            }}
            display={{
              base: 'none',
              lg: 'initial',
            }}
            overflowY="auto"
            borderRightWidth="1px"
            // bg="gray.50"
          >
            <Navbar />
          </Box>

          <Box
            bg={'white'}
            flex="1"
            overflowY="auto"
            onScroll={(x) => setSidebarIsScrolled(x.currentTarget.scrollTop > 32)}
            id={"mainContainer"}
          >
            {showHeading && <HeadingContainerWithNavbar sidebarIsScrolled={sidebarIsScrolled} isOpen={isOpen} onClose={onClose} onOpen={onOpen} />}
            <Box
              px={{ base: '4', lg: '10' }}
            >
              <Outlet />
            </Box>
          </Box>
        </Flex>
      </Flex>
    </Box>
  )
}

export const HeadingContainerWithNavbar = ({sidebarIsScrolled, isOpen, onClose, onOpen}) => {
  return (
    <ColumnHeader shadow={sidebarIsScrolled ? 'base' : 'none'}>
      <HStack justify="space-between" width="full">
        <HStack spacing="3">
          <ColumnIconButton
            onClick={onOpen}
            aria-label="Open Navigation"
            icon={<FiMenu/>}
            ml={"12px"}
            // display={'inline-flex'}
            display={{base: 'inline-flex', lg: 'none'}}
          />
          <BodyHeading/>
          <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
            <DrawerOverlay/>
            <DrawerContent>
              <Navbar onClose={onClose}/>
            </DrawerContent>
          </Drawer>
        </HStack>
      </HStack>
    </ColumnHeader>
  )
}
