import {theme as proTheme} from "@chakra-ui/pro-theme";
import {extendTheme, theme as baseTheme} from "@chakra-ui/react";
import "@fontsource-variable/inter";

export const theme = extendTheme(
  {
    colors: { ...(proTheme.colors as object), brand: baseTheme.colors.blue },
    fonts: {
      heading: "'Inter Variable', -apple-system, system-ui, sans-serif",
      body: "'Inter Variable', -apple-system, system-ui, sans-serif",
    },
    styles: {
      global: {
        // styles for the `a` tag
        'html, body, input, button, select, option, textarea': {
          _focus: {
            outline: 'none',
            boxShadow: 'none',
            // Alternatively, set your own focus styles here
          },
        },
      },
    },
  },
  proTheme,
);
