import {
  Box,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Icon,
  Spinner,
  Square,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import React, {useState} from "react";
import {NavButton} from "./Navigation";
import FileDownloaderButton from "../../../components/FileDownloaderButton";
import {IoDownloadOutline} from "react-icons/io5";
import {Pagination} from "../../../components/Pagination";
import {useGetFileExportsQuery} from "../../../services/dealExportsApi";
import {FiFileText} from 'react-icons/fi'
import {kumoDayJs} from "../../../utils/dayjs";
import {useQuery} from "@apollo/client";
import {CURRENT_USER_QUERY} from "../../../graphql/current-user";
import {SwitchToPaidModal} from "../../../components/upgrade-ctas/switch-to-paid-modal";

export const UserDownloadsButton = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { loading: userLoading, data: userData } = useQuery(CURRENT_USER_QUERY);
  const isUltimate = ["Ultimate"].includes(userData?.currentUser?.account?.subscriptionLevel || "Public");

  return (
    <>
      <NavButton to="#" exact="true" icon={IoDownloadOutline} label={"Downloads"} onClick={ onOpen} subscriptionTiers={["Ultimate"]}></NavButton>
      {isOpen && isUltimate && <DownloadsDrawer isOpen={isOpen} onClose={onClose} />}
      {isOpen && !isUltimate && <SwitchToPaidModal isOpen={isOpen} onClose={onClose} plan={"Ultimate"} />}
    </>
  )
}

const DownloadsDrawer = ({onClose, isOpen}) => {
  return(
    <Drawer onClose={onClose} isOpen={isOpen} size={{base: 'full', lg: 'md'}}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Your Downloads</DrawerHeader>
        <DrawerBody>
          <DrawerContents />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

const DrawerContents = () => {
  const exportableTypes = [
    {name: 'Your Manual Exports', exportableType: 'User::DealExportRequest'},
    // {name: 'Daily Email Exports', exportableType: 'SavedSearch::UserHistory'},
  ]

  const [tabIndex, setTabIndex] = useState(0);

  return(
    <Tabs size={'md'} variant="underline" onChange={(index) => setTabIndex(index)}>
      <TabList>
        {exportableTypes.map((type, index) => (
          <Tab key={index}>{type.name}</Tab>
        ))}
      </TabList>
      <TabIndicator />
      <TabPanels>
        {exportableTypes.map((type, index) => (
          <TabPanel key={index} px={"0"}>
            <DownloadTable exportableType={type.exportableType} />
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  )
}

const DownloadTable = ({exportableType}) => {
  const [pageQuery, setPageQuery] = useState(1);
  const [sortOrder, setSortOrder] = useState("DESC");
  const [sortField, setSortField] = useState("created_at");
  let queryHook;
  let queryOptions;

  queryHook = useGetFileExportsQuery;
  queryOptions = {
    exportableType: exportableType,
    page: pageQuery,
    sortField: sortField,
    sortOrder: sortOrder,
  };

  const { data, isLoading, isFetching, isError } = useGetFileExportsQuery(queryOptions, {
    refetchOnMountOrArgChange: true, // Refetch on mount or argument changes
  });


  const downloads = data?.data;
  const meta = data?.meta;

  if (isLoading || isFetching) {
    return (
      <Center w="100%" h="150px">
        <Spinner />
      </Center>
    );
  }
  if (isError) return <div>Error: {JSON.stringify(isError)}</div>;


  const setPageAndScrollToTop = (e) => {
    setPageQuery(e.page);
  }

  if (downloads && downloads.length === 0 && exportableType === "User::DealExportRequest") {
    return (
      <Box borderWidth="2px" px={{base: "2", lg: "3"}} rounded="xl" borderStyle="dashed"
           position="relative"
      >
        <Box mx="auto" overflowY="auto" pt={{base: "3", lg: "5"}} pb={{base: "3", lg: "5"}} w={{base: "95%"}}>
          <Center gap="6">
            <Text fontSize={{base: "sm"}} color="fg.muted" fontWeight="medium">
              <b>You have not exported any deals yet.</b>
              <br />
              Click "Export to CSV" when searching deals and files will appear here.
            </Text>
          </Center>
        </Box>
      </Box>
    )
  }

  return (
    <>
      <Box>
        <VStack>
          {downloads && downloads.map((download, index) => (
            <DownloadBox key={index} download={download} />
          ))}
        </VStack>
      </Box>
      <Box mt={"5"}>
        {meta && meta?.total_pages > 1 && (
          <Pagination
            count={meta?.total_pages}
            pageSize={1}
            siblingCount={2}
            page={pageQuery}
            onChange={(e) => setPageAndScrollToTop(e)}
          />
        )}
      </Box>
    </>
  )
}


const DownloadBox = ({download}) => {
  return (
    <Box borderWidth={{ base: '0', md: '1px' }} p={{ base: '0', md: '4' }} borderRadius="lg" width={"100%"}>
      <Stack justify="space-between" direction={{ base: 'column', md: 'row' }} spacing="5">
        <HStack spacing="3">
          <Square size="10" bg="bg.subtle" borderRadius="lg">
            <Icon as={FiFileText} boxSize="5" />
          </Square>
          <Box fontSize="sm">
            <Text color="empahsized" fontWeight="medium"  noOfLines={1} css={{ display: '-webkit-box', overflow: 'hidden', WebkitLineClamp: 1, WebkitBoxOrient: 'vertical' }}>
              {download.attributes.title}
            </Text>
            <Text color="fg.muted">
              {`Created ${kumoDayJs(download.attributes.created_at).fromNow()}`}
            </Text>
          </Box>
        </HStack>
        <Stack spacing="3" direction={{ base: 'column-reverse', md: 'row' }}>
          {download.attributes.status === "file_generation_succeeded" && !download.attributes.expired &&
            <FileDownloaderButton fileId={download.id} fileName={`${download.attributes.file_name}`}/>
          }
          {download.attributes.status !== "file_generation_succeeded" && !download.attributes.expired && download.attributes.status !== "file_generation_failed" && (
            <Text color="fg.muted" fontSize="sm">
              Processing
            </Text>
          )}
          {download.attributes.status === "file_generation_failed" && !download.attributes.expired && (
            <Text color="red.500" fontSize="sm">
              Download generation failed
            </Text>
          )}
          {download.attributes.expired && (
            <Text color="red.500" fontSize="sm">
              Link Expired
            </Text>
          )}

        </Stack>
      </Stack>
    </Box>
  )
}