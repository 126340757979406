import {
  Badge,
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import {MdComment, MdOutlineComment} from "react-icons/md";
import {NotesInterface} from "./notes-interface";
import {useQuery} from "@apollo/client";
import {CURRENT_USER_QUERY} from "../../graphql/current-user";
import {SwitchToPaidModal} from "../upgrade-ctas/switch-to-paid-modal";
import {SignupForFreeModal} from "../upgrade-ctas/signup-for-free-modal";

export const TriggerButton = React.forwardRef((props, ref) => {
  const { deal, buttonType, buttonText, ...otherProps } = props;
  const notes = deal?.attributes?.notes || [];

  const notesPresent = () => {
    if (notes.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const FillableIcon = () => {
    return(
      <Icon
        as={notesPresent() ? MdComment : MdOutlineComment}
        // fill={showBookmarked() ? "black" : "white"}
        transition="all 0.15s ease"
      />
    )
  }

  if (buttonType === "textButton") {
    return(
      <Button leftIcon={<FillableIcon />}
              variant={"outline"}
              // bg={showBookmarked() ? 'brand.600' : 'white'}
              ref={ref}
              // color="gray.900"
              size="xs"
              width={"full"}
              _hover={{ transform: "scale(1.05)" }}
              sx={{ ":hover > svg": { transform: "scale(1.05)" } }}
              transition="all 0.15s ease"
              {...otherProps} // if you want to spread otherProps
      >
        {notesPresent() ? `Notes (${notes.length})` : 'Add note'}
      </Button>
    )
  } else if (buttonType === "textLink") {
    return(
      <Button variant={"link"}
              // bg={showBookmarked() ? 'brand.600' : 'white'}
              ref={ref}
              // color="gray.900"
              _hover={{ decoration: "none" }}
              {...otherProps} // if you want to spread otherProps
      >
        {buttonText}
      </Button>
    )
  } else {
    return (
      <Box position="relative" display="inline-block">
        <IconButton
          isRound
          ref={ref}
          cursor={"pointer"}
          bg="white"
          color="gray.900"
          size="md"
          _hover={{ transform: "scale(1.1)" }}
          sx={{ ":hover > svg": { transform: "scale(1.1)" } }}
          transition="all 0.15s ease"
          icon={
            <FillableIcon />
          }
          aria-label="Notes"
          {...otherProps} // if you want to spread otherProps
        />
        {notesPresent() &&
          <Badge
            position="absolute"
            bottom="-1"
            right="-1"
            height="24px" // Height of the badge
            width="24px" // Width of the badge
            bg="red.500" // Badge background color
            color="white" // Text color
            borderRadius="full"
          >
            <Flex
              height="100%"
              width="100%"
              alignItems="center" // Center items vertically
              justifyContent="center" // Center items horizontally
            >
              <Text fontSize={"9px"}>
                {notes.length}
              </Text>
            </Flex>
          </Badge>
        }

      </Box>
    );
  }


});

TriggerButton.displayName = "NotesButton";

export const NotesButton = ({ deal, buttonType, buttonText, ...otherProps }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { loading: userLoading, data: userData } = useQuery(CURRENT_USER_QUERY);
  const subscriptionLevel = userData?.currentUser?.account?.subscriptionLevel || "Public"
  const isPaid = ["Pro", "Ultimate"].includes(subscriptionLevel);

  const ActiveUserModal = () => {
    return (
      <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={"lg"}>Notes for "{deal?.attributes?.title}"</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb={4}>
              <NotesInterface deal={deal} />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  }

  return (
    <>
      <Box
        onClick={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          onOpen();
        }}
      >
        {!isPaid &&
          <Tooltip label="Upgrade to Pro or Ultimate to manage notes"
                   borderRadius="md"
                   p={2} // padding
                   maxWidth="200px" // adjust as needed to control the text breaking
                   textAlign="center" // for centered text, if needed
                   hasArrow={true}
                   arrowSize={10} // adjust the size of the arrow if needed
          >
            <TriggerButton deal={deal} buttonType={buttonType} buttonText={buttonText} />
          </Tooltip>}
        {isPaid && <TriggerButton deal={deal} buttonType={buttonType} buttonText={buttonText} />}
      </Box>

      {!userLoading && isPaid && isOpen && <ActiveUserModal />}
      {!userLoading && !isPaid && isOpen && subscriptionLevel === "Public" && <SignupForFreeModal isOpen={isOpen} onClose={onClose} />}
      {!userLoading && !isPaid && isOpen && subscriptionLevel !== "Public" && <SwitchToPaidModal isOpen={isOpen} onClose={onClose} plan={"Pro"} />}

    </>
  );
};
