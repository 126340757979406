import {createSlice} from "@reduxjs/toolkit";
import {useGetChildIndustriesQuery, useGetSearchableLocationsQuery} from "../services/savedSearchesApi";
import {useMemo} from "react";
// Custom Hook
import {useSelector} from 'react-redux';
import numbro from "numbro";

export const defaultFilters = {
  dealCategoryId: [],
  dealChildIndustryId: [],
  dealListingSourceId: [],
  ebitda: [0, 100000000],
  price: [0, 100000000],
  revenue: [0, 100000000],
  price_to_ebitda_or_sde_ratio: [0, 100],
  showHidden: "false",
  showRead: "true",
  addedDaysAgo: "",
  updatedDaysAgo: "",
  quantitativeChanges: "",

  locationFilterType: 'dealSearchableLocationId',
  //dealSearchableLocationId
  dealSearchableLocationId: [],
  //dealLocationCoordinateRange
  locationPointTextDescription: '',
  locationPointCoordinates: [],
  locationPointRadius: 50,

  textSearchType: 'SimpleSearch',
  // SimpleSearch
  titleAndDescriptionSearch: "",
  // AdvancedSearch
  ANDSearch: [],
  ORSearch: [],
  NOTSearch: []
};

export const suggestedSearches = [
  {
    id: "5to100million",
    name: "$5M-$100M",
    description: "Businesses in the $5M-$100M price range.",
    filters: {
      ...defaultFilters,
      price: [5000000, 100000000],
    },
  },
  {
    id: "1to5million",
    name: "$1M-$5M",
    description: "Businesses in the $1M-$5M price range.",
    filters: {
      ...defaultFilters,
      price: [1000000, 5000000],
    },
  },
  {
    id: "100kto500k",
    name: "Micro Acquisitions",
    description: "Businesses in the $100K-$500K price range.",
    filters: {
      ...defaultFilters,
      price: [100000, 500000],
    },
  },
  {
    id: "restaurants300kto3m",
    name: "Restaurants ($300K-$3M)",
    description: "Restaurants and similar F&B, $300k to $3m price range.",
    filters: {
      ...defaultFilters,
      dealChildIndustryId:["93","95","100","231"],
      price: [300000,3000000],
    }
  },
  {
    id: "onlinetech500kto2m",
    name: "Online ($500K-$2M)",
    description: "Online / Tech businesses, excluding e-commerce, asking between $500k to $2m.",
    filters: {
      ...defaultFilters,
      dealChildIndustryId:["159","154","153","156","157","229","160","158","161"],
      price:[500000,2000000],
    }
  },
  {
    id: "retailstoresunder1_5m",
    name: "Retail in the Tri-state",
    description: "Retail stores under $1.5m price in the Tri-state area.",
    filters: {
      ...defaultFilters,
      dealChildIndustryId: ["215","203","220","206","204","205","202","218","221","207","208","217","201","209","210","211","200","216","212","214","219","213"],
      price: [0,1500000],
      dealSearchableLocationId: ["33", "35", "59"]
    }
  }
];

const defaultSort = {
  sortField: "added_to_kumo_at",
  sortOrder: "DESC",
};

const defaultState = {
  filters: defaultFilters,
  sort: defaultSort,
  page: 1,
}

const initialState = defaultState;

const localSearch = createSlice({
  name: "localSearch",
  initialState,
  reducers: {
    updateActiveSearch(state, action) {
      if (action.payload.filters) {
        state.filters = action.payload.filters;
      }
      if (action.payload.sort) {
        state.sort = action.payload.sort;
      }

      if (action.payload.page) {
        state.page = action.payload.page;
      }
    },
    resetActiveSearchFilters(state) {
      state = defaultState;
    },
  },
});


export default localSearch;
export const localSearchActions = localSearch.actions;

export const selectActiveSearch = (state) => {
  return state.localSearch
};

export const selectDefaultSearch = (state) => {
  return defaultState
}

export const selectDefaultFilters = (state) => {
  return defaultFilters
}

export const useFormattedFilterChanges = (filtersToCompare) => {
  const localFilters = useSelector(state => state.localSearch.filters);
  const { data: childIndustriesData } = useGetChildIndustriesQuery();
  const { data: searchableLocationsData } = useGetSearchableLocationsQuery();

  const childIndustries = childIndustriesData?.data;
  const searchableLocations = searchableLocationsData?.data;

  // console.log('local filters', localFilters)

  const changes = useMemo(() => {
    const currentFilters = filtersToCompare || localFilters;
    const computedChanges = {};

    // For each property in the default filters
    for (let key in defaultFilters) {
      const defaultValue = defaultFilters[key];
      const currentValue = currentFilters[key];

      // Compare arrays
      if (Array.isArray(defaultValue) && Array.isArray(currentValue)) {
        if (defaultValue.toString() !== currentValue.toString()) {
          computedChanges[key] = currentValue;
        }
      }
      // Compare other types (string, number, etc.)
      else if (defaultValue !== currentValue) {
        computedChanges[key] = currentValue;
      }
    }

    return computedChanges;
  }, [localFilters, filtersToCompare]);

  const formattedChanges = useMemo(() => {
    const formattedChangesHash = { ...changes };
    // console.log('changes', changes)
    if (changes.ebitda || changes.price || changes.revenue || changes.price_to_ebitda_or_sde_ratio) {
      const numberFormat = {
        average: true,
        totalLength: 2,
        // mantissa: 2,
      };
      const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }


      const formatNumberChanges = (key) => {
        if (changes && changes[key] && key === 'price_to_ebitda_or_sde_ratio') {
          const [min, max] = changes[key];
          if (max > 0) {
            if (min === 0) {
              formattedChangesHash[key] = `Price/Profit: Up to ${numbro(max).format(numberFormat).toUpperCase()}x`;
            } else {
              formattedChangesHash[key] = `Price/Profit: ${numbro(min).format(numberFormat).toUpperCase()}x to ${numbro(max).format(numberFormat).toUpperCase()}x`;
            }
          } else {
            return "";
          }
        } else if (changes && changes[key]) {
          const [min, max] = changes[key];
          if (max > 0) {
            if (min === 0) {
              formattedChangesHash[key] = `${capitalizeFirstLetter(key)}: Up to $${numbro(max).format(numberFormat).toUpperCase()}`;
            } else {
              formattedChangesHash[key] = `${capitalizeFirstLetter(key)}: $${numbro(min).format(numberFormat).toUpperCase()} to $${numbro(max).format(numberFormat).toUpperCase()}`;
            }
          } else {
            return "";
          }
        } else {
          return ""
        }
      }

      ['ebitda', 'price', 'revenue', 'price_to_ebitda_or_sde_ratio'].forEach(formatNumberChanges)
    }

    const textSearchType = filtersToCompare?.textSearchType || 'SimpleSearch';

    if (textSearchType === 'SimpleSearch') {
      if (changes.titleAndDescriptionSearch) {
        formattedChangesHash.text_search = `Simple Text Search | ${changes.titleAndDescriptionSearch}`
      }
    } else if (textSearchType === 'AdvancedSearch') {
      let searchDescriptions = [];
      if (changes.ANDSearch?.length > 0) {
        // We want to add quotes around each term
        const andSearch = changes.ANDSearch.map(term => `"${term}"`);
        searchDescriptions.push(`AND: ${andSearch.join(', ')}`)
      }
      if (changes.ORSearch?.length > 0) {
        // We want to add quotes around each term
        const orSearch = changes.ORSearch.map(term => `"${term}"`);
        searchDescriptions.push(`OR: ${orSearch.join(', ')}`)
      }
      if (changes.NOTSearch?.length > 0) {
        // We want to add quotes around each term
        const notSearch = changes.NOTSearch.map(term => `"${term}"`);
        searchDescriptions.push(`NOT: ${notSearch.join(', ')}`)
      }

      if (searchDescriptions.length > 0) {
        formattedChangesHash.text_search = `Text Search | ${searchDescriptions.join(' | ')}`
      }
    }

    // We want to limit the length of the text search to 50 characters
    if (formattedChangesHash.text_search && formattedChangesHash.text_search.length > 75) {
      formattedChangesHash.text_search = `${formattedChangesHash.text_search.slice(0, 75)}...`
    }

    // The code above manages the formatted changes hash
    delete formattedChangesHash.titleAndDescriptionSearch;
    delete formattedChangesHash.textSearchType;
    delete formattedChangesHash.ANDSearch;
    delete formattedChangesHash.ORSearch;
    delete formattedChangesHash.NOTSearch;

    if (changes.addedDaysAgo) {
      formattedChangesHash.addedDaysAgo = `Days on Kumo: ${changes.addedDaysAgo}`
    }


    const locationFilterType = filtersToCompare?.locationFilterType || 'dealSearchableLocationId';
    if (locationFilterType === 'dealSearchableLocationId') {
      if (searchableLocations) {
        if (changes.dealSearchableLocationId?.length > 0) {
          const locationNames = changes.dealSearchableLocationId?.map(id => {
            const location = searchableLocations.find(loc => loc.id === id);
            return location ? location.attributes.name : null;
          }).filter(Boolean) || []

          formattedChangesHash.location = `Regions: ${locationNames.slice(0, 1).join(', ')}${locationNames.length > 1 ? `, +${locationNames.length - 1}` : ''}`
        }
      } else {
        if (changes.dealSearchableLocationId?.length > 0) {
          formattedChangesHash.location = `Regions: ${changes.dealSearchableLocationId.length}`
        }
      }
    } else if (locationFilterType === 'dealLocationCoordinateRange') {
      if (filtersToCompare?.locationPointTextDescription && filtersToCompare?.locationPointTextDescription.length > 0) {
        formattedChangesHash.location = `Location: Within ${filtersToCompare.locationPointRadius} miles of ${filtersToCompare.locationPointTextDescription}`
      }
    }

    // The code above manages the formatted changes hash
    delete formattedChangesHash.locationFilterType
    delete formattedChangesHash.locationPointTextDescription
    delete formattedChangesHash.locationPointRadius
    delete formattedChangesHash.locationPointCoordinates
    delete formattedChangesHash.dealSearchableLocationId

    if (childIndustries) {
      if (changes.dealChildIndustryId?.length > 0) {
        const industryNames = changes.dealChildIndustryId.map(id => {
          const industry = childIndustries.find(cat => cat.id === id);
          return industry ? industry.attributes.name : null;
        }).filter(Boolean) || []

        formattedChangesHash.dealChildIndustryId = `Industries: ${industryNames.slice(0, 1).join(', ')}${industryNames.length > 1 ? `, +${industryNames.length - 1}` : ''}`
      }
    } else {
      if (changes.dealChildIndustryId?.length > 0) {
        formattedChangesHash.dealChildIndustryId = `Industries: ${changes.dealChildIndustryId.length}`
      }
    }
    return formattedChangesHash;
  }, [localFilters, filtersToCompare, changes, childIndustries, searchableLocations]);

  return formattedChanges;
};