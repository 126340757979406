import {combineReducers} from "redux";
import {backendApi} from "../services/backendApi";
import {dealsApi} from "../services/dealsApi";
import {savedSearchesApi} from "../services/savedSearchesApi";
import localSearch from "./local-search";
import {dealAnalyticsApi} from "../services/dealAnalyticsApi";
import {userApi} from "../services/userApi";
import {dealExportsApi} from "../services/dealExportsApi";
import {dealFeedsApi} from "../services/dealFeedsApi";

const rootReducer = combineReducers({
  localSearch: localSearch.reducer,
  [backendApi.reducerPath]: backendApi.reducer,
  [dealsApi.reducerPath]: dealsApi.reducer,
  [dealFeedsApi.reducerPath]: dealFeedsApi.reducer,
  [dealAnalyticsApi.reducerPath]: dealAnalyticsApi.reducer,
  [savedSearchesApi.reducerPath]: savedSearchesApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [dealExportsApi.reducerPath]: dealExportsApi.reducer,
});

export default rootReducer;
