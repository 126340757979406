import {HeadingContext} from "../heading-provider";
import * as React from "react";
import {useContext} from "react";
import {Box, Link as ChakraLink, Text, useBreakpointValue} from "@chakra-ui/react";
import {NavLink as RouterNavLink} from "react-router-dom";

export const BodyHeading = () => {
  const { headingContent } = useContext(HeadingContext);
  return (
    <>
      {headingContent}
    </>
  );
};

export default BodyHeading;

export const StandardTextHeading = ({text}) => {
  const headingSize = useBreakpointValue({ base: '15px', lg: '20px' });
  return (
    <Box
      pl={{ base: '0', lg: '10' }}
    >
      <Text fontSize={headingSize}
            fontWeight={"medium"}
            noOfLines={1}
            css={{ display: '-webkit-box', overflow: 'hidden', WebkitLineClamp: 1, WebkitBoxOrient: 'vertical' }}
      >{text}</Text>
    </Box>
  );
}

export const HeadingLink = ({text, url}) => {
  const headingSize = useBreakpointValue({ base: '15px', lg: '20px' });
  return (
    <ChakraLink
      as={RouterNavLink}
      to={url}
    >
      <Text fontSize={headingSize}
            fontWeight={"medium"}
            noOfLines={1}
            css={{ display: '-webkit-box', overflow: 'hidden', WebkitLineClamp: 1, WebkitBoxOrient: 'vertical' }}
      >{text}</Text>
    </ChakraLink>
  )
}
export const HeadingText = ({text}) => {
  const headingSize = useBreakpointValue({ base: '15px', lg: '20px' });
  return (
    <Text fontSize={headingSize}
          fontWeight={"medium"}
          noOfLines={1}
          css={{ display: '-webkit-box', overflow: 'hidden', WebkitLineClamp: 1, WebkitBoxOrient: 'vertical' }}
    >{text}</Text>
  )
}