import { gql } from "@apollo/client";

export const EDIT_USER_NOTIFICATION_SETTINGS = gql`
  mutation EditUserNotificationSettings(
    $activityEmailFrequency: String!
    $notificationEmail: String!
  ) {
    editUserNotificationSettings(
      input: {
        activityEmailFrequency: $activityEmailFrequency
        notificationEmail: $notificationEmail
      }
    ) {
      response
    }
  }
`;
