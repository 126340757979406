import { gql } from "@apollo/client";
import { PROSPECT_FRAGMENT } from "./fragments/prospect";

export const DEAL_FLOW_STAGES = gql`
  ${PROSPECT_FRAGMENT}
  query DealFlowStages($addedDaysAgo: Int) {
    dealFlowStages {
      id
      colorScheme
      name
      order
      prospects(addedDaysAgo: $addedDaysAgo) {
        ...prospect
      }
      unchangeable
      updatedAt
    }
  }
`;
