import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  StackProps,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import * as React from "react";
import {LogoIcon} from "../../../components/Logo";
import {Link, NavLink as ReactRouterLink, useNavigate, useSearchParams} from "react-router-dom";
import {Field, Form, Formik} from "formik";
import {SigninSchema} from "../../../validators/auth-validators";
import {signinUser} from "../../../api/signin-user";
import {AuthContext} from "../../../auth/auth-context";
import {
  authenticationErrorMessageGenerator,
  AuthErrorMessage,
} from "../../../utils/authenticationErrorMessageGenerator";

export const SignInForm = (props: StackProps) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { setAuthenticated } = React.useContext(AuthContext);
  const navigate = useNavigate();
  const [authErrorMessage, setAuthErrorMessage] = React.useState<AuthErrorMessage | null>(null);
  const [searchParams] = useSearchParams();
  const from = searchParams.get('from') || '/';

  return (
    <Stack spacing="8" {...props}>
      <Stack spacing="6">
        {isMobile && <LogoIcon />}
        <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
          <Heading size={useBreakpointValue({ base: "xs", md: "sm" })}>
            Log in to your account
          </Heading>
          <HStack spacing="1" justify="center">
            <Text color="muted">Don't have an account?</Text>
            <Button as={ReactRouterLink} to="/signup" variant="link" colorScheme="blue">
              Sign up
            </Button>
          </HStack>
        </Stack>
      </Stack>
      <Formik
        initialValues={{ email: "", password: "" }}
        onSubmit={async (values) => {
          signinUser(values)
            .then(() => {
              setAuthenticated(true);
              setAuthErrorMessage(null);
              navigate(from);
            })
            .catch((e) => {
              const errorState = authenticationErrorMessageGenerator(e.response.data.error);
              setAuthErrorMessage(errorState);
            });
        }}
        validationSchema={SigninSchema}>
        {({ errors, touched, isSubmitting }) => {
          return (
            <Form>
              <Stack spacing="5">
                <Field name="email">
                  {({ field }: any) => (
                    <FormControl>
                      <FormLabel htmlFor="email">Email</FormLabel>
                      <Input {...field} id="email" placeholder="Enter your email" type="email" />
                      {errors.email && touched.email && (
                        <FormHelperText textColor="red">{errors.email}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                </Field>
                <Field name="password">
                  {({ field }: any) => (
                    <FormControl>
                      <FormLabel htmlFor="password">Password</FormLabel>
                      <Input {...field} id="password" placeholder="********" type="password" />
                      {errors.password && touched.password && (
                        <FormHelperText textColor="red">{errors.password}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                </Field>
              </Stack>
              {authErrorMessage && (
                <Text textColor="red" fontSize="sm" mt={2}>
                  {`${authErrorMessage.message}`}
                  {authErrorMessage.link ? (
                    <>
                      {" "}
                      <Text
                        as={Link}
                        variant="link"
                        textColor="red"
                        textDecoration="underline"
                        display="inline"
                        fontSize="sm"
                        to={authErrorMessage.link}>
                        reset your password here.
                      </Text>
                    </>
                  ) : (
                    ""
                  )}
                </Text>
              )}

              <Stack spacing="4" mt="35px">
                <Button variant="primary" isLoading={isSubmitting} type="submit">
                  Sign in
                </Button>
                {/* <Button
                  variant="secondary"
                  leftIcon={<GoogleIcon boxSize="5" />}
                  iconSpacing="3"
                >
                  Sign in with Google
                </Button> */}
              </Stack>

              <HStack justify="center" mt={"20px"}>
                {/*<Checkbox defaultIsChecked>Remember me</Checkbox>*/}
                <Button
                  variant="link"
                  colorScheme="blue"
                  size="sm"
                  mt={"10px"}
                  mb={"10px"}
                  as={ReactRouterLink}
                  to={"/reset-password"}>
                  Forgot your password?
                </Button>
              </HStack>
            </Form>
          );
        }}
      </Formik>
    </Stack>
  );
};
