import {
  Box,
  Heading,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import {StripeCheckoutButton} from "../stripe-checkout-button";
import React from "react";
import {CheckIcon, CloseIcon} from '@chakra-ui/icons';
import {useQuery} from "@apollo/client";
import {CURRENT_USER_QUERY} from "../../graphql/current-user";
import {gotoStripeBilling} from "../../api/goto-stripe-billing";

export const SwitchToPaidModal = ({isOpen, onClose, ctaText, plan, onOpen}) => {
  return(
    <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}
           size={{base: "xl", lg: "lg"}}
    >
      <ModalOverlay />
      <ModalContent
        // bg="bg.accent.default"
        // color="fg.accent.default"
        // borderRadius="xl"
        px={"3"}
        py={"5"}
      >
        <ModalCloseButton
          // color={"white"}
        />
        <ModalBody>
          <Stack spacing="8" direction={{ base: 'column' }} justify="space-between" align="center">
            <Stack spacing="4" maxW="2xl" align="center">
              <Heading size="xs">
                Ready to Upgrade?
              </Heading>
              {plan && <Text fontSize={{ base: 'md', lg: 'sm' }}>
                For full access, you'll need to upgrade to {plan === "Pro" ? "Pro or Ultimate" : "Ultimate"}.
              </Text>}

              <Box>
                <SubscriptionComparison></SubscriptionComparison>
              </Box>

            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

const subscriptionPlans = [
  // {
  //   name: 'Free',
  //   features: {
  //     "100K+ Deals Database": "Limited",
  //     "Access to Listings": "No",
  //     "Email Alerts": "No",
  //     "Saved Searches": "No",
  //     "Deal Management": "No",
  //     "CSV Exports": "No",
  //     "Historical Listing Data": "No",
  //     "Analytics": "No",
  //   },
  // },
  {
    name: 'Pro',
    features: {
      "100K+ Deals Database": "Yes",
      "Access to Listings": "Yes",
      "Email Alerts": "Yes",
      "Saved Searches": "Yes",
      "Deal Management": "Yes",
      "CSV Exports": "No",
      "Historical Listing Data": "No",
      "Analytics": "No",
    },
  },
  {
    name: 'Ultimate',
    features: {
      "100K+ Deals Database": "Yes",
      "Access to Listings": "Yes",
      "Email Alerts": "Yes",
      "Saved Searches": "Yes",
      "Deal Management": "Yes",
      "CSV Exports": "Yes",
      "Historical Listing Data": "Yes",
      "Analytics": "Yes",
    },
  },
];

const toolTips = {
  "100K+ Deals Database": "We update our database of deals daily, so you can be sure you're getting the most up-to-date information.",
  "Access to Listings": "Get access to the original listings with seller's contact information, so you can reach out to them directly.",
  "Email Alerts": "Get weekly or daily email alerts when new deals are added that match your criteria.",
  "Saved Searches": "Save your search criteria, so you can easily find deals that match your criteria.",
  "Deal Management": "Add notes, favorite deals, and hide them from future searches.",
  "CSV Exports": "Export your search results to a CSV file, so you can use them in your own spreadsheets.",
  "Historical Listing Data": "Track changes in listing price, key numbers, and more.",
  "Analytics": "See trends in multiples across industries, and locations, and make better decisions.",
}

// Component to display the subscription comparison table
const SubscriptionComparison = () => {
  const featureList = Object.keys(subscriptionPlans[0].features);
  const { loading: userLoading, data: userData } = useQuery(CURRENT_USER_QUERY);
  const isPro = ["Pro"].includes(userData?.currentUser?.account?.subscriptionLevel || "Public");

  return (
    <Box overflowX={"auto"}>
      <Table variant="simple" borderWidth={'1px'} borderColor={"gray.200"}  size={{base: "md", md: 'md'}} maxWidth="100%">
        <Thead>
          <Tr>
            <Th py={"3"}></Th>
            {subscriptionPlans.map(plan => (
              <Th textAlign="center" key={plan.name} py={"3"}>{plan.name}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {featureList.map(feature => (
            <Tr key={feature}>
              <Td>
                <Popover>
                  <PopoverTrigger>
                    <Box as="span" borderBottom="1px dashed" pb="1" cursor="help">
                      <Text as="span" cursor="help">{feature}</Text>
                    </Box>
                  </PopoverTrigger>
                  <PopoverContent
                    borderRadius="md"
                    p={2} // padding
                    maxWidth="300px" // Controls text breaking
                    width="auto" // Adjusts width based on content
                    overflow="visible" // Ensures no text is hidden due to overflow
                  >
                    <Box
                      width={"full"}
                      textAlign="center" // Centers text, if needed
                    >
                      <Text whiteSpace="normal" wordBreak="break-word">
                        {toolTips[feature]}
                      </Text>
                    </Box>
                  </PopoverContent>
                </Popover>
              </Td>
              {subscriptionPlans.map(plan => (
                <FeatureCell available={plan.features[feature]} key={plan.name} />
              ))}
            </Tr>
          ))}
          <Tr>
            <Td>
              <Text fontWeight="bold">Monthly Price</Text>
            </Td>
            {/*<Td textAlign="center">*/}
            {/*  <Text fontWeight="bold">$0</Text>*/}
            {/*</Td>*/}
            <Td textAlign="center">
              <Text fontWeight="bold">$100</Text>
            </Td>
            <Td textAlign="center">
              <Text fontWeight="bold">$200</Text>
            </Td>
          </Tr>
          <Tr>
            {/*<Td>*/}
            {/*</Td>*/}
            <Td>
              {/*<Button*/}
              {/*  as={ReactRouterLink}*/}
              {/*  to="/plan-selection"*/}
              {/*  variant="ghost"*/}
              {/*  size="sm">*/}
              {/*  Learn more*/}
              {/*</Button>*/}
            </Td>
            <Td textAlign="center">
              {!isPro && <StripeCheckoutButton tracking="switch_to_pro_modal" plan={"Pro"}>
                Upgrade
              </StripeCheckoutButton>}
              {isPro && <Text>You're Pro!</Text>}
            </Td>
            <Td textAlign="center">
              {isPro &&  <Link
                textDecoration="underline"
                fontWeight="medium"
                onClick={async () => {
                  const resp = await gotoStripeBilling();
                  window.location.replace(resp.redirect_url);
                }}>
                Upgrade
              </Link>}
              {!isPro && <StripeCheckoutButton tracking="switch_to_pro_modal" plan={"Ultimate"}>
                Upgrade
              </StripeCheckoutButton>}
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
}

// Component to display each feature cell
function FeatureCell({ available }) {
  const isAvailable = available === "Yes";
  const isLimited = available === "Limited";

  return (
    <Td textAlign="center"> {/* Center align content */}
      {isAvailable ? <CheckIcon color="green.500" /> : (isLimited ? <Text color="orange.500">Limited</Text> : <CloseIcon color="red.500" />)}
    </Td>
  );
}