import React, {useState} from 'react';
import {useGetListingRedirectUrlQuery} from "../../services/dealsApi";

export const ListingOpener = ({ children, listingId }) => {
  const [skipDownload, setSkipDownload] = useState(true);

  const { data, error, isLoading, isFetching, refetch } = useGetListingRedirectUrlQuery(listingId, { skip: skipDownload });

  const handleClick = async () => {
    if (skipDownload) {
      setSkipDownload(false);
    } else {
      if (data) {
        window.open(`${process.env.REACT_APP_API_BASE_URL}${data.redirect_url}`, '_blank');
      }
    }
  };

  React.useEffect(() => {
    if (data) {
      window.open(`${process.env.REACT_APP_API_BASE_URL}${data.redirect_url}`, '_blank');
    }
  }, [data]);

  const renderChildren = () => {
    const styleWithPointer = { cursor: 'pointer' };

    if (typeof children === 'function') {
      // If children is a function, call it with onClick and style
      return children({ onClick: handleClick, style: styleWithPointer });
    } else {
      // If children is a React element, clone it with onClick and style
      return React.cloneElement(children, { onClick: handleClick, style: styleWithPointer });
    }
  };

  return (
    <>
      {renderChildren()}
    </>
  );
};

// Usage:
// <ListingOpener>
//   <button>Click me</button>
// </ListingOpener>
//
// <ListingOpener>
//   {({ onClick }) => <button onClick={onClick}>Click me</button>}
// </ListingOpener>