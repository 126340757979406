import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_BASE_URL}api`,
  // prepareHeaders is used to configure the header of every request and gives access to getState which we use to include the token from the store
  prepareHeaders: (
    headers
    // { getState }
  ) => {
    const token = localStorage.getItem("with-kumo-token");
    if (token) {
      headers.set("authorization", token);
    }
    return headers;
  },
});

export const backendApi = createApi({
  reducerPath: "backendApi",
  baseQuery: baseQuery,
  tagTypes: ["DealFlowStage"],
  endpoints: (builder) => ({
    getDealFlowStages: builder.query({
      query: () => `/deal_flow_stages/`,
      providesTags: ["DealFlowStage"],
    }),
    createDealFlowStage: builder.mutation({
      query: ({ name, order, hidden }) => ({
        url: `/deal_flow_stages/create`,
        method: "POST",
        body: {
          name: name,
          order: order,
          hidden: hidden,
        },
      }),
      invalidatesTags: ["DealFlowStage"],
    }),
    destroyDealFlowStage: builder.mutation({
      query: (id) => ({
        url: `/deal_flow_stages/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["DealFlowStage"],
    }),
    updateDealFlowStageBatch: builder.mutation({
      query: (stage_inputs) => ({
        url: `/deal_flow_stages/update_batch`,
        method: "POST",
        body: {
          stage_inputs: stage_inputs,
        },
      }),
      invalidatesTags: ["DealFlowStage"],
    }),
  }),
});

export const {
  useGetDealFlowStagesQuery,
  useCreateDealFlowStageMutation,
  useDestroyDealFlowStageMutation,
  useUpdateDealFlowStageBatchMutation,
} = backendApi;
