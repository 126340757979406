import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  // eslint-disable-next-line no-undef
  baseUrl: `${process.env.REACT_APP_API_BASE_URL}api/deal_exports`,
  // prepareHeaders is used to configure the header of every request and gives access to getState which we use to include the token from the store
  prepareHeaders: (
    headers
    // { getState }
  ) => {
    const token = localStorage.getItem("with-kumo-token");
    if (token) {
      headers.set("authorization", token);
    }
    return headers;
  },
});

export const dealExportsApi = createApi({
  reducerPath: "dealExportsApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    requestCsvExport: builder.mutation({
      query: ({request_params}) => ({
        url: `/create`,
        method: "POST",
        body: {
          request_params: request_params
        },
      }),
    }),
    getFileExports: builder.query({
      query: ({ exportableType, page, sortField, sortOrder }) => ({
        url: '/index',
        method: 'POST',
        body: {
          exportable_type: exportableType,
          page: page,
          // sort_field: sortField,
          // sort_order: sortOrder
        }
      }),
      providesTags: (result) => {
        return result ? result.data.map((dealExport) => ({ type: "UserDealExport", id: dealExport.id })) : []
      }
    }),
    downloadCsvFile: builder.query({
      query: (fileId) => ({
        url: `/download/${fileId}`,
        method: "GET",
        responseHandler: async (response) => {
          if (response.status !== 200) {
            throw new Error('File not found');
          }
          const blob = await response.blob();
          return URL.createObjectURL(blob);
        }
      }),
    }),
  }),
});


export const {
  useRequestCsvExportMutation,
  useDownloadCsvFileQuery,
  useGetFileExportsQuery
} = dealExportsApi;
