import * as Yup from "yup";

export const SigninSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email address format.").required("Email is required."),
  password: Yup.string()
    .required("Password is required.")
    .min(8, "Password is too short - should be 8 characters minimum."),
});

export const SignupSchema = Yup.object().shape({
  name: Yup.string().required("Name is required."),
  email: Yup.string().email("Invalid email address format.").required("Email is required."),
  password: Yup.string()
    .required("Password is required.")
    .min(8, "Password is too short - should be 8 characters minimum."),
  passwordConfirmation: Yup.string().oneOf([Yup.ref("password")], "Passwords must match."),
  acceptedTerms: Yup.boolean().oneOf(
    [true],
    "You must accept the Terms and Conditions and Privacy Policy"
  ),
});

export const ResetSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email address format.").required("Email is required."),
});

export const ResetConfirmationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password is too short - should be 8 characters minimum.")
    .required("Password is required."),
});
