import {
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure
} from "@chakra-ui/react";
import * as React from "react";
import {useState} from "react";
import {useUpdateSavedSearchMutation} from "../../../services/savedSearchesApi";
import {FiEdit2} from "react-icons/fi";
import {SearchFiltersForm} from "../../../components/search-filters-form";
import {isEqual} from "lodash";
import {SearchSettingsForm} from "./SearchSettingsForm";

export const EditSearchButton = ({search}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [localSearch, setLocalSearch] = useState(search);

  const [updateSavedSearch] = useUpdateSavedSearchMutation();

  const saveChanges = async () => {
    const { data } = await updateSavedSearch({
      id: search.id,
      title: localSearch.attributes.title,
      include_in_weekly_digest: localSearch.attributes.include_in_weekly_digest,
      filters: localSearch.attributes.filters,
    });

    if (data) {
      onClose();
    }
  }

  const updateLocalSearch = (key, value) => {
    setLocalSearch({
      ...localSearch,
      attributes: {
        ...localSearch.attributes,
        [key]: value
      }
    })
  }

  const [tabIndex, setTabIndex] = useState(0)

  const handleFiltersChange = (filters) => {
    updateLocalSearch("filters", filters)
  }

  return (
    <>
      <IconButton icon={<FiEdit2 />} onClick={onOpen} variant="tertiary" aria-label="Edit" />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit "{search.attributes.title}"</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Tabs onChange={(index) => setTabIndex(index)}>
              <TabList>
                <Tab>Settings</Tab>
                <Tab>Search Filters</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <SearchSettingsForm localSearch={localSearch} updateLocalSearch={updateLocalSearch} />
                </TabPanel>
                <TabPanel>
                  <SearchFiltersForm filters={localSearch.attributes.filters} handleFiltersChange={handleFiltersChange} />
                </TabPanel>
              </TabPanels>
            </Tabs>
            {}
          </ModalBody>

          <ModalFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={saveChanges} isDisabled={isEqual(search, localSearch)}>
              Save Changes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};