import { useKeyPress } from "../../../../hooks/use-key-press";
import DOMPurify from "dompurify";
import {
  RefObject,
  useState,
  useRef,
  useEffect,
  KeyboardEvent,
  ChangeEvent,
  useCallback,
} from "react";

interface UseEditFieldProps {
  fieldId?: string;
  onCreate: (field: string) => void;
  onEdit: (id: string, field: string) => void;
  autoFocus?: boolean;
}

interface UseEditFieldResult {
  field: string;
  setField: (s: string) => void;
  isEditing: boolean;
  setIsEditing: (bool: boolean) => void;
  onKeyPressed: (event: KeyboardEvent) => void;
  handleBlur: () => void;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  inputRef: RefObject<HTMLInputElement> | null;
}

const useEditField = ({
  fieldId,
  onCreate,
  onEdit,
  autoFocus = false,
}: UseEditFieldProps): UseEditFieldResult => {
  const [field, setField] = useState("");
  const [prevField, setPrevField] = useState("");
  const [isEditing, setIsEditing] = useState(autoFocus);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isEditing && inputRef?.current) {
      setPrevField(field);
      inputRef?.current?.focus();
    }
  }, [isEditing, inputRef]);

  const handleCreate = () => {
    if (field.length > 0) {
      onCreate(field);
      setIsEditing(false);
      setField("");
    } else {
      setIsEditing(false);
    }
  };

  const handleEdit = () => {
    if (fieldId && field.length > 0) {
      onEdit(fieldId, field);
      setIsEditing(false);
      setField("");
    } else {
      setIsEditing(false);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setField(DOMPurify.sanitize(e.target.value));
  };

  const handleBlur = () => {
    if (fieldId) {
      handleEdit();
    } else {
      handleCreate();
    }
  };

  const onKeyPressed = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      if (fieldId) {
        handleEdit();
      } else {
        handleCreate();
      }
    }
  };

  const enter = useKeyPress("Enter");
  const esc = useKeyPress("Escape");

  const onEnter = useCallback(() => {
    if (enter && fieldId) {
      handleEdit();
    }
  }, [enter, field, onEdit]);

  const onEsc = useCallback(() => {
    if (esc && fieldId) {
      setField(prevField);
      setIsEditing(false);
    }
  }, [esc, field]);

  useEffect(() => {
    if (isEditing) {
      onEnter();
      onEsc();
    }
  }, [onEnter, onEsc, isEditing]);

  return {
    field,
    setField,
    isEditing,
    setIsEditing,
    onKeyPressed,
    handleBlur,
    handleChange,
    inputRef,
  };
};

export default useEditField;
