export const FreshdeskInitialize = (email) => {
  if (!window.freshdeskInitialized) {
    console.log("initializing...");
    window.freshdeskInitialized = true;
    const script2 = document.createElement("script");
    script2.setAttribute("id", "freshworks-widget");
    script2.src = "https://widget.freshworks.com/widgets/72000002009.js";
    script2.async = true;
    script2.defer = true;

    window.fwSettings = {
      widget_id: 72000002009,
    };
    document.body.appendChild(script2);
  }

  !(function () {
    if ("function" != typeof window.FreshworksWidget) {
      var n = function () {
        n.q.push(arguments);
      };
      (n.q = []), (window.FreshworksWidget = n);
    }
  })();

  // eslint-disable-next-line no-undef
  FreshworksWidget("identify", "ticketForm", {
    email,
  });
  if (email) {
    // eslint-disable-next-line no-undef
    FreshworksWidget("disable", "ticketForm", ["email"]);
  }

};
