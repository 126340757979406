import {useParams} from "react-router-dom";
import {useGetChildIndustriesQuery} from "../../../services/savedSearchesApi";
import {selectDefaultFilters} from "../../../store/local-search";
import {useSelector} from "react-redux";
import {Box, Flex} from "@chakra-ui/react";
import {TimeSeriesGraph} from "../shared/time-series";
import {DistributionBarChart} from "../shared/distribution-bar-chart";
import {AskingPriceBuckets, DefaultBarChartBuckets} from "../shared/common-visualization-elements";
import {BoxPlot} from "../shared/box-plot";
import {Tooltip} from "@chakra-ui/react";

import * as React from "react";
import {useContext, useEffect} from "react";
import {HeadingContext} from "../../heading-provider";
import {HeadingLink, HeadingText} from "../../layouts/BodyHeading";


export const IndustryAnalytics = () => {
  const { setHeadingContent } = useContext(HeadingContext);

  const updateHeadingContent = (content) => {
    if (setHeadingContent) {
      setHeadingContent(content);
    }
  };

  let defaultFilters = Object.assign(useSelector(selectDefaultFilters))
  const { industryId } = useParams();

  const { data: industriesData, isLoading: industriesDataIsLoading, isFetching: industriesDataIsFetching, isError: industriesDataIsError } = useGetChildIndustriesQuery();
  const industries = industriesData?.data || []

  useEffect(() => {
    if (industriesDataIsLoading || industriesDataIsFetching || industriesDataIsError) {
      updateHeadingContent(
        <Box
          pl={{ base: '0', lg: '10' }}
        >
          <Flex align="center" gap="2">
            <HeadingLink text={"Industry Analytics"} url={'/analytics/industries'} />
            <HeadingText text={" > Loading..."} />
          </Flex>
        </Box>
      );
    } else if (industriesData) {
      const industry = industries.find(i => parseInt(i.id) === parseInt(industryId))
      updateHeadingContent(
        <Box
          pl={{ base: '0', lg: '10' }}
        >
          <Flex align="center" gap="2">
            <HeadingLink text={"Industry Analytics"} url={'/analytics/industries'} />
            <HeadingText text={" > " + (industry?.attributes?.label || "Unknown")} />
          </Flex>
        </Box>
      );
    }

  }, [industriesData, industriesDataIsLoading, industriesDataIsFetching, industriesDataIsError]);

  if (industriesDataIsLoading || industriesDataIsFetching) {
    return (<div>Loading...</div>)
  }

  if (industryId && industries.length > 0) {
    let filters = {
      ...defaultFilters,
      dealChildIndustryId: [industryId]
    }
    const industry = industries.find(i => parseInt(i.id) === parseInt(industryId))
    return (
      <Box>
        <Box>
          <TimeSeriesGraph filters={filters} title={"New Deals on Kumo (Listed & Delisted)"} graphType={"# of Deals"} timePeriod={"1 year"}/>
        </Box>

        <Box>
          <TimeSeriesGraph filters={filters} title={"Number of Days on Market"}   graphType={"Avg. # of Days to Sale"} timePeriod={"1 year"}/>
        </Box>

        <Box>
          <DistributionBarChart title={"Number of Deals by Asking Price Band"}   filters={filters} xmetric={"asking_price"} ymetric={"num_deals"} buckets={DefaultBarChartBuckets} />
        </Box>

        <Box>
          <DistributionBarChart title={"Number of Deals by Annual Revenue"} filters={filters} xmetric={"annual_revenue"} ymetric={"num_deals"} buckets={DefaultBarChartBuckets} />
        </Box>

        <Box>
          <DistributionBarChart title={"Number of Deals by Profit"} filters={filters} xmetric={"annual_ebitda_or_sde"} ymetric={"num_deals"} buckets={DefaultBarChartBuckets} titleToolTip={"EBITDA or SDE, based on what is present on each deal"}/>
        </Box>

        <Box>
          <DistributionBarChart title={"Number of Deals by Price-To-Profit Multiple"} filters={filters} xmetric={"ebitda_multiple"} ymetric={"num_deals"} buckets={DefaultBarChartBuckets} titleToolTip={"EBITDA or SDE, based on what is present on each deal"}/>
        </Box>

        <Box>
          <BoxPlot title={"Price-to-Profit Multiple by Price Band"}  filters={filters} ymetric={"asking_price"} buckets={AskingPriceBuckets} titleToolTip={"EBITDA or SDE, based on what is present on each deal"}/>
        </Box>
      </Box>
    )
  }
}