import React, {useEffect, useState} from 'react';
import {Box, Button, Flex, FormHelperText, FormLabel, Select, Spinner, Text, VStack} from '@chakra-ui/react';
import {AutoComplete, AutoCompleteInput, AutoCompleteItem, AutoCompleteList} from '@choc-ui/chakra-autocomplete';
import {useGetLocationAutocompleteQuery} from "../../services/savedSearchesApi";

export const FormForSearchByRadius = ({ filters, handleFilterChange, handleFiltersChange }) => {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const debouncedInputValue = useDebouncedValue(inputValue, 300);
  const [showSuggestions, setShowSuggestions] = useState(false);

  // Enhance useGetLocationAutocompleteQuery to manage loading state
  const { data: suggestions, isFetching } = useGetLocationAutocompleteQuery(
    { query: debouncedInputValue },
    { skip: !debouncedInputValue || debouncedInputValue.length < 2 }
  );


  useEffect(() => {
    if (isFetching) {
      setOptions([]);
      setShowSuggestions(false);
    } else if (suggestions?.addresses && suggestions.addresses.length > 0) {
      setOptions(suggestions.addresses);
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  }, [isFetching, suggestions]);

  const assignAutoCompleteValue = ({item}) => {
    const val = item.originalValue
    if (!val) {
      console.error('Selected suggestion is undefined');
      return;
    }

    const coordinates = val?.latitude && val?.longitude ? [val.latitude, val.longitude] : [];
    handleFiltersChange({
      ...filters,
      locationPointTextDescription: val.formattedAddress,
      locationPointCoordinates: coordinates,
      locationPointRadius: filters.locationPointRadius
    });

    setShowSuggestions(false); // Hide suggestions when an option is selected
  };

  const onChangeInputHandler = (evt) => {
    setInputValue(evt.target.value)
    setShowSuggestions(true);
  };

  const RadiusForm = () => {
    return (
      <Box mt={5}>
        <FormLabel>Radius in miles</FormLabel>
        <Select
          value={filters.locationPointRadius}
          onChange={(e) => handleFilterChange('locationPointRadius', e.target.value)}
          mt={2}
        >
          <option value="25">25 miles</option>
          <option value="50">50 miles</option>
          <option value="100">100 miles</option>
          <option value="200">200 miles</option>
          <option value="300">300 miles</option>
        </Select>
      </Box>
    );
  }

  const onTagClose = () => {
    handleFiltersChange({
      ...filters,
      locationPointTextDescription: '',
      locationPointCoordinates: [],
      locationPointRadius: filters.locationPointRadius
    });
    setInputValue('')
  }


  return (
    <Flex justify="center" align="center" w="full">
      <VStack>
        <Box w="full">
          <FormLabel>Search for a city</FormLabel>

          <AutoComplete openOnFocus onSelectOption={(val) => assignAutoCompleteValue(val)} isLoading={isFetching}>
            <AutoCompleteInput
              variant="filled"
              value={inputValue}
              onChange={onChangeInputHandler}
              loadingIcon={<Spinner color="red.500" />} // Loading icon when waiting for suggestions
            >
            </AutoCompleteInput>

            {showSuggestions && (
              <AutoCompleteList>
                {options.map((suggestion, index) => (
                  <AutoCompleteItem
                    key={`suggestion-${suggestion.formattedAddress}-${index}`}
                    placeholder={filters.locationPointTextDescription}
                    value={suggestion}
                    getValue={(item) => item.formattedAddress}
                  >
                    <Text fontWeight={suggestion.formattedAddress === inputValue ? 'bold' : ''}>{suggestion.formattedAddress}</Text>
                  </AutoCompleteItem>
                ))}
              </AutoCompleteList>
            )}
          </AutoComplete>
          {filters.locationPointTextDescription.length > 0 &&
            <Box my={3} display="flex" alignItems="center"> {/* Added display flex and align items */}
              <Text fontSize={"sm"} mt={"3px"} mr={1} fontWeight={"semiBold"}>Selected: </Text> {/* Added margin right for spacing */}
              <Text fontSize={"sm"} mt={"3px"} mr={3} fontWeight={"bold"}>{filters.locationPointTextDescription}</Text>
              <Button size={"sm"} variant={"link"} onClick={onTagClose}>
                Clear
              </Button>
            </Box>
          }


          <FormHelperText>Not all deals have location data. You'll usually see 20% more deals with a regional filter.</FormHelperText>
        </Box>
        <Box w={"full"}>
          {filters.locationPointCoordinates.length > 0 && <RadiusForm />}
        </Box>
      </VStack>

    </Flex>
  );
};

const useDebouncedValue = (input, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(input);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(input);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [input, delay]);

  return debouncedValue;
};
