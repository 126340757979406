import {Box, Divider, Text, VStack} from "@chakra-ui/react";
import {ResponsiveBar} from '@nivo/bar'
import {
  darkerCustomColors,
  DefaultAnalyticsContainer,
  DefaultErrorContainer,
  DefaultLoadingContainer,
  defaultTimeValues,
  TitleRowWithTimeSelector
} from "./common-visualization-elements";
import {useGetHistogramQuery} from "../../../services/dealAnalyticsApi";
import numbro from "numbro";
import {useState} from "react";

const ymetricOptions = {
  'perc_deals': '% of Deals',
  'num_deals': '# of Deals',
}

const xmetricOptions = {
  'asking_price': 'Asking Price',
  'annual_revenue': 'Revenue',
  'annual_ebitda_or_sde': 'Profit (EBITDA or SDE)',
  'ebitda_multiple': 'Price-to-Profit Multiple',
}

export const DistributionBarChart = ({filters, title, ymetric, asking_price_range,
                                       xmetric, buckets, titleToolTip, ...otherProps}) => {
  let queryHook;
  let queryOptions;

  const defaultTimeRange = defaultTimeValues[0].value;
  const [currentTimePeriod, setCurrentTimePeriod] = useState(defaultTimeRange);

  queryHook = useGetHistogramQuery;
  queryOptions = {
    filters: filters,
    asking_price_range: asking_price_range,
    buckets: buckets,
    added_at_time_range: currentTimePeriod
  };

  const handleTimeChange = (value) => {
    setCurrentTimePeriod(value);
  };

  const { data, isLoading, isFetching, isError } = queryHook(queryOptions);

  if (isLoading || isFetching) {
    return (
      <DefaultLoadingContainer {...otherProps} >
        <TitleRowWithTimeSelector
          options={defaultTimeValues} title={title} timePeriod={currentTimePeriod} handleTimeChange={handleTimeChange} />
      </DefaultLoadingContainer>
    )
  } else if (isError) {
    return (
      <DefaultErrorContainer {...otherProps} >
        <TitleRowWithTimeSelector
          options={defaultTimeValues} title={title} timePeriod={currentTimePeriod} handleTimeChange={handleTimeChange} />
      </DefaultErrorContainer>
    )
  }

  if (data) {
    const relevantData = data.filter(item => item.distribution_of_deals_within === xmetric)
    const bucketNames = buckets[xmetric] || []
    const transformedData = data ? transformToNivoFormat(relevantData, bucketNames) : [];

    return (
      <DefaultAnalyticsContainer {...otherProps}>
        <TitleRowWithTimeSelector
          options={defaultTimeValues} title={title} timePeriod={currentTimePeriod} handleTimeChange={handleTimeChange} titleToolTip={titleToolTip}/>
        <Box width={"full"} height={"full"}>
          <HistogramWithFormatting
            data={transformedData}
            ymetric={ymetric}
            xAxisName={xmetricOptions[xmetric]}
            yAxisName={ymetricOptions[ymetric]}
          />
        </Box>
      </DefaultAnalyticsContainer>
    )
  }
}

const CustomTooltip = ({ id, value, indexValue, data, totalDeals }) => {
  const formattedNumDeals = numbro(data.num_deals).format({ thousandSeparated: true });
  const formattedTotalDeals = numbro(totalDeals).format({ thousandSeparated: true });

  return (
    <Box p={3} bg="white" boxShadow="md" borderRadius="md">
      <VStack align="start">
        <Text fontSize="md" fontWeight="bold">
          {xmetricOptions[data.distribution_of_deals_within]}: {indexValue}
        </Text>
        <Divider />
        <Text fontSize="md">{`Percentage of Deals: ${data.perc_deals}%`}</Text>
        <Text fontSize="md">{`Number of Deals: ${formattedNumDeals} out of ${formattedTotalDeals}`}</Text>
      </VStack>
    </Box>
  );
};

function transformToNivoFormat(rawData, bucketNames) {
  const nivoData = [];
  bucketNames.forEach(bucket => {
    const item = rawData.find(item => item.bucket === bucket.name);
    if (item) {
      nivoData.push({
        ...item,
        bucket: bucket.name,
      });
    } else {
      nivoData.push({
        bucket: bucket.name,
        distribution_of_deals_within: bucket.metric,
        perc_deals: 0,
        num_deals: 0,
      });
    }
  })
  // rawData.forEach(item => {
  //   const bucket = bucketNames.find(bucket => bucket.name === item.bucket);
  //   nivoData.push({
  //     ...item,
  //     bucket: bucket.name,
  //   });
  // });

  return nivoData;
}


const customTheme = {
  axis: {
    domain: {
      line: {
        strokeWidth: '1',
        stroke: 'rgba(204,204,204,0.6)'
      }
    },
    ticks: {
      text: {
        fontSize: 12, // Change the font size
        fill: 'gray', // Text color
        // Add additional styles as needed
      },
      line: {
        stroke: 'gray', // Grey border color
        strokeWidth: 1, // Border width
      }
    },
    legend: {
      text: {
        fontSize: 18,    // Change the font size for legend
        fill: 'gray', // Text color
        fontWeight: 700  // Font weight for legend
      }
    }
  },
  labels: {
    text: {
      // Customize the label color, font size, and weight
      fill: '#ffffff', // Text color
      fontSize: 16,    // Font size
      fontWeight: 600  // Font weight
    }
  },

};

const HistogramWithFormatting = ({data, ymetric, yAxisName, xAxisName}) => {
  const formatTickValue = (value) => {
    if (ymetric === 'perc_deals') {
      return `${value}%`
    } else {
      const formattedNumDeals = numbro(value).format({ thousandSeparated: true });
      return `${formattedNumDeals}`
    }
  }


  const totalDeals = data.reduce((acc, item) => acc + item.num_deals, 0);

  return (
    <ResponsiveBar
      data={data}
      keys={[ymetric]} // or ['perc_deals']
      indexBy="bucket"
      theme={customTheme}
      colors={ darkerCustomColors }
      margin={{ top: 0, right: 0, bottom: 80, left: 60 }}
      padding={0.3}
      groupMode="grouped"
      colorBy="indexValue"
      borderRadius={4}
      // borderWidth={1}
      // borderColor={{ theme: 'grid.line.stroke' }}
      axisTop={null}
      axisRight={null}
      enableGridY={false}
      // valueScale={{ type: 'symlog' }}
      axisBottom={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        legend: xAxisName,
        legendPosition: 'middle',
        legendOffset: 45,
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        tickValues: 5,
        // legend: yAxisName,
        // legendPosition: 'middle',
        // legendOffset: -60,
        format: formatTickValue
      }}
      label={d =>
      {
        if (d.id === 'perc_deals') {
          return `${d.formattedValue}%`
        } else {
          const formattedNumDeals = numbro(d.formattedValue).format({ thousandSeparated: true });
          return `${formattedNumDeals}`
        }
      }
      }
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={'white'}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      tooltip={(barData) => <CustomTooltip {...barData} totalDeals={totalDeals} />}
    />
  )

}