import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Link,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import {useDispatch, useSelector} from "react-redux";
import {
  localSearchActions,
  selectActiveSearch,
  selectDefaultFilters,
  useFormattedFilterChanges
} from "../../../store/local-search";
import React, {useState} from "react";
import {SearchFiltersButtons} from "../../../components/search-filters-buttons";
import {SearchFiltersForm} from "../../../components/search-filters-form";
import {isEqual} from "lodash";

export const FiltersDrawerButton = ({customButtonText, customButton, ...rest}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const  { filters, sort, page } = useSelector(selectActiveSearch)
  const [preChange, setPreChange] = useState(filters);
  const dispatch = useDispatch()

  const defaultFilters = useSelector(selectDefaultFilters)

  const handleFiltersChange = (newFilters) => {
    const filterChange = {
      filters: newFilters,
      sort: sort,
      page: 1
    };
    dispatch(localSearchActions.updateActiveSearch(filterChange));
  }

  const setKeyToDefault = (key) => {
    // We have special behaviors for "location", "text_search"
    if (key === "location") {
      handleFiltersChange({
        ...filters,
        locationFilterType: defaultFilters.locationFilterType,
        dealSearchableLocationId: defaultFilters.dealSearchableLocationId,
        locationPointTextDescription: defaultFilters.locationPointTextDescription,
        locationPointCoordinates: defaultFilters.locationPointCoordinates,
        locationPointRadius: defaultFilters.locationPointRadius
      });
      return;
    }

    if (key === "text_search") {
      handleFiltersChange({
        ...filters,
        textSearchType: defaultFilters.textSearchType,
        titleAndDescriptionSearch: defaultFilters.titleAndDescriptionSearch,
        ANDSearch: defaultFilters.ANDSearch,
        ORSearch: defaultFilters.ORSearch,
        NOTSearch: defaultFilters.NOTSearch
      });
      return;
    }

    const newFilters = {...filters};
    newFilters[key] = defaultFilters[key];
    handleFiltersChange(newFilters);
  }

  const setFiltersToDefault = () => {
    handleFiltersChange(defaultFilters);
  }

  const formattedChanges = useFormattedFilterChanges(null)
  const changedFiltersLength = Object.entries(formattedChanges).length

  const ButtonWithOnClick = customButton ? React.cloneElement(customButton, { onClick: onOpen }) : <Button onClick={onOpen}>Filters</Button>;

  return (
    <>
      <Flex wrap="wrap" spacing={"3"}>
        {customButtonText && <Link onClick={onOpen}>{customButtonText}</Link>}
        {customButton && <ButtonWithOnClick />}
        {!customButton && !customButtonText && <SearchFiltersButtons onTagClick={onOpen} onTagClose={setKeyToDefault} filtersToCompare={filters} includeFiltersButton={true} includeOtherFiltersCount={true}/>}
      </Flex>
      <Drawer onClose={onClose} isOpen={isOpen} size={'md'}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottom={"1px"} borderColor={"gray.200"} py={"14px"}>
            <Flex alignItems="center" height="100%">
              <Text>Filters</Text>
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            <SearchFiltersForm filters={filters} handleFiltersChange={handleFiltersChange} />
          </DrawerBody>
          <DrawerFooter borderTopWidth="1px">
            <Flex w="100%" align="center">
              <Button
                variant="link"
                size={{base: 'xs', lg: 'md'}}
                onClick={() => { setFiltersToDefault() }}
                isDisabled={!(changedFiltersLength > 0)}
              >
                Reset Filters
              </Button>

              <Button
                variant="outline"
                mr={3}
                size={{base: 'xs', lg: 'md'}}
                onClick={() => { handleFiltersChange(preChange) }}
                isDisabled={isEqual(filters, preChange)}
                ml="auto" // This pushes the button to the end of the Flex container
              >
                Undo Changes
              </Button>

              <Button
                colorScheme="blue"
                size={{base: 'xs', lg: 'md'}}
                onClick={() => { onClose() }}>
                See Results
              </Button>
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}