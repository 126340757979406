import {
  Box,
  Circle,
  Flex,
  Heading,
  HStack,
  Icon,
  List,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import * as React from "react";
import {FiCheck} from "react-icons/fi";
import {Card, CardProps} from "./card";
import {PlanRate} from "./page";

export interface PricingCardData {
  header: string;
  features: any[];
  name: string;
  price: string;
  annualPrice?: string;
  additionalPlanDescription?: string;
  plan: string;
  planRate: PlanRate;
  showRate?: boolean;
}

interface PricingCardProps extends CardProps {
  data: PricingCardData;
  button: React.ReactElement;
}

export const PricingCard = (props: PricingCardProps) => {
  const accentColor = useColorModeValue("blue.600", "blue.200");
  const iconColor = useColorModeValue("blue.50", "whiteAlpha.50");
  const { data, button, ...rest } = props;
  const {
    header,
    features,
    price,
    annualPrice,
    name,
    additionalPlanDescription,
    plan,
    planRate,
    showRate = true,
  } = data;

  return (
    <Card rounded="xl" {...rest}
          maxWidth={{ base: "full", lg: "500px" }}
          height={"100%"}
    >
      <VStack spacing={6} mx="6">
        <Heading size={useBreakpointValue({ base: "sm", md: "md" })} fontWeight="extrabold">
          {name}
        </Heading>
        <Text fontSize="lg" textAlign="center">
          {header}
        </Text>
      </VStack>
      <Flex
        align="flex-end"
        justify="center"
        fontWeight="extrabold"
        color={accentColor}
        mt={{ base: "5", lg: "12" }}
        mb={{ base: "5", lg: "8" }}>
        <Heading size={useBreakpointValue({ base: "sm", lg: "md" })} fontWeight="inherit">
          {planRate === "year" ? annualPrice : price}
          {showRate && (
            <Text display="inline" fontSize="lg">
              {" "}
              / month
            </Text>
          )}
        </Heading>
      </Flex>
      <Stack mx="6" spacing="8">
        {button}
        <Box>
          <Box mb={"4"}>
            {
              <Text fontSize="md" fontWeight="bold">
                {additionalPlanDescription && additionalPlanDescription}
              </Text>
            }
          </Box>

          <List spacing="4">
            {features.map((feature, index) => (
              <HStack fontWeight="medium" as="li" spacing="4" key={index}>
                {/* <ListIcon fontSize="xl" as={HiCheckCircle} marginEnd={2} color={accentColor} /> */}
                <Circle size="6" bg={iconColor}>
                  <Icon as={FiCheck} color="accent" />
                </Circle>
                <Text>
                  <span style={{ fontWeight: 'bold' }}>{feature.title}.</span>
                  <span color="muted"> {feature.description}</span>
                </Text>
              </HStack>
            ))}
          </List>
        </Box>

      </Stack>
    </Card>
  );
};

