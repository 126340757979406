import {
  Box,
  Link,
  Select,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import {NavLink as ReactRouterLink, useNavigate, useParams} from "react-router-dom";
import {Billing} from "./billing";
import {NotificationsTab} from "./notifications";
import {useContext, useEffect} from "react";
import {HeadingContext} from "../heading-provider";
import {StandardTextHeading} from "../layouts/BodyHeading";

export enum SettingsRoute {
  billing = "Billing",
  notifications = "Notifications",
  // 'your-team' = "Your Team",
}

const tabPanels = Object.keys(SettingsRoute);
const tabTitles = Object.values(SettingsRoute);

export const SettingsPage = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const routes = Object.values(SettingsRoute).map((v) => v);
  const { tabPanel } = useParams();
  const foundIndex = tabPanels.findIndex((t) => t === tabPanel);
  const tabPanelId = foundIndex === -1 ? 0 : foundIndex;

  const { setHeadingContent } = useContext(HeadingContext);
  const options = Object.keys(SettingsRoute).map((key) => ({
    value: key,
    label: SettingsRoute[key as keyof typeof SettingsRoute],
  }));

  useEffect(() => {
    const pageName = tabPanel ? `Settings > ${tabTitles[tabPanelId]}` : `Settings`;
    setHeadingContent(<StandardTextHeading text={pageName} />);
  }, [setHeadingContent, tabPanel]);


  return (
    <Box p={"3"} border={"1px"} borderColor={"gray.200"} borderRadius={"md"}>
      <Tabs variant="indicator" size="lg" index={tabPanelId}>
        {isMobile ? (
          <MobileSelect value={tabPanels[tabPanelId]} options={options} />
        ) : (
          <TabList outline="0">
            {routes.map((route, idx) => (
              <Tab as={ReactRouterLink} to={"/settings/" + tabPanels[idx]} key={route}
                   _focus={{ outline: 'none'}}
              >
                {route}
              </Tab>
            ))}

          </TabList>
        )}

        {!isMobile && <TabIndicator />}

        <TabPanels paddingTop={6}>
          <TabPanel>
            <Billing />
          </TabPanel>
          <TabPanel>
            <NotificationsTab />
          </TabPanel>
          <TabPanel>
            <Text>
              Want to have dedicated logins for multiple members of your team?</Text>
            <Text>
              If you're interested in this feature, {' '}
              <Link
                href="mailto:support@withkumo.com?subject='Requesting Team Features'"
                color="blue.500"
              >
                email us
              </Link>
              {' '} to be the first to access.
            </Text>
          </TabPanel>

        </TabPanels>
      </Tabs>
    </Box>
  );
};

type Option = {
  value: string; // or number, depending on your use case
  label: string;
};

type MobileSelectProps = {
  value: string; // or number, depending on your use case
  options: Option[];
};

const MobileSelect: React.FC<MobileSelectProps> = ({ value, options }) => {
  const navigate = useNavigate();

  const handleChangeInDropDown = (event: React.ChangeEvent<HTMLSelectElement>) => {
    navigate(`/settings/${event.target.value}`);
  };

  return (
    <Select size="md"
            aria-label="Sort by"
            value={value} // Changed from defaultValue to value for controlled component
            rounded="md"
            onChange={handleChangeInDropDown}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  );
};