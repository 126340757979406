export const GtagInitializer = (userId) => {
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag("js", new Date());

  gtag("config", "G-EQH88TS87N", {
    user_id: userId,
    'linker': {
      'domains': ['checkout.stripe.com']
    }
  });
  gtag("set", "user_properties", {
    crm_id: userId,
  });
  window.gtag = gtag;
  window.gtagUserInitialized = true;
};

export const GtagScriptInitializer = () => {
  if (!document.getElementById("#gtag-script")) {
    const script = document.createElement("script");
    script.setAttribute("id", "gtag-script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-EQH88TS87N";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }
};
