import axios from "axios";

export const resetPassword = async ({ email }: { email: string }) => {
  const resp = await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/users/password`, {
    user: {
      email,
    },
  });
  const token = resp.headers.authorization;
  localStorage.setItem("with-kumo-token", token);
  return resp.data;
};
