import { getCookie } from "./../utils/get-cookie";
import axios from "axios";

export const signupUser = async ({
  name,
  email,
  password,
}: {
  name: string;
  email: string;
  password: string;
}) => {
  const uniqueBrowserId = getCookie("unique_browser_id");
  const resp = await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/users/signup`, {
    user: {
      name,
      email,
      password,
    },
    unique_browser_id: uniqueBrowserId,
  });
  const token = resp.headers.authorization;
  localStorage.setItem("with-kumo-token", token);
  return resp.data;
};
