import {useQuery} from "@apollo/client";
import {WarningTwoIcon} from "@chakra-ui/icons";
import {Box, Button, HStack, Stack, Text} from "@chakra-ui/react";
import numbro from "numbro";
import {CURRENT_USER_QUERY} from "../../../graphql/current-user";
import {TOTAL_DEALS_COUNT_LAST_THIRTY_DAYS} from "../../../graphql/total-deals-count-last-thirty";
import {NavLink as ReactRouterLink} from "react-router-dom";

export const FreeUserCta = () => {
  const { data, loading } = useQuery(TOTAL_DEALS_COUNT_LAST_THIRTY_DAYS);
  const { data: userData, loading: userLoading } = useQuery(CURRENT_USER_QUERY);

  if (loading || userLoading || ["Pro", "Ultimate"].includes(userData?.currentUser?.account?.subscriptionLevel || "Public")) return null;

  return (
    <Box
      bg="yellow.50"
      p={"5"}
      borderStyle={'dashed'}
      borderWidth={"1px"}
      borderColor={"gray.300"}
      borderRadius="lg"
      mb={4}>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        spacing={{ base: '5', md: '6' }}
        justify="space-between"
      >
        <HStack spacing={"4"}>
          <WarningTwoIcon display={{ base: 'none', md: 'inline-block' }} color="yellow.400" h={10} w={10} />{" "}
          <Stack spacing="1">
            <Text fontSize="sm" fontWeight="medium">
              You are not viewing {numbro(data?.dealsCount || 0).format({ thousandSeparated: true, mantissa: 0 })} recent deals!
            </Text>
            <Text fontSize="sm" color="fg.muted">
              Only Pro & Ultimate users can search deals from the last 30 days.
            </Text>
          </Stack>
        </HStack>

        <Box>
          {/*<ProPlanCtaCheckout tracking="upgrade_pro_deals_search_click">*/}
          {/*  <Button>Upgrade to Pro</Button>*/}
          {/*</ProPlanCtaCheckout>*/}
          <Button
            as={ReactRouterLink}
            to="/plan-selection">
            Learn more
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};
