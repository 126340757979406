import {
  Avatar,
  Box,
  Center,
  Flex,
  HStack,
  Icon,
  Skeleton,
  SkeletonText,
  Stack,
  Text,
  useBreakpointValue,
  Wrap,
} from "@chakra-ui/react";
import * as React from "react";
import {GoCalendar, GoGlobe} from "react-icons/go";
import numbro from "numbro";
import {kumoDayJs} from "../../../utils/dayjs";
import {FiInfo} from "react-icons/fi";

export const KeyNumbersComponent = ({ deal, bg }) => {
  const {
    title,
    status,
    business_summary,
    location,
    added_to_kumo_at,
    asking_price,
    annual_ebitda,
    annual_sde,
    annual_revenue,
    highest_profit_number,
    ...dealAttributes
  } = deal?.attributes || {};

  let multiple;
  if ((annual_ebitda || annual_sde) && asking_price) {
    const value = asking_price / (annual_ebitda || annual_sde)
    if (value >= 0 && value <= 100) {
      const multiple_string = numbro(asking_price / (annual_ebitda || annual_sde)).format({
        average: true,
        totalLength: 3,
      });
      multiple = `${multiple_string}x`;
    } else {
      multiple = "N/A";
    }
  } else {
    multiple = "N/A";
  }
  const numberFormat = {
    average: true,
    totalLength: 3,
    // mantissa: 2,
  };

  const stats = [
    {
      label: `Price${multiple !== "N/A" ? ` (${multiple})` : ""}`,
      value: asking_price ? numbro(asking_price).format(numberFormat).toUpperCase() : "N/A",
    },
    {
      label: "Revenue",
      value: annual_revenue ? numbro(annual_revenue).format(numberFormat).toUpperCase() : "N/A",
    },
    {
      label: "EBITDA/SDE",
      value: highest_profit_number ? numbro(highest_profit_number).format(numberFormat).toUpperCase() : "N/A",
    },

    // { label: "Multiple", value: multiple },
  ];

  return (
    <HStack width="full">
      {stats.map((stat) => (
        <Box key={stat.label} flex={1}>
          <Stat label={stat.label} value={stat.value} bg={bg ? bg : "gray.50"} />
        </Box>
      ))}
    </HStack>
  );
}

export const DealHeader = ({ deal }) => {
  const isDesktop = useBreakpointValue({ base: false, md: true }, { ssr: false });
  if (deal) {
    const {
      title,
      status,
      business_summary,
      location,
      added_to_kumo_at,
      asking_price,
      annual_ebitda,
      annual_sde,
      annual_revenue,
      thumbnail_photo_url,
      ...dealAttributes
    } = deal?.attributes || {};

    return (
      <Box>
        <Stack>
          <StatusAlert status={status} />
          <Flex direction={{ base: "row" }} align="top">
            <Avatar
              size={{base: "lg", lg: "xl"}}
              src={thumbnail_photo_url}
              name={title}
              borderRadius="md" // Rounded square shape
            />

            <Flex direction="column" flex="1" ml={"4"}>
              <Text fontWeight="bold" fontSize={{ base: "sm", lg: "lg" }}>
                {title}
              </Text>
              <Text fontSize={{ base: "sm", lg: "md" }}>{business_summary}</Text>
            </Flex>
          </Flex>
          <Wrap shouldWrapChildren my="2" spacing="4">
            <HStack fontSize="sm" align="center">
              <Icon as={GoGlobe} color="gray.500" />
              <Text color={"fg.muted"}>{location || "Not Available"}</Text>
            </HStack>
            <HStack fontSize="sm" align="center">
              <Icon as={GoCalendar} color="gray.500" />
              <Text color={"fg.muted"}>
                Added{" "}
                {added_to_kumo_at ? `${kumoDayJs(added_to_kumo_at).fromNow()}` : `Not available`}
              </Text>
            </HStack>
          </Wrap>
          <Box>
            <KeyNumbersComponent deal={deal} />
          </Box>
        </Stack>
      </Box>
    );
  } else {
    return <DealHeaderSkeleton />;
  }
};

const DealHeaderSkeleton = () => {
  return (
    <Box>
      <Stack>
        <SkeletonText mt="4" noOfLines={1} spacing="4" skeletonHeight="7" />
        <SkeletonText mt="4" noOfLines={2} spacing="1" skeletonHeight="5" />
        <Wrap shouldWrapChildren my="2" spacing="4">
          <HStack fontSize="sm" align={"center"}>
            <Icon as={GoGlobe} color="gray.500" />
            <SkeletonText width={"200px"} noOfLines={1} spacing="1" skeletonHeight="5" />
          </HStack>
          <HStack fontSize="sm" align={"center"}>
            <Icon as={GoCalendar} color="gray.500" />
            <SkeletonText width={"200px"} noOfLines={1} spacing="1" skeletonHeight="5" />
          </HStack>
        </Wrap>
        <Box>
          <HStack>
            {Array.from({ length: 5 }).map((_, index) => (
              <Skeleton key={index} width="125px" height={"50px"} mr={"10px"} />
            ))}
          </HStack>
        </Box>
      </Stack>
    </Box>
  );
};

const StatusAlert = ({ status }) => {
  const Alert = ({ message, bgColor }) => {
    return (
      <Box mt={"10px"} mb={"10px"} width={"100%"}>
        <Flex
          direction={"row"}
          // boxShadow="md"
          borderWidth="1px"
          borderColor="gray.200"
          bg="bg.surface"
          borderRadius="md"
          align={"center"}
          overflow="hidden">
          <Center bg={bgColor} px="3" py={"12px"}>
            <Icon as={FiInfo} boxSize="7" color="fg.accent.default" />
          </Center>
          <Stack direction="row" p="4" spacing="3" flex="1">
            <Stack spacing="2.5" flex="1">
              <Stack spacing="1">
                <Text textStyle="sm" color="fg.muted">
                  {message}
                </Text>
              </Stack>
            </Stack>
          </Stack>
        </Flex>
      </Box>
    );
  };

  if (status === "listed") {
    return <></>;
  } else if (["pending", "unknown", "no_status"].includes(status)) {
    return (
      <>
        <Alert
          message="This business has been reported as under offer or it's status is unclear."
          bgColor="yellow.500"
        />
      </>
    );
  } else if (["sold", "delisted"].includes(status)) {
    return (
      <>
        <Alert message="This business has been reported as unavailable." bgColor="red.400" />
      </>
    );
  }
};

const Stat = (props) => {
  const { label, value, ...boxProps } = props;
  return (

    <Box
      px={{
        base: '4',
        md: '4',
      }}
      py={{
        base: '3',
        md: '3',
      }}
      borderRadius="lg"
      // border={"1px"}
      // borderColor={"gray.100"}
      // boxShadow="sm"
      {...boxProps}
    >
      <Stack spacing={"1"}>
        <Text fontSize={{ base: "md", md: "lg" }}
              fontWeight={value === "N/A" ? "normal" : "normal"}
              color={value === "N/A" ? "fg.muted" : "fg.default"}
        >
          {value === "N/A" ? "" : "$"}{value}
        </Text>
        <Text textStyle={{ base: "xs", md: "sm" }} color="fg.muted">
          {label}
        </Text>
      </Stack>
    </Box>
    // <Box
    //   py={{ base: "1", md: "2" }}
    //   bg="bg-surface"
    //   borderRadius="sm"
    //   textAlign={"left"}
    //   // border="1px"
    //   // borderColor="gray.100"
    //   // boxShadow={useColorModeValue("sm", "sm-dark")}
    //   {...boxProps}>
    //   <Stack spacing={"0"}>
    //     <Text fontSize={{ base: "md", md: "lg" }}
    //           fontWeight={value === "N/A" ? "normal" : "medium"}
    //           color={value === "N/A" ? "fg.muted" : "fg.default"}
    //     >
    //       {value}
    //     </Text>
    //     <Text fontSize={{ base: "xs", md: "sm" }} color="fg.muted">
    //       {label}
    //     </Text>
    //
    //   </Stack>
    // </Box>
  );
};
