import * as React from "react";
import {Box, Flex, ListItem, SkeletonText, Stack, Text, UnorderedList, useDisclosure, VStack} from "@chakra-ui/react";
import {useQuery} from "@apollo/client";
import {CURRENT_USER_QUERY} from "../../../graphql/current-user";
import {SignupForFreeModal} from "../../../components/upgrade-ctas/signup-for-free-modal";
import {AiOutlineUnlock} from 'react-icons/ai';

const LoadingSkeleton = () => {
	return (
		<Box>
			<Stack>
				<SkeletonText mt="4" noOfLines={1} spacing="4" skeletonHeight="7" width={"300px"} />
				<SkeletonText mt="4" noOfLines={2} spacing="1" skeletonHeight="5" />
				<SkeletonText mt="4" noOfLines={15} spacing="1" skeletonHeight="5" />
			</Stack>
		</Box>
	)
}

export const AboutThisBusiness = ({ deal }) => {
	const { loading: userLoading, data: userData } = useQuery(CURRENT_USER_QUERY);
	const subscriptionLevel = userData?.currentUser?.account?.subscriptionLevel || "Public";

	const dealAttributes = deal?.attributes
	if (dealAttributes && !userLoading) {
		return (
			<>
				<Box mt={"5px"}>
					<DealTags deal={deal}/>
				</Box>
				<Box mt={"5px"}>
					{subscriptionLevel === "Public" && <PublicDealContentColumns deal={deal} />}
					{subscriptionLevel !== "Public" && <DealContentColumns deal={deal} />}
				</Box>
			</>
		)

	} else {
		return (
			<>
				<LoadingSkeleton />
			</>
		)
	}
}

const DealTags = ({ deal }) => {
	const dealAttributes = deal?.attributes
	if (dealAttributes) {
		const tags = dealAttributes.tags || [];
		const separatorSpacing = "8px"
		return (
			<Box>
				{tags.map((tag, index) => (
					<Text key={index} display="inline" color="fg.muted" fontSize={{ base: "sm" }}>
						{tag}
						{index < tags.length - 1 && (
							<Text as="span" color="#E2E8F0" mx={separatorSpacing}>
								|
							</Text>
						)}
					</Text>
				))}
			</Box>
		)
	} else {
		return <></>
	}


}

export const DealContentColumns = ({ deal }) => {
	const dealAttributes = deal?.attributes
	if (dealAttributes) {
		return (
			<>
				<Box>

					<Text>
						<Box whiteSpace="pre-wrap">
							<Text fontSize={"md"} fontWeight="semibold" mt="8" mb="2">
								Top Highlights
							</Text>
							<Box dangerouslySetInnerHTML={{ __html: dealAttributes.top_highlights || "" }} fontSize={"md"} mb={"20px"} />
							<Text fontSize={"md"} fontWeight="semibold" mt="8" mb="2">
								Other Info
							</Text>
							<Box dangerouslySetInnerHTML={{ __html: dealAttributes.additional_information || "" }} fontSize={"md"} mb={"20px"} />
						</Box>
					</Text>

					{/*<Grid*/}
					{/*	h='200px'*/}
					{/*	templateColumns='repeat(4, 1fr)'*/}
					{/*	gap={4}*/}
					{/*>*/}
					{/*	<GridItem colSpan={2}>*/}

					{/*		*/}
					{/*	</GridItem>*/}
					{/*	<GridItem colSpan={2}>*/}
					{/*		<Text>Column 2</Text>*/}

					{/*	</GridItem>*/}
					{/*</Grid>*/}
				</Box>
			</>
		)
	}
}

const PublicDealContentColumns = ({ deal }) => {
	const { isOpen, onOpen, onClose } = useDisclosure()

	return(
		<>
			<Box whiteSpace="pre-wrap">
				<Text fontSize={"md"} fontWeight="semibold" mt="8" mb="2">
					Top Highlights
				</Text>
				<Box>
					<BlurredContenWithCta onOpen={onOpen}>
						<UnorderedList>
							<ListItem>Established Customer Base: Boasts a loyal customer base with high repeat business, developed over 10 years of exceptional service.</ListItem>
							<ListItem>Prime Location: Situated in a bustling commercial area with high foot traffic, ideal for attracting new customers.</ListItem>
							<ListItem>Strong Online Presence: Features a well-maintained e-commerce website and active social media channels with a large following.</ListItem>
							<ListItem>Award-Winning Services: Recognized locally for outstanding service, winning multiple community awards in the past three years.</ListItem>
							<ListItem>Diverse Product Range: Offers a wide selection of products, catering to a broad demographic and constantly adapting to market trends.</ListItem>
							<ListItem>Strong Online Presence: Features a well-maintained e-commerce website and active social media channels with a large following.</ListItem>
							<ListItem>Award-Winning Services: Recognized locally for outstanding service, winning multiple community awards in the past three years.</ListItem>
							<ListItem>Diverse Product Range: Offers a wide selection of products, catering to a broad demographic and constantly adapting to market trends.</ListItem>
							<ListItem>Strong Online Presence: Features a well-maintained e-commerce website and active social media channels with a large following.</ListItem>
							<ListItem>Award-Winning Services: Recognized locally for outstanding service, winning multiple community awards in the past three years.</ListItem>
							<ListItem>Diverse Product Range: Offers a wide selection of products, catering to a broad demographic and constantly adapting to market trends.</ListItem>
						</UnorderedList>
					</BlurredContenWithCta>

				</Box>
				<Text fontSize={"md"} fontWeight="semibold" mt="8" mb="2">
					Other Info
				</Text>
				<Box>
					<BlurredContenWithCta onOpen={onOpen}>
						<UnorderedList>
							<ListItem>Sustainable Practices: Committed to environmentally friendly operations, including waste reduction and energy-efficient systems.</ListItem>
							<ListItem>Community Engagement: Actively participates in local events and charities, building a strong community presence and goodwill.</ListItem>
							<ListItem>Experienced Staff: Team of knowledgeable and dedicated staff, providing excellent customer service and operational expertise.</ListItem>
							<ListItem>Growth Potential: Significant opportunities for expansion through franchising or opening additional locations.</ListItem>
							<ListItem>Comprehensive Training Program: Offers an in-depth training program for new owners to ensure a smooth transition and continued success.</ListItem>
							<ListItem>Experienced Staff: Team of knowledgeable and dedicated staff, providing excellent customer service and operational expertise.</ListItem>
							<ListItem>Growth Potential: Significant opportunities for expansion through franchising or opening additional locations.</ListItem>
							<ListItem>Comprehensive Training Program: Offers an in-depth training program for new owners to ensure a smooth transition and continued success.</ListItem>
							<ListItem>Strong Online Presence: Features a well-maintained e-commerce website and active social media channels with a large following.</ListItem>
							<ListItem>Award-Winning Services: Recognized locally for outstanding service, winning multiple community awards in the past three years.</ListItem>
							<ListItem>Diverse Product Range: Offers a wide selection of products, catering to a broad demographic and constantly adapting to market trends.</ListItem>
						</UnorderedList>
					</BlurredContenWithCta>
				</Box>
			</Box>

			{isOpen && <SignupForFreeModal isOpen={isOpen} onClose={onClose} onOpen={onOpen} />}
		</>
	)
}
const BlurredContenWithCta = ({ onOpen, children }) => {
	return (
		<Box position="relative" width="100%">
			{/* Children Content */}
			{children}

			{/* Blurred Overlay */}
			<Box
				position="absolute"
				top="0"
				left="0"
				width="100%"
				height="100%"
				backgroundColor="rgba(255, 255, 255, 0.8)"
				backdropFilter="blur(3px)"
				zIndex="1"
			/>

			{/* Overlay Content in a Centered Card */}
			<Flex
				position="absolute"
				top="50%"
				left="50%"
				transform="translate(-50%, -50%)" // Center the card
				zIndex="2"
				alignItems="center"
				justifyContent="center"
			>
				<Box onClick={onOpen} cursor="pointer">
					<VStack
						spacing={4}
						p={5}
						backgroundColor="white"
						borderRadius="md"
						border="1px"
						borderColor="gray.600"
						boxShadow="md"
						width="400px"
						alignItems="center"
					>
						<Text fontSize="xl" fontWeight="bold" color={"accent"} align={"center"}>
							Want to learn more about this business listing?
						</Text>
						<AiOutlineUnlock size="2em" color={"accent"}/>
						<Text>Sign up for a free account</Text>
					</VStack>
				</Box>
			</Flex>
		</Box>
	)
}