import {
  Avatar,
  Box,
  Center,
  Circle,
  Container,
  HStack,
  Icon,
  Link,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Tooltip,
  Tr,
  useBreakpointValue,
  useDisclosure,
  Wrap,
} from "@chakra-ui/react";
import {NavLink as ReactRouterLink, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useGetDealsForUserQuery} from "../../services/dealsApi";
import {Pagination} from "../../components/Pagination";
import {FiMapPin} from "react-icons/fi";
import numbro from "numbro";
import TableHeaders, {buildHeaderAttributes, SortDropdown} from "./my-deals-table/table-headers";
import {BookmarkButton} from "../../components/deal/bookmark-button";
import {kumoDayJs} from "../../utils/dayjs";
import {DealDrawer} from "../../components/deal/deal-drawer";
import {MobileMyDealCards} from "./mobile-my-deal-cards";
import {IoBookmarkOutline} from "react-icons/io5";
import {AiOutlineHistory} from "react-icons/ai";
import {MdChangeHistory} from "react-icons/md";

const defaultSortFields = {
  "user_history" : "deal_read_associations.created_at",
  "user_favorites" : "favorites.created_at",
  "deal_pipeline" : "deal_flow_prospects.created_at",
}

export const MyDealsTable = ({ indexType }) => {
  const { subIndexType } = useParams();
  const [pageQuery, setPageQuery] = useState(1);
  const [sortOrder, setSortOrder] = useState("DESC");
  const [sortField, setSortField] = useState(defaultSortFields[indexType]);
  let queryHook;
  let queryOptions;

  queryHook = useGetDealsForUserQuery;
  queryOptions = {
    filter: "finished",
    page: pageQuery,
    indexType: indexType,
    dealFlowStageId: subIndexType,
    sortField: sortField,
    sortOrder: sortOrder,
  };

  const { data, isLoading, isFetching, isError } = queryHook(queryOptions);

  const deals = data?.data;
  const meta = data?.meta;

  const setPageAndScrollToTop = (e) => {
    setPageQuery(e.page);
    const element = document.getElementById("top-of-page");
    if (element) {
      element.scrollIntoView({ block: "start", inline: "nearest", behavior: "smooth" });
    }
  };


  const handleSort = (newSort) => {
    setSortField(newSort.sortField);
    setSortOrder(newSort.sortOrder);
  };

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [modalDealId, setModalDealId] = useState(null)
  const handleDealClick = (dealId) => {
    onOpen()
    setModalDealId(dealId)
  }

  const temporaryDeal = deals?.find((deal) => deal.id === modalDealId)

  useEffect(() => {
    const currentDealIndex = deals?.findIndex((deal) => deal.id === modalDealId);

    if (currentDealIndex > 0) {
      const prevDealId = deals[currentDealIndex - 1].id;
      const element = document.getElementById(`deal-${prevDealId}`);
      if (element) {
        element.scrollIntoView({ block: "start", inline: "nearest", behavior: "smooth" });

        // Use a timeout to wait for the scrolling animation to start
        setTimeout(() => {
          // Adjust this if the scroll container is not the body
          // Using `document.documentElement` for scrolling the entire page, modify if you have a specific scroll container
          document.getElementById("top-of-page").scrollTo({
            top: 100,
            behavior: "smooth",
          });
        }, 1000);
      }
    }
  }, [modalDealId]);


  const DealsTable = ({handleDealClick}) => {
    return (
      <Table size={{base: "sm", "2xl": "md"}}>
        <TableHeaders indexType={indexType} queryOptions={queryOptions} handleSort={handleSort} />
        <Tbody>
          {!(isLoading || isFetching) && deals && deals.map((deal) => <TableRow handleDealClick={handleDealClick} indexType={indexType} deal={deal} key={deal.id} />)}
          {(isLoading || isFetching) &&
            Array.from({ length: 50 }).map((_, index) => (
              <LoadingTableRow key={index} indexType={indexType} />
            ))}
        </Tbody>
      </Table>
    );
  };

  const isMobile = useBreakpointValue({ base: true, xl: false });

  if (deals && deals.length === 0) {
    return (
      <EmptyDealsTable indexType={indexType} />
    )
  }

  if (isMobile) {
    return (
      <Box bg="bg.surface">
        <Box display={{ base: "block", xl: "none" }}
             mb={"20px"}
             w="full"
        >
          <HStack justifyContent={"flex-end"}>
            <Text flexShrink={0} color="gray.500" fontSize="sm">
              Sort by
            </Text>
            <Box maxW={"xs"}>
              <SortDropdown handleSort={handleSort} queryOptions={queryOptions} />
            </Box>
          </HStack>
        </Box>

        {modalDealId && <DealDrawer deals={deals} dealId={modalDealId} temporaryDeal={temporaryDeal}
                                    setModalDealId={setModalDealId}
                                    isOpen={isOpen} onOpen={onOpen} onClose={onClose} />}

        <MobileMyDealCards handleDealClick={handleDealClick} deals={deals} isLoading={isLoading} isFetching={isFetching} queryOptions={queryOptions} handleSort={handleSort} />
        <Container
          py={{
            base: "4",
            md: "6",
          }}>
          {meta && (
            <Pagination
              count={meta?.total_pages}
              pageSize={1}
              siblingCount={2}
              page={pageQuery}
              onChange={(e) => setPageAndScrollToTop(e)}
            />
          )}
        </Container>
      </Box>
    );

  } else {
    return (
      <Box bg="bg.surface">
        <Box display={{ base: "block", lg: "none" }}
             mb={"20px"}
             mt={"5px"}
             w="full"
        >
          <HStack justifyContent={"flex-end"}>
            <Text flexShrink={0} color="gray.500" fontSize="sm">
              Sort by
            </Text>
            <Box maxW={"xs"}>
              <SortDropdown handleSort={handleSort} indexType={indexType} queryOptions={queryOptions} />
            </Box>
          </HStack>
        </Box>

        <Box bg="bg.surface"
             borderWidth="1px">
          <Box overflowX="auto" >
            <DealsTable handleDealClick={handleDealClick} handleSort={handleSort}/>
          </Box>
          {modalDealId && <DealDrawer deals={deals} dealId={modalDealId} temporaryDeal={temporaryDeal}
                                      setModalDealId={setModalDealId}
                                      isOpen={isOpen} onOpen={onOpen} onClose={onClose} />}
          <Container
            py={{
              base: "4",
              md: "6",
            }}>
            {meta && (
              <Pagination
                count={meta?.total_pages}
                pageSize={1}
                siblingCount={2}
                page={pageQuery}
                onChange={(e) => setPageAndScrollToTop(e)}
              />
            )}
          </Container>
        </Box>

      </Box>
    );
  }


};


export const TableRow = ({ handleDealClick, indexType, deal }) => {
  const dealAttributes = deal.attributes;

  const {first_read_at, favorited_at, added_to_deal_pipeline_at} = dealAttributes;
  const hasListingHistoryEvents = dealAttributes?.total_qual_event_count > 0 || dealAttributes?.total_quant_event_count > 0;

  const AdditionalColumns = () => {
    switch (indexType) {
      case 'user_history':
        return (
          <Td>
            {first_read_at ? `${kumoDayJs(first_read_at).fromNow()}` : `Not available`}
          </Td>
        )
      case 'user_favorites':
        return (
          <Td>
            {favorited_at ? `${kumoDayJs(favorited_at).fromNow()}` : `Not available`}
          </Td>
        )
      case 'deal_pipeline':
        return (
          <Td>
            {added_to_deal_pipeline_at ? `${kumoDayJs(added_to_deal_pipeline_at).fromNow()}` : `Not available`}
          </Td>
        )
      default:
        break;
    }
  }

  return (
    <Tr key={deal.id}
        id={`deal-${deal.id}`}
    >
      <Td>
        <Box
          maxWidth={
            {base: ['450px', 'max(450px, 40vw)'], "xl": ['400px', 'max(400px, 30vw)'], "2xl": ['300px', 'max(300px, 30vw)']}
          }
          isTruncated
          py={{xl: "6px", "2xl": "0px"}}
        >
          <HStack spacing="2"
                  onClick={e => {
                    handleDealClick(deal.id)
                  }}
                  align={"top"}
                  cursor={'pointer'}
          >
            <Box pr={"2"} display={
              {base: "block", "2xl": "block"}
            }>
              <Avatar
                cursor="pointer"
                height={"40px"}
                width={"40px"}
                src={dealAttributes.thumbnail_photo_url}
                name={dealAttributes.title || ""}
                borderRadius="md" // Rounded square shape
              />
            </Box>

            <Box>
              <Box pb={{xl: "6px", "2xl": "3px"}}>
                <Text fontWeight="medium" isTruncated>
                  {dealAttributes.title}
                </Text>
              </Box>
              <HStack spacing={6}>
                {dealAttributes.location && dealAttributes.location.length > 0 ? (
                  <Wrap>
                    <HStack spacing={"1"}>
                      <Icon as={FiMapPin} color="gray.500" />
                      <Text fontSize="xs" color="fg.muted">
                        {dealAttributes.location}
                      </Text>
                    </HStack>
                  </Wrap>
                ) : (
                  <Wrap>
                    <HStack spacing={"1"}>
                      <Icon as={FiMapPin} color="gray.500" />
                      <Text fontSize="xs" color="fg.muted">
                        No location available
                      </Text>
                    </HStack>
                  </Wrap>
                )}

                {hasListingHistoryEvents && (
                  <Tooltip label={'A listing on this deal was updated or changed'}>
                    <Box color="gray.500" borderBottom={"1px dashed"} >
                      <HStack spacing={"1"}>
                        <Icon as={MdChangeHistory} boxSize="1.25em" />
                        <Text fontSize="xs" color="fg.muted">History</Text>
                      </HStack>
                    </Box>
                  </Tooltip>
                )}
              </HStack>

              <Box mt={"2"}>
                <Text fontSize={"sm"} color="fg.muted" isTruncated noOfLines={1} whiteSpace="normal">
                  {dealAttributes.business_summary}
                </Text>
              </Box>

            </Box>
          </HStack>
          {/*<Box display={{base: "block", "2xl": "none"}}>*/}
          {/*  <Box py={"2"} width={"100%"} mt={"2"}>*/}
          {/*    <KeyNumbersComponent deal={deal} />*/}
          {/*  </Box>*/}
          {/*</Box>*/}

        </Box>

      </Td>
      {<AdditionalColumns />}
      {
        ['asking_price_to_display', 'annual_revenue_to_display', 'ebitda_or_sde_to_display'].map((attr, index) => (
          <Td key={index}
              display={{base: "table-cell", "2xl": "table-cell"}}
          >
            {dealAttributes[attr] ? (
              <Text>
                ${numbro(dealAttributes[attr]).format( {
                average: true,
                totalLength: 2,
              }).toUpperCase()}
              </Text>
            ) : (
              <Tooltip label={'When this # is unavailable in the listing, KUMO uses an estimated number to sort'}>
                <Text color="fg.muted" textDecoration="dotted">
                  N/A
                </Text>
              </Tooltip>
            )}
          </Td>
        ))
      }
      <Td>
        <Text fontSize="xs" color="muted">
          {dealAttributes.added_to_kumo_at ? `${kumoDayJs(dealAttributes.added_to_kumo_at).fromNow()}` : "--"}
        </Text>
      </Td>
      <Td>
        <HStack spacing="1">
          <BookmarkButton deal={deal} />
        </HStack>
      </Td>
    </Tr>
  );
};

export const LoadingTableRow = ({ indexType }) => {
  const headers = buildHeaderAttributes({ indexType });
  return (
    <Tr>
      <Td colSpan={headers.length}>
        <Skeleton height={"40px"} />
      </Td>
    </Tr>
  );
};

export const EmptyDealsTable = ({ indexType }) => {

  const NoHistory = () => {
    return (
      <>
        <Circle
          bg="bg.accent.default"
          color="fg.default"
          size="10" // Reduced size
          outline="4px solid" // Reduced outline thickness
          outlineColor="fg.accent.subtle"
          ml={{base: "2", lg: "4"}}
        >
          <Icon as={AiOutlineHistory} color="fg.inverted" />
        </Circle>
        <Text fontSize={{base: "md", lg: "xl"}} color="fg.muted" fontWeight="medium">
          You haven't viewed any deals yet. <br/> Start <Link><ReactRouterLink to={"/search"}>browsing deals</ReactRouterLink></Link> and deals you've viewed will show in this page.
        </Text>
      </>
    )
  }

  const NoFavorites = () => {
    return (
      <>
        <Circle
          bg="bg.accent.default"
          color="fg.default"
          size="10" // Reduced size
          outline="4px solid" // Reduced outline thickness
          outlineColor="fg.accent.subtle"
          ml={{base: "2", lg: "4"}}
        >
          <Icon as={IoBookmarkOutline} color="fg.inverted" />
        </Circle>
        <Text fontSize={{base: "md", lg: "xl"}} color="fg.muted" fontWeight="medium">
          You don't have any favorites yet. <br/> Start <Link><ReactRouterLink to={"/search"}>browsing deals</ReactRouterLink></Link> and click &nbsp;
          <span style={{ display: 'inline-block' }}><IoBookmarkOutline /></span>&nbsp; to add it to your favorites.
        </Text>
      </>
    )
  }


  return (
    <Box mt="6" borderWidth="2px" px={{base: "5", lg: "20"}} py={{base: "5", lg: "10"}} minH="80vh" rounded="xl" borderStyle="dashed"
         position="relative"
    >
      <Box mx="auto" overflowY="auto" pt={{base: "5", lg: "10"}} pb={{base: "5", lg: "10"}} w={{base: "95%", lg: "lg"}}>
        <Center gap="6">
          {indexType === 'user_history' && <NoHistory />}
          {indexType === 'user_favorites' && <NoFavorites />}
        </Center>
      </Box>
    </Box>
  )
}
