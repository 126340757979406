import {
	Box,
	Button,
	Divider,
	Flex,
	HStack,
	Icon,
	Link as ChakraLink,
	MenuItem,
	Stack,
	Text,
	useBreakpointValue,
	useColorModeValue as mode,
	useDisclosure,
} from '@chakra-ui/react'
import {FiArrowUpRight, FiBarChart, FiHelpCircle, FiLogOut, FiX,} from 'react-icons/fi'
import React from "react";
import {NavLink as RouterNavLink, useLocation, useNavigate} from 'react-router-dom';
import {MdFavoriteBorder, MdLockOutline, MdOutlinePriceChange, MdSavedSearch, MdSearch} from "react-icons/md";
import {useQuery} from "@apollo/client";
import {CURRENT_USER_QUERY} from "../../../graphql/current-user";
// import {NavButton} from "./navbar/NavButton";
import {signoutUser} from "../../../api/signout-user";
import {AuthContext} from "../../../auth/auth-context";
import {LogoImage} from "../../../components/LogoImage";
import {AiOutlineHistory} from "react-icons/ai";
import {PiKanbanLight} from "react-icons/pi";
import {ColumnIconButton} from "./Column";
import {useGetSavedSearchesForUserQuery} from "../../../services/savedSearchesApi";
import {TbListSearch} from "react-icons/tb";
import {UserPopoverButton} from "./UserPopoverButton";
import {UserDownloadsButton} from "./UserDownloadsButton";
import {CollapsableButtonGroup} from "./CollapsableButtonGroup";
import {SwitchToPaidModal} from "../../../components/upgrade-ctas/switch-to-paid-modal";
import {IoHelpBuoyOutline} from "react-icons/io5";
import {FaRegRectangleList} from "react-icons/fa6";
import {skipToken} from '@reduxjs/toolkit/query/react';
import {SignupForFreeModal} from "../../../components/upgrade-ctas/signup-for-free-modal";

const showNewFeatures = process.env.REACT_APP_SHOW_NEW_FEATURES === "true" || false;

export const Navbar = (props) => {
	const {onClose, isLoading, isError, ...otherProps} = props
	const { loading: userLoading, data: userData } = useQuery(CURRENT_USER_QUERY);
	const user = userData?.currentUser
	const subscriptionLevel = userData?.currentUser?.account?.subscriptionLevel || "Public";

	const isUltimate = ["Ultimate"].includes(subscriptionLevel);
	const isPro = ["Pro"].includes(subscriptionLevel);
	const isUltimateOrPro = subscriptionLevel === 'Ultimate' || subscriptionLevel === 'Pro';

	// Skip the query if the subscription level is not Ultimate or Pro
	const { data: savedSearchData, isLoading: savedSearchLoading, isError: savedSearchError } = useGetSavedSearchesForUserQuery(
		isUltimateOrPro ? undefined : skipToken
	);

	// Use the data only if the subscription level is Ultimate or Pro
	const searches = isUltimateOrPro ? savedSearchData?.data : [];

	const isMobile = useBreakpointValue({ base: true, lg: false });

	const navigate = useNavigate();
	const handleLoginRedirect = () => {
		// Get the current location
		const from = window.location.pathname;
		if (from === "/signup") {
			// If the signup form is not embedded in another page, no from to restart from
			navigate(`/signin`);
		} else {
			navigate(`/signin?from=${encodeURIComponent(from)}`);
		}
	};


	return (
		<Flex as="nav" height="full" direction="column" justify="space-between" {...otherProps}
					px={'15px'} py={'15px'}
					overflowY="auto"
		>
			<Stack
				spacing={{
					base: '5',
					sm: '6',
				}}
				shouldWrapChildren
			>
				<HStack spacing="3">
					<ColumnIconButton
						onClick={props.onClose}
						aria-label="Close navigation"
						icon={<FiX />}
						display={{ base: 'inline-flex', lg: 'none' }}
					/>
					<RouterNavLink to={"/"}>
						<LogoImage maxWidth={"90px"} />
					</RouterNavLink>
				</HStack>

				<Stack spacing="1">
					<NavButton alternateTo={"/"} to="/deal-feeds" label="Explore Deals" icon={FaRegRectangleList} loginNotRequired></NavButton>
					<NavButton to="/search" label="Search Deals" exact={"true"} icon={MdSearch}></NavButton>
					<NavButton to="/my-deals/user_history" label="History" icon={AiOutlineHistory} subscriptionTiers={["Pro", "Ultimate"]}></NavButton>
					<NavButton to="/my-deals/user_favorites" label="Favorites" icon={MdFavoriteBorder} subscriptionTiers={["Pro", "Ultimate"]}></NavButton>
					{showNewFeatures && <NavButton to="/deal-pipeline" label="Deal Pipeline" icon={PiKanbanLight}></NavButton>}
					<CollapsableButtonGroup routes={["/analytics", "/analytics/industries", "/analytics/locations"] } icon={FiBarChart} label={"Analytics"}>
						<Stack spacing="1" alignItems="stretch" ps={"5"} py="1">
							<NavButton to="/analytics/" label="Overview" exact={"true"} collapsable={true} subscriptionTiers={["Ultimate"]}></NavButton>
							<NavButton to="/analytics/industries" label="By Industry" exact={"true"} collapsable={true} subscriptionTiers={["Ultimate"]}></NavButton>
							<NavButton to="/analytics/locations" label="By Location" exact={"true"} collapsable={true} subscriptionTiers={["Ultimate"]}></NavButton>

							{/*{['Resumes', 'Cover Letter', 'Personal', 'Education'].map((item) => (*/}
							{/*	<Button key={item} variant="tertiary" justifyContent="start">*/}
							{/*		{item}*/}
							{/*	</Button>*/}
							{/*))}*/}
						</Stack>
					</CollapsableButtonGroup>
					{subscriptionLevel !== 'Public' && <UserDownloadsButton />}
					{isMobile && <NavButton to="/saved-searches" label={`Manage Saved Searches ${(searches && searches.length > 0 && ` (${searches.length})` || "")}`} icon={MdSavedSearch} subscriptionTiers={["Pro", "Ultimate"]}></NavButton>}
				</Stack>
				{!isMobile && <Stack>
					<Text textStyle="sm" color="fg.subtle" fontWeight="medium">
						Saved Searches
					</Text>
					<Stack spacing="1">
						{(isPro || isUltimate) && savedSearchData && searches && searches.slice(0, 5).map((search) => {
							return (
								<NavButton key={search.id} to={`/search/saved/${search.id}`} label={search.attributes.title} icon={MdSavedSearch}></NavButton>
							)
						})}
						<NavButton to="/saved-searches" label={`Manage Searches ${(searches && searches.length > 0 && ` (${searches.length})` || "")}`} icon={TbListSearch} subscriptionTiers={["Pro", "Ultimate"]}></NavButton>
					</Stack>
				</Stack>}
			</Stack>
			<Stack
				spacing={{
					base: '2',
					sm: '1',
				}}
			>
				{subscriptionLevel === 'Public' && <ExternalNavLink to="https://www.withkumo.com/pricing" icon={MdOutlinePriceChange} label="Pricing" />}
				{!(isPro || isUltimate) && <ExternalNavLink to="https://www.withkumo.com/faq" icon={FiHelpCircle} label="FAQ" />}

				<HelpButton email={user?.email} />
				<Divider mt={"3"}/>
				{user ? <UserPopoverButton />: <Button onClick={handleLoginRedirect} my={"7"}>Login</Button>}
			</Stack>
		</Flex>
	)
}
const HelpButton = (props) => {
	const { email, ...buttonProps } = props

	const toggleFreshdesk = () => {
		if (typeof window.FreshworksWidget === 'function') {
			window.FreshworksWidget('boot');
			window.FreshworksWidget('open');
			if (email) {
				window.FreshworksWidget("identify", "ticketForm", {
					email,
				});
				// eslint-disable-next-line no-undef
				window.FreshworksWidget("disable", "ticketForm", ["email"]);
			}
			// const isOpen = window.FreshworksWidget && window.FreshworksWidget('widgetRenderComplete');
			// if (isOpen) {
			// 	window.FreshworksWidget('hide');
			// } else {
			// 	window.FreshworksWidget('open');
			//
			// }
		}
	}

	return (
		<NavButton to="#" exact="true" icon={IoHelpBuoyOutline} label={"Help Center"} onClick={ () => toggleFreshdesk()}{...buttonProps} loginNotRequired></NavButton>
	);
}

const IconButton = ({ showActive, collapsable, iconOverride, label, icon, ...buttonProps}) => {
	return (
		<Button variant="ghost"
						color={showActive ? 'on-accent' : 'gray.700'}
						width="100%"
						pr={"2"}
						pl={collapsable ? "5" : "2"}
						py={"2"}
						bg={showActive ? 'gray.100' : ''}
						disabled
						isTruncated
						position={"relative"}
						justifyContent="start" {...buttonProps} aria-current={(showActive) ? 'page' : undefined}>
			{collapsable && !iconOverride &&
				<Text>{label}</Text>
			}
			{(!collapsable || iconOverride) &&
				<HStack spacing="3">
					<Icon as={iconOverride ? iconOverride : icon} boxSize="5" color="subtle" />
					<Text>{label}</Text>
				</HStack>
			}
		</Button>
	)
}

export const ExternalNavLink = (props) => {
	const { to, exact, icon, label, ...buttonProps } = props
	return (
		<ChakraLink href={to} isExternal
								sx={{
									// Your custom styles here
									_hover: {
										textDecoration: 'none', // Removes underline on hover
									}
								}}
		>
			<HStack justify="space-between">
				<IconButton icon={icon} label={label} {...buttonProps} />
				<Icon as={FiArrowUpRight} boxSize="4" color={mode('gray.600', 'gray.400')} />
			</HStack>
		</ChakraLink>
	)
}



export const NavButton = (props) => {
	const { to, alternateTo, exact, icon, label, collapsable, subscriptionTiers, loginNotRequired, ...buttonProps } = props
	const { isOpen, onOpen, onClose } = useDisclosure()
	const { loading: userLoading, data: userData } = useQuery(CURRENT_USER_QUERY);
	const subscriptionLevel = userData?.currentUser?.account?.subscriptionLevel || "Public";

	const isUltimate = ["Ultimate"].includes(subscriptionLevel);
	const isPaid = ["Pro", "Ultimate"].includes(subscriptionLevel);

	if (!loginNotRequired && subscriptionLevel === "Public") {
		return(
			<>
				<IconButton
					showActive={false} collapsable={collapsable}
					opacity={0.65}
					iconOverride={MdLockOutline}
					icon={icon}
					label={label}
					onClick={onOpen}
					{...buttonProps} />
				{isOpen && <SignupForFreeModal isOpen={isOpen} onClose={onClose}/>}
			</>
		)
	}

	if (subscriptionTiers && subscriptionTiers.length > 0) {
		if (subscriptionTiers.includes("Pro") && !isPaid) {
			return(
				<>
					<IconButton
						showActive={false} collapsable={collapsable}
						opacity={0.65}
						iconOverride={MdLockOutline}
						icon={icon}
						label={label}
						onClick={onOpen}
						{...buttonProps} />
					{isOpen && <SwitchToPaidModal
						isOpen={isOpen} onClose={onClose} plan={"Pro"}/>}
				</>
			)
		} else if (subscriptionTiers.includes("Ultimate") && !subscriptionTiers.includes("Pro") && !isUltimate) {
			return(
				<>
					<IconButton
						showActive={false} collapsable={collapsable}
						opacity={0.65}
						iconOverride={MdLockOutline}
						icon={icon}
						label={label}
						onClick={onOpen}
						{...buttonProps} />
					{isOpen && <SwitchToPaidModal
						isOpen={isOpen} onClose={onClose} plan={"Ultimate"}/>}
				</>
			)
		}
	}

	const location = useLocation();
	return (
		<RouterNavLink to={to} exact={exact}>
			{(props) => {
				const { isActive } = props;
				const selectedValue = isActive
				const pathIncludes = location.pathname.toString().includes(to)
				const showActive = exact ? (location.pathname.toString() === to) : (selectedValue || pathIncludes || location.pathname.toString() === alternateTo)
				return (
					<IconButton showActive={showActive} icon={icon} label={label} collapsable={collapsable} {...buttonProps} />
				)
			}}
		</RouterNavLink>
	)
}

export const SectionLink = (props) => {
	const { icon, setShowNav, ...linkProps } = props
	return (
		<ChakraLink
			as={RouterNavLink}
			px="2"
			py="1.5"
			borderRadius="md"
			_hover={{
				bg: mode('gray.100', 'gray.700'),
			}}
			_activeLink={{
				bg: 'brand.300',
				color: 'white',
			}}
			onClick={() => setShowNav(false) }
			{...linkProps}
		>
			<HStack justify="space-between">
				<HStack spacing="1">
					{/*<Icon as={icon} />*/}
					<Box width="39px" textAlign={"center"} pr={"8px"}>
						<Text fontSize={"23px"}>{props.number}</Text>
					</Box>
					<Box flex={"1"}>
						<Text fontSize="sm" noOfLines={2} css={{ display: '-webkit-box', overflow: 'hidden', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }}>
							{props.children}
						</Text>
					</Box>
				</HStack>
				{props.isExternal && (
					<Icon as={FiArrowUpRight} boxSize="4" color={mode('gray.600', 'gray.400')} />
				)}
			</HStack>
		</ChakraLink>
	)
}

export const NavLink = (props) => {
	const { icon, ...linkProps } = props
	return (
		<ChakraLink
			p={"1"}
			borderRadius="md"
			_hover={{
				bg: mode('gray.100', 'gray.700'),
			}}
			_activeLink={{
				bg: 'gray.700',
				color: 'white',
			}}
			{...linkProps}
		>
			<HStack justify="space-between">
				<HStack spacing="1">
					{/*<Icon as={icon} />*/}
					<Box width="30px">
						<Text fontSize={"23px"}>{props.number}</Text>
					</Box>
					<Box flex={"1"}>
						<Text fontSize="xs" noOfLines={2} css={{ display: '-webkit-box', overflow: 'hidden', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }}>
							{props.children}
						</Text>
					</Box>
				</HStack>
				{props.isExternal && (
					<Icon as={FiArrowUpRight} boxSize="4" color={mode('gray.600', 'gray.400')} />
				)}
			</HStack>
		</ChakraLink>
	)
}


export const NavHeading = (props) => (
	<Text
		as="h4"
		fontSize="xs"
		fontWeight="semibold"
		px="2"
		lineHeight="1.25"
		color={mode('gray.600', 'gray.400')}
		{...props}
	/>
)


export const LogoutButton = ({ buttonType, ...otherProps }) => {
	const navigate = useNavigate();
	const { setAuthenticated } = React.useContext(AuthContext);

	const logout = async () => {
		// onClose();
		await signoutUser();
		setAuthenticated(false);
		window.location.reload();
	}

	if (buttonType === 'NavButton') {
		return(
			<Button variant="ghost"
							width="100%"
							color={'gray.700'}
							p={"2"}
							justifyContent="start" {...otherProps}
							onClick={logout}
			>
				<HStack spacing="3">
					<Icon as={FiLogOut} boxSize="5" color="subtle" />
					<Text>Logout</Text>
				</HStack>
			</Button>
		)
	} else {
		return(
			<MenuItem icon={<FiLogOut />} onClick={logout}>
				Logout
			</MenuItem>
		)
	}

}
