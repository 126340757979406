import {Avatar, Box, Flex, HStack, Icon, Link, SkeletonText, Stack, Text, useColorModeValue} from "@chakra-ui/react";
import {FiClock, FiMapPin} from "react-icons/fi";
import {kumoDayJs} from "../../utils/dayjs";
import {BookmarkButton} from "../../components/deal/bookmark-button";
import {KeyNumbersComponent} from "../deal/sections/deal-header";
import {AiOutlineEye} from "react-icons/ai";
import {NotesButton} from "../../components/deal/notes-button";
import * as React from "react";


export const MobileMyDealCards = ({
                                    deals = [],
                                    handleDealClick, isLoading, isFetching,
                                    ...otherProps // Ensure otherProps are actually needed, otherwise remove this
                                  }) => {


  if (isLoading || isFetching) {
    return (
      <>
        {
          Array.from({ length: 30 }).map((_, index) => (
            <LoadingSearchCard key={index} />
          ))
        }
      </>
    )

  } else {
    return (
      <>
        {deals.map((deal) => {
          if (!deal?.attributes) {
            return null; // Return null if deal or deal attributes are not defined
          }

          return (
            <MobileMyDealCard
              key={deal.id}
              deal={deal}
              handleDealClick={handleDealClick}
              {...otherProps} // Pass otherProps to the card
            />
          )
        })}
      </>
    );
  }

};


const MobileMyDealCard = ({deal, handleDealClick}) => {
  const {
    id,
    attributes: {
      title,
      location,
      added_to_kumo_at,
      business_summary,
      thumbnail_photo_url,
      first_read_at,
      ...dealAttributes // Only destructure what you need
    },
  } = deal;

  return (
    <Box
      bg="bg-surface"
      py="4"
      boxShadow={useColorModeValue("sm", "sm-dark")}
      borderRadius="lg"
      border={"1px"}
      borderColor={"gray.100"}
      mb={4}
    >
      <Link
        textDecoration={"none"}
        _hover={{ textDecoration: "none", opacity: 0.8}}
        color={"gray.700"}
        onClick={(e) => {
          e.preventDefault(); // Prevent default to stop navigation
          handleDealClick(id);
        }}
        href={`/deal/${id}`} // Removed target="_blank" as it's not typical for mobile links
      >
        <Stack
          fontSize="sm"
          px="4"
          spacing="2"
        >
          <Flex direction={{ base: "row" }} align="top">
            <Avatar
              size="md"
              src={thumbnail_photo_url}
              name={title}
              borderRadius="md"
            />

            <Flex direction="column" flex="1" ml="3" overflow="hidden"> {/* Constrain the width */}
              <Box>
                <Text
                  fontSize="md"
                  fontWeight="medium"
                  color="gray.900"
                  sx={{
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                    overflow: "hidden",
                    display: "-webkit-box",
                  }}
                >
                  {title || ""}
                </Text>
              </Box>
              <HStack spacing={"4"} mt={"1"}>
                <HStack>
                  <Icon as={FiMapPin} color="gray.400" />
                  <Text fontSize="sm" color="muted">
                    {location || "Not Available"}
                  </Text>
                </HStack>
                <HStack>
                  <Icon as={FiClock} color="gray.400" />
                  <Text fontSize="sm">
                    {added_to_kumo_at
                      ? `Added ${kumoDayJs(added_to_kumo_at).fromNow()}`
                      : "--"}
                  </Text>
                </HStack>

              </HStack>
            </Flex>
          </Flex>

          <Box py={"2"}>
            <Text
              color="muted"
              fontSize="sm"
              sx={{
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                overflow: "hidden",
                display: "-webkit-box",
              }}
            >
              {business_summary}
            </Text>
          </Box>

          <Box py={"2"}>
            <KeyNumbersComponent deal={deal} />
          </Box>
        </Stack>

      </Link>
      <HStack px="4" justify={"space-between"}>
        <Stack spacing="1"
        >
          {
            first_read_at &&
            <HStack>
              <Icon as={AiOutlineEye} color="gray.400" />
              <Text fontSize="xs">
                Viewed {kumoDayJs(first_read_at).fromNow()}
              </Text>
            </HStack>

          }
        </Stack>
        <HStack>
          <BookmarkButton deal={deal} />
          <NotesButton deal={deal}/>
        </HStack>
      </HStack>
    </Box>
  );
}

const LoadingSearchCard = () => {
  return (
    <Box
      bg="bg-surface"
      py="4"
      px={"4"}
      boxShadow={useColorModeValue("sm", "sm-dark")}
      borderRadius="lg"
      mb={4}
    >
      <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
    </Box>

  )
}

