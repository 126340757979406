import * as React from "react";
import {useState} from "react";
import {
  Box,
  Button,
  HStack,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useColorModeValue
} from "@chakra-ui/react";
import {FiEdit, FiTrash} from "react-icons/fi";
import {kumoDayJs} from "../../utils/dayjs";
import {useUserActionOnDealMutation} from "../../services/dealsApi";
import {CiStickyNote} from "react-icons/ci";

export const NotesInterface = ({ deal }) => {
  const notes = deal?.attributes?.notes || [];
  const NotesSection = ({notes}) => {
    if (notes.length > 0) {
      return (
        <Stack spacing="5">
          {notes.map((note) => (
            <Note key={note.id} note={note} deal={deal}/>
          ))}
        </Stack>
      )
    } else {
      return (

        <Box py={"0px"}>
          <Text>Add notes here for your own future reference.</Text>
        </Box>
      )
    }
  }

  return (
    <>
      <CreateNoteForm deal={deal}/>
      <Box pt={"20px"}>
        <NotesSection notes={notes}/>
      </Box>
    </>
  )
}

const CreateNoteForm = ({deal}) => {
  const [content, setContent] = useState("");
  const [runUserActionOnDealMutation, { isLoading, isError }] = useUserActionOnDealMutation();


  const submitNote = async () => {
    let mutationOptions;
    mutationOptions = {
      dealId: deal.id,
      secondaryId: null,
      actionOnDeal: "create_note",
      content: content
    };
    await runUserActionOnDealMutation(mutationOptions);
    if (!isError) {
      setContent('');
    }
  }

  return (
    <Box as="form" pos="relative" pb="1">
      <Input
        name="message"
        placeholder="Leave a note (only visible to you)"
        maxHeight="200px"
        paddingEnd="9"
        resize="none"
        rows={2}
        value={content}
        _placeholder={{ color: 'fg.subtle' }}
        onInput={(event) => {
          // This code works if you want to make it a Textarea
          // const textarea = event.currentTarget
          // textarea.style.height = 'auto'
          // const borderHeight = textarea.offsetHeight - textarea.clientHeight
          // textarea.style.height = textarea.scrollHeight + borderHeight + 'px'
          setContent(event.currentTarget.value)
        }}
      />
      <Box pos="absolute" top="3" right="0" zIndex="2">
        <Button size="sm" type="submit" variant="text" colorScheme="gray" onClick={submitNote}
                isLoading={isLoading} isDisabled={isLoading || content.length === 0}
        >
          <CiStickyNote />
        </Button>
      </Box>
    </Box>

    // <HStack justifyContent="flex-end">
    //   <Input placeholder="Leave a note (only visible to you)" value={content} onChange={(ev) => setContent(ev.currentTarget.value)}
    //   />
    //   <Button onClick={submitNote} isLoading={isLoading} isDisabled={isLoading || content.length === 0}
    //   >Save Note</Button>
    // </HStack>
  )
}

export const Note = ({ deal, note }) => {
  const [internalNote, setInternalNote] = useState(note);
  const [isEditing, setIsEditing] = useState(false);

  const [runUserActionOnDealMutation, { isLoading, isError }] = useUserActionOnDealMutation();
  let mutationOptions;
  mutationOptions = {
    dealId: deal.id,
    secondaryId: note.id,
    actionOnDeal: "create_note",
    content: null
  };

  const handleNoteChange = (ev) => {
    const updatedNote = {
      ...internalNote,
      content: ev.currentTarget.value
    };
    setInternalNote(updatedNote);
  }
  const noteChanged = () => {
    return internalNote.content !== note.content;
  }

  const submitNoteChange = async () => {
    mutationOptions.content = internalNote.content;
    mutationOptions.actionOnDeal = "update_note";
    await runUserActionOnDealMutation(mutationOptions);
  }

  const deleteNote = async () => {
    mutationOptions.actionOnDeal = "destroy_note";
    await runUserActionOnDealMutation(mutationOptions);
  }

  if (isEditing) {
    return (
      <Stack>
        <HStack justifyContent="flex-end">
          <Input
            placeholder="Leave a note (only visible to you)"
            value={internalNote.content}
            onChange={handleNoteChange}
          />
          <Button
            onClick={() => {
              setInternalNote(note);
              setIsEditing(false);
            }}>
            Cancel
          </Button>
          <Button disabled={!noteChanged() || internalNote.content.length === 0} onClick={() => {submitNoteChange()}}>
            Save
          </Button>
        </HStack>
      </Stack>
    );
  }

  const noteBgColor = "yellow.100";
  const noteTextColor = useColorModeValue("gray.800", "white");
  const mutedTextColor = useColorModeValue("gray.500", "gray.400");

  return (
    <Stack key={note.id} fontSize="sm" spacing="2">
      <Box borderRadius="sm" p={3} borderWidth={"1px"} borderColor={"gray.200"} bg={noteBgColor}>
        <HStack justifyContent="space-between">
          <Text fontSize="sm" color={noteTextColor} >
            {note.content}
          </Text>
          <Popover>
            <PopoverTrigger>
              <Button size="xs" variant="ghost">...</Button>
            </PopoverTrigger>
            <PopoverContent maxWidth="fit-content">
              <PopoverBody>
                <Box display={"inline-block"}>
                  <Button onClick={() => setIsEditing(true)} size="xs" leftIcon={<FiEdit />} variant="ghost">Edit</Button>
                  <Button onClick={() => deleteNote()} size="xs" leftIcon={<FiTrash />} variant="ghost">Delete</Button>
                </Box>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </HStack>
      </Box>

      <Text color={mutedTextColor} fontSize="xs">
        Updated {kumoDayJs(note.updated_at).fromNow()}
      </Text>
    </Stack>
  );
}