import { gql } from "@apollo/client";

export const PROSPECT_FRAGMENT = gql`
  fragment prospect on Prospect {
    id
    deal {
      id
      formattedTitle
      sortableAskingPrice
      sortableAnnualRevenue
      tags
      noteThread {
        mostRecentNoteCreatedAt
        notes {
          content
        }
      }
    }
    order
    updatedAt
    createdAt
  }
`;
