import {Avatar, Box, Card, HStack, Icon, Link, Skeleton, Stack, Text, useDisclosure} from "@chakra-ui/react";
import * as React from "react";
import {kumoDayJs} from "../../../utils/dayjs";
import {HorizontalScrollBox} from "../../../components/HorizontalScrollBox";
import {ListingOpener} from "../../../components/deal/listing-opener";
import {useQuery} from "@apollo/client";
import {CURRENT_USER_QUERY} from "../../../graphql/current-user";
import {SwitchToPaidModal} from "../../../components/upgrade-ctas/switch-to-paid-modal";
import {MdLockOutline} from "react-icons/md";
import {SignupForFreeModal} from "../../../components/upgrade-ctas/signup-for-free-modal";

export const Listings = ({ deal }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { loading: userLoading, data: userData } = useQuery(CURRENT_USER_QUERY);
  const subscriptionLevel = userData?.currentUser?.account?.subscriptionLevel || "Public"
  const isPaid = ["Pro", "Ultimate"].includes(subscriptionLevel);

  if (deal) {
    const { listing_associations } = deal?.attributes || {};

    if (isPaid) {
      return (
        <>
          <Box>
            {listing_associations?.length > 0 && <HorizontalScrollBox>
              {listing_associations.map((listingAssociation, index) => (
                <ListingLink key={index} listingAssociation={listingAssociation} />
              ))}
            </HorizontalScrollBox>}
          </Box>
        </>
      );
    } else {
      return (
        <>
          <Box>
            <HorizontalScrollBox>
              <ListingCard
                primaryString={"View this business"}
                secondaryString={"See original listing"}
                onClick={onOpen}
              />
            </HorizontalScrollBox>
          </Box>
          {isOpen && subscriptionLevel === "Public" && <SignupForFreeModal isOpen={isOpen} onClose={onClose} />}
          {isOpen && subscriptionLevel !== "Public" && <SwitchToPaidModal isOpen={isOpen} onClose={onClose} plan={"Pro"} />}
        </>
      );
    }

  } else {
    return <Box py={3}>
      <ListingsSkeleton />
    </Box>;
  }
};

const ListingLink = ({ listingAssociation }) => {
  return (
    <ListingOpener listingId={listingAssociation.listing_generated_uid}>
      <Link
        referrerPolicy="no-referrer"
        textDecoration="none"
        _hover={{ textDecoration: "none" }}
        target="_blank">
        <ListingCard
          primaryString={listingAssociation.listing_source_name}
          imageUrl={listingAssociation.listing_source_avatar_image_url}
          secondaryString={`Added ${kumoDayJs(listingAssociation.created_at).fromNow()}`}
        />
      </Link>
    </ListingOpener>
  );
};

const ListingCard = ({ imageUrl, primaryString, secondaryString, ...otherProps }) => {
  return (
    <Card p={"10px"} width={"225px"}
          my={"5px"}
          mr={"20px"}
          cursor={"pointer"}
          boxShadow='sm'
          _hover={{
            textDecoration: "none",
            transform: 'translateY(3px)', // Moves the card down by 2 pixels
            transition: 'transform 0.2s, box-shadow 0.2s' // Smooth transition for the effect
          }}
          {...otherProps}
    >
      <HStack alignItems="center">
        <Box mr={"1"}>
          <Box position="relative" display="inline-block">
            {imageUrl ? (
              <Avatar
                cursor="pointer"
                size="sm"
                src={imageUrl}
                name={primaryString}
                showBorder={true}
                borderWidth={"1px"}
                borderColor={"gray.200"}
              />
            ) : (
              <Box
                size="sm"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="full"
                borderWidth="1px"
                borderColor="gray.200"
                overflow="hidden"
                w={10} // width of the box containing the icon
                h={10} // height of the box containing the icon
                bg="gray.200" // background color of the box containing the icon
              >
                <Icon as={MdLockOutline} w={6} h={6} color="gray.500"/>
              </Box>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}>
          <Text
            fontSize={"sm"}
            fontWeight="medium"
            noOfLines={1}
            sx={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
              whiteSpace: "nowrap",
            }}>
            {primaryString}
          </Text>
          <Box mt={"2px"}>
            <HStack spacing={"1"} color={"gray.600"}>
              {/*<Icon as={FiClock} />*/}
              <Text
                fontSize="xs"
                fontWeight="medium"
                noOfLines={1}
                sx={{
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: "1",
                  overflow: "hidden",
                  display: "-webkit-box",
                }}>
                {secondaryString}
              </Text>
            </HStack>
          </Box>
        </Box>
      </HStack>
    </Card>
  )
}

const ListingsSkeleton = () => {
  return (
    <Box>
      <Stack>
        <Box>
          <HorizontalScrollBox>
            {Array.from({ length: 8 }).map((_, index) => (
              <Skeleton key={index} width="225px" height={"50px"} mr={"10px"} />
            ))}
          </HorizontalScrollBox>
        </Box>
      </Stack>
    </Box>
  );
};
