import MultiSelect from "@kenshooui/react-multi-select";
import * as React from "react";
import {useState} from "react";
import {Box, Center, Checkbox, Input, InputGroup, InputRightElement, Spinner} from "@chakra-ui/react";
// import "@kenshooui/react-multi-select/dist/style.css"
import {FiSearch, FiXCircle} from "react-icons/fi";

export const CheckboxSearchForm = ({isLoading, items, values, onChange, ...props}) => {
  const [searchValue, setSearchValue] = useState("");

  const filterFunction = value => item => {
    return (String(item.label).toLowerCase().includes(value.toLowerCase()) || String(item.group).toLowerCase().includes(value.toLowerCase()))
  }

  // const sortedItems = items.sort((a, b) => {
  //   // Compare by group first
  //   if (a.group < b.group) return -1;
  //   if (a.group > b.group) return 1;
  //
  //   // If the group is the same, compare by label
  //   if (a.label < b.label) return -1;
  //   if (a.label > b.label) return 1;
  //
  //   return 0; // return 0 if both are equal, though this case is likely rare or impossible due to unique IDs
  // }).filter(filterFunction(searchValue))

  // We use the sort it comes out of the API as...
  const sortedItems = items.filter(filterFunction(searchValue))

  const formatValuesAndChange = (selectedItems) => {
    const valuesToUse = [...new Set(selectedItems.map(item => item.id))];
    // setLocalValues(valuesToUse)
    onChange(valuesToUse)
    setSearchValue(searchValue)
  }

  const currentlySelectedItems = items.filter(item => values.includes(item.id))

  return (
    <>
      <Box>
        <CustomSearch searchValue={searchValue} setSearchValue={setSearchValue}/>
        <MultiSelect
          key={searchValue}
          showSelectAll={false}
          showSelectedItems={false}
          items={sortedItems}
          withGrouping={true}
          selectedItems={currentlySelectedItems}
          onChange={selected => {formatValuesAndChange(selected)}}
          loading={isLoading}
          responsiveHeight={"calc(100vh - 350px)"}
          /*Had to override search renderer to survive state changes*/
          searchValue={searchValue}
          onSearchChange={setSearchValue}
          // searchRenderer={(props) => <></>}
          showSearch={false}
          // Not really using filter function because we do it on the items level (just for redundancy)
          filterFunction={filterFunction}
          itemRenderer={(itemProps) => <CustomItemRenderer items={sortedItems}
                                                           selectedItems={currentlySelectedItems}
                                                           onChange={formatValuesAndChange}
                                                           {...itemProps} />}

          loaderRenderer={() => <Center w="100%">
            <Spinner />
          </Center>}
        />
      </Box>
    </>

  )
}

const CustomSearch = (props) => {

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      props.setSearchValue('');
    }
  }

  const ElementButton = ({searchValue}) => {
    if (searchValue.length > 0) {
      return (
        <InputRightElement pointerEvents="auto" color="gray.400" fontSize="lg" onClick={ () => props.setSearchValue("")} cursor="pointer">
          <FiXCircle />
        </InputRightElement>
      );
    } else {
      return (
        <InputRightElement pointerEvents="none" color="gray.400" fontSize="lg">
          <FiSearch />
        </InputRightElement>
      );
    }
  }

  return (
    <Box mb={"10px"}>
      <InputGroup size="md" pb="1">
        <Input
          // placeholder="Search..."
          value={props.searchValue}
          onChange={e => {props.setSearchValue(e.target.value)}}
          onKeyDown={handleKeyDown}
          rounded="md"
          focusBorderColor={'brand.500'}
        />
        <ElementButton searchValue={props.searchValue} />
      </InputGroup>
    </Box>
  );
}

const CustomItemRenderer = ({ item, selectedItems, onChange, items, ...props }) => {
  // You can put your custom logic and styles here
  if (item.isGroup) {
    return (
      <CustomGroupRenderer item={item} selectedItems={selectedItems} onChange={onChange} items={items} {...props} />
    )
  } else {
    return (
      <Box ml="25px" onClick={props.onClick}>
        <Checkbox key={item.id} isChecked={props.checked} >
          <span>{item.label}</span>
        </Checkbox>
      </Box>
    );
  }
};

const CustomGroupRenderer = ({item, selectedItems, onChange, items, ...props}) => {
  const itemsInGroup = items.filter(i => i.group === item.label);
  const areAllSelected = itemsInGroup.every(i => selectedItems.includes(i));

  const customClickBehavior = (e) => {
    if (areAllSelected) {
      onChange(selectedItems.filter(i => !itemsInGroup.includes(i)));
    } else {
      onChange([...selectedItems, ...itemsInGroup]);
    }
  };

  return (
    <Box onClick={e => {customClickBehavior(e)}}>
      <Checkbox key={item.id} isChecked={areAllSelected} >
        <span>{item.label}</span>
      </Checkbox>
    </Box>
  );
}