import {Box, Center, Circle, Grid, GridItem, HStack, Icon, Stack, Text, VStack} from '@chakra-ui/react';
import {IoSearch} from 'react-icons/io5';
import {suggestedSearches} from "../../../store/local-search";
import {NavLink as RouterNavLink, useNavigate} from "react-router-dom";
import {useGetSavedSearchesForUserQuery} from "../../../services/savedSearchesApi";
import React from "react";
import {kumoDayJs} from "../../../utils/dayjs";
import Xarrow from 'react-xarrows';
import {useQuery} from "@apollo/client";
import {CURRENT_USER_QUERY} from "../../../graphql/current-user";
import {skipToken} from "@reduxjs/toolkit/dist/query/react";


export const EmptySearchBox = () => {
  const { loading: userLoading, data: userData } = useQuery(CURRENT_USER_QUERY);
  const subscriptionLevel = userData?.currentUser?.account?.subscriptionLevel || "Public";
  const isFree = ["Free", "Public"].includes(subscriptionLevel);

  // Skip the query if the subscription level is not Ultimate or Pro
  const { data: savedSearchData, isLoading: savedSearchLoading, isFetching: savedSearchFetching, isError: savedSearchError } = useGetSavedSearchesForUserQuery(
    isFree ? skipToken : undefined
  );
  const searches = savedSearchData?.data

  return (
    // Position relative is needed for the Xarrow to work
    <Box mt="6" borderWidth="2px" px={{base: "5", lg: "20"}} py={{base: "5", lg: "10"}} minH="80vh" rounded="xl" borderStyle="dashed"
         position="relative"
    >
      <Box mx="auto" overflowY="auto" pt={{base: "5", lg: "10"}} pb={{base: "5", lg: "10"}} w={{base: "95%", lg: "lg"}}>
        <Center gap="6">
          <Circle
            bg="bg.accent.default"
            color="fg.default"
            size="10" // Reduced size
            outline="4px solid" // Reduced outline thickness
            outlineColor="fg.accent.subtle"
            ml={{base: "2", lg: "4"}}
          >
            <Icon as={IoSearch} color="fg.inverted" />
          </Circle>
          <Text fontSize={{base: "md", lg: "xl"}} color="fg.muted" fontWeight="medium">
            Start a search by {' '}
            <span style={{ display: 'inline-flex', alignItems: 'center', lineHeight: 'inherit' }} id={"deals_page_empty_cta"}>
              <Text fontWeight={"bold"}>adding filters</Text>
            </span>
            {' '} or exploring a suggested search below.
          </Text>
        </Center>
      </Box>

      <Xarrow
        start={'deals_page_empty_cta'}
        end={'filters-button'}
        startAnchor={"top"}
        endAnchor={"bottom"}
        lineColor={"lightgray"} // Set the color to gray
        headColor={"lightgray"} // Set the arrow head color to gray
        dashness={{ strokeLen: 10, nonStrokeLen: 5, animation: 1 }} // Make the line dotted
        strokeWidth={2} // Set a thinner line for less obtrusiveness
        // showHead={true} // Optionally, hide the arrow head
      />


      {/* Responsive Grid for Suggested and Saved Searches */}
      <Grid templateColumns={{ base: "repeat(1, 1fr)", xl: "repeat(2, 1fr)" }} gap={12} mt={"30px"}>
        {/* Suggested Searches */}
        <GridItem>
          <Text fontSize="md" fontWeight="semibold">Suggested Searches</Text>
          <VStack spacing={"4"} mt={"10px"}>
            {suggestedSearches.map((search, index) => (
              <SuggestedSearchButton key={index} searchId={search.id} name={search.name} description={search.description} />
            ))
            }
          </VStack>

        </GridItem>

        {/* Saved Searches */}
        <GridItem>
          <Text fontSize="md" fontWeight="semibold"> Your Saved Searches</Text>
          <VStack spacing={"4"} mt={"10px"}>
            {!isFree && (savedSearchLoading || savedSearchFetching) &&
              <BoxButton
                customContent={
                  <Center py={"4"}>
                    <Text textAlign={"center"} color="fg.muted">
                      Loading Saved Searches...
                    </Text>
                  </Center>
              }
              />
            }
            {!isFree && savedSearchData && searches && searches.length > 0 && searches.slice(0, 5).map((search) => {
              return (
                <SavedSearchButton key={search.id} searchId={search.id} name={search.attributes.title} description={`Updated ${kumoDayJs(search.attributes.updated_at).fromNow()}`} />
              )
            })}
            {!isFree && savedSearchData && searches && searches.length === 0 &&
              <BoxButton cursor={"default"} customContent={<Box><Text color="fg.muted"  textAlign={"center"}>No Saved Searches</Text><Text color="fg.muted" >Create a new search to begin tracking.</Text></Box>} borderStyle={'dashed'} />
            }
            {isFree &&
              <BoxButton
                as={RouterNavLink}
                to={"/plan-selection"}
                borderStyle={'dashed'}
                bg={"gray.50"}
                customContent={
                  <Center py={"4"}>
                    <VStack spacing={"1"}>
                      <Box>
                        <Text textAlign={"center"} color="fg.muted">
                          Want to save your searches and be alerted for new deals?
                        </Text>
                      </Box>
                      <Box>
                        <Text textAlign={"center"} fontWeight={"semibold"}>
                          Upgrade to Pro or Ultimate.
                        </Text>
                      </Box>
                    </VStack>
                  </Center>
                }
              />
            }
          </VStack>


          {/* List of saved searches */}
          {/* ... */}
        </GridItem>
      </Grid>

      {/* Tags Section */}
      {/*<Box mt="6">*/}
      {/*  <Text fontSize="md" fontWeight="semibold"> Explore Deals by Category</Text>*/}
      {/*  <SimpleGrid columns={{ base: 2, md: 4 }} spacing={2}>*/}
      {/*    /!* Replace these with actual category tags *!/*/}
      {/*    <Tag>Category 1</Tag>*/}
      {/*    <Tag>Category 2</Tag>*/}
      {/*    /!* ... *!/*/}
      {/*  </SimpleGrid>*/}
      {/*</Box>*/}
    </Box>
  );
};


export const SuggestedSearchButton = (props) => {
  const navigate = useNavigate();
  const { searchId, name, description, onClick } = props

  const handleClick = () => {
    // Open the URL in the same tab
    // window.open(`/search/suggested/${searchId}`, '_self');
    navigate(`/suggested-search/${searchId}`);
  };

  return (
    <BoxButton onClick={handleClick} heading={name} description={description} />
  )
}

export const SavedSearchButton = (props) => {
  const navigate = useNavigate();
  const { searchId, name, description, onClick } = props

  const handleClick = () => {
    // Open the URL in the same tab
    // window.open(`/search/suggested/${searchId}`, '_self');
    navigate(`/search/saved/${searchId}`);
  };

  return (
    <BoxButton onClick={handleClick} heading={name} description={description} />
  )
}

export const BoxButton = (props) => {
  const { customContent, heading, description, onClick, borderStyle, ...rest } = props
  return (
    <Box w={"full"}
         as="button"
         bg="bg.surface"
         borderWidth="1px"
         rounded="md"
         spacing="0"
         px="4"
         py="2"
         type="button"
         fontSize="sm"
         borderStyle={borderStyle || "solid"}
         onClick={onClick}
         {...rest}
    >
      <Stack
        data-group
      >
        {customContent}
        {!customContent &&
          <HStack>
          <Text
          align="start"
          fontWeight="medium"
          _groupHover={{
          color: 'accent',
        }}
          >
        {heading}
          </Text>
          </HStack>}
        {!customContent && description &&
          <Text align="start" color="fg.muted">
        {description}
          </Text>
        }
      </Stack>
    </Box>

  )
}