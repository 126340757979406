import {useSelector} from "react-redux";
import {selectDefaultFilters} from "../../../store/local-search";
import {Box} from "@chakra-ui/react";
import {BoxPlot} from "../shared/box-plot";
import {AskingPriceBuckets, DefaultBarChartBuckets} from "../shared/common-visualization-elements";
import {DistributionBarChart} from "../shared/distribution-bar-chart";
import {TimeSeriesGraph} from "../shared/time-series";
import {StandardTextHeading} from "../../layouts/BodyHeading";
import * as React from "react";
import {useContext, useEffect} from "react";
import {HeadingContext} from "../../heading-provider";

export const AnalyticsOverview = () => {
  const { setHeadingContent } = useContext(HeadingContext);
  const updateHeadingContent = (content) => {
    if (setHeadingContent) {
      setHeadingContent(content);
    }
  };

  let filters = Object.assign(useSelector(selectDefaultFilters))

  useEffect(() => {
    updateHeadingContent(<StandardTextHeading text={"Analytics"} />);
  }, [filters]);

  return (
    <Box>
      <Box>
        <TimeSeriesGraph filters={filters} title={"New Deals on Kumo (Listed & Delisted)"} graphType={"# of Deals"} timePeriod={"1 year"}/>
      </Box>

      <Box>
        <TimeSeriesGraph filters={filters} title={"Number of Days on Market"}  graphType={"Avg. # of Days to Sale"} timePeriod={"1 year"}/>
      </Box>

      <Box>
        <DistributionBarChart filters={filters} title={"Number of Deals by Asking Price Band"}   xmetric={"asking_price"} ymetric={"num_deals"} buckets={DefaultBarChartBuckets} />
      </Box>

      <Box>
        <DistributionBarChart filters={filters} title={"Number of Deals by Annual Revenue"} xmetric={"annual_revenue"} ymetric={"num_deals"} buckets={DefaultBarChartBuckets} />
      </Box>

      <Box>
        <DistributionBarChart filters={filters} title={"Number of Deals by Profit"} xmetric={"annual_ebitda_or_sde"} ymetric={"num_deals"} buckets={DefaultBarChartBuckets} titleToolTip={"EBITDA or SDE, based on what is present on each deal"}/>
      </Box>

      <Box>
        <DistributionBarChart filters={filters} title={"Number of Deals by Price-To-Profit Multiple"} xmetric={"ebitda_multiple"} ymetric={"num_deals"} buckets={DefaultBarChartBuckets} titleToolTip={"EBITDA or SDE, based on what is present on each deal"}/>
      </Box>

      <Box>
        <BoxPlot title={"Price-to-Profit Multiple by Price Band"}  filters={filters} ymetric={"asking_price"} buckets={AskingPriceBuckets} titleToolTip={"EBITDA or SDE, based on what is present on each deal"}/>
      </Box>
    </Box>
  )
}