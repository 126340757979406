import { UseToastOptions } from "@chakra-ui/react";

export const defaultErrorMessage = "Whoops something went wrong.";

export const defaultErrorToastSettings: UseToastOptions = {
  title: defaultErrorMessage,
  status: "error",
  duration: 9000,
  isClosable: true,
};
