import {
  chakra,
  FormControlOptions,
  forwardRef,
  HStack,
  HTMLChakraProps,
  Icon,
  omitThemingProps,
  Text,
  ThemingProps,
  useFormControl,
  useMergeRefs,
  useMultiStyleConfig,
  usePopper,
} from "@chakra-ui/react";
import { mergeWith } from "@chakra-ui/utils";
import { useSelect } from "downshift";
import * as React from "react";
import { HiChevronDown } from "react-icons/hi";

export interface SelectProps
  extends FormControlOptions,
    ThemingProps<"Select">,
    Omit<
      HTMLChakraProps<"button">,
      "disabled" | "required" | "readOnly" | "size" | "value" | "onChange"
    > {
  placeholder?: string;
  value?: string | null | undefined;
  onChange?: (item: string) => void;
}

export const CustomSelect = React.forwardRef<HTMLButtonElement, SelectProps>((props, ownRef) => {
  const { id, value, children, placeholder, onChange, ...rest } = omitThemingProps(props);
  const ownButtonProps = useFormControl<HTMLButtonElement>(rest);
  const styles = useMultiStyleConfig("CustomSelect", props);

  const validChildren = React.useMemo(
    () =>
      React.Children.toArray(children)
        .filter<React.ReactElement<{ value: string; children?: React.ReactElement }>>(
          React.isValidElement
        )
        .filter((child) => "value" in child.props),
    [children]
  );

  const items = validChildren.map((child) => child.props.value);

  const { isOpen, selectedItem, getToggleButtonProps, getMenuProps, getItemProps } = useSelect({
    id,
    items,
    selectedItem: value,
    onSelectedItemChange: (val) => (val.selectedItem ? onChange?.(val.selectedItem) : undefined),
  });

  const { referenceRef: popperRef, getPopperProps } = usePopper({
    enabled: isOpen,
    gutter: 2,
  });
  const { ref: useSelectToggleButtonRef, ...useSelectToggleButtonProps } = getToggleButtonProps();

  const toggleButtonRef = useMergeRefs(ownRef, useSelectToggleButtonRef, popperRef);
  const toggleButtonProps = mergeWith(ownButtonProps, useSelectToggleButtonProps);

  return (
    <chakra.div position="relative">
      <chakra.button
        ref={toggleButtonRef}
        __css={styles.field}
        {...toggleButtonProps}
        paddingStart="3"
        paddingEnd="2"
        paddingY="1.5"
        fontSize="sm">
        <HStack justify="space-between" spacing="1" w="100%">
          <Text fontWeight="medium">
            {validChildren.find((child) => child.props.value === selectedItem)?.props.children ||
              selectedItem || <chakra.span>{placeholder}</chakra.span>}
          </Text>
          <Icon as={HiChevronDown} fontSize="xl" color="gray.400" />
        </HStack>
      </chakra.button>
      <chakra.div
        zIndex="1"
        width="100%"
        {...mergeWith(getPopperProps(), {
          style: { visibility: isOpen ? "visible" : "hidden" },
        })}>
        <chakra.ul __css={styles.menu} {...getMenuProps()}>
          {isOpen &&
            validChildren.map((item, index) =>
              React.createElement(
                chakra.div,
                {
                  __css: styles.option,
                  ...getItemProps({ item: item.props.value, index }),
                },
                React.cloneElement(item)
              )
            )}
        </chakra.ul>
      </chakra.div>
    </chakra.div>
  );
});

CustomSelect.displayName = "CustomSelect";

export interface OptionProps extends HTMLChakraProps<"li">, ThemingProps {
  value: string;
}

export const Option = forwardRef<OptionProps, "li">((props, ref) => {
  const { children, value, ...rest } = omitThemingProps(props);
  const { option } = useMultiStyleConfig("CustomSelect", props);
  return (
    <chakra.li ref={ref} __css={option} {...rest}>
      {children || value}
    </chakra.li>
  );
});
