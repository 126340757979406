import {Button, Icon, IconButton, useClipboard, useToast,} from "@chakra-ui/react";
import React from "react";
import {LuClipboardCopy} from "react-icons/lu";

export const ShareDealButton = (props) => {
  const { deal, buttonType, ...otherProps } = props;
  const dealAttributes = deal.attributes;

  const toast = useToast();
  const url = `https://app.withkumo.com/deal/${deal.id}`
  const { onCopy } = useClipboard(url);

  const handleClick = () => {
    onCopy();
    toast({
      title: 'Copied to clipboard',
      description: `Link to Deal "${deal?.attributes?.title}" has been copied to your clipboard.`,
      status: 'success',
      duration: 1000,
      isClosable: true,
      position: "top-right"
    });
  };



  const FillableIcon = () => {
    return(
      <Icon
        as={LuClipboardCopy}
        transition="all 0.15s ease"
      />
    )
  }

  if (buttonType === "textButton") {
    return(
      <Button leftIcon={<FillableIcon />}
              variant={"outline"}
              size="xs"
              width={"full"}
              _hover={{ transform: "scale(1.05)" }}
              sx={{ ":hover > svg": { transform: "scale(1.05)" } }}
              transition="all 0.15s ease"
              onClick={handleClick}
              {...otherProps} // if you want to spread otherProps
      >
        Share
      </Button>
    )
  } else {
    return (
      <IconButton
        isRound
        bg="white"
        color="gray.900"
        size="md"
        _hover={{ transform: "scale(1.1)" }}
        sx={{ ":hover > svg": { transform: "scale(1.1)" } }}
        transition="all 0.15s ease"
        onClick={handleClick}
        icon={
          <FillableIcon />
        }
        aria-label="Share Deal"
        {...otherProps} // if you want to spread otherProps
      />
    );
  }


};