import {useParams} from "react-router-dom";
import {useGetSearchableLocationsQuery} from "../../../services/savedSearchesApi";
import {useSelector} from "react-redux";
import {selectDefaultFilters} from "../../../store/local-search";
import {Box, Flex} from "@chakra-ui/react";
import {TimeSeriesGraph} from "../shared/time-series";
import {DistributionBarChart} from "../shared/distribution-bar-chart";
import {AskingPriceBuckets, DefaultBarChartBuckets} from "../shared/common-visualization-elements";
import {BoxPlot} from "../shared/box-plot";
import * as React from "react";
import {useContext, useEffect} from "react";
import {HeadingLink, HeadingText} from "../../layouts/BodyHeading";
import {HeadingContext} from "../../heading-provider";


export const LocationAnalytics = () => {
  const { setHeadingContent } = useContext(HeadingContext);

  const updateHeadingContent = (content) => {
    if (setHeadingContent) {
      setHeadingContent(content);
    }
  };

  let defaultFilters = Object.assign(useSelector(selectDefaultFilters))
  const { locationId } = useParams();

  const { data: locationData, isLoading, isFetching, isError } = useGetSearchableLocationsQuery();
  const locations = locationData?.data || []

  useEffect(() => {
    if (isLoading || isFetching || isError) {
      updateHeadingContent(
        <Box
          pl={{ base: '0', lg: '10' }}
        >
          <Flex align="center" gap="2">
            <HeadingLink text={"Location Analytics"} url={'/analytics/locations'} />
            <HeadingText text={" > Loading..."} />
          </Flex>
        </Box>
      );
    } else if (locationData) {
      const location = locations.find(loc => parseInt(loc.id) === parseInt(locationId))
      updateHeadingContent(
        <Box
          pl={{ base: '0', lg: '10' }}
        >
          <Flex align="center" gap="2">
            <HeadingLink text={"Location Analytics"} url={'/analytics/locations'} />
            <HeadingText text={" > " + (location?.attributes?.label || "Unknown")} />
          </Flex>
        </Box>
      );
    }

  }, [locationData, isLoading, isFetching, isError]);


  if (isLoading || isFetching) return (<div>Loading...</div>)

  if (locationId && locations.length > 0) {
    let filters = {
      ...defaultFilters,
      dealSearchableLocationId: [locationId]
    }
    const location = locations.find(loc => parseInt(loc.id) === parseInt(locationId))
    return (
      <Box>
        <Box>
          <TimeSeriesGraph filters={filters}  title={"New Deals on Kumo (Listed & Delisted)"} graphType={"# of Deals"} timePeriod={"1 year"}/>
        </Box>

        <Box>
          <TimeSeriesGraph filters={filters} title={"Number of Days on Market"}   graphType={"Avg. # of Days to Sale"} timePeriod={"1 year"}/>
        </Box>

        <Box>
          <DistributionBarChart title={"Number of Deals by Asking Price Band"}   filters={filters} xmetric={"asking_price"} ymetric={"num_deals"} buckets={DefaultBarChartBuckets} />
        </Box>

        <Box>
          <DistributionBarChart title={"Number of Deals by Annual Revenue"} filters={filters} xmetric={"annual_revenue"} ymetric={"num_deals"} buckets={DefaultBarChartBuckets} />
        </Box>

        <Box>
          <DistributionBarChart title={"Number of Deals by Profit"} filters={filters} xmetric={"annual_ebitda_or_sde"} ymetric={"num_deals"} buckets={DefaultBarChartBuckets} titleToolTip={"EBITDA or SDE, based on what is present on each deal"}/>
        </Box>

        <Box>
          <DistributionBarChart title={"Number of Deals by Price-To-Profit Multiple"} filters={filters} xmetric={"ebitda_multiple"} ymetric={"num_deals"} buckets={DefaultBarChartBuckets} titleToolTip={"EBITDA or SDE, based on what is present on each deal"}/>
        </Box>

        <Box>
          <BoxPlot title={"Price-to-Profit Multiple by Price Band"}  filters={filters} ymetric={"asking_price"} buckets={AskingPriceBuckets} titleToolTip={"EBITDA or SDE, based on what is present on each deal"}/>
        </Box>
      </Box>
    )
  }
}