import * as React from "react";
import {useEffect, useState} from "react";
import {Box, Center, Flex, Link, Select, SkeletonText, Stack, Tag, TagLabel, Text} from "@chakra-ui/react";
import {useSelector} from "react-redux";
import {selectDefaultFilters} from "../../../store/local-search";
import {useGetChildIndustriesQuery, useGetSearchableLocationsQuery} from "../../../services/savedSearchesApi";
import {TimeSeriesGraph} from "../../analytics/shared/time-series";
import {DistributionBarChart} from "../../analytics/shared/distribution-bar-chart";
import {AskingPriceBuckets, DefaultBarChartBuckets} from "../../analytics/shared/common-visualization-elements";
import {BoxPlot} from "../../analytics/shared/box-plot";


export const Analytics = ({ deal }) => {
  const [analyticsType, setAnalyticsType] = useState('industry_id');
  const [selectableOptionsToDisplay, setSelectableOptionsToDisplay] = useState([]); // [{id, type, attributes, formValue}
  const [selectedIds, setSelectedIds] = useState([]);

  // Always call hooks at the top level
  const industriesQuery = useGetChildIndustriesQuery();
  const locationsQuery = useGetSearchableLocationsQuery();

  // Determine which data to use based on analyticsType
  let options = []
  if (analyticsType === 'industry_id') {
    options = industriesQuery.data?.data || []
  } else if (analyticsType === 'location_id') {
    options = locationsQuery.data?.data || []
  }


  useEffect(() => {
    if (deal && deal.id && options && options.length > 0) {


      const relatedOptions = options.filter(option => {
        if (analyticsType === 'industry_id') {
          return deal.attributes.deal_child_industry_ids.includes(parseInt(option.id));
        } else if (analyticsType === 'location_id') {
          return deal.attributes.deal_searchable_location_ids.includes(parseInt(option.id));
        }
      });

      // Identify unique groups in related options
      const uniqueGroups = new Set(relatedOptions.map(option => option.attributes.group));

      // Map groups to all corresponding child IDs from all options
      const groupToAllChildIdsMap = Array.from(uniqueGroups).reduce((acc, groupName) => {
        acc[groupName] = options
          .filter(option => option.attributes.group === groupName)
          .map(option => option.id);
        return acc;
      }, {});

      // Create parent options with formValue containing all child IDs for each group
      const parentOptions = Object.entries(groupToAllChildIdsMap).map(([groupName, childIds]) => {
        return {
          id: `parent-${groupName.split(' ')[0]}`, // Example: ID generation
          type: 'ParentIndustry',
          attributes: {
            name: groupName,
            label: `Group: ${groupName}`,
          },
          formValue: childIds,
        };
      });

      const selectableOptions = relatedOptions.map(option => ({
        ...option,
        formValue: [option.id],
      }));

      setSelectableOptionsToDisplay([...selectableOptions, ...parentOptions])
      setSelectedIds([...selectableOptions, ...parentOptions][0]?.formValue || []);
    }
  }, [deal, options, analyticsType]);

  const handleAnalyticsTypeChange = (event) => {
    setAnalyticsType(event.target.value);
    // Additional logic to update selectableOptionsToDisplay and selectedIds
  };

  if (!deal || !deal.id || !options || options.length === 0) {
    return <LoadingSkeleton />;
  }

  return (
    <>
      <Flex align="center" wrap="wrap">
        <Text as="span">Analytics for similar deals by </Text>
        <Select
          value={analyticsType}
          onChange={handleAnalyticsTypeChange}
          size="sm"
          width="auto"
          minW="100px"
          mx={"3"}
        >
          <option value="industry_id">Industry</option>
          <option value="location_id">Location</option>
        </Select>
        {/*<Text as="span"> from the last 12 months</Text>*/}
      </Flex>
      <AnalyticsCharts analyticsType={analyticsType} selectableOptionsToDisplay={selectableOptionsToDisplay}
                       setSelectedIds={setSelectedIds} deal={deal}
                       selectedIds={selectedIds} />
    </>
  );
};

export const AnalyticsCharts = ({deal, selectableOptionsToDisplay, analyticsType, selectedIds, setSelectedIds }) => {
  const defaultFilters = useSelector(selectDefaultFilters);

  let filters
  if (selectedIds.length > 0 && selectableOptionsToDisplay.length > 0) {
    if (analyticsType === 'location_id') {
      filters = {
        ...defaultFilters,
        dealSearchableLocationId: selectedIds
      }
    } else if (analyticsType === 'industry_id') {
      filters = {
        ...defaultFilters,
        dealChildIndustryId: selectedIds
      }
    }
  }

  if (filters && selectableOptionsToDisplay && selectableOptionsToDisplay.length > 0) {
    console.log('selectable', selectableOptionsToDisplay)
    return (
      <>
        <Flex mt={"3"} flexWrap={"wrap"}>
          {selectableOptionsToDisplay.map(option => (
            <Tag
              size="sm"
              key={option.id}
              variant={selectedIds === option.formValue ? "solid" : "outline"}
              p={2}
              mr={2}
              mb={2}
              cursor="pointer"
              onClick={() => setSelectedIds(option.formValue)}
            >
              <TagLabel>{option.attributes.name}</TagLabel>
            </Tag>
          ))}
        </Flex>


        <Box>
          <Box>
            <TimeSeriesGraph filters={filters}  title={"New Deals on Kumo (Listed & Delisted)"} graphType={"# of Deals"} timePeriod={"1 year"}/>
          </Box>

          <Box>
            <TimeSeriesGraph filters={filters} title={"Number of Days on Market"}   graphType={"Avg. # of Days to Sale"} timePeriod={"1 year"}/>
          </Box>

          <Box>
            <DistributionBarChart title={"Number of Deals by Asking Price Band"}   filters={filters} xmetric={"asking_price"} ymetric={"num_deals"} buckets={DefaultBarChartBuckets} />
          </Box>

          <Box>
            <DistributionBarChart title={"Number of Deals by Annual Revenue"} filters={filters} xmetric={"annual_revenue"} ymetric={"num_deals"} buckets={DefaultBarChartBuckets} />
          </Box>

          <Box>
            <DistributionBarChart title={"Number of Deals by Profit"} filters={filters} xmetric={"annual_ebitda_or_sde"} ymetric={"num_deals"} buckets={DefaultBarChartBuckets} titleToolTip={"EBITDA or SDE, based on what is present on each deal"}/>
          </Box>

          <Box>
            <DistributionBarChart title={"Number of Deals by Price-To-Profit Multiple"} filters={filters} xmetric={"ebitda_multiple"} ymetric={"num_deals"} buckets={DefaultBarChartBuckets} titleToolTip={"EBITDA or SDE, based on what is present on each deal"}/>
          </Box>

          <Box>
            <BoxPlot title={"Price-to-Profit Multiple by Price Band"}  filters={filters} ymetric={"asking_price"} buckets={AskingPriceBuckets} titleToolTip={"EBITDA or SDE, based on what is present on each deal"}/>
          </Box>
        </Box>
      </>

    )
  } else {
    return (
      <Box mt="6" borderWidth="2px" px={{base: "2", lg: "3"}} rounded="xl" borderStyle="dashed"
           position="relative"
      >
        <Box mx="auto" overflowY="auto" pt={{base: "3", lg: "5"}} pb={{base: "3", lg: "5"}} w={{base: "95%"}}>
          <Center gap="6">
            <Text fontSize={{base: "md"}} color="fg.muted" fontWeight="medium">
              There are no analytics by {analyticsType === 'industry_id' ? 'industry' : 'location'} for this deal. To view all analytics, you can go to <Link href={"/analytics/"}>Analytics</Link>.
            </Text>
          </Center>
        </Box>
      </Box>
    )
  }



}

const LoadingSkeleton = () => {
  return (
    <Box>
      <Stack>
        <SkeletonText mt="4" noOfLines={1} spacing="4" skeletonHeight="7" width={"300px"} />
        <SkeletonText mt="4" noOfLines={2} spacing="1" skeletonHeight="5" />
        <SkeletonText mt="4" noOfLines={15} spacing="1" skeletonHeight="5" />
      </Stack>
    </Box>
  )
}