import {Box, Flex, Heading} from "@chakra-ui/react";
import {ScatterPlotByIndustryOrLocation} from "../shared/scatter-plot-by-industry-or-location";
import {TableByIndustryOrLocation} from "../shared/industry-or-location-table";
import * as React from "react";
import {useContext, useEffect} from "react";
import {HeadingContext} from "../../heading-provider";
import {HeadingLink, HeadingText} from "../../layouts/BodyHeading";


export const AnalyticsLocationsIndex = () => {
  const { setHeadingContent } = useContext(HeadingContext);

  const updateHeadingContent = (content) => {
    if (setHeadingContent) {
      setHeadingContent(content);
    }
  };

  useEffect(() => {
    updateHeadingContent(
      <Box
        pl={{ base: '0', lg: '10' }}
      >
        <Flex align="center" gap="2">
          <HeadingLink text={"Analytics"} url={'/analytics'} />
          <HeadingText text={" > All Locations"} />
        </Flex>
      </Box>
    );
  }, []);



  return (
    <Box>
      <Box>
        <Heading size={"xs"}>
          Locations Page
        </Heading>
        <ScatterPlotByIndustryOrLocation filters={{}} group_by={"location_id"} />
      </Box>

      <Box>
        <TableByIndustryOrLocation filters={{}} group_by={"location_id"} />
      </Box>


    </Box>
  )
}