import {useGetSavedSearchesForUserQuery} from "../../services/savedSearchesApi";
import {Box, Flex, HStack, Link, Skeleton, Table, Tbody, Td, Text, Th, Thead, Tr} from "@chakra-ui/react";
import * as React from "react";
import {useContext, useEffect} from "react";
import {HeadingContext} from "../heading-provider";
import {StandardTextHeading} from "../layouts/BodyHeading";
import {useNavigate} from "react-router-dom";
import {SearchFiltersButtons} from "../../components/search-filters-buttons";
import {EditSearchButton} from "./components/EditSearchButton";
import {DeleteButton} from "./components/DeleteSearchButton";
import Helmet from "react-helmet";


export const SavedSearches = () => {
  const { setHeadingContent } = useContext(HeadingContext);
  useEffect(() => {
    setHeadingContent(<StandardTextHeading text={'All Saved Searches'} />);
  }, [setHeadingContent]);

  return (
    <Box as="section" bg="bg-surface">
      <Helmet title={`Kumo | All Saved Searches`} />
      <Box mt={"15px"}>
        <SavedSearchesList />
      </Box>
    </Box>
  )
}

const SavedSearchesList = () => {
  const { data, isLoading, isFetching, isError } = useGetSavedSearchesForUserQuery();

  const searches = data?.data;

  const headers = [
    {
      text: "Title",
      width: "20%"
    },
    {
      text: "Include in Emails?",
      width: "5%"
    },
    {
      text: "Filters",
      width: "",
      hideOnMobile: true
    },
    {
      text: "Actions",
      width: "5%"
    },
  ]

  return (
    <Box>
      <Table borderWidth="1px">
        <Thead>
          <Tr>
            {headers.map((header, index) => (
              <Th
                key={index}
                width={header.width}
                display={header.hideOnMobile ? { base: "none", lg: "table-cell" } : "table-cell"}
                // px={"12px"} py={"12px"}
              >
                <HStack spacing="1">
                  <Text>{header.text}</Text>
                </HStack>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {searches && searches?.map((search) => <TableRow key={search.id} search={search} />)}
          {(isLoading) &&
            Array.from({ length: 5 }).map((_, index) => (
              <LoadingTableRow headers={headers} key={index} />
            ))}
        </Tbody>
      </Table>
    </Box>
  )
}

export const LoadingTableRow = ({headers}) => {
  return (
    <Tr>
      <Td colSpan={headers.length}>
        <Skeleton height={"40px"} />
      </Td>
    </Tr>
  );
};

const TableRow = ({search}) => {
  const navigate = useNavigate();


  const handleClick = () => {
    navigate(`/search/saved/${search.id}`);
  }

  return(
    <Tr>
      <Td cursor={"pointer"}>
        <Link onClick={handleClick} >
          {search.attributes.title}
        </Link>
      </Td>
      <Td>
        {search.attributes.include_in_weekly_digest === "true" ? "Yes" : "No"}
      </Td>
      <Td display={{ base: "none", lg: "table-cell" }}>
        <Box maxWidth={"500px"}>
          <Flex wrap="wrap" spacing={"3"}>
            <SearchFiltersButtons onTagClick={handleClick} filtersToCompare={search.attributes.filters} includeOtherFiltersCount={true} />
          </Flex>
        </Box>
      </Td>
      <Td>
        <EditSearchButton search={search} />
        <DeleteButton search={search} />
      </Td>

    </Tr>
  )

}




