import {
	Box,
	Flex,
	RangeSlider,
	RangeSliderFilledTrack,
	RangeSliderThumb,
	RangeSliderTrack,
	Stack,
	Text,
} from '@chakra-ui/react'
import {useEffect, useMemo, useState} from "react";
import {MdGraphicEq} from "react-icons/md";
import numbro from "numbro";

export const PriceRangePicker = ({ filterKey, filterValue, step, min, max, onChange, numberFormat, ...props }) => {
	const [localValue, setLocalValue] = useState(filterValue)

	// reset local value when value changes
	useEffect(() => {
		setLocalValue(filterValue)
	}, [filterValue])

	// Calculate scaling segments dynamically based on max
	const scalingSegments = useMemo(() => {
		const ranges = [
			{ upper: 0.05, range: [0, 60] },  // Upper limit is 5% of max
			{ upper: 0.1, range: [60, 80] },  // Upper limit is 10% of max
			{ upper: 0.5, range: [80, 90] },  // Upper limit is 50% of max
			{ upper: 1.0, range: [90, 100] }, // Upper limit is 100% of max
		];

		let previousUpper = 0;
		return ranges.map((r, index) => {
			const domainStart = previousUpper * max;
			const domainEnd = r.upper * max;
			const rangeStart = index === 0 ? 0 : ranges[index - 1].range[1];
			const rangeEnd = r.range[1];
			previousUpper = r.upper;

			return {
				domain: [domainStart, domainEnd],
				range: [rangeStart, rangeEnd],
				factor: (rangeEnd - rangeStart) / (domainEnd - domainStart),
			};
		});
	}, [max]);

	const getSegmentForValue = (value, isInverse = false) => {
		return scalingSegments.find(segment =>
			isInverse
				? value >= segment.range[0] && value <= segment.range[1]
				: value >= segment.domain[0] && value <= segment.domain[1]
		);
	};

	const logScale = value => {
		const segment = getSegmentForValue(value);
		if (!segment) return value;  // or handle edge cases differently
		return segment.range[0] + (value - segment.domain[0]) * segment.factor;
	};

	const inverseLogScale = value => {
		const segment = getSegmentForValue(value, true);
		if (!segment) return value;  // or handle edge cases differently
		return segment.domain[0] + (value - segment.range[0]) / segment.factor;
	};

	const handleLocalValueChange = values => {
		const [minValue, maxValue] = values.map(val => inverseLogScale(val));
		setLocalValue([minValue, maxValue]);
	};

	const handleLocalValueChangeEnd = values => {
		const [minValue, maxValue] = values.map(val => inverseLogScale(val));
		onChange(filterKey, [minValue, maxValue]);
	};

	const formattedValue0 = numbro(localValue[0]).format({ average: true, totalLength: 2 }).toUpperCase();
	const formattedValue1 = numbro(localValue[1]).format({ average: true, totalLength: 2 }).toUpperCase();



	return (
		<>
			<Stack>
				<RangeSlider
					onChangeEnd={values => handleLocalValueChangeEnd(values)}
					onChange={values => handleLocalValueChange(values)}
					step={step}
					min={logScale(min)}
					max={logScale(max)}
					aria-label={['min', 'max']}
					value={localValue.map(logScale)}
					{...props}
				>
					<RangeSliderTrack>
						<RangeSliderFilledTrack />
					</RangeSliderTrack>
					{filterValue?.map((_, index) => (

						<RangeSliderThumb boxSize={6} key={index} index={index}>
							<Box color='brand' as={MdGraphicEq} />
						</RangeSliderThumb>
					))}
				</RangeSlider>
				{!numberFormat &&
					<Flex justifyContent="space-between">
						<Text>$ {formattedValue0}</Text>
						<Text>$ {formattedValue1}</Text>
					</Flex>
				}
				{numberFormat === 'multiple' &&
					<Flex justifyContent="space-between">
						<Text>{formattedValue0}x</Text>
						<Text>{formattedValue1}x</Text>
					</Flex>
				}
			</Stack>

		</>
	)
}
