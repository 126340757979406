import React from "react";
import { usePrevious } from "./use-previous";
import { AuthContext } from "./auth-context";

export type AuthProviderProps = {
  defaultAuthenticated?: boolean;
  onLogin?: () => void;
  onLogout?: () => void;
  children: React.ReactNode;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ onLogin, onLogout, children }) => {
  const [authenticated, setAuthenticated] = React.useState(
    !!localStorage.getItem("with-kumo-token")
  );

  const previousAuthenticated = usePrevious(authenticated);

  React.useEffect(() => {
    if (!previousAuthenticated && authenticated) {
      onLogin && onLogin();
    }
  }, [previousAuthenticated, authenticated, onLogin]);

  React.useEffect(() => {
    if (previousAuthenticated && !authenticated) {
      onLogout && onLogout();
    }
  }, [previousAuthenticated, authenticated, onLogout]);

  const contextValue = React.useMemo(
    () => ({
      authenticated,
      setAuthenticated,
    }),
    [authenticated]
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
