import {
  Button,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { MdSettings } from "react-icons/md";
import { DealFlowStageManager } from "./deal-flow-stage-manager";

export const DealFlowStagesDrawer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button onClick={onOpen} variant="outline">
        <HStack spacing={1}>
          <Icon as={MdSettings} />
          <Text>Adjust Deal Stages</Text>
        </HStack>
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Your Deal Stages</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <DealFlowStageManager />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
