import React from "react";

import { Droppable } from "react-beautiful-dnd";
import Prospect from "../Prospect";

import {
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { sort } from "fast-sort";
import { MdOutlineMoreVert } from "react-icons/md";
import { DealFlowStages_dealFlowStages } from "../../../../graphql/__generated__/dealFlowStages";
import useEditField from "../hooks/useEditField";

interface Props {
  column?: DealFlowStages_dealFlowStages;
  index: number;
  createStage: (status: any) => void;
  editStage: (status: any) => void;
  hasNotesFilter: string;
}

const Column: React.FC<Props> = ({ column, createStage, editStage, hasNotesFilter }) => {
  const prospects = sort(column?.prospects || []).asc((prospect) => prospect.order);
  const {
    field: statusTitle,
    isEditing: isEditingStage,
    setIsEditing: setIsEditingStage,
    setField: setStageTitle,
    inputRef,
    handleBlur,
    handleChange,
    onKeyPressed,
  } = useEditField({
    fieldId: column?.id,
    onCreate: (field) => createStage({ title: field }),
    onEdit: (id, field) => editStage({ id: id, title: field }),
  });

  const DealFlowMenu = () => {
    return (
      <Menu closeOnBlur closeOnSelect>
        <MenuButton
          as={Button}
          size="sm"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignored
          variant="ghost">
          <Icon as={MdOutlineMoreVert} />
        </MenuButton>
        <MenuList>
          <MenuItem
            onClick={() => {
              setIsEditingStage(true);
              setStageTitle(column?.name || "");
            }}>
            Edit Stage Name
          </MenuItem>
          {/* <MenuItem
            onClick={() => {
              hideStage && column && hideStage({ id: column?.id });
            }}>
            Hide Stage
          </MenuItem> */}
          {/* <MenuItem
            onClick={() => {
              deleteStage && column && deleteStage({ id: column?.id });
            }}>
            Remove Stage
          </MenuItem> */}
        </MenuList>
      </Menu>
    );
  };

  const renderEmptyColumnHeader = () => {
    return (
      <Flex mb={4} px={2} justify="space-between" align="center">
        {isEditingStage ? (
          <Input
            onBlur={handleBlur}
            ref={inputRef}
            value={statusTitle}
            autoFocus
            onKeyPress={onKeyPressed}
            onChange={handleChange}
            variant="unstyled"
            placeholder="Stage name"
          />
        ) : (
          <Button
            variant="outline"
            onClick={() => setIsEditingStage(true)}
            size="sm"
            bg="transparent">
            Add a new stage
          </Button>
        )}
      </Flex>
    );
  };

  const Header = ({
    columnObj,
    dragHandleProps,
  }: {
    columnObj: DealFlowStages_dealFlowStages;
    dragHandleProps?: any;
  }) => {
    return (
      <Flex mb={4} pl={2} justify="space-between" align="center" {...dragHandleProps}>
        {isEditingStage ? (
          <Input
            onBlur={handleBlur}
            ref={inputRef}
            value={statusTitle}
            onKeyPress={onKeyPressed}
            onChange={handleChange}
            autoFocus
            variant="unstyled"
            placeholder="Stage name"
          />
        ) : (
          <HStack>
            <Badge colorScheme={columnObj.colorScheme || "green"} whiteSpace="normal">
              <Text fontSize="md" fontWeight="bold" wordBreak="break-all">
                {columnObj.name}
              </Text>
            </Badge>
            <Text color="muted">{(columnObj?.prospects || []).length}</Text>
          </HStack>
        )}
        <DealFlowMenu />
      </Flex>
    );
  };

  const renderProspectList: any = (columnObj: DealFlowStages_dealFlowStages) => {
    return (
      <Droppable type="PROSPECT" droppableId={columnObj.id}>
        {({ droppableProps, innerRef, placeholder }) => (
          <Box minH={200} ref={innerRef} {...droppableProps}>
            {column &&
              prospects &&
              prospects
                .filter((prospect) => {
                  if (hasNotesFilter === "Has Notes") {
                    return (prospect.deal?.noteThread?.notes || []).length > 0;
                  } else if (hasNotesFilter === "No Notes") {
                    return (prospect.deal?.noteThread?.notes || []).length === 0;
                  } else {
                    return true;
                  }
                })
                .map((prospect, index) => (
                  <Prospect
                    statusId={column.id}
                    key={prospect?.id}
                    prospect={prospect}
                    index={index}
                  />
                ))}
            {placeholder}
          </Box>
        )}
      </Droppable>
    );
  };

  if (column) {
    if (column.unchangeable) {
      return (
        <Box
          // boxShadow="md"
          // rounded="md"
          borderRight="1.5px solid"
          borderRightColor={"gray.200"}
          minH="60vh"
          minW={200}
          w={{ base: "100%", sm: "50%", md: 300 }}
          p={3}
          // m={2}
          backgroundColor={"inherit"}>
          {column ? <Header columnObj={column} /> : renderEmptyColumnHeader()}
          {column && prospects && renderProspectList(column)}
        </Box>
      );
    }
    return (
      <Box
        // boxShadow="md"
        // rounded="md"
        borderRight="1.5px solid"
        borderRightColor={"gray.200"}
        minW={200}
        minH="60vh"
        w={{ base: "100%", sm: "50%", md: 300 }}
        p={3}
        // m={2}
        backgroundColor={"inherit"}>
        {column ? <Header columnObj={column} /> : renderEmptyColumnHeader()}
        {column && prospects && renderProspectList(column)}
      </Box>
    );
  }

  return (
    <Box
      // boxShadow="md"
      // rounded="md"
      borderRight="1.5px solid"
      borderRightColor={"gray.200"}
      minH="60vh"
      minW={200}
      w={{ base: "100%", sm: "50%", md: 300 }}
      p={3}
      // m={2}
      backgroundColor={"inherit"}>
      {renderEmptyColumnHeader()}
    </Box>
  );
};

export default Column;
