import {
  Box,
  Button,
  chakra,
  Container,
  Drawer,
  DrawerBody,
  DrawerContent,
  HStack,
  IconButton,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import {LogoImage} from "../../../components/LogoImage";
import React from "react";
import {useQuery} from "@apollo/client";
import {CURRENT_USER_QUERY} from "../../../graphql/current-user";

export const OnboardingNav = () => {
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  })
  const mobileNavbar = useDisclosure()
  const { data } = useQuery(CURRENT_USER_QUERY);
  const isMobile = useBreakpointValue({ base: true, md: false })

  return (
    <Box borderBottomWidth="1px" bg="bg.surface" position="relative" zIndex="tooltip">
      <Container py="4" maxW={"full"}>
        <HStack justify="space-between">
          <HStack spacing={4}>
            <LogoImage maxWidth={"90px"} />
            {isDesktop && data?.currentUser?.name && <Text>{`Welcome to Kumo, ${data?.currentUser.name}`}</Text>}
          </HStack>
          {/*<Link*/}
          {/*  as={ReactRouterLink}*/}
          {/*  to="/search"*/}
          {/*  color="fg.muted"*/}
          {/*  fontSize="md"*/}
          {/*>*/}
          {/*  Skip for now*/}
          {/*</Link>*/}


        </HStack>
      </Container>
    </Box>
  )
}

export const MobileDrawer = (props) => (
  <Drawer placement="top" {...props}>
    <DrawerContent>
      <DrawerBody mt="16">
        <Stack spacing="6" align="stretch">
          {['Components', 'Pricing', 'Marketplace', 'Support'].map((item) => (
            <Button key={item} size="lg" variant="text" colorScheme="gray">
              {item}
            </Button>
          ))}
          <Button>Sign Up</Button>
        </Stack>
      </DrawerBody>
    </DrawerContent>
  </Drawer>
)

export const ToggleButton = (props) => {
  const { isOpen, ...iconButtonProps } = props
  return (
    <IconButton
      variant="unstyled"
      display="inline-flex"
      size="xs"
      icon={<ToggleIcon active={isOpen} />}
      {...iconButtonProps}
    />
  )
}
const ToggleIcon = (props) => {
  const { active } = props
  return (
    <Box
      color="fg.muted"
      className="group"
      data-active={active ? '' : undefined}
      as="span"
      display="block"
      w="1.5rem"
      h="1.5rem"
      pos="relative"
      aria-hidden
      pointerEvents="none"
    >
      <Bar
        top="0.4375rem"
        _groupActive={{
          top: '0.6875rem',
          transform: 'rotate(45deg)',
        }}
      />
      <Bar
        bottom="0.4375rem"
        _groupActive={{
          bottom: '0.6875rem',
          transform: 'rotate(-45deg)',
        }}
      />
    </Box>
  )
}
const Bar = chakra('span', {
  baseStyle: {
    display: 'block',
    pos: 'absolute',
    w: '1.25rem',
    h: '0.125rem',
    rounded: 'full',
    bg: 'currentcolor',
    mx: 'auto',
    insetStart: '0.125rem',
    transition: 'all 0.12s',
  },
})