import { Container } from "@chakra-ui/react";
import * as React from "react";
import { ResetPasswordForm } from "./reset-password-form";

export const ForgotPasswordPage = () => {
  return (
    <Container maxW="md" py={{ base: "12", md: "24" }}>
      <ResetPasswordForm
        px={{ base: "4", md: "8" }}
        py={{ base: "12", md: "48" }}
        width="full"
        maxW="md"
      />
    </Container>
  );
};
