import { DragHandleIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  FormControl,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Stack,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import equal from "fast-deep-equal";
import produce from "immer";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FiLock, FiPlus, FiTrash } from "react-icons/fi";
import {
  useCreateDealFlowStageMutation,
  useDestroyDealFlowStageMutation,
  useGetDealFlowStagesQuery,
  useUpdateDealFlowStageBatchMutation,
} from "../../../services/backendApi";
import { defaultErrorToastSettings } from "../../../constants/default-error";

const NEWLY_ADDED_PREFIX = "newly-added-";

export const DealFlowStageManager = () => {
  const toast = useToast();
  const [internalState, setInternalState] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);

  const [destroyDealFlowStage] = useDestroyDealFlowStageMutation();
  const [createDealFlowStage] = useCreateDealFlowStageMutation();
  const [updateDealFlowStageBatch] = useUpdateDealFlowStageBatchMutation();

  const {
    data: dealFlowStageResponse,
    isLoading,
    // isFetching, isError
  } = useGetDealFlowStagesQuery();
  const data = dealFlowStageResponse?.data;

  useEffect(() => {
    if (data && data.length > 0) {
      setInternalState(data);
    }
  }, [data]);
  useEffect(() => {
    setHasChanges(!equal(data || [], internalState));
  }, [internalState, data]);

  const handleDragEnd = (args) => {
    if (!args.source || !args.destination) {
      return;
    }

    const state = produce(internalState, (draft) => {
      const stageId = args.draggableId;
      const currentStage = draft.find((s) => s.id === stageId);

      const sourceIndex = args.source.index;
      const destinationIndex = args.destination.index;
      if (currentStage) {
        const removedSourceStages = draft.filter((_, index) => index !== sourceIndex);
        const updatedStages = removedSourceStages
          .slice(0, Number(destinationIndex))
          .concat(currentStage)
          .concat(removedSourceStages.slice(Number(destinationIndex)))
          .map((s, index) => ({
            ...s,
            attributes: {
              ...s.attributes,
              order: index,
            },
          }));
        draft = updatedStages;
        return draft;
      }
    });
    setInternalState(state);
  };

  const handleChange = (stageId, value) => {
    const state = produce(internalState, (draft) => {
      const index = draft.findIndex((s) => s.id === stageId);
      if (index !== undefined) {
        draft[index].attributes.name = value;
      }
    });
    setInternalState(state);
  };

  const handleDelete = async (stageId) => {
    if (String(stageId).includes(NEWLY_ADDED_PREFIX)) {
      const state = produce(internalState, (draft) => {
        const index = draft.findIndex((s) => s.id === stageId);
        if (index !== -1) {
          // remember, findIndex returns -1 if not found
          draft.splice(index, 1);
        }
      });
      setInternalState(state);
    } else {
      try {
        const { data } = await destroyDealFlowStage(stageId); // Destructure the 'data' from the response object

        if (data) {
          // Delete the deal flow stage from the internal state
          const newState = produce(internalState, (draft) => {
            const index = draft.findIndex((s) => s.id === stageId);
            if (index !== -1) {
              // remember, findIndex returns -1 if not found
              draft.splice(index, 1);
            }
          }); // Add closing parenthesis for the 'produce' function
          setInternalState(newState);
        } else {
          toast({
            ...defaultErrorToastSettings,
            title: "Error: Data not available in the response", // Provide a default error message
          });
        }
      } catch (error) {
        toast({
          ...defaultErrorToastSettings,
          title: error.message,
        });
      }
    }
  };

  const handleCancel = () => {
    setInternalState(data || []);
  };

  const handleSave = async () => {
    const toBeCreatedInputs = internalState
      .filter((s) => String(s.id).includes(NEWLY_ADDED_PREFIX))
      .map((s) => ({
        name: s?.attributes?.name,
        order: s?.attributes?.order,
      }));
    const toBeUpdatedInputs = internalState
      .filter((s) => !String(s.id).includes(NEWLY_ADDED_PREFIX))
      .map((s) => ({
        stage_id: s.id,
        name: s?.attributes?.name,
        order: s?.attributes?.order,
      }));

    const createPromises = toBeCreatedInputs.map((input) => createDealFlowStage(input));

    const updatePromises = updateDealFlowStageBatch(toBeUpdatedInputs);

    try {
      // Use Promise.all to execute all the create and update promises in parallel
      await Promise.all([Promise.all(createPromises), updatePromises]);
    } catch (error) {
      // Handle any errors that occurred during the mutation
      console.error("Error:", error);
    }
  };

  const handleAddNewStage = () => {
    const state = produce(internalState, (draft) => {
      draft.push({
        id: NEWLY_ADDED_PREFIX + draft.length.toString(),
        attributes: {
          name: "",
          order: draft.length,
          colorScheme: "red",
          unchangeable: false,
          prospectCounts: 0,
        },
        type: "DealFlowStage",
      });
    });
    setInternalState(state);
  };

  if (isLoading) {
    return (
      <Center w="100%" h="150px">
        <Spinner />
      </Center>
    );
  }

  const canSave = internalState.every((s) => s.name !== "");

  return (
    <Box py={{ base: "2", md: "2" }}>
      <Stack spacing="4">
        <Box>
          <Text color="muted" fontSize="sm">
            Drag-and-drop your Deal Stages here to change the order in which they appear on your
            Pipeline in Kanban view.
          </Text>
        </Box>
        <Stack>
          <StageInputsContainer
            stages={internalState}
            onDragEnd={handleDragEnd}
            onDelete={handleDelete}
            onChange={handleChange}
          />
        </Stack>
        <Stack pt={"15px"}>
          <Button variant="primary" onClick={() => handleAddNewStage()}>
            <Icon as={FiPlus} /> Add a Deal Stage
          </Button>
        </Stack>
        {hasChanges && (
          <HStack alignItems="flex-end" align="flex-end" justify="flex-end">
            <Button variant="outline" onClick={() => handleCancel()}>
              Cancel
            </Button>
            <Tooltip
              label={"Deal stage name cannot be blank. Please try again."}
              isDisabled={canSave}>
              <Box>
                <Button variant="primary" onClick={() => handleSave()} disabled={!canSave}>
                  Save Changes
                </Button>
              </Box>
            </Tooltip>
          </HStack>
        )}
      </Stack>
    </Box>
  );
};

const StageInputsContainer = ({ stages, onDragEnd, onChange, onDelete }) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable type="STAGE_SETTINGS" droppableId="stage-settings">
        {({ droppableProps, innerRef, placeholder }) => (
          <Stack ref={innerRef} {...droppableProps}>
            {stages.map((s, index) => {
              return (
                <StageInput
                  stage={s}
                  key={s.id}
                  index={index}
                  onChange={onChange}
                  onDelete={onDelete}
                />
              );
            })}
            {placeholder}
          </Stack>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const StageInput = ({ stage, index, onChange, onDelete }) => {
  if (stage?.attributes.unchangeable) {
    return (
      <Box>
        <HStack spacing={4}>
          <InputGroup>
            <FormControl>
              <Input
                pl={8}
                id="medium"
                size="md"
                value={stage?.attributes?.name || ""}
                data-peer
                onChange={(ev) => onChange(stage.id, ev.currentTarget.value)}
              />
            </FormControl>
          </InputGroup>
          <Tooltip label={"Inbox cannot be deleted"} closeOnClick={false}>
            <Box>
              <IconButton
                aria-label="Delete Stage"
                disabled
                colorScheme="blue"
                icon={<FiLock color="white" />}
                onClick={() => onDelete(stage.id)}
                color="muted"
              />
            </Box>
          </Tooltip>
        </HStack>
      </Box>
    );
  }
  return (
    <Draggable key={stage.id} draggableId={String(stage.id)} index={index}>
      {(provided) => (
        <Box ref={provided.innerRef} {...provided.draggableProps}>
          <HStack spacing={4}>
            <InputGroup>
              <InputLeftElement justifyContent={"flex-start"}>
                <IconButton
                  aria-label="Drag and Drop Stage"
                  colorScheme="gray"
                  borderRightRadius="0px"
                  minW="30px"
                  icon={<DragHandleIcon w="25px" color="black" />}
                  {...provided.dragHandleProps}
                />
              </InputLeftElement>
              <FormControl>
                <Input
                  pl={"35px"}
                  id="medium"
                  size="md"
                  value={stage?.attributes?.name || ""}
                  data-peer
                  onChange={(ev) => onChange(stage.id, ev.currentTarget.value)}
                />
              </FormControl>
            </InputGroup>
            <Tooltip
              isDisabled={(stage?.attributes.prospect_counts || 0) === 0}
              label="You can't delete this Deal Stage with prospects in it. Please move them to a different deal stage, and try again."
              closeOnClick={false}>
              <Box>
                <IconButton
                  aria-label="Delete Stage"
                  colorScheme="blue"
                  disabled={(stage?.attributes.prospect_counts || 0) > 0}
                  icon={<FiTrash color="white" />}
                  onClick={() => onDelete(stage.id)}
                  color="muted"
                />
              </Box>
            </Tooltip>
          </HStack>
        </Box>
      )}
    </Draggable>
  );
};
