import {
  Box,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  Tooltip
} from "@chakra-ui/react";
import {FiSearch} from 'react-icons/fi'
import {IoArrowDown, IoArrowUp} from 'react-icons/io5'
import React, {useMemo, useState} from 'react';
import numbro from 'numbro';
import {useGetChildIndustriesQuery, useGetSearchableLocationsQuery} from "../../../services/savedSearchesApi";
import {useGetScatterPlotByIndustryOrLocationQuery} from "../../../services/dealAnalyticsApi";
import {Link} from "react-router-dom";

const TableTypes = {
  'industry_id': 'Industry',
  'location_id': 'Location'
}

export const TableByIndustryOrLocation = ({filters, group_by}) => {
  let queryHook;
  let queryOptions;

  queryHook = useGetScatterPlotByIndustryOrLocationQuery;
  queryOptions = {
    filters: filters,
    group_by: group_by,
  };

  const { data, isLoading, isFetching, isError } = queryHook(queryOptions);


  let options = [];
  if (group_by === 'industry_id') {
    const { data: industriesData, isLoading: industriesDataIsLoading, isFetching: industriesDataIsFetching, isError: industriesDataIsError } = useGetChildIndustriesQuery();
    options = industriesData?.data || []
  } else if (group_by === 'location_id') {
    const { data: locationData, isLoading, isFetching, isError } = useGetSearchableLocationsQuery();
    options = locationData?.data || []
  }

  let formattedData = [];
  if (options && options.length > 0 && data ) {
    formattedData = data.map(item => {
      const option = options.find(ind => parseInt(ind.id) === parseInt(item[group_by]));
      return {
        ...option?.attributes,
        ...item,
      }
    })

    return (
      <>
        <TableContainer data={formattedData} group_by={group_by} />
      </>
    )
  } else {
    return (
      <>
        <Text>Loading...</Text>
      </>
    )
  }


}

export const TableContainer = ({data, group_by}) => {
  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  })

  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  const sortedData = useMemo(() => {
    if (!sortConfig.key) return data;

    return [...data].sort((a, b) => {
      // Convert to numbers if they are numeric strings
      const aValue = isNaN(a[sortConfig.key]) ? a[sortConfig.key] : Number(a[sortConfig.key]);
      const bValue = isNaN(b[sortConfig.key]) ? b[sortConfig.key] : Number(b[sortConfig.key]);

      if (aValue < bValue) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  }, [data, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };


  // console.log('sortConfig', sortConfig)
  // console.log('sortedData', sortedData.slice(0, 10))


  return (
    <Box>
      <Box
        bg="bg.surface"
        border={"1px"}
        borderColor={"gray.100"}
        borderRadius={{
          base: 'none',
          md: 'lg',
        }}
      >
        <Stack spacing="5">
          <Box
            px={{
              base: '4',
              md: '6',
            }}
            pt="5"
          >
            <Stack
              direction={{
                base: 'column',
                md: 'row',
              }}
              justify="space-between"
            >
              <Text textStyle="lg" fontWeight="medium">
                {TableTypes[group_by]} Table
              </Text>
              <InputGroup maxW="xs">
                <InputLeftElement pointerEvents="none">
                  <Icon as={FiSearch} color="fg.muted" boxSize="5" />
                </InputLeftElement>
                <Input placeholder="Search" />
              </InputGroup>
            </Stack>
          </Box>
          <Box overflowX="auto">
            <OnlyTable data={sortedData} requestSort={requestSort} sortConfig={sortConfig} group_by={group_by} />
          </Box>
        </Stack>
      </Box>
    </Box>
  )
}

const SortableHeader = ({column, requestSort, sortConfig, toolTip}) => {
  const { label, sortKey } = column;
  const direction = sortConfig.key === sortKey && sortConfig.direction;
  return (
    <Th onClick={() => requestSort(sortKey)} cursor={"pointer"}>
      <HStack spacing="3">
        <Tooltip label={toolTip} aria-label="tooltip">
          <HStack spacing="1">
            <Text>{label}</Text>
            {sortConfig.key === sortKey && (sortConfig.direction === 'ascending' ? <Icon as={IoArrowUp} color="fg.muted" boxSize="4" /> : <Icon as={IoArrowDown} color="fg.muted" boxSize="4" />)}
          </HStack>
        </Tooltip>
      </HStack>
    </Th>
  )
}
const columns = {
  name: {
    label: 'Name',
    sortKey: 'name',
    dataType: 'string',
    toolTip: 'State (US only) or country',
  },
  number_of_deals: {
    label: '# deals',
    sortKey: 'num_deals',
    dataType: 'integer',
    toolTip: 'Total number of active deals',
  },
  asking_price: {
    label: 'Asking Price',
    sortKey: 'avg_sortable_asking_price',
    dataType: 'money',
    toolTip: 'Average asking price',
  },
  multiple: {
    label: 'Multiple',
    sortKey: 'avg_multiple',
    dataType: 'decimal',
    toolTip: 'Average multiple (price to SDE or EBITDA)',
  },
  revenue: {
    label: 'Revenue',
    sortKey: 'avg_revenue',
    dataType: 'money',
    toolTip: 'Average revenue',
  },
  days_on_market: {
    label: 'Days on Market',
    sortKey: 'avg_days_on_market_to_delist',
    dataType: 'integer',
    toolTip: 'Average days on market',
  },
}

const formatData = (value, dataType) => {
  if (value === null || value === "") return null;

  const numberFormat = {
    average: true,
    totalLength: 2,
    // mantissa: 2,
  };

  switch (dataType) {
    case 'money':
      return numbro(value).formatCurrency(numberFormat);
      // return numbro(value).formatCurrency({mantissa: 2});
    case 'integer':
      return numbro(value).format({ thousandSeparated: true, mantissa: 0 });
    case 'decimal':
      return numbro(value).format({ mantissa: 1 });
    default:
      return value;
  }
};

export const OnlyTable = ({data, requestSort, sortConfig, group_by, ...otherProps}) => {
  const baseUrl = group_by === 'industry_id' ? '/analytics/industries' : '/analytics/locations'
  return (
    <Table {...otherProps}>
      <Thead>
        <Tr>
          {Object.keys(columns).map((key) => {
            const column = columns[key];
            return <SortableHeader key={key} column={column} requestSort={requestSort} sortConfig={sortConfig} toolTip={column['toolTip']} />
          })}
        </Tr>
      </Thead>
      <Tbody>
        {data.map((row) => (
          <Tr key={row[group_by]}>
            {Object.keys(columns).map((key) => {
              const cellValue = row[columns[key].sortKey];
              const formattedValue = formatData(cellValue, columns[key].dataType);
              console.log(row.id, row)
              if (key === 'name') {
                return (
                  <Td key={`${row[group_by]}-${key}`}>
                    <Box
                      as={Link}
                      to={`${baseUrl}/${row[group_by]}`}
                      cursor={"pointer"}
                    >
                      <Text fontWeight="medium">{formattedValue}</Text>
                    </Box>
                  </Td>
                )
              } else {
                return (
                  <Td key={`${row.id}-${key}`}><Text fontWeight="medium">{formattedValue}</Text></Td>
                )
              }

            })}
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}
